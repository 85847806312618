import React from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import ReactTable from "react-table";

import Pagination from "../../components/DatatablePagination";

const img_base_url = 'https://api.tuatuagye.com/get-image?type=product&id='

const BestSellers = ({ data, title="dashboards.best-sellers"}) => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: props => <Row style={{marginLeft:0,marginRight:0,alignItems:'center'}}>
        <img 
          style={{width:50,height:50,borderRadius:3,marginRight:10,backgroundColor:'#ccc'}} 
          src={`${img_base_url}${props.original.thumbnail}`}
          // src="https://www.exeind.com/wp-content/uploads/2017/02/Nasco-32.jpg" 
          alt=""
        />
        <span style={{fontSize:16}}>{props.value}</span>
      </Row>
    },
    // {
    //   Header: "Sales",
    //   accessor: "quatity",
    //   width: 70,
    //   Cell: props => <p className="text-muted" style={{paddingTop:25}}>GH₵ {props.value || 0}</p>
    // },
    // {
    //   Header: "Sold",
    //   accessor: "sold",
    //   width:70,
    //   headerStyle: {textAlign:'center'},
    //   Cell: props => <p className="text-muted" style={{paddingTop:25,textAlign:'center'}}>{props.value || 0}</p>
    // },
    {
      Header: "Category",
      accessor: "category",
      width: 90,
      Cell: props => <p className="text-muted" style={{paddingTop:25}}>{'Electronics'}</p>
    }
  ];
  return (
    <Card className="h-100">
      <CardBody>
        <CardTitle>
          Top Selling Products
        </CardTitle>
        <ReactTable
          defaultPageSize={6}
          data={data.slice(0, 12)}
          columns={columns}
          minRows={0}
          showPageJump={false}
          showPageSizeOptions={false}
          PaginationComponent={Pagination}
        />
      </CardBody>
    </Card>
  );
};
export default BestSellers;
