import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Row } from "reactstrap";
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import { Colxx } from "../../../components/common/CustomBootstrap";

import {
  getContacts,
  getConversations,
  changeConversation,
  addMessageToConversation,
  addToMessages,
  getMessages,
  sendingMessage,
  updateConversation
} from "../../../redux/actions";
import ChatApplicationMenu from "../../../containers/applications/ChatApplicationMenu";
import ChatHeading from "../../../components/applications/ChatHeading";
import MessageCard from "../../../components/applications/MessageCard";
import SaySomething from "../../../components/applications/SaySomething";
import { NotificationManager } from "../../../components/common/react-notifications";

class ChatApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActiveTab: "chats",
      messageInput: ""
    };
  }

  componentDidMount() {
    const search = this.props.location.search;

    if(search){
      let id = search.split('=');
      id = id[1];

      axios.get(`https://api.tuatuagye.com/users/${id}`)
      .then(async res => {
        let cht = await axios.get(`https://api.tuatuagye.com/chats?filter[where][ownerId]=${id}`);
        if(cht && cht.data && cht.data[0]){
          this.props.changeConversation({
            ...res.data,
            pushToken: cht.data[0].pushToken,
            platform: cht.data[0].platform,
            chatId: cht.data[0].id,
            unreadCount: cht.data[0].unreadCount
          });
        }
        else {
          this.props.changeConversation(res.data);
        }

        this.props.getMessages(res.data.id);
      })
      .catch(e => {
        console.log('eror',e);
        // alert('Failed getting chat info')
        NotificationManager.error(
          "Error",
          "Could not load chat details",
          3000,
          null,
          null,
        );
      })
    }

    const currentUserId = 0;
    this.props.getContacts();
    this.props.getConversations(currentUserId);
  }

  componentDidUpdate() {
    if (
      this.props.chatApp.loadingConversations &&
      this.props.chatApp.loadingContacts &&
      this.props.chatApp.selectedUser == null
    ) {
      // this.props.changeConversation(this.props.chatApp.selectedUserId);
    }

    if (this._scrollBarRef) {
      this._scrollBarRef._ps.element.scrollTop = this._scrollBarRef._ps.contentHeight;
    }
  }

  handleChatInputPress = e => {
    const { user } = this.props;
    if (e.key === "Enter") {
      if (this.state.messageInput.length > 0) {
        // this.props.addMessageToConversation(
        //   this.props.chatApp.currentUser.id,
        //   this.props.chatApp.selectedUser.id,
        //   this.state.messageInput,
        //   this.props.chatApp.conversations
        // );
        const msg = {
          body: this.state.messageInput,
          senderId: user.id,
          senderName: `${user.firstname} ${user.lastname}`,//(this.props.chatApp.currentUser && this.props.chatApp.currentUser.firstname )? `${this.props.chatApp.currentUser.firstname} ${this.props.chatApp.currentUser.lastname}`:'Test Dev',
          receiverId: this.props.chatApp.selectedUser.ownerId || this.props.chatApp.selectedUser.id,
          imageId: null,
          timestamp: new Date().toJSON(),
          sent: true,
          received: false,
          read: false
        }
        // this.props.addToMessages(msg)
        this.setState({
          messageInput: "",
          menuActiveTab: "chats"
        });
        this.sendMessage(msg)
      }
    }
  };

  handleChatInputChange = e => {
    this.setState({
      messageInput: e.target.value
    });
  };

  handleSendButtonClick = () => {
    const { user } = this.props;
    if (this.state.messageInput.length > 0) {
      const msg = {
        body: this.state.messageInput,
        senderId: user.id,
        senderName: `${user.firstname} ${user.lastname}`,
        receiverId: this.props.chatApp.selectedUser.ownerId || this.props.chatApp.selectedUser.id,
        imageId: null,
        timestamp: new Date().toJSON(),
        sent: false,
        received: false,
        read: false
      }
      // this.props.addToMessages(msg);
      this.setState({
        messageInput: "",
        menuActiveTab: "chats"
      });

      // console.log('payload to send',msg);
      this.sendMessage(msg)
    }
    // else alert('Type something...')
  };

  sendMessage = (msg) => {
    const payload = {
      body: msg.body,
      senderId: msg.senderId,
      senderName: msg.senderName,
      receiverId: msg.receiverId,
      sent: true
    }
    
    this.props.sendingMessage(true);
    const { unreadCount, chatId, pushToken, platform } = this.props.chatApp.selectedUser

    axios.post('https://api.tuatuagye.com/messages',payload)
    .then(async res => {
      // console.log('done sending');
      // alert('msg sent')
      this.props.addToMessages(res.data);
      this.props.sendingMessage(false);

      if(chatId){
          await axios.patch(`https://api.tuatuagye.com/chats/${chatId}`,{unreadCount: unreadCount+1, lastMessage: res.data, createdAt: new Date()})
          this.props.updateConversation({...this.props.chatApp.selectedUser, lastMessage: res.data})
      }
      else {
        // console.log('else part sel user',this.props.chatApp.selectedUser.ownerId);
        //TODO, reaffirm flow for creating new chat
        //axios.post(`https://api.tuatuagye.com/chats`,{unreadCount: unreadCount+1, lastMessage: res.data})
      }

      if(pushToken){
        const pushPayload = {
          ...res.data, 
          type: 'MSG'
        }

        delete pushPayload['sent'];

        await axios.post('https://api.tuatuagye.com/do-push',{ token: pushToken, data: pushPayload, platform })
      }
      
      // if(window.wsClient){
      //   if(window.wsClient.OPEN){
      //     // console.log('is open... send. pl',res.data);
      //     window.wsClient.send(JSON.stringify({...res.data, fromAdmin: true, type: 'MSG'}))
      //   }
      //   else {
      //     this.props.sendingMessage(false);
      //     // console.log('naaa, not open');
      //   }
      // }
    })
    .catch(e=> {
      // console.log('error...',e);
      // alert('error sending msg')
      NotificationManager.error(
        "Error",
        "Could not send message. Please try again.",
        3000,
        null,
        null,
      );
    })
  }

  toggleAppMenu = tab => {
    this.setState({
      menuActiveTab: tab
    });
  };

  render() {
    const {
      allContacts,
      conversations,
      loadingConversations,
      loadingContacts,
      currentUser,
      selectedUser,
      messages,
      loadingMessages
    } = this.props.chatApp;

    const { menuActiveTab, messageInput } = this.state;
    const { user } = this.props;
    // const { messages } = this.props.intl;

    // console.log('User is',selectedUser);

    // const selectedConversation =
    //   !loadingConversations && !loadingContacts && selectedUser && currentUser
    //     ? 
    //     conversations.find(
    //         x =>
    //           x.users.includes(currentUser.id) &&
    //           x.users.includes(selectedUser.id)
    //       )
    //     : null;
    return !loadingConversations ? (
      <Fragment>
        <Row className="app-row">
          <Colxx xxs="12" className="chat-app">
            {selectedUser && (
              <ChatHeading
                userId={selectedUser.ownerId || selectedUser.id}
                name={selectedUser.firstname ? `${selectedUser.firstname} ${selectedUser.lastname}`: selectedUser.ownerName}
                thumb={selectedUser.thumb}
                lastSeenDate={''}
                // lastSeenDate={selectedUser.lastSeen || ''}
              />
            )}

            {loadingMessages ? <span>Loading messages...</span> : messages.length > 0 &&
            (
              <PerfectScrollbar
                ref={ref => {
                  this._scrollBarRef = ref;
                }}
                containerRef={ref => {}}
                option={{ suppressScrollX: true, wheelPropagation: false }}
              >
                {/* {selectedConversation.messages.map((item, index) => { */}
                {selectedUser && messages.filter(m=> (m.senderId === selectedUser.ownerId || m.receiverId === selectedUser.ownerId) || (m.senderId === selectedUser.id || m.receiverId === selectedUser.id)).map((item, index) => {
                  // const sender = allContacts.find(x => x.id === "f7bfed10-4d62-4987-a369-88df9574bcb8") //DEV
                    // item.sender);
                  return (
                    <MessageCard
                      key={index}
                      sender={user}
                      item={item}
                      currentUserid={user.id}
                      selectedUser={selectedUser}
                      // currentUserid="f7bfed10-4d62-4987-a369-88df9574bcb8"
                    />
                  );
                })}
              </PerfectScrollbar>
            )}
          </Colxx>
        </Row>
        {selectedUser && 
          <SaySomething
            placeholder={"Say something..."}
            messageInput={messageInput}
            handleChatInputPress={this.handleChatInputPress}
            handleChatInputChange={this.handleChatInputChange}
            handleSendButtonClick={this.handleSendButtonClick}
            sendingMessage={this.props.chatApp.sendingMessage}
          />
        }
        {!selectedUser && <div style={{height:500,display:'flex',flexDirection:'column',marginRight:250,justifyContent:'center',alignItems:'center'}}>
          <img style={{width:300,height:300,borderRadius:5,marginRight:20}} 
            src='/assets/img/chat2.png' 
            alt=''/>
          <p style={{fontSize:16,marginTop:20}}>Select an existing chat or user</p>
          </div>
        }
        <ChatApplicationMenu
          user={user}
          activeTab={menuActiveTab}
          toggleAppMenu={this.toggleAppMenu}
        />
      </Fragment>
    ) : (
      <div className="loading" />
    );
  }
}

const mapStateToProps = ({ chatApp, authUser }) => {
  const { user } = authUser;
  return { chatApp, user };
};
export default withRouter(
  connect(
    mapStateToProps,
    {
      getContacts,
      getConversations,
      changeConversation,
      addMessageToConversation,
      addToMessages,
      getMessages,
      sendingMessage,
      updateConversation
    }
  )(ChatApp)
);
