import {
    ADD_NAV_MESSAGE,
    REMOVE_NAV_MESSAGE
} from '../actions';

const INIT_STATE = {
    entities: [
        // {
//         "id": "193a0fee-f5ce-4d1f-acdf-a5d600e763c6",
// "body": "Quick question for me 🤣",
// "senderId": "3c19f350-d836-48be-90a3-cf8cc7d47432",
// "senderName": "Kingsford Asimadu",
// "receiverId": "__admin__",
// "imageId": null,
// "timestamp": "2021-02-24T20:38:50.088Z",
// "sent": true,
// "received": false,
// "read": false
    // }
]
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case ADD_NAV_MESSAGE:
            const temp = state.entities.map(m => m);
            temp.unshift(action.payload);
            return Object.assign({}, state, {
				entities: temp
			})

		case REMOVE_NAV_MESSAGE:
            return Object.assign({}, state, {
                entities: state.entities.filter(m=> m.id !== action.payload.id)
            })

		default: return { ...state };
	}
}
