import React, { Component } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      processing: false
    };
  }
  
  handleSubmit(){
    const { email } = this.state;

    if(!email){
      alert('Email required')
      return;
    }
    
    
  }
  render() {
    const { processing } = this.state;
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="4" className="mx-auto my-auto">
          <Card className="auth-card">
            {/* <div className="position-relative image-side ">
              <p className="text-white h2">MAGIC IS IN THE DETAILS</p>
              <p className="white mb-0">
                Please use your e-mail to reset your password. <br />
                If you are not a member, please{" "}
                <NavLink to={`/register`} className="white">
                  register
                </NavLink>
                .
              </p>
            </div> */}
            <div className="form-side" style={{paddingTop:0}}>
              <div style={{marginBottom:70,position:'relative',backgroundColor:'gold'}}>
                <img src="/assets/img/logo.png" style={{width:70,height:70,padding:7,borderRadius:35,backgroundColor:'#fff',position:'absolute', top:'50%',left:'50%', marginRight:'-50%', transform: 'translate(-50%, -50%)'}}/>
              </div>
              <CardTitle className="mb-4">
                <IntlMessages id="user.forgot-password" />
              </CardTitle>
              <Form>
                <Label className="form-group has-float-label mb-4">
                  <Input type="email" defaultValue={this.state.email} />
                  <IntlMessages id="user.email" />
                </Label>

                <div className="d-flex justify-content-between align-items-center">
                <NavLink to={`/user/login`}>
                    Login
                  </NavLink>
                  <Button
                    disabled={processing}
                    onClick={()=> this.handleSubmit()}
                    // href="/app"
                    color="primary"
                    className="btn-shadow"
                    size="lg"
                  >
                    {processing ? 'Please wait...':'Reset'}
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
