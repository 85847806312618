import React, { Component, Fragment } from "react";
import { Card, CardBody, CardTitle, Row, Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter } from "reactstrap";
import ReactTable from "react-table";
import axios from 'axios';
import { connect } from "react-redux";
import Select from "react-select";

import Pagination from "../components/DatatablePagination";
import { NotificationManager } from "../components/common/react-notifications";
import CustomSelectInput from "../components/common/CustomSelectInput";

const message_url = 'https://api.tuatuagye.com/broadcast-message'
const broadcasts_url = 'https://api.tuatuagye.com/broadcast-messages'
const __getTime = (_date) => {
    // 3:16pm
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    // let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime}`;
}
  
const __getDate = (_date) => {
    // Mar 2, 2024
    if(!_date) return '';
    const date = new Date(_date);
  
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

class SMSBroadcasts extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            fullscreenImageModalOpen: false,
            processing: false,
            selectedMarket: {},
            message:'',
            file: null,
            loading: false,
            adminCode: '',
            messages: [],
            locations: [],
            name: '',
            name2:'',
            category: {},
            selectedCategory: {},
            selectedType: {},
            subCategories: [
                { label: "Admins", value: "admins", key: 3 },
                { label: "Merchants", value: "merchants", key: 4 },
                { label: "Self-Service Customers", value: "self-service", key: 5 },
                { label: "POS Customers", value: "pos", key: 6 },
                { label: "All Users", value: "all", key: 9},
                { label: "In-active Customers (last payment 3months ago)", value: "inactive-1", key: 10},
                { label: "Dormant Customers (last payment 2months ago)", value: "inactive-2", key: 11},
                { label: "Latent Customers (last payment 1month ago)", value: "inactive-3", key: 12}
            ],
            messageTypes: [
                { label: "Text Message (SMS)", value: 1, key: 1 },
                { label: "Push Notification", value: 2, key: 2 },
            ]
        }

        this.inp = null;

        this.columns = [
            {
            Header: "Message",
            accessor: "body",
            Cell: props => <Row style={{marginLeft:0,marginRight:0,alignItems:'center'}}>
                <span style={{fontSize:16}}>{props.value}</span>
            </Row>
            },
            {
                Header: "Sender",
                accessor: "senderName",
                Cell: props => <p className="text-muted">{props.value}</p>
            },
            {
                Header: "Mode",
                accessor: "mode",
                Cell: props => <p className="text-muted">{props.value}</p>
            },
            {
                Header: "Date & Time",
                accessor: "createdAt",
                width: 115,
                headerStyle:{textAlign:'center'},
                Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{__getTime(props.value)}<br/>{__getDate(props.value)}</p>
            },
        ];
    }

    componentDidMount() {
        axios.get(broadcasts_url)
            .then(res=> {
                this.setState({
                    messages: res.data
                })
            })
            .catch(err=> {
                this.setState({loading: false});

                NotificationManager.error(
                    "Loading Error",
                    "An unknown error occurred. Please try again.",
                    3000,
                    null,
                    null,
                );
            })
    }

    toggleFullScreenImageModal = () => {
        this.setState({fullscreenImageModalOpen: !this.state.fullscreenImageModalOpen, adminCode: ''})
    }
        
    handleSubmit = async () => {
        this.setState({processing:true});
        const { selectedCategory, selectedType, message, messages } = this.state;
        // { message, type, userType, inActive, data }
        const payload = {
            message, 
            userType: selectedCategory.key,
            type: selectedType.key,
            inActive: selectedCategory.key === 10,
            data: {
                title: 'Notification',
                body: message
            }
        };

        axios.post(message_url,payload)
            .then(r=> {
                // console.log('message done',r.data);
                this.toggleFullScreenImageModal()
                this.setState({message: '', selectedCategory: {}});
                NotificationManager.success(
                    "Request successful",
                    "SMS batch processing request submitted successfully.",
                    3000,
                    null,
                    null,
                );
                
                return axios.post(broadcasts_url, {
                    body: payload.message,
                    senderName: `${this.props.user.firstname} ${this.props.user.lastname}`,
                    senderId: this.props.user.id,
                    mode: payload.type === 1 ? 'Text Message (SMS)' : 'Push Notification'
                }) 
            })
            .then(r=> {
                if(r.data && r.data.id){
                    const tmp = messages.map(m=> m);
                    tmp.unshift(r.data);
                    this.setState({messages: tmp, processing: false})
                }
            })
            .catch(error=> {
                // console.log('error',error);
                this.setState({processing:false});
                NotificationManager.error(
                    "Message Error",
                    "An unknown error occurred. Please check console for details or try again.",
                    3000,
                    null,
                    null,
                );
            })
    }

    handleChange = (val) => {
        this.setState({selectedCategory: val})
    }

    handleTypeChange = (val) => {
        this.setState({selectedType: val})
    }

    render() {
        const { fullscreenImageModalOpen, subCategories, messageTypes, message, loading, adminCode, processing, selectedCategory, messages } = this.state;
        return (
            <Fragment>
                <Card className="h-100">
                    <CardBody>
                        <CardTitle>
                            Message Broadcasts
                        </CardTitle>
                        {loading ? 
                            <p>Loading...</p>
                            :
                            <div>
                                <Button
                                    color="primary"
                                    onClick={()=> {
                                        this.toggleFullScreenImageModal();
                                    }}
                                    style={{marginBottom :20}}
                                >
                                    Add Message
                                </Button>
                                <ReactTable
                                    defaultPageSize={6}
                                    data={messages}
                                    columns={this.columns}
                                    minRows={0}
                                    showPageJump={false}
                                    showPageSizeOptions={false}
                                    PaginationComponent={Pagination}
                                />
                            </div>
                        }
                    </CardBody>

                    <Modal
                        isOpen={fullscreenImageModalOpen}
                        toggle={this.toggleFullScreenImageModal}
                    >
                        {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
                        <div className="loading" />  
                        </div>}
                        <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleFullScreenImageModal}>
                            Create & Send New Message
                        </ModalHeader>
                        <ModalBody style={{paddingVertical:40}}>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                placeholder="Select group/section"
                                className="react-select"
                                classNamePrefix="react-select"
                                // closeMenuOnSelect={false}
                                // isMulti
                                name="select-paymenttype-options"
                                value={this.state.selectedCategory}
                                onChange={this.handleChange}
                                options={subCategories}
                            />
                            <textarea rows={4} type="text" value={message} style={{padding:10,outline:'none',width:'100%',fontSize:16,marginTop:30,marginBottom:30}} onChange={(event)=> this.setState({message: event.target.value})}></textarea>

                            <Select
                                components={{ Input: CustomSelectInput }}
                                placeholder="Select broadcast type"
                                className="react-select"
                                classNamePrefix="react-select"
                                name="select-paymenttype-options"
                                value={this.state.selectedType}
                                onChange={this.handleTypeChange}
                                options={messageTypes}
                            />

                            <label style={{marginTop:30}}>
                                Authorization Code:
                                <input type="password" value={adminCode} style={{marginLeft:10,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                            </label>
                        </ModalBody>
                        <ModalFooter>
                        <Button
                            color=""
                            onClick={this.toggleFullScreenImageModal}
                        >
                            Close
                        </Button>

                        <Button
                            color="primary"
                            onClick={()=> {
                                if(!selectedCategory.key){
                                    NotificationManager.error(
                                        "Category",
                                        "Please select a category.",
                                        3000,
                                        null,
                                        null,
                                    );
                                }
                                else if(!message || message.length < 5){
                                    NotificationManager.error(
                                        "Message",
                                        "Please message should be at least 5 characters.",
                                        3000,
                                        null,
                                        null,
                                    );
                                }
                                else if(!adminCode){
                                    NotificationManager.error(
                                        "Authorization Code",
                                        "Your security code required to perform this transaction. Thank you.",
                                        3000,
                                        null,
                                        null,
                                    );
                                }
                                else if(adminCode !== this.props.user.authCode) {
                                    NotificationManager.error(
                                        "Invalid Code",
                                        "Sorry, the code you entered is invalid. Check and try again.",
                                        3000,
                                        null,
                                        null,
                                    );
                                }
                                else {
                                    this.handleSubmit();
                                }
                            }}
                            >
                            Send
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Card>
            </Fragment>
        );
    }
};

const mapStateToProps = ({ authUser }) => {
    const { user } = authUser;
    return {user};
  };
  
  export default connect(
    mapStateToProps,
    {}
  )(SMSBroadcasts);