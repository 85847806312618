import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";

import { Colxx } from "../../components/common/CustomBootstrap";
import { _getDates } from '../../helpers/Utils';

import TopMobileBanners from "../../containers/dashboards/TopMobileBanners";
import Markets from "../../containers/dashboards/Markets";
import Locations from "../../containers/dashboards/Locations";
import TopWebBanners from "../../containers/dashboards/TopWebBanners";
import MainCategories from "../../containers/MainCategories";
import SubCategories from "../../containers/SubCategories";
import Categories from "../../containers/Categories";
import SMSBroadcasts from "../../containers/SMSBroadcasts";

class Settings extends Component {
  constructor(props){
    super(props);

    this.state = {
      sales: [],
      recentDeliveries: [],
      pendingDeliveriesCount: 0,
      completedDeliveriesCount: 0,
      refundRequest: 0,
      paymentStats: [],
      logs:[],
      newUsers: [],
      topSellingProducts: [],
      topBanners: []
    }
  }
  componentDidMount() {

  }

  render() {
    const { sales, recentDeliveries, topSellingProducts, topBanners, newUsers, logs, completedDeliveriesCount, pendingDeliveriesCount, refundRequest, paymentStats } = this.state;
    // const {messages} = this.props.intl;

    return (
      <Fragment>
        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <TopMobileBanners data={topBanners} />
          </Colxx>
        </Row>

        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <TopWebBanners />
          </Colxx>
        </Row>

        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <Markets />
          </Colxx>
        </Row>

        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <Locations />
          </Colxx>
        </Row>

        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <MainCategories />
          </Colxx>
        </Row>

        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <SubCategories />
          </Colxx>
        </Row>

        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <Categories />
          </Colxx>
        </Row>

        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <SMSBroadcasts />
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}
export default Settings;