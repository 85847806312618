import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Row, Card, CardBody, Input,Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,Label } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import axios from 'axios';
import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import DispatchAutoSuggest from "../../../containers/DispatchAutoSuggest";
import ProductsAutoSuggest from "../../../containers/ProductsAutoSuggest";
import * as FileSaver from 'file-saver';

import { NotificationManager } from "../../../components/common/react-notifications";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';


import {
  setContainerClassnames,
  changeDefaultClassnames
} from "../../../redux/actions";

const selectData = [
  { label: "All", value: "all", key: 0 },
  { label: "Pending", value: "pending", key: 1 },
  { label: "Shipped", value: "shipped", key: 2 },
  { label: "Delivered", value: "delivered", key: 3 }
];

const getCode = () => {
  let code = '';
  [1, 1, 1, 1, 1, 1].forEach(element => {
    const num = Math.floor(Math.random() * 9) + 1;
    code += num;
  });
  
  return code;
}

const formatDate = (_date) => {
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`; // ${date.getFullYear()}
}

const formatAmount = (amount) => {
  if(!amount) return '0.00';
  if(amount > 999){
    let temp = amount.toString();
    let decimal = '.00';

    if(temp.indexOf('.') > -1){
      decimal = '.'+temp.split('.')[1];
      temp = temp.split('.')[0];
    }
    
    temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
    return temp;
  }
  
  return Number(amount).toFixed(2);
}

const products_url = 'https://api.tuatuagye.com/products';
const deliveries_url = 'https://api.tuatuagye.com/deliveries'
const dispatch_url = 'https://api.tuatuagye.com/dispatches';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      confirmModal:false,
      deliveredModal: false,
      modalViewType:'',
      searchKeyword:'',
      adminCode: '',
      fetchingData: true,
      selectedOption: selectData[0],
      dispatchModalOpen:false,
      productModalOpen:false,
      selectedDispatch:null,
      selectedProduct: null,
      selectedItem: {},
      data: [],
      loading: true,
      fee: '',
      location:'',
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
      dateModal: false,
      pageSize: 50
    }

    this.timer = 0;

    this.columns =   [
      {
        Header: "Customer",
        accessor: "buyerName",
        Cell: props => <Row style={{marginLeft:0,marginRight:0,alignItems:'center'}}>
          <span style={{fontSize:16,color:'#000'}}>{props.value}</span>
        </Row>
      },
      {
        Header: "Product",
        accessor: "productName",
        Cell: props => <p style={{paddingTop:0}}>{props.value}</p>
      },
      
      {
        Header: "Delivery Location",
        accessor: "deliveryLocation",
        headerStyle:{textAlign:'center'},
        Cell: props => <p className="text-muted"  style={{paddingTop:0,textAlign:'center'}}>{props.value || 'N/A'}</p>
      },
      {
        Header: "Delivery Fee",
        accessor: "fee",
        headerStyle:{textAlign:'center'},
        width:120,
        Cell: props => <p className="text-muted"  style={{paddingTop:0,textAlign:'center'}}>{props.value ? Number(props.value).toFixed(2) : 'N/A'}</p>
      },
      // {
      //   Header: "Quantity",
      //   accessor: "quantity",
      //   headerStyle:{textAlign:'center'},
      //   width:80,
      //   Cell: props => <p className="text-muted"  style={{paddingTop:0,textAlign:'center'}}>{props.value}</p>
      // },
      {
        Header: "Date Paid",
        accessor: "createdAt",
        width:150,
        Cell: props => <p className="text-muted"  style={{paddingTop:0}}>{this.__formatDate(props.value)}</p>
      },
      {
        Header: "Date Delivered",
        accessor: "deliveredDate",
        width:150,
        Cell: props => <p className="text-muted"  style={{paddingTop:0}}>{props.value ? this.__formatDate(props.value) : 'N/A'}</p>
      },
      {
        Header: "Status",
        accessor: "status",
        width:110,
        Cell: props => <div style={{paddingTop:0}}><span style={{backgroundColor: props.value === 1 ? '#B7950B' : props.value === 2 ? '#2E86C1' : '#117A65',color:'#fff',paddingRight:7,paddingLeft:7,paddingTop:5,paddingBottom:5,borderRadius:4}}>{props.value === 1 ? 'Pending' : props.value === 2 ? 'Shipped' : props.value === 3 ? 'Delivered':'Confirmed'}</span></div>
      }
    ]

    if((props.user.type === 1) || (props.user.phone === '0556527173')){
      this.columns.push(
        {
          Header: "Action",
          accessor: "status",
          width:110,
          Cell: props => <Row style={{paddingLeft:"12px",paddingTop:0}}>
            {props.value === 1 && 
              <div
                onClick={()=> {
                  this.setState({modalViewType:'DISPATCH',selectedItem: props.original, selectedDispatch:null,selectedProduct:null,adminCode:''});
                  this.toggleModal();
                }}
                style={{cursor:'pointer',fontSize:17}}  
                className={"glyph-icon iconsminds-yes"} 
              />
            }
            {props.value === 1 && (
              <div
                onClick={()=> {
                  // this.setState({selectedCollection: props.original, deleteModal: true})
                  const response = prompt('Are you sure you want to delete this delivery? If you want to continue deleting, type YES');

                  if(response === 'YES'){
                    const adminCode = prompt('Kindly input your admin code');

                    if(adminCode === this.props.user.authCode){
                      if(props.original.id){
                        // axios.delete(`${deliveries_url}/${props.original.id}`)
                        //   .then(res=> {
                        //     const temp = this.state.data.filter(dl=> dl.id !== props.original.id);
                        //     this.setState({data: temp});

                        //     NotificationManager.success('Delete Done','Delete successful',4000,null,null,null);
                        //   })
                        //   .catch(err=> {
                        //     console.log('error deleting',err)
                        //     NotificationManager.error(
                        //       "Delete error!",
                        //       "An unknown error occurred. Please try again.",
                        //       3000,
                        //       null,
                        //       null,
                        //     );
                        //   })
                      }
                      else {
                        NotificationManager.error(
                          "Delete error!",
                          "Sorry, an unknown error occurred. Please report to admin.",
                          3000,
                          null,
                          null,
                        );
                      }
                    }
                    else if(!!adminCode){
                      alert('Wrong admin code')
                    }
                  }
                  else if(!!response) alert('Invalid response')
                }}
                style={{color:'red',cursor:'pointer',fontSize:16,marginLeft:25,marginRight:10,marginTop:4}} 
                className={"glyph-icon simple-icon-trash"} 
              />
            )}
            {props.value === 2 && 
              <div
                title="Confirm Delivery"
                onClick={()=> {
                  this.setState({modalViewType:'DISPATCH',selectedItem: props.original, selectedDispatch:null,selectedProduct:null,adminCode:''});
                  this.toggleDeliveredModal();
                }}
                style={{cursor:'pointer',fontSize:19,marginRight:15}}  
                className={"glyph-icon iconsminds-box-close"} 
              />
            }
            {/* {props.value === 1 && 
              <div 
                onClick={()=> {
                  this.setState({modalViewType:'PRODUCT',selectedItem: props.original, selectedDispatch:null,selectedProduct:null});
                  this.toggleModal();
                }}
                style={{cursor:'pointer',fontSize:17,marginLeft:15}} 
                className={"glyph-icon iconsminds-sync"} 
              />
            } */}
            {(props.value === 2 || props.value === 3) && 
              <NavLink
                to={`/app/deliveries/dispatch/${props.original.dispatchId}`}>
                  <div 
                    title="View Dispatch"
                    style={{fontSize:16,paddingTop:5}} 
                    className={"simple-icon-info"} 
                />
              </NavLink>
            }
            {(props.original.type === 1) && (
              <NavLink
                to={`/app/users/customers/${props.original.buyerId}`}>
                <div 
                  title="View Customer"
                  style={{fontSize:17,marginLeft:15}} 
                  className={"glyph-icon iconsminds-male"} 
                />
              </NavLink>
            )}
          </Row>
        }
      )
    }
  }

  componentDidMount() {
    const splits = window.location.href.split('=');
    if(splits.length === 1){
      this.loadData();
    }
    else {
      const _tag = splits[1]
      if(!!_tag){
        const status = _tag === 'pending' ? 1 : _tag === 'shipped' ? 2 : _tag === 'completed' ? 3 : 0;
        
        let query = `filter[where][status]=${status}&filter[order]=createdAt%20DESC`;

        axios.get(`${deliveries_url}?${query}`)
          .then(res=> {
            const len = res.data.length;
            let pgsz = len < 150 ? 15 : Math.ceil(len/15);
            this.setState({data: res.data, pageSize: pgsz, loading:false, fetchingData: false})
          })
          .catch(err => {
            // console.log('error loading product',err)
            NotificationManager.error(
              "Loading error!",
              "An unknown error occurred. Please refresh page.",
              3000,
              null,
              null,
            );
          })
      }
      else this.loadData()
    }
  }

  loadData = () => {
    this.setState({loading: true});

    const {startDate, endDate} = this.state.selectionRange;
    let start = startDate;
    start.setHours(0)
    start.setMinutes(0);
    start.setSeconds(0);
    start = start.toJSON();

    let end = new Date(endDate);
    end.setHours(23)
    end.setMinutes(59);
    end.setSeconds(59);
    end = end.toJSON();

    let query = `filter[where][and][0][createdAt][gte]=${start}&filter[where][and][1][createdAt][lte]=${endDate}&filter[order]=createdAt%20DESC&filter[limit]=100`;

    axios.get(`${deliveries_url}?${query}`)
      .then(res=> {
        this.setState({data: res.data, loading:false, fetchingData: false})
      })
      .catch(err => {
        console.log('error loading product',err)
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
      })
  }

  handleSelect = (ranges) => {
		this.setState({selectionRange: {
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
			key: 'selection',
		}})
	}

  toggleDateModal = () => {
    this.setState(prevState => ({
      dateModal: !prevState.dateModal,
    }));
  };

  handleFilterChange = selectedOption => {
    this.setState({ selectedOption });

    this.filter(selectedOption.key)
  };

  toggleModal = () => {
    this.setState(prevState => ({
      dispatchModalOpen: !prevState.dispatchModalOpen
    }))
  }

  toggleConfirmModal = () => {
    this.setState(prevState => ({
      confirmModal: !prevState.confirmModal
    }))
  }

  toggleDeliveredModal = () => {
    this.setState(prevState => ({
      deliveredModal: !prevState.deliveredModal
    }))
  }

  onDispatchChange = (val) => {
    this.setState({selectedDispatch: val});
  }

  onProductSelected = (val) => {
    this.setState({selectedProduct: val});
  }

  onChangeProduct = (val) => {
    this.setState({selectedProduct: val})
  }

  handleSubmit = async () => {
    this.setState({processing:true});

    const { modalViewType, data, confirmModal, fee, location } = this.state;

    if(modalViewType === 'DISPATCH'){
      const { selectedItem, selectedDispatch } = this.state;
      let payload;
      const promises = [];

      if(selectedItem.status === 1){
        payload = {
          status: 2,
          code: getCode(),
          dispatchId: selectedDispatch.id,
          dateAssigned: new Date()
        }

        const _payload = {
          assignedDeliveries: selectedDispatch.assignedDeliveries + 1
        }
        // console.log('disp pl',_payload);

        const p = axios.patch(`${dispatch_url}/${selectedDispatch.id}`,_payload);
        
        const message = `Hello ${selectedItem.buyerName}, your product "${selectedItem.productName}" has been assigned to a dispatch rider. Kindly provide this code: ${payload.code} when he/she arrives. Thank you.`;
        const s = axios.post('https://api.tuatuagye.com/send-message', { phone: selectedItem.buyerPhone,msg: message});
        
        promises.push(p);
        promises.push(s);
      }
      else {
        payload = {
          status: 3,
          deliveryLocation: location,
          deliveredDate: new Date(),
          fee: Number(fee)
        }

        const dispatchFound = await axios.get(`${dispatch_url}/${selectedItem.dispatchId}`);
        if(dispatchFound && dispatchFound.data){
          const _payload = {
            totalDeliveries: dispatchFound.data.totalDeliveries + 1
          }
          // console.log('2___ disp pl',_payload);

          const p = axios.patch(`${dispatch_url}/${selectedItem.dispatchId}`,_payload)
          promises.push(p);
        }
        else {
          // console.log('Dispatch not found, id',selectedItem.dispatchId);
        }

        const productFound = await axios.get(`${products_url}/${selectedItem.productId}`);
        if(productFound && productFound.data){
          const sold = productFound.data.sold ? productFound.data.sold + 1 : 1;
          const p1 = axios.patch(`${products_url}/${selectedItem.productId}`,{ sold });

          promises.push(p1);
        }
        else {
          // console.log('no product found');
        }
      }

      const p2 = axios.patch(`${deliveries_url}/${selectedItem.id}`,payload);
      promises.push(p2);

      Promise.all(promises)
        .then(res=> {
          NotificationManager.success('Processing successful','Change of status delivery item successful',4000,null,null,null);
          
          const temp = data.map(d=> {
            if(d.id === selectedItem.id){
              return {...d, ...payload}
            }
            return d;
          })

          if(confirmModal){
            this.toggleConfirmModal();
            this.toggleModal();
          }
          else {
            this.toggleDeliveredModal();
          }
          this.setState({data: temp, processing:false});
        })
        .catch(err=> {
          // console.log('error found',err);;
          this.setState({processing: false});
          NotificationManager.error(
            "Update error!",
            "An unknown error occurred. Please try again.",
            3000,
            null,
            null,
          );
        })
    }
    else {
      const { user } = this.props;
      const { selectedProduct, selectedItem } = this.state;
      // const { newProduct } = this.state;
      // const { oldProduct, done } = this.props.route.params;

      const swap = {
        oldProductName: selectedItem.productName,
        newProductName: selectedProduct.name,
        date: new Date().toJSON(),
        byId: user.id,
        byName: `${user.firstname} ${user.lastname}` 
      }

      const payload = {
        productId: selectedProduct.id,
        productName: selectedProduct.name,
        initialPrice: selectedProduct.posTTGPrice,
        currentPrice: selectedProduct.posTTGPrice,
        amountPaid: selectedItem.amountPaid,
        percentageCompleted: (selectedItem.amountPaid / selectedProduct.posTTGPrice) * 100,
        status: selectedItem.amountPaid >= selectedProduct.posTTGPrice ? 2 : 1,
        swapHistory: selectedItem.swapHistory ? [swap, ...selectedItem.swapHistory] : [swap]
      }
      // console.log('payload is',payload);
    }
    // setTimeout(() => {
    //   this.setState({processing:false})
    //   // this.toggleConfirmModal();
    //   // this.toggleModal();
    // }, 4000);
  }

  searchWithName = (searchText) => {
    this.setState({loading: true});
    axios.get(`${deliveries_url}?filter[where][or][0][buyerName][regexp]=/${searchText}/i&filter[where][or][1][productName][regexp]=/${searchText}/i&filter[order]=createdAt%20DESC&filter[limit]=20`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({data: res.data, loading: false});
        }
        else {
          this.setState({data:[], loading:false})
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please try again.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  }

  filter = (status) => {
    this.setState({loading: true});
    const {startDate, endDate} = this.state.selectionRange;
    let start = startDate;
    start.setHours(0)
    start.setMinutes(0);
    start.setSeconds(0);
    start = start.toJSON();

    let end = new Date(endDate);
    end.setHours(23)
    end.setMinutes(59);
    end.setSeconds(59);
    end = end.toJSON();

    let query = '';
    if(status === 0){
      query = `filter[where][status][gt]=0&filter[where][and][0][createdAt][gte]=${start}&filter[where][and][1][createdAt][lte]=${endDate}`;
    }
    else {
      query = `filter[where][status]=${status}&filter[where][and][0][createdAt][gte]=${start}&filter[where][and][1][createdAt][lte]=${endDate}`
    }
    axios.get(`${deliveries_url}?${query}`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({data: res.data, loading: false});
        }
        else {
          this.setState({data:[], loading:false})
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please try again.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  }

  __formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let yr = `${date.getFullYear()}`.slice(2);
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
  }

  exportToExcel() {
    const data = this.state.data.map(record=> {
      return {
        Customer: record.buyerName,
        Product: record.productName,
        'Delivery Location': record.deliveryLocation,
        'Delivery Fee': record.fee,
        'Date Paid': this.__formatDate(record.createdAt),
        'Date Delivered': this.__formatDate(record.deliveredDate),
        Status: record.status === 1 ? 'Pending' : record.status === 2 ? 'Shipped' : record.status === 3 ? 'Delivered':'Confirmed',
        // 'Date Added': this.__formatDate(record.createdAt)
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `Deliveries Data - ${new Date().toJSON()}`);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  render() {
    const { selectionRange, dispatchModalOpen, pageSize, fetchingData, fee, location, deliveredModal, adminCode, loading, searchKeyword, selectedItem, data, processing, selectedDispatch, confirmModal, selectedProduct, modalViewType } = this.state;
    const { startDate, endDate } = selectionRange;

    return (
      <Fragment>
        {/* {!processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>} */}
        <Row>
          <Colxx xxs="12">
            <Row style={{justifyContent:'space-between',marginLeft:0,marginRight:0}}>
              <h1>Deliveries | Count : {data.length}</h1>

              <Row style={{marginBottom:10,marginLeft:0,marginRight:0}}>
                <div className="payments-search">
                  <Input
                    style={{backgroundColor:'#fff'}}
                    name=""
                    // id="searchKeyword"
                    placeholder={"Search" }
                    value={searchKeyword}
                    onChange={e => {
                      let value = e.target.value;
                      this.setState({searchKeyword: value})
                      clearTimeout(this.timer);
                      this.timer = setTimeout(() => {
                        this.searchWithName(value);
                      }, 1000);
                    }}
                  />
                  <span
                      className="search-icon"
                      style={{top:10}}
                      // onClick={e => this.handleSearchIconClick(e)}
                  >
                      <i className="simple-icon-magnifier" />
                  </span>
                </div>

                <div style={{minWidth:"130px",marginLeft:10}}>
                  <Select
                    components={{ Input: CustomSelectInput }}
                    placeholder="All"
                    className="react-select"
                    classNamePrefix="react-select"
                    name="form-field-name"
                    value={this.state.selectedOption}
                    onChange={this.handleFilterChange}
                    options={selectData}
                  />
                </div>

                <div onClick={this.toggleDateModal} style={{alignItems:'center',justifyContent:'center',display:'flex',paddingRight:10,paddingLeft:10,borderRadius:3,marginLeft:"10px",color:'#fff',backgroundColor:'rgb(0, 136, 148)',cursor:'pointer'}}>
                  {startDate.toDateString() == endDate.toDateString() ? startDate.toDateString() : `${startDate.toDateString()} - ${endDate.toDateString()}`}
                </div>

              </Row>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card>
              <CardBody>
                {loading && <div className='loading'/>}
              {/* {!processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>} */}
                {!fetchingData && 
                  <ReactTable
                    data={data}
                    columns={this.columns}
                    defaultPageSize={pageSize}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    className={"react-table-fixed-height"}
                  />
                }

                {data.length > 0 && 
                  <div
                    onClick={()=> {
                      this.exportToExcel();
                    }} 
                    className="d-flex justify-content-end" style={{flex:1,cursor:"pointer"}}>
                    Download to Excel 
                    <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                  </div>
                }

                {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                  <div style={{display:'flex',flexDirection:'row',marginRight:15}}>
                    Export
                    <div style={{marginLeft:5}} className={"glyph-icon iconsminds-right-1"}/>
                  </div>

                  <div style={{display:'flex',flexDirection:'row'}}>
                    Print 
                    <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-printer"}/>
                  </div>
                </div> */}
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Modal
            style={{width:300}}
            isOpen={dispatchModalOpen}
            toggle={this.toggleModal}
            wrapClassName="modal-right"
            backdrop="static"
        >
          <ModalHeader toggle={this.toggleModal}>
            {modalViewType === 'DISPATCH' ? 'Assign Dispatch' : 'Swap Product'}
          </ModalHeader>
          <ModalBody>
            <Label>Product</Label>
            <h5>{selectedItem.productName}</h5>

            {modalViewType === 'DISPATCH' ? 
              <Label style={{marginTop:30}}>Rider</Label>
              :
              <Label style={{marginTop:30}}>New Product</Label>
            }
            
            {modalViewType === 'DISPATCH' ? 
              <DispatchAutoSuggest 
                  onChange={this.onDispatchChange}
              />
              :
              <ProductsAutoSuggest
                onChange={this.onProductSelected}
              />
            }

            {(modalViewType === 'DISPATCH' && selectedDispatch) ?
                <div style={{marginTop:20}}>
                    <h6 style={{marginTop:20}}>
                        Name: <b>{selectedDispatch.name}</b>
                    </h6>

                    <h6 style={{marginTop:20}}>
                        Phone: <b>{selectedDispatch.phone}</b>
                    </h6>

                    <h6 style={{marginTop:20}}>
                        License: <b>{selectedDispatch.driverLicenseNumber}</b>
                    </h6>
                </div>
                : (modalViewType === 'PRODUCT' && selectedProduct) ?
                <div style={{marginTop:20}}>
                    <h6 style={{marginTop:20}}>
                        Brand: <b>{selectedProduct.brand}</b>
                    </h6>

                    <h6 style={{marginTop:20}}>
                        Name: <b>{selectedProduct.name}</b>
                    </h6>

                    <h6 style={{marginTop:20}}>
                        Price: <b>GH₵ {formatAmount(selectedProduct.posTTGPrice)}</b>
                    </h6>
                </div>
                :
                null
            }
          </ModalBody>
          <ModalFooter>
            <Button color="default" outline 
              onClick={()=> {
                this.toggleModal()
                this.setState({selectedProduct:null,selectedDispatch:null,modalViewType:''})
              }}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.toggleConfirmModal}>
              {modalViewType === 'DISPATCH' ? 'Assign':'Swap'}
            </Button>{" "}
          </ModalFooter>

          <Modal
            isOpen={confirmModal}
            toggle={this.toggleConfirmModal}
            // className={this.props.className}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleConfirmModal}>
                <Label style={{}}>Confirm Transaction</Label>
            </ModalHeader>
            <ModalBody>
              <h6>Complete transaction by providing your <b>authorization code</b> in the box below</h6>
                <label style={{}}>
                Authorization Code:
                <input type="password" value={adminCode} style={{marginLeft:20,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                </label>
            </ModalBody>
            <ModalFooter>
            <Button
                color="cancel"
                onClick={this.toggleConfirmModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color="primary"
                onClick={()=> {
                  if(!adminCode){
                      NotificationManager.error(
                          "Authorization Code",
                          "Your security code required to perform this transaction. Thank you.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else if(adminCode !== this.props.user.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else {
                      this.handleSubmit(selectedItem);
                  }
                }}
            >
              {modalViewType === 'DISPATCH' ? 'Assign':'Swap'} Now
            </Button>
            {/* <div className="loading" /> */}
            </ModalFooter>
        </Modal>
        </Modal>

        <Modal
            isOpen={deliveredModal}
            toggle={this.toggleDeliveredModal}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleDeliveredModal}>
                <Label style={{}}>Confirm Delivery</Label>
            </ModalHeader>
            <ModalBody>
              <h6>Complete transaction by providing your <b>authorization code</b> in the box below</h6>
                <label style={{}}>
                  Authorization Code:
                  <input type="password" value={adminCode} style={{marginLeft:20,paddingLeft:5,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                </label>

                <label style={{marginLeft:15}}>
                  Delivery Fee:
                  <input value={fee} style={{marginLeft:20,paddingBottom:10,paddingLeft:5,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({fee: event.target.value})} />
                </label>

                <label style={{marginTop:15}}>
                  Delivery Location:
                  <input value={location} style={{paddingLeft:5,marginLeft:15,paddingBottom:10,paddingTop:10,outline:'none',width:230}} onChange={(event)=> this.setState({location: event.target.value})} />
                </label>
            </ModalBody>
            <ModalFooter>
            <Button
                color="cancel"
                onClick={this.toggleDeliveredModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color="primary"
                onClick={()=> {
                  if(!adminCode){
                      NotificationManager.error(
                          "Authorization Code",
                          "Your security code required to perform this transaction. Thank you.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else if(adminCode !== this.props.user.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else if(fee.length === 0) {
                    NotificationManager.error(
                        "Add Delivery Fee",
                        "Please specify delivery fee.",
                        3000,
                        null,
                        null,
                    );
                  }
                  else {
                      this.handleSubmit(selectedItem);
                  }
                }}
            >
              Mark As Delivered
            </Button>
            {/* <div className="loading" /> */}
            </ModalFooter>
        </Modal>

        {/* DATE MODAL */}

        <Modal
          isOpen={this.state.dateModal}
          toggle={this.toggleDateModal}
          className={'date-modal'} 
        >
          <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleDateModal}>
            <Label>Set Date Range</Label>
          </ModalHeader>
          <ModalBody>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={this.handleSelect}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              onClick={this.toggleDateModal}
            >
              Cancel
            </Button>{" "}
            
            <Button
              color="success"
              onClick={()=> {
                this.loadData();
                this.setState({loading: true});
                this.toggleDateModal();
              }}
            >
              Filter Now
            </Button>
            
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ menu, authUser }) => {
  const {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems
  } = menu;
  const { user } = authUser;

  return {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems,
    user
  };
};

export default connect(
  mapStateToProps,
  {
    setContainerClassnames,
    changeDefaultClassnames
  }
)(Home);
