import React, { useEffect } from "react";
import {  Card,  CardBody,  CardTitle} from "reactstrap";
import { ThemeColors } from '../../helpers/ThemeColors'
import {PolarAreaChart} from "../../components/charts"

const colors = ThemeColors();

const ACD = {
  labels: ['Mobile', 'Web', 'POS'],
  datasets: [
    {
      borderWidth: 2,
      borderColor: [colors.themeColor1, colors.themeColor2, colors.themeColor3],
      backgroundColor: [
        colors.themeColor1_10,
        colors.themeColor2_10,
        colors.themeColor3_10
      ]
    }
  ]
}

const SalesCategoriesPolarArea = ({data, chartClass="chart-container"}) => {
  useEffect(()=> {

  }, [])

  return (
    <Card>
      <CardBody>
        <CardTitle>
            Sales Categories
        </CardTitle>
        <div className={chartClass}>
          <PolarAreaChart shadow data={{...ACD, datasets: [{...ACD.datasets[0], data }]}} />
        </div>
      </CardBody>
    </Card>
  );
};

export default SalesCategoriesPolarArea;
