import React, { Component, Fragment } from "react";
import {
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  Button,
  TabContent,
  TabPane,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,Label
} from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import classnames from "classnames";
import axios from 'axios';
import { connect } from "react-redux";
import QRCode from 'qrcode'

import GradientWithRadialProgressCard from "../../../components/cards/GradientWithRadialProgressCard";

import { Colxx } from "../../../components/common/CustomBootstrap";
import IntlMessages from "../../../helpers/IntlMessages";
// import RecentMessages from "../../../containers/users/RecentMessages";
// import RecentPayments from "../../../containers/users/RecentPayments";
import RecentCollections from "../../../containers/users/RecentCollections";
import { NotificationManager } from "../../../components/common/react-notifications";

import {
  removeMerchant
} from "../../../redux/new-merchants/actions";
import MerchantDayTransactions from "../../../containers/pages/MerchantDayTransactions";
import MerchantCommissions from "../../../containers/pages/MerchantCommissions";

const users_url = 'https://api.tuatuagye.com/users';
const merchants_url = 'https://api.tuatuagye.com/merchants';
const img_base_url = 'https://api.tuatuagye.com/get-image?type=profile&id='
const commissions_url = 'https://api.tuatuagye.com/merchant-commissions'

class MerchantDetails extends Component {
  constructor(props) {
    super(props);

    this.toggleTab = this.toggleTab.bind(this);
    this.state = {
      activeFirstTab: "1",
      modalOpen: false,
      commissionModalOpen:false,
      transactionsModalOpen: false,
      commissionsModalOpen: false,
      adminCode:'',
      commissionAmount:'',
      processing: false,
      modalViewType:'',
      loadingDetails: true,
      details: {},
      messages:[],
      merchantInfo: null,
      collectionsTotal: 0,
      collectionsCommission: 0,
      signUpsTotal: 0,
      signUpsCommission: 0,
      cashSalesTotal: 0,
      cashSalesCommission: 0,
      startDate: '',
      commissionsData: []
    };
  }

  componentDidMount() {
    const id = this.props.location.pathname.split('/').pop();
    
    if(this.props.location.search && this.props.location.search.indexOf('new') > -1){
      this.props.removeMerchant({ id });
    }

    axios.get(`${users_url}/${id}`)
      .then(res => {
        if(res.data){
          this.setState({details: res.data, loadingDetails: false});
          return axios.get(`${merchants_url}?filter[where][userId]=${id}&filter[limit]=1`)
        }
        else {
          this.setState({loadingDetails:false})
          return null;
        }
      })
      .then(res => {
        if(res && res.data){
          this.setState({merchantInfo: res.data[0]});
        }
        // alert(JSON.stringify(res.data))
        // return axios.get(`${payments_url}/collections?filter[where][merchantId]=${id}&filter[include][]=payment`)
        return axios.get(`${commissions_url}?filter[where][merchantId]=${id}&filter[where][paidStatus]=0&filter[order]=createdAt desc`)
      })
      .then(res => {
        if(res && res.data){
          let collectionsTotal = 0;
          let collectionsCommission = 0;
          let signUpsTotal = 0;
          let signUpsCommission = 0;
          let cashSalesTotal = 0;
          let cashSalesCommission = 0;
          
          res.data.forEach(comm => {
            collectionsTotal += comm.collections;
            collectionsCommission += comm.collectionsCommission;
            signUpsTotal += comm.signUps;
            signUpsCommission += comm.signUpsCommission;
            cashSalesTotal += comm.cashSales;
            cashSalesCommission += comm.cashSalesCommission 
          })
          
          const tmp = res.data[res.data.length - 1]
          if(tmp){
            this.setState({startDate: tmp.createdAt})
          }
          this.setState({
            collectionsTotal, 
            collectionsCommission,
            signUpsTotal,
            signUpsCommission,
            cashSalesTotal,
            cashSalesCommission,
            commissionsData: res.data
          });
        }
      })
      // .then(res => {
      //   if(res && res.data){
      //     let _total = 0;
      //     res.data.forEach(signup => _total += signup.amount)
          
      //     this.setState({signUpsTotal: _total, signUpsCommission: _total * 0.02});
      //   }

      //   return axios.get(`${pods}?filter[where][merchantId]=${id}&filter[where][status]=2`)
      // })
      // .then(res => {
      //   if(res && res.data){
      //     let _total = 0;
      //     res.data.forEach(payment => _total += payment.amount)
          
      //     this.setState({cashSalesTotal: _total, cashSalesCommission: _total * 0.02});
      //   }
      // })
      .catch(error => {
        this.setState({loadingDetails: false});
        // alert('Sorry, an unknown error occurred.');
        console.log('error...',error);
      })
  }

  viewSignups = () => {
    const { details } = this.state;
    window.open(`/app/merchant-sign-ups?ref=${this.state.merchantInfo.userId}&start=${this.state.startDate}&name=${details.firstname.trim()}-${details.lastname.trim()}`,'_blank');
  }

  viewCollections = () => {
    const { details } = this.state;
    window.open(`/app/merchant-collections?ref=${this.state.merchantInfo.userId}&start=${this.state.startDate}&name=${details.firstname.trim()}-${details.lastname.trim()}`,'_blank');
  }

  viewCashsales = () => {
    const { details } = this.state;
    window.open(`/app/merchant-cash-sales?ref=${this.state.merchantInfo.userId}&start=${this.state.startDate}&name=${details.firstname.trim()}-${details.lastname.trim()}`,'_blank');
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }))
  }

  toggleCommissionModal = () => {
    this.setState(prevState => ({
      commissionModalOpen: !prevState.commissionModalOpen
    }))
  }

  toggleTransactionsModal = () => {
    this.setState(prevState => ({
      transactionsModalOpen: !prevState.transactionsModalOpen
    }))
  }

  toggleCommissionsModal = () => {
    this.setState(prevState => ({
      commissionsModalOpen: !prevState.commissionsModalOpen
    }))
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }

  handleSubmit = () => {
    this.setState({processing:true});
    const { merchantInfo } = this.state;

    const url = `${merchants_url}/${merchantInfo.id}`;
    const payload = {mType: merchantInfo.mType == 2 ? 1 : 2};
    console.log(payload);
    axios.patch(url, payload)
    .then(res=> {
      if(res.status === 204){
        NotificationManager.success(
          "Update successful",
          "The merchant's profile type updated successfully.",
          3000,
          null,
          null,
        );

        this.setState({merchantInfo: Object.assign({}, merchantInfo, payload)});
        this.toggleModal();
      }
      else {
        NotificationManager.error(
          "Failed",
          "Sorry, an unknown error occurred. Please try again.",
          3000,
          null,
          null,
        );
      }
    })
    .catch(err=> {
      NotificationManager.error(
        "Failed",
        "Sorry, an unknown error occurred. Please try again.",
        3000,
        null,
        null,
      );
    })
    .finally(()=> this.setState({processing: false}));
  }

  getTotalCommission = (merchantInfo) => {
    return this.state.collectionsCommission + this.state.signUpsCommission + this.state.cashSalesCommission
  }

  handlePayCommission = () => {
    this.setState({processing:true});

    setTimeout(() => {
      this.toggleCommissionModal();
    }, 4000);
  }

  formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
      return temp;
    }
    
    return Number(amount).toFixed(2);
  }

  generateQR = () => {
    const { details } = this.state;
    const url = `https://api.tuatuagye.com/verify-merchant?ref=${details.id}`

    QRCode.toDataURL(url)
      .then(async (url) => {
        // console.log('url',url)

        const response = await fetch(url);
        const blobImage = await response.blob();

        const href = URL.createObjectURL(blobImage);

        const anchorElement = document.createElement('a');
        anchorElement.href = href;
        anchorElement.download = `ttg_qr_code_${details.firstname}`;

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch(err => {
        console.error(err)
        NotificationManager.error(
          "Failed",
          "Sorry, an unknown error occurred. Please try again.",
          3000,
          null,
          null,
        );
      })
  }

  render() {
    const { adminCode, transactionsModalOpen, startDate, collectionsTotal, commissionsModalOpen, collectionsCommission, signUpsTotal, signUpsCommission, cashSalesTotal,cashSalesCommission,  loadingDetails, details, merchantInfo, messages, commissionModalOpen, processing, modalOpen, user } = this.state;

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <h1>Merchant Details</h1>
            <div style={{paddingTop:10,paddingRight:10}} className="float-sm-right mb-2">
                <Row>
                    {/* {details && !details.enabled &&
                      <div
                          onClick={()=> {
                              this.setState({modalViewType: 'ENABLE'})
                              this.toggleModal();
                          }}
                          style={{cursor:'pointer',fontSize:20,color:'#2ECC71',marginRight:20}}  
                          className={"glyph-icon iconsminds-add-user"} 
                      />
                    } */}

                    {/* {this.props.user.type === 1 && (
                      <div
                          onClick={()=> {
                              const authCode = prompt('Provide your authorization code to update this merchant');

                              if(authCode && authCode === this.props.user.authCode){                              
                                const url = `${users_url}/merchants/${merchantInfo.id}`;
                                const payload = {allowProcessing: !merchantInfo.allowProcessing};
                                console.log(payload);
                                axios.patch(url, payload)
                                .then(res=> {
                                  if(res.status === 204){
                                    NotificationManager.success(
                                      "Update successful",
                                      "The merchant's auto-processing status updated successfully.",
                                      3000,
                                      null,
                                      null,
                                    );

                                    this.setState({merchantInfo: Object.assign({}, merchantInfo, payload)})
                                  }
                                  else {
                                    NotificationManager.error(
                                      "Failed",
                                      "Sorry, an unknown error occurred. Please try again.",
                                      3000,
                                      null,
                                      null,
                                    );
                                  }
                                });

                                alert('Please wait... updating now');
                              }
                              else if(authCode && authCode !== this.props.user.authCode){
                                NotificationManager.error(
                                  "Invalid Code",
                                  "Sorry, the authorization code you provided is invalid.",
                                  3000,
                                  null,
                                  null,
                                );
                              }
                          }}
                          style={{cursor:'pointer',fontSize:16,color: (merchantInfo && merchantInfo.allowProcessing) ? '#2ECC71':'red',marginRight:40}}  
                          // className={"glyph-icon iconsminds-wallet"} 
                      ><span>Allow Auto-Processing</span></div>
                    )} */}

                    {this.props.user.type === 1 && (
                      <div
                          onClick={()=> {
                            this.toggleModal();
                          }}
                          style={{cursor:'pointer',fontSize:16,color: '#00f',marginRight:40}}  
                          // className={"glyph-icon iconsminds-wallet"} 
                      ><span>Set Merchant Type</span></div>
                    )}

                    {this.props.user.type === 1 && (
                      <div
                          onClick={()=> {
                              const authCode = prompt('Provide your authorization code to update this merchant');

                              if(authCode && authCode === this.props.user.authCode){                              
                                const url = `${merchants_url}/${merchantInfo.id}`;
                                const payload = {permitCash: !merchantInfo.permitCash};
                                console.log(payload);
                                axios.patch(url, payload)
                                .then(res=> {
                                  if(res.status === 204){
                                    NotificationManager.success(
                                      "Update successful",
                                      "The merchant's access to Cash Collection changed successfully.",
                                      3000,
                                      null,
                                      null,
                                    );

                                    this.setState({merchantInfo: Object.assign({}, merchantInfo, payload)})
                                  }
                                  else {
                                    NotificationManager.error(
                                      "Failed",
                                      "Sorry, an unknown error occurred. Please try again.",
                                      3000,
                                      null,
                                      null,
                                    );
                                  }
                                });

                                // alert('Please wait... updating now');

                              }
                              else if(authCode && authCode !== this.props.user.authCode){
                                NotificationManager.error(
                                  "Invalid Code",
                                  "Sorry, the authorization code you provided is invalid.",
                                  3000,
                                  null,
                                  null,
                                );
                              }
                          }}
                          style={{cursor:'pointer',fontSize:16,color: (merchantInfo && merchantInfo.permitCash) ? '#2ECC71':'red',marginRight:40}}  
                      ><span>Permit Cash</span></div>
                    )}

                    {/* {this.props.user.type === 1 && (
                      <div
                        onClick={()=> {
                            const authCode = prompt('Provide your authorization code to update this merchant');
                            if(authCode && authCode === this.props.user.authCode){                              
                              const url = `${merchants_url}/${merchantInfo.id}`;
                              const payload = {permitMomo: !merchantInfo.permitMomo};
                              console.log(payload);
                              axios.patch(url, payload)
                              .then(res=> {
                                if(res.status === 204){
                                  NotificationManager.success(
                                    "Update successful",
                                    "The merchant's access to MoMo Collection changed successfully.",
                                    3000,
                                    null,
                                    null,
                                  );

                                  this.setState({merchantInfo: Object.assign({}, merchantInfo, payload)})
                                }
                                else {
                                  NotificationManager.error(
                                    "Failed",
                                    "Sorry, an unknown error occurred. Please try again.",
                                    3000,
                                    null,
                                    null,
                                  );
                                }
                              });

                              alert('Please wait... updating now');

                            }
                            else if(authCode && authCode !== adminCode){
                              NotificationManager.error(
                                "Invalid Code",
                                "Sorry, the authorization code you provided is invalid.",
                                3000,
                                null,
                                null,
                              );
                            }
                        }}
                        style={{cursor:'pointer',fontSize:16,color: (merchantInfo && merchantInfo.permitMomo) ? '#2ECC71':'red',marginRight:20}}  
                        // className={"glyph-icon iconsminds-dollar"} 
                      ><span>Permit MoMo</span></div>
                    )} */}

                    {/* <NavLink to={`/app/applications/chat?ref=${details ? details.id : ''}`}>
                      <Button outline color={"primary"} className="icon-button">
                          <i className="simple-icon-speech" />
                      </Button>
                    </NavLink> */}
                </Row>
            </div>

            <Nav tabs className="separator-tabs ml-0 mb-5" style={{marginTop:1}}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "1",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("1");
                  }}
                  to="#"
                >
                  <IntlMessages id="pages.details" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "2",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                  to="#"
                >
                  ABOUT
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeFirstTab}>
              <TabPane tabId="1">
                {loadingDetails && <div className='loading'/>}
                
                <Row>
                {details && <Colxx xxs="12" lg="3" className="mb-4">
                    <Card className="mb-4">
                        <img
                            src={details.imageThumbnail ? `${img_base_url}${details.imageThumbnail}` : "/assets/img/no-profile-image.jpeg"}
                            alt="Detail"
                            className="card-img-top"
                        />

                        <CardBody>
                            <p className="text-muted text-small mb-2">
                            Name
                            </p>
                            <h6 className="mb-3">
                              {details.firstname} {details.lastname}
                            </h6>

                            <p className="text-muted text-small mb-2">
                            Phone
                            </p>
                            <h6 className="mb-3">
                              {details.phone}
                            </h6>

                            <p className="text-muted text-small mb-2">
                            Email
                            </p>
                            <h6 className="mb-3">
                            {details.email || 'N/A'}
                            </h6>

                            <p className="text-muted text-small mb-2">
                            Location
                            </p>
                            <h6 className="mb-3">
                            {merchantInfo && merchantInfo.location}
                            </h6>

                            {/* <p className="text-muted text-small mb-2">
                            Last Seen
                            </p> */}
                            {/* <h6 className="mb-3" style={{color:'#2ECC71'}}> */}
                              {/* {this.formatDate(details.lastSeen)} */}
                            {/* </h6> */}

                            {/* <p className="text-muted text-small mb-2">
                            Zone
                            </p>
                            <h6 className="mb-3">
                            1
                            </h6> */}

                            <p className="text-muted text-small mb-2">
                            Date Joined
                            </p>
                            <h6 className="mb-3">
                              {this.formatDate(details.createdAt)}
                            </h6>

                            <p className="text-muted text-small mb-2">
                            Status
                            </p>
                            <h6 className="mb-3" style={{color:details.enabled ? '#2ECC71':'#E74C3C'}}>
                            {details.enabled ? 'ENABLED':'DISABLED'}
                            </h6>

                            <p className="text-muted text-small mb-2">
                            Account Type
                            </p>
                            <h6 className="mb-3" style={{color:merchantInfo && (merchantInfo.mType == 2) ? '#2ECC71':'#4287f5'}}>
                            {merchantInfo && (merchantInfo.mType == 2) ? 'IN-HOUSE':'REGULAR'}
                            </h6>

                            <p className="text-muted text-small mb-2">
                            Current App Version
                            </p>
                            <h6 className="mb-3">
                            {merchantInfo && merchantInfo.appVersion}
                            </h6>

                            {/* <p className="text-muted text-small mb-2" style={{marginTop:35}}>
                            Pending Commission.
                            </p>
                            <h6 className="mb-3" style={{color:'#008894'}}>
                            GH₵ {merchantInfo ? this.formatAmount(this.getTotalCommission(merchantInfo)) : '...'}
                            </h6> */}

                            <Button className="mb-4" onClick={this.toggleTransactionsModal} color="primary">VIEW TRANSACTIONS</Button>
                            <Button className="mb-4" onClick={this.toggleCommissionsModal} color="defualt">VIEW COMMISSIONS</Button>

                            <Button className="mb-4" onClick={this.generateQR} color="defualt">GENERATE QR CODE</Button>
                            {/* {merchantInfo && this.getTotalCommission(merchantInfo) > 0 && <Button onClick={this.toggleCommissionModal} color="primary">PAY COMMISSION</Button>} */}
                        </CardBody>
                    </Card>
                  </Colxx>
                }

                  <Colxx xxs="12" lg="9">
                  <Row>
                    <Colxx lg="4" md="6" className="mb-4">
                        <GradientWithRadialProgressCard
                          icon="iconsminds-financial"
                          title={`Coll.`}
                          detail={`Comm. GH₵ ${this.formatAmount(collectionsCommission)}`}
                          percent={(5 * 100) / 12}
                          progressText={this.formatAmount(collectionsTotal)}
                          onClick={this.viewCollections}
                        />
                    </Colxx>
                    <Colxx lg="4" md="6" className="mb-4">
                        <GradientWithRadialProgressCard
                            icon="iconsminds-male"
                            title={`Sign ups`}
                            detail={`Comm. GH₵ ${this.formatAmount(signUpsCommission)}`}
                            percent={(4 * 100) / 6}
                            progressText={this.formatAmount(signUpsTotal)}
                            onClick={this.viewSignups}
                        />
                    </Colxx>
                    <Colxx lg="4" md="6" className="mb-4">
                        <GradientWithRadialProgressCard
                            icon="iconsminds-dollar"
                            title={`C. Sales`}
                            detail={`Comm. GH₵ ${this.formatAmount(cashSalesCommission)}`}
                            percent={(8 * 100) / 10}
                            progressText={this.formatAmount(cashSalesTotal)}
                            onClick={this.viewCashsales}
                        />
                    </Colxx>
                    </Row>

                    {startDate && <h3 className="mb-4">Calculated from {this.formatDate(startDate)}</h3>}
                    {/* <RecentPayments className="mb-4" user={details} controls={false} /> */}
                    {details.id && <RecentCollections className="mb-4" user={details} controls={false} />}
                    {/* <RecentMessages data={messages} className="mb-4" displayRate={true} /> */}
                  </Colxx>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                {merchantInfo &&
                  <Row>
                      <Colxx xxs="12" lg="4" className="mb-4">
                          <Card className="mb-4">
                              {merchantInfo.guarantorImage && merchantInfo.guarantorImage !== 'N/A' &&
                                <img
                                    src={`${img_base_url}${merchantInfo.guarantorImage}`}
                                    alt="Bus. Cert."
                                    className="card-img-top"
                                />
                              }

                              <CardBody>
                                  <p className="text-muted text-small mb-2">
                                      Guarantor Name
                                  </p>
                                  <h6 className="mb-3">
                                    {merchantInfo.guarantorName}
                                  </h6>

                                  <p className="text-muted text-small mb-2">
                                  Guarantor Phone
                                  </p>
                                  <h6 className="mb-3">
                                    {merchantInfo.guarantorPhone}
                                  </h6>

                                  <p className="text-muted text-small mb-2">
                                  Guarantor Location
                                  </p>
                                  <h6 className="mb-3">
                                    {merchantInfo.guarantorLocation}
                                  </h6>
                              </CardBody>
                          </Card>
                      </Colxx>
                      <Colxx xxs="12" lg="4" className="mb-4">
                          <Card className="mb-4">
                              {merchantInfo.nationalIdImage && merchantInfo.nationalIdImage !== 'N/A' &&
                                <img
                                    src={`${img_base_url}${merchantInfo.nationalIdImage}`}
                                    alt="Bus. Cert."
                                    className="card-img-top"
                                />
                              }
                              <CardBody>
                                  <p className="text-muted text-small mb-2">
                                      National ID
                                  </p>
                                  {/* <h6 className="mb-3">
                                      2385901083
                                  </h6> */}
                              </CardBody>
                          </Card>
                      </Colxx>
                      <Colxx xxs="12" lg="4" className="mb-4">
                          <Card className="mb-4">
                              {merchantInfo.businessCertificateImage && merchantInfo.businessCertificateImage !== 'N/A' &&
                                <img
                                    src={`${img_base_url}${merchantInfo.businessCertificateImage}`}
                                    alt="Bus. Cert."
                                    className="card-img-top"
                                />
                              }
                              <CardBody>
                                  <p className="text-muted text-small mb-2">
                                      Momo Merchant Name
                                  </p>
                                  <h6 className="mb-3">
                                      {merchantInfo.momoMerchantName}
                                  </h6>

                                  <p className="text-muted text-small mb-2">
                                      Merchant ID
                                  </p>
                                  <h6 className="mb-3">
                                      {merchantInfo.momoMerchantId}
                                  </h6>
                              </CardBody>
                          </Card>
                      </Colxx>
                  </Row>
                }
              </TabPane>
            </TabContent>
          </Colxx>
        </Row>

        {transactionsModalOpen &&
          <MerchantDayTransactions
            modalOpen={transactionsModalOpen}
            toggleModal={this.toggleTransactionsModal}
            merchant={merchantInfo}
            userDetails={details}
          />
        }

        {commissionsModalOpen && 
          <MerchantCommissions
            modalOpen={commissionsModalOpen}
            toggleModal={this.toggleCommissionsModal}
            merchant={{
              ...merchantInfo,
              total: this.getTotalCommission()
            }}
            commissionsData={this.state.commissionsData}
            userDetails={details}
          />
        }

        {/* Authorization modal */}
        <Modal
            isOpen={modalOpen}
            toggle={this.toggleModal}
            // className={this.props.className}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleModal}>
                {/* <img style={{width:"50px",height:"50px",borderRadius:"25px",marginRight:"10px"}} src="/assets/img/profile-pic-l-4.jpg" alt="Avatar"/> */}
                <Label style={{}}>Authorize Merchant</Label>
            </ModalHeader>
            <ModalBody>
              {(merchantInfo && (merchantInfo.mType === 2)) ?
                <h6 style={{marginBottom:10}}>Are you sure you want to change account type to <span style={{color:'#4287f5'}}><b>REGULAR</b></span> for <b>{details.firstname} {details.lastname}'s account</b>?</h6>
                :
                <h6 style={{marginBottom:10}}>Are you sure you want to change account type to <span style={{color:'#2ECC71'}}><b>IN-HOUSE</b></span> for <b>{details.firstname} {details.lastname}'s account</b>?</h6>
              }
              <h6>Complete transaction by providing your <b>authorization code</b> in the box below</h6>
                <label style={{}}>
                Authorization Code:
                <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,paddingLeft:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                </label>
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.toggleModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color={"primary"}
                onClick={()=> {
                  if(!adminCode){
                    NotificationManager.error(
                        "Authorization Code",
                        "Your security code required to perform this transaction. Thank you.",
                        3000,
                        null,
                        null,
                    );
                  }
                  else if(adminCode !== this.props.user.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else {
                    this.handleSubmit()
                  }
                }}
            >
              Change Now
            </Button>
            {/* <div className="loading" /> */}
            </ModalFooter>
        </Modal>

        {/* commission modal */}
        <Modal
            isOpen={commissionModalOpen}
            toggle={this.toggleCommissionModal}
            // className={this.props.className}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleModal}>
                {/* <img style={{width:"50px",height:"50px",borderRadius:"25px",marginRight:"10px"}} src="/assets/img/profile-pic-l-4.jpg" alt="Photo"/> */}
                <Label style={{}}>Pay {details.firstname} {details.lastname}'s Commission</Label>
            </ModalHeader>
            <ModalBody>
              {/* <label>Amount: <input type="number" value={commissionAmount} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({commissionAmount: event.target.value})} /></label> */}
              <h6 style={{marginTop:20}}>Complete transaction by providing your <b>authorization code</b> in the box below</h6>
                <label style={{}}>
                Authorization Code:
                <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                </label>
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.toggleCommissionModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color={"primary"}
                onClick={()=> {
                    // if(!commissionAmount){
                    //     alert('Amount required.')
                    // }
                    // else 
                    if(!adminCode){
                        alert('Your authorization code required.')
                    }
                    else {
                        this.handlePayCommission();
                    }
                }}
            >
              Pay Now
            </Button>
            </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;

  return { user };
};

export default withRouter(connect(
  mapStateToProps,
  { removeMerchant }
)(MerchantDetails));