import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Row, Card, CardBody, Input } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import axios from 'axios';
import { NavLink } from "react-router-dom";
import { NotificationManager } from "../../components/common/react-notifications";
import * as FileSaver from 'file-saver';

import DataTablePagination from "../../components/DatatablePagination";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import {
  setContainerClassnames,
  changeDefaultClassnames
} from "../../redux/actions";

const selectData = [
  { label: "Page 1", value: "1", key: 1 },
  { label: "Page 2", value: "2", key: 2 },
  { label: "Page 3", value: "3", key: 3 },
  { label: "Page 4", value: "4", key: 4 },
  { label: "Page 5", value: "5", key: 5 },
  { label: "Page 6", value: "6", key: 6 },
  { label: "Page 7", value: "7", key: 7 },
  { label: "Page 8", value: "8", key: 8 },
  { label: "Page 9", value: "9", key: 9 },
  { label: "Page 10", value: "10", key: 10 }
];

const formatDate = (_date) => {
  // 3:16pm | Mar 2 2021
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
}

const formatAmount = (amount) => {
  if(!amount) return '0.00';
  if(amount > 999){
    let temp = amount.toString();
    let decimal = '.00';

    if(temp.indexOf('.') > -1){
      decimal = '.'+temp.split('.')[1];
      temp = temp.split('.')[0];
    }
    
    temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
    return temp;
  }
  
  return Number(amount).toFixed(2);
}

const my_products_url = 'https://api.tuatuagye.com/m-products';

class UserProductsNearingCompletion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      confirmModal:false,
      modalViewType:'',
      searchKeyword:'',
      adminCode: '',
      selectedOption: selectData[0],
      dispatchModalOpen:false,
      productModalOpen:false,
      selectedDispatch:null,
      selectedProduct: null,
      selectedItem: {},
      data: [],
      loading: true,
    }

    this.timer = 0;
  }

  componentDidMount() {
    axios.get(`${my_products_url}?filter[where][and][0][percentageCompleted][gte]=80&filter[where][and][1][percentageCompleted][lt]=100&filter[limit]=50&filter[order]=lastPaymentDate desc`)
    .then(res=> {
      this.setState({data: res.data, loading:false})
    })
    .catch(err => {
      console.log('error loading product',err);
      NotificationManager.error(
        "Loading error!",
        "An unknown error occurred. Please refresh page.",
        3000,
        null,
        null,
      );
    })
  }

  handleFilterChange = selectedOption => {
    this.setState({ selectedOption });
    let skip = 0;
    if(selectedOption.key > 1){
      skip = (selectedOption.key - 1) * 50
    }

    this.setState({loading: true});
    axios.get(`${my_products_url}?filter[where][and][0][percentageCompleted][gte]=80&filter[where][and][1][percentageCompleted][lt]=100&filter[skip]=${skip}&filter[limit]=50&filter[order]=lastPaymentDate desc`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({data: res.data, loading: false});
        }
        else {
          this.setState({data:[], loading:false})
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  };

  toggleModal = () => {
    this.setState(prevState => ({
      dispatchModalOpen: !prevState.dispatchModalOpen
    }))
  }

  toggleConfirmModal = () => {
    this.setState(prevState => ({
      confirmModal: !prevState.confirmModal
    }))
  }

  onDispatchChange = (val) => {
    this.setState({selectedDispatch: val});
  }

  onProductSelected = (val) => {
    this.setState({selectedProduct: val});
  }

  onChangeProduct = (val) => {
    this.setState({selectedProduct: val})
  }

  handleSubmit = () => {
    this.setState({processing:true});

    setTimeout(() => {
      this.toggleConfirmModal();
      this.toggleModal();
    }, 4000);
  }

  searchWithName = (searchText) => {
    this.setState({loading: true});
    axios.get(`${my_products_url}?filter[where][and][0][productName][regexp]=/${searchText}/i&filter[where][and][1][percentageCompleted][gte]=80&filter[where][and][2][percentageCompleted][lt]=100&filter[limit]=50&filter[order]=lastPaymentDate desc`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({data: res.data, loading: false});
        }
        else {
          this.setState({data:[], loading:false})
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  }

  __formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let yr = `${date.getFullYear()}`.slice(2);
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
  }

  exportToExcel() {
    const data = this.state.data.map(record=> {
      return {
        Product: record.productName,
        'Last Payment Date': this.__formatDate(record.lastPaymentDate),
        'Percentage Completed': Number(record.percentageCompleted).toFixed(2)
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `User-Products Data - ${new Date().toJSON()}`);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  render() {
    const { dispatchModalOpen,loading, searchKeyword, selectedItem, data, processing, selectedDispatch, confirmModal, selectedProduct, modalViewType } = this.state;
    return (
      <Fragment>
        {/* {!processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>} */}
        <Row>
          <Colxx xxs="12">
            <Row style={{justifyContent:'space-between',marginLeft:0,marginRight:0}}>
                <h1>Almost Completed Products</h1>

                <Row style={{marginBottom:10,marginLeft:0,marginRight:0}}>
                <div className="payments-search">
                    <Input
                    style={{backgroundColor:'#fff'}}
                    name="searchKeyword"
                    id="searchKeyword"
                    placeholder={"Search" }
                    value={searchKeyword}
                    onChange={e => {
                        let value = e.target.value;
                        this.setState({searchKeyword: value})
                        clearTimeout(this.timer);
                        this.timer = setTimeout(() => {
                        this.searchWithName(value);
                        }, 1000);
                    }}
                    />
                    <span
                        className="search-icon"
                        style={{top:10}}
                        // onClick={e => this.handleSearchIconClick(e)}
                    >
                        <i className="simple-icon-magnifier" />
                    </span>
                </div>

                <div style={{minWidth:"90px",marginLeft:10}}>
                    <Select
                    components={{ Input: CustomSelectInput }}
                    placeholder="Page 1"
                    className="react-select"
                    classNamePrefix="react-select"
                    name="form-field-name"
                    value={this.state.selectedOption}
                    onChange={this.handleFilterChange}
                    options={selectData}
                    />
                </div>

                </Row>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card>
              <CardBody>
                {loading && <div className='loading'/>}
              
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Product",
                      accessor: "productName",
                      Cell: props => <p className="list-item-heading truncate">{props.value}</p>
                    },
                    {
                      Header: "Last Payment Date",
                      accessor: "lastPaymentDate",
                      width:200,
                      Cell: props => <p className="text-muted"  style={{paddingTop:0}}>{formatDate(props.value)}</p>
                    },
                    {
                      Header: "Status",
                      accessor: "percentageCompleted",
                      width:200,
                      Cell: props => <div style={{backgroundColor:'#ccc',height:20,position:'relative',marginRight:50,borderRadius:3,overflow:'hidden',marginTop:0}}>
                          <span style={{position:'absolute', color:'#fff', fontWeight:'bold', top:'50%',left:'50%', marginRight:'-50%', transform: 'translate(-50%, -50%)',zIndex:9}}>{Number(props.value).toFixed()}%</span>
                          <div style={{position:'absolute',height:20,width: `${props.value}%`,backgroundColor: props.value >= 95 ? 'red' : props.value >= 90 ? 'gold' : '#228B22',flexDirection:'row',justifyContent:'center',alignItems:'center'}}/>
                        </div>
                    },
                    {
                      Header: "Actions",
                      accessor: "userId",
                      width:100,
                      Cell: props => <Row style={{paddingLeft:"12px",paddingTop:0}}>
                        {/* {props.value === 1 && 
                          <div
                            onClick={()=> {
                              this.setState({modalViewType:'DISPATCH',selectedItem: props.original, selectedDispatch:null,selectedProduct:null});
                              this.toggleModal();
                            }}
                            style={{cursor:'pointer',fontSize:17}}  
                            className={"glyph-icon iconsminds-yes"} 
                          />
                        }
                        {props.value === 1 && 
                          <div 
                            onClick={()=> {
                              this.setState({modalViewType:'PRODUCT',selectedItem: props.original, selectedDispatch:null,selectedProduct:null});
                              this.toggleModal();
                            }}
                            style={{cursor:'pointer',fontSize:17,marginLeft:15}} 
                            className={"glyph-icon iconsminds-sync"} 
                          />
                        } */}
                          <NavLink
                            to={`/app/users/customers/${props.original.userId}`}>
                              <div 
                              style={{fontSize:17}} 
                              className={"glyph-icon iconsminds-male"} 
                            />
                          </NavLink>

                          <NavLink
                            to={`/app/products/${props.original.productId}`}>
                              <div 
                              style={{fontSize:17,marginLeft:20,marginTop:5}} 
                              className={"simple-icon-info"} 
                            />
                          </NavLink>
                      </Row>
                    }
                  ]}
                  defaultPageSize={50}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  PaginationComponent={DataTablePagination}
                  className={"react-table-fixed-height"}
                />

                <div
                  onClick={()=> {
                    this.exportToExcel();
                  }} 
                  className="d-flex justify-content-end" style={{flex:1,cursor:"pointer"}}>
                  Download to Excel 
                  <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                </div>

                {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                  <div style={{display:'flex',flexDirection:'row',marginRight:15}}>
                    Export
                    <div style={{marginLeft:5}} className={"glyph-icon iconsminds-right-1"}/>
                  </div>

                  <div style={{display:'flex',flexDirection:'row'}}>
                    Print 
                    <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-printer"}/>
                  </div>
                </div> */}
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        
      </Fragment>
    );
  }
}
const mapStateToProps = ({ menu }) => {
  const {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems
  } = menu;
  return {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems
  };
};

export default connect(
  mapStateToProps,
  {
    setContainerClassnames,
    changeDefaultClassnames
  }
)(UserProductsNearingCompletion);
