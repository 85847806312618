import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import home from "./home";
import dispatch from "./dispatch/motors";
import details from "./dispatch/dispatchDetails";

const Deliveries = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
      <Route path={`${match.url}/home`} component={home} />
      <Route path={`${match.url}/dispatch/:id`} exact component={details} />
      <Route path={`${match.url}/dispatch`} component={dispatch} />

      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Deliveries;
