import React, { useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { NavLink } from "react-router-dom";

const formatDate = (_date) => {
  // 3:16pm | Mar 2 2021
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
}


const RecentMessages = ({ data, className = "", displayRate = false }) => {
  const [failedImages, setFailedImages] = useState({});

  return (
    <Card className={className}>
      <CardBody style={{paddingBottom:10}}>
        <CardTitle>
          Recent Messages
        </CardTitle>
        <div className="">
          {/* <PerfectScrollbar
            option={{ suppressScrollX: true, wheelPropagation: false }}
          > */}
            {data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex flex-row mb-3 pb-3 border-bottom"
                >
                  {/* <NavLink to="/app/pages/details"> */}
                    <img
                      src={failedImages[item.id] ? '/assets/img/no-image.jpg' : item.senderId} //DEV FORM REAL LINK
                      alt={item.title}
                      onError={()=> {
                        const temp = Object.assign({}, failedImages);
                        temp[item.id] = true;
                        setFailedImages(temp);
                      }}
                      className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                    />
                  {/* </NavLink> */}

                  <div className="pl-3 pr-2">
                    {/* <NavLink to="/app/pages/details"> */}
                      <p className="font-weight-medium mb-0">{item.senderName}</p>
                      <p className="text-muted mb-0 font-weight-medium">
                        {item.body}
                      </p>
                      
                        <div className="form-group mb-1 mt-2">
                          {formatDate(item.timestamp)}
                        </div>
                  </div>
                </div>
              );
            })}
          {/* </PerfectScrollbar> */}
        </div>
      </CardBody>
    </Card>
  );
};

export default RecentMessages;
