import React from "react";
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const search_products_url = 'https://api.tuatuagye.com/search-product';

export default class ProductsAutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  loadOptions = (inputValue, callback) => {
    axios.get(`${search_products_url}?name=${inputValue}`)
      .then(res => {
        callback(res.data.map(product => {
          product.label = `GH₵ ${product.posTTGPrice} - ${product.name}`;
          product.value = `${product.id}`
          return product;
        }));
      })
      .catch(error => {
        console.log('Error...',error);
      })
  };

  onChange = (newValue) => {
    // console.log('on change',newValue);
    this.props.onChange(newValue);
  };

  render() {
    return (
      <AsyncSelect
        cacheOptions
        loadOptions={this.loadOptions}
        defaultOptions
        onChange={this.onChange}
      />
    );
  }
}