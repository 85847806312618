import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import customers from "./customers";
import merchants from "./merchants";
import admins from "./admins";
import customerDetails from "./customerDetails";
import merchantDetails from "./merchantDetails";

const Users = ({ match, user }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/customers`} />
      <Route path={`${match.url}/customers/:id`} exact component={customerDetails} />
      {user.type == 1 && <Route path={`${match.url}/admins`} component={admins} />}
      {user.type < 3 && (<Route path={`${match.url}/merchants/:id`} exact component={merchantDetails} />)}
      <Route path={`${match.url}/customers`} component={customers} />
      {user.type < 3 && (<Route path={`${match.url}/merchants`} component={merchants} />)}

      <Redirect to="/error" />
    </Switch>
  </div>
);

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return { user };
};

export default connect(
    mapStateToProps,
    {}
)(Users);