import React from "react";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, CardBody, CardTitle } from "reactstrap";

// import IntlMessages from "../../helpers/IntlMessages";

const img_base_url = 'https://api.tuatuagye.com/get-image?type=profile&id='

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  const strTime = hours + ':' + minutes + '' + ampm;

  let mon = date.getMonth()+1;
  if(mon < 10){
      mon = '0'+mon;
  }

  let day = date.getDate();
  if(day < 10){
      day = '0'+day;
  }

  return `${strTime} | ${day}.${mon}.${date.getFullYear()}`;
}

const NewUsers = ({ data }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle>
          New Users
        </CardTitle>
        <div className="dashboard-list-with-user">
          <PerfectScrollbar
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            {data.map((user, index) => {
              return (
                <div
                  key={index}
                  className="d-flex flex-row mb-3 pb-3 border-bottom"
                >
                  <NavLink to={`/app/users/${user.type === 4 ? 'merchants' : 'customers'}/${user.id}`}>
                    <img
                      // src='https://widgetwhats.com/app/uploads/2019/11/free-profile-photo-whatsapp-4.png'
                      src={user.imageThumbnail ? `${img_base_url}${user.imageThumbnail}`: '/assets/img/no-image.jpg'}
                      alt=''
                      className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                    />
                  </NavLink>

                  <div className="pl-3 pr-2">
                    <NavLink to={`/app/users/${user.type === 4 ? 'merchants' : 'customers'}/${user.id}`}>
                      <p className="font-weight-medium mb-0 ">{user.firstname} {user.lastname}</p>
                      <p className="text-muted mb-0 text-small">
                        {formatTimestamp(user.createdAt)}
                      </p>
                    </NavLink>
                  </div>
                </div>
              );
            })}
          </PerfectScrollbar>
        </div>
      </CardBody>
    </Card>
  );
};
export default NewUsers;
