import React, { Component, Fragment } from "react";
import { Card, CardBody, CardTitle, Row, Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter } from "reactstrap";
import ReactTable from "react-table";
import Resizer from "react-image-file-resizer";
import axios from 'axios';

import Pagination from "../../components/DatatablePagination";
import { NotificationManager } from "../../components/common/react-notifications";

const BASE_PRODUCT_IMAGE_URL = 'https://api.tuatuagye.com/get-image'
const image_upload_url = 'https://api.tuatuagye.com/upload-product-images';
const banners_url = 'https://api.tuatuagye.com/top-web-banners'

class TopWebBanners extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            fullscreenImageModalOpen: false,
            processing: false,
            selectedBanner: {},
            fileUrl:'',
            file: null,
            loading: true,
            topBanners: [],
            searchKey: ''
        }

        this.inp = null;

        this.columns = [
            {
            Header: "Info",
            accessor: "name",
            Cell: props => <Row style={{marginLeft:0,marginRight:0,alignItems:'center'}}>
                <img 
                style={{width:90,height:50,borderRadius:3,marginRight:10,backgroundColor:'#ccc'}} 
                src={`${BASE_PRODUCT_IMAGE_URL}?type=product&id=${props.original.imageId}`}
                alt=""
                />
                <span style={{fontSize:16}}>Filter: '{props.value}'</span>
            </Row>
            },
            {
                Header: "Action",
                accessor: "",
                width: 90,
                Cell: props => <div
                    onClick={()=> {
                        this.setState({selectedBanner: props.original, searchKey: props.original.name});
                        this.toggleFullScreenImageModal();
                    }}
                    style={{cursor:'pointer',fontSize:14,paddingTop:25}}  
                    className={"glyph-icon iconsminds-pen-2"} 
                />
            }
        ];
    }

    componentDidMount() {
        axios.get(`${banners_url}`)
            .then(res=> {
                this.setState({
                    topBanners: res.data, loading: false
                })
            })
            .catch(err=> {
                console.log('banner fetch err',err);
                this.setState({loading: false});

                NotificationManager.error(
                    "Loading Web Banners Error",
                    "An unknown error occurred. Please try again.",
                    3000,
                    null,
                    null,
                );
            })
    
    }

    imageSelected = (event) => {
        // console.log('file is ',event.target.files[0]);;
        this.setState({file: event.target.files[0]})
        const reader = new FileReader();
    
        const _func = (e) => this.setState({fileUrl: e.target.result})
        
        reader.onload = function (e) {
          _func(e)
        }
    
        reader.readAsDataURL(event.target.files[0]);
      }

    toggleFullScreenImageModal = () => {
        this.setState({fullscreenImageModalOpen: !this.state.fullscreenImageModalOpen, fileUrl: ''})
    }

    resizeFile = (file, w, h) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                w,
                h,
                "JPEG",
                90,
                0,
                (uri) => {
                resolve(uri);
                },
                "file"
            );
        });

    handleKeyUpdate = async () => {
        this.setState({processing:true});
        const { searchKey } = this.state;
        const bannerId = this.state.selectedBanner.id;

        if(!bannerId) {
            alert('An unknown error occurred.')
            return;
        }
        axios.patch(`${banners_url}/${bannerId}`,{ name: this.state.searchKey })
            .then(res=> {
                if(res){
                    const tmp = this.state.topBanners.map(tb=> {
                        if(tb.id == bannerId){
                            tb.name = searchKey;
                        }
                        return tb;
                    })
                    this.setState({topBanners: tmp, processing:false});

                    this.toggleFullScreenImageModal();
                }
            })
            .catch(error=> {
                console.log('error',error);
                this.setState({processing:false});
                NotificationManager.error(
                    "Upload Error",
                    "An unknown error occurred. Please check console for details or try again.",
                    3000,
                    null,
                    null,
                );
            })
    }
        
    handleSubmit = async () => {
        this.setState({processing:true});
        const { file } = this.state;
        const image = await this.resizeFile(file, 1080, 568);
        const data = new FormData();
    
        data.append('images', image);

        let imageId = '';

        axios.post(image_upload_url,data)
            .then(res=> {
                // console.log('upload success',res.data);
                if(res.data.ids && res.data.ids[0]){
                    imageId = res.data.ids[0];

                    const bannerId = this.state.selectedBanner.id;

                    if(bannerId){
                        return axios.patch(`${banners_url}/${bannerId}`,{ imageId })
                    }
                }
                
                return null;
            })
            .then(res=> {
                if(res){
                    const tmp = this.state.topBanners.map(tb=> {
                        if(tb.id === this.state.selectedBanner.id){
                            tb.imageId = imageId;
                        }
                        return tb;
                    })
                    this.setState({topBanners: tmp, processing:false});

                    this.toggleFullScreenImageModal();
                }
            })
            .catch(error=> {
                console.log('error',error);
                this.setState({processing:false});
                NotificationManager.error(
                    "Upload Error",
                    "An unknown error occurred. Please check console for details or try again.",
                    3000,
                    null,
                    null,
                );
            })
    }

    render() {
        const { fullscreenImageModalOpen, searchKey, loading, fileUrl, processing, selectedBanner } = this.state;
        return (
            <Fragment>
                <Card className="h-100">
                    <CardBody>
                        <CardTitle>
                            Web Top Banners
                        </CardTitle>
                        {loading ? 
                            <p>Loading...</p>
                            :
                            <ReactTable
                                defaultPageSize={6}
                                data={this.state.topBanners}
                                columns={this.columns}
                                minRows={0}
                                showPageJump={false}
                                showPageSizeOptions={false}
                                PaginationComponent={Pagination}
                            />
                        }
                    </CardBody>

                    <Modal
                        isOpen={fullscreenImageModalOpen}
                        toggle={this.toggleFullScreenImageModal}
                    >
                        {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
                        <div className="loading" />  
                        </div>}
                        <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleFullScreenImageModal}>
                            Banner Details | {selectedBanner.name}
                        </ModalHeader>
                        <ModalBody style={{padding:0}}>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                {fileUrl.length > 0 ? <img onDoubleClick={()=> this.inp.click()} style={{width:600,maxWidth:'98%',borderRadius:3}} src={fileUrl} alt="Img"/> :<img onDoubleClick={()=> this.inp.click()} style={{width:600,maxWidth:'98%',borderRadius:3}} src={`${BASE_PRODUCT_IMAGE_URL}?type=product&id=${selectedBanner.imageId}`} alt="Img"/>}
                                <input type='file' accept="image/jpeg, image/jpg, image/png" ref={(inp)=> this.inp = inp} onChange={this.imageSelected} name='image' hidden={true} />
                            </div>
                            <div style={{padding:10}}>
                                <input type="text" value={searchKey} style={{paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:120}} onChange={(event)=> this.setState({searchKey: event.target.value})} />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {searchKey.length > 0 && (<Button
                                color="secondary"
                                onClick={this.handleKeyUpdate}
                                style={{marginRight:20}}
                            >
                                Update Filter Key
                            </Button>)}{" "}
                            {fileUrl.length > 0 && (<Button
                                color="success"
                                onClick={this.handleSubmit}
                                style={{marginRight:20}}
                            >
                                Update Image
                            </Button>)}{" "}
                            <Button
                                color=""
                                onClick={this.toggleFullScreenImageModal}
                            >
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Card>
            </Fragment>
        );
    }
};
export default TopWebBanners;