import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label, Row, ModalFooter, Button
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";

import ReactTable from "react-table";
import { connect } from "react-redux";

import axios from 'axios';

import DataTablePagination from "../../components/DatatablePagination";
const base_image_url = 'https://api.tuatuagye.com/get-profile-image';

const commissions_url = 'https://api.tuatuagye.com/merchant-commissions'

const formatAmount = (amount) => {
  if(!amount) return '0.00';
  if(amount > 999){
    let temp = amount.toString();
    let decimal = '.00';

    if(temp.indexOf('.') > -1){
      decimal = '.'+temp.split('.')[1];
      temp = temp.split('.')[0];
    }
    
    temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
    return temp;
  }
  
  return Number(amount).toFixed(2);
}

const __getTime = (_date) => {
  // 3:16pm
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  // let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime}`;
}

const __getDate = (_date) => {
  // Mar 2
  if(!_date) return '';
  const date = new Date(_date);

  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${months[date.getMonth()]} ${date.getDate()}`;
}

class MerchantCommissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commissions: props.merchant.entities || [],
      selectedPayment: {},
      selectedCollection: {},
      deleteModal: false,
      deleteText: '',
      adminCode: '',
      deleting: false,
      updateBalance: false,
      showPayAllBtn: true,
      processing: false
    }

    this.columns = [
      {
        Header: "Date",
        accessor: "calculatedAt",
        Cell: props => <p className="list-item-heading">{__getDate(props.value)} | {__getTime(props.value)}</p>
      },
      {
        Header: "Collections",
        accessor: "collectionsCommission",
        Cell: props => <p className="text-muted truncated">{formatAmount(props.value)}</p>
      },
      {
        Header: "Signups",
        accessor: "signUpsCommission",
        // width: 100,
        headerStyle:{textAlign:'center'},
        Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{formatAmount(props.value)}</p>
      },
      {
        Header: "Cash Sales",
        accessor: "cashSalesCommission",
        // width: 100,
        headerStyle:{textAlign:'center'},
        Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{formatAmount(props.value)}</p>
      },
      {
        Header: "Amount",
        accessor: "amount",
        headerStyle: {textAlign:'center'},
        width:80,
        Cell: props => <p className="list-item-heading" style={{textAlign:'center'}}>{formatAmount(props.original.collectionsCommission + props.original.signUpsCommission + props.original.cashSalesCommission)}</p>
      },
      {
        Header: "Status",
        accessor: "paidStatus",
        headerStyle: {textAlign:'center'},
        width:80,
        Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{props.value == 1 ? "PAID":"PENDING"}</p>
      },
      // {
      //   Header: "No. of Calc.",
      //   accessor: "noOfCals",
      //   width: 120,
      //   headerStyle:{textAlign:'center'},
      //   Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{props.value}</p>
      // },
      // {
      //   Header: "Action",
      //   accessor: "",
      //   width:80,
      //   Cell: ({ original }) => (
      //     <div
      //       onClick={()=> {
      //         // console.log('or',original);
      //       //   this.setState({selectedCollection: original, selectedPayment: original.payment, deleteModal: true})
      //       }}
      //       style={{color:'green',cursor:'pointer',fontSize:16}} 
      //       className={"glyph-icon simple-icon-wallet ml-1"} 
      //     />
      //   )
      // }
    ]
  }

  componentDidMount() {
    if(this.props && this.props.commissionsData){
      console.log('commissionsData',this.props.commissionsData);
      // this.getCommissions(this.props.merchant.id);
      this.setState({commissions: this.props.commissionsData})
    }
  }

  getCommissions(id) {
    axios.get(`${commissions_url}?filter[where][merchantId]=${id}&filter[where][paidStatus]=0&filter[order]=createdAt desc`)
      .then(r=> {
        this.setState({commissions: r.data})
      })
      .catch(e=> {
        console.log('failed to get commissions');
      })
      .finally(f=> this.setState({processing: false}))
  }

  toggleDeleteModal = () => {
    this.setState({deleteModal: !this.state.deleteModal})
  }

  payAllCommissions = () => {
    const { commissions, selectedPayment, selectedCollection, updateBalance } = this.state;
    // return;
    this.setState({processing: true})
    const promises = [];
    if(commissions.forEach){
      commissions.forEach(c=> {
        if(c.id){
          const p = axios.patch(`${commissions_url}/${c.id}`,{paidStatus: 1});
          promises.push(p);
        }
      })

      if(promises.length > 0){
        Promise.all(promises)
          .then(r=> {
            if(r.length > 0){
              const x = this.state.commissions.map(c=> {
                c.paidStatus = 1;
                return c;
              })
              this.setState({showPayAllBtn: false, commissions: x})
              NotificationManager.success(
                "Success",
                "All payments processed successfully",
                3000,
                null,
                null,
              );

              this.toggleDeleteModal();
            }
            else {
              NotificationManager.error(
                "Incorrect Output",
                "Unknow failure. Please try again.",
                3000,
                null,
                null,
            );
            }
          })
          .catch(e=> {
            NotificationManager.error(
              "Error",
              "An error occurred, please try again",
              3000,
              null,
              null,
            );
          })
          .finally(()=> this.setState({processing: false}))
      }
    }
  }

  render(){
    const { merchant, modalOpen, userDetails, toggleModal } = this.props;
    const { deleting, deleteModal, deleteText, commissions, processing, selectedPayment, adminCode } = this.state;
    return (
      <Modal
        style={{width:850}}
        isOpen={modalOpen}
        toggle={toggleModal}
        wrapClassName="modal-right"
      >
        {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
        <ModalHeader style={{alignItems:"center"}} toggle={toggleModal}>
          <img 
            style={{width:"60px",height:"60px",borderRadius:"30px",marginRight:"10px"}} 
            src={`${base_image_url}?type=main&id=${merchant.userId}`} 
            />
          <Label>{userDetails.firstname}'s Daily Commissions</Label>
        </ModalHeader>
        <ModalBody style={{padding:"0px"}}>
          
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',margin:20}}>
            <h5 style={{color:'#008894'}}>
                Total GHS {formatAmount(this.props.merchant.total)}
            </h5>
            {this.state.showPayAllBtn && this.props.merchant.total > 0 && (
              <Button
                disabled={deleting}
                color="default"
                onClick={this.toggleDeleteModal}
              >
                Pay All
              </Button>
            )}
          </div>
          <Colxx xxs="12">
            <ReactTable
                data={commissions}
                columns={this.columns}
                defaultPageSize={20}
                // filterable={true}
                showPageJump={true}
                PaginationComponent={DataTablePagination}
                showPageSizeOptions={true}
            />
          </Colxx>
        </ModalBody>

        {/* DELETE */}
        <Modal
          isOpen={deleteModal}
          toggle={()=> {
            if(!deleting){
              this.toggleDeleteModal();
            }
          }}
          // className={props.className}
        >
          {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
          <ModalHeader 
            toggle={()=> {
              if(!deleting){
                this.toggleDeleteModal();
              }
            }}
            style={{alignItems:"center",padding:""}} 
          >
            <Label>
              Pay all {userDetails.firstname}'s Commissions 
            </Label>
          </ModalHeader>
          <ModalBody>
            <h6>To pay all pending commissions - <b>GHS {Number(this.props.merchant.total).toFixed(2)}</b> - type <b>pay all now</b> in the box below and provide your authorization code as well.</h6>
            <Row style={{paddingLeft:16,marginTop:20}}>
              <input
                disabled={deleting} 
                type="text" 
                value={deleteText} 
                style={{outline:'none',height:45,paddingLeft:10}} 
                onChange={(event)=> {
                  this.setState({deleteText: event.target.value});
                }}
              />

              <label style={{marginLeft:20}}>
                Authorization Code:
                <input disabled={deleting} type="password" value={adminCode} style={{marginLeft:20,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
              </label>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={deleting}
              color="default"
              onClick={this.toggleDeleteModal}
            >
              Cancel
            </Button>{" "}
            <Button
              disabled={deleting}
              color="success"
              onClick={()=> {
                if(deleteText !== 'pay all now'){
                  NotificationManager.error(
                    "Incorrect input",
                    "Review your input and try again",
                    3000,
                    null,
                    null,
                );
                }
                else if(!adminCode){
                    NotificationManager.error(
                        "Authorization Code",
                        "Your security code required to perform this transaction. Thank you.",
                        3000,
                        null,
                        null,
                    );
                }
                else if(adminCode !== this.props.user.authCode) {
                    NotificationManager.error(
                        "Invalid Code",
                        "Sorry, the code you entered is invalid. Check and try again.",
                        3000,
                        null,
                        null,
                    );
                }
                else {
                  this.payAllCommissions();
                }
              }}
            >
              {deleting ? 'Please wait...' : 'Pay Now'}
            </Button>
            
          </ModalFooter>
        </Modal>
      </Modal>
    );
  }
};

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return {user};
};

export default connect(
  mapStateToProps,
  {}
)(MerchantCommissions);