import React, {useState} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../helpers/IntlMessages";
import ProductsAutoSuggest from "./ProductsAutoSuggest";
import axios from 'axios';
import { connect } from "react-redux";
import { NotificationManager } from "../components/common/react-notifications";

const pod_url = 'https://api.tuatuagye.com/pods';

const formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
      return temp;
    }
    
    return Number(amount).toFixed(2);
}

const AddNewOrderModal = ({ user, onAdd, modalOpen, toggleModal }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [customerName, setCustomerName] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerLocation, setCustomerLocation] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [processing, setProcessing] = useState(false);
    
    const [confirmModal, setConfirmModal] = useState(false);
    const [adminCode, setAdminCode] = useState('')
    const toggleConfirmModal = () => {
        setConfirmModal(!confirmModal);
    }
    
    const onChange = (val) => {
        console.log('value changed...',val);
        setSelectedProduct(val);
    }

    const addOder = () => {
        const payload = {
            customerName,
            customerPhone,
            customerId: `new_${Date.now()}`,
            customerLocation,
            productName: selectedProduct.name,
            productId: selectedProduct.id,
            quantity: 1,
            amount: selectedProduct.cashPrice
        }
        console.log('order payload,,,',payload);
        
        axios.post(`${pod_url}`,payload)
            .then(res=> {
                onAdd(res.data);
                toggleConfirmModal();
                toggleModal();
                setProcessing(false);
                setSelectedProduct(null);
                setCustomerName('');
                setCustomerPhone('');
                setQuantity('');
                setCustomerLocation('');
            })
            .catch(e=> {
                console.log('err pods',e);
                setProcessing(false)
            })
    }

    return (
    <Modal
        style={{width:400}}
        isOpen={modalOpen}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
    >
      <ModalHeader toggle={toggleModal}>
        New Order
      </ModalHeader>
      <ModalBody>
        <Label>Select Product</Label>
        
        <ProductsAutoSuggest 
            onChange={onChange}
        />

        {selectedProduct &&
            <div style={{marginTop:20}}>
                <h6 style={{marginTop:10}}>
                    <b>{selectedProduct.name}</b>
                </h6>
                <h6 style={{marginTop:20}}>
                    Amount: <b>GH₵ {formatAmount(selectedProduct.cashPrice)}</b>
                </h6>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                    <h6 style={{marginRight:20}}>Customer:</h6>
                    <Input
                        style={{backgroundColor:'#eee',width:'70%'}}
                        // name="customerName"
                        // id="customerName"
                        placeholder={""}
                        value={customerName}
                        onChange={e => setCustomerName(e.target.value)}
                    />
                </div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                    <h6 style={{marginRight:20}}>Phone:</h6>
                    <Input
                        style={{backgroundColor:'#eee',width:'70%'}}
                        // name="customerPhone"
                        // id="customerPhone"
                        placeholder={""}
                        value={customerPhone}
                        onChange={e => setCustomerPhone(e.target.value)}
                    />
                </div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                    <h6 style={{marginRight:20}}>Location:</h6>
                    <Input
                        style={{backgroundColor:'#eee',width:'70%'}}
                        // name="customerLocation"
                        // id="customerLocation"
                        placeholder={""}
                        value={customerLocation}
                        onChange={e => setCustomerLocation(e.target.value)}
                    />
                </div>
                {/* <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                    <h6 style={{marginRight:20}}>Quantity:</h6>
                    <Input
                        style={{backgroundColor:'#eee',width:'70%'}}
                        name="quantity"
                        min={1}
                        id="quantity"
                        placeholder={""}
                        value={quantity}
                        onChange={e => setQuantity(e.target.value)}
                    />
                </div> */}
            </div>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="default" outline onClick={toggleModal}>
          <IntlMessages id="pages.cancel" />
        </Button>
        <Button color="primary" onClick={toggleConfirmModal}>
          Order
        </Button>{" "}
      </ModalFooter>

      <Modal
        isOpen={confirmModal}
        toggle={toggleConfirmModal}
    >
        <ModalHeader style={{alignItems:"center",padding:""}} toggle={toggleConfirmModal}>
            <Label>Confirm Order </Label>
        </ModalHeader>
        <ModalBody>
            {processing && <div className='loading'/>}
            <h6>Complete order by providing your <b>authorization code</b> in the box below</h6>
            <label style={{}}>
            Authorization Code:
            <input type="password" value={adminCode} style={{paddingBottom:10,marginLeft:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> setAdminCode(event.target.value)} />
            </label>
        </ModalBody>
        <ModalFooter>
        <Button
            color="default"
            onClick={toggleConfirmModal}
        >
            Cancel
        </Button>{" "}
        <Button
            color="primary"
            onClick={()=> {
                if(!adminCode){
                    NotificationManager.error(
                        "Authorization Code",
                        "Your security code required to perform this transaction. Thank you.",
                        3000,
                        null,
                        null,
                    );
                }
                else if(adminCode !== user.authCode) {
                    NotificationManager.error(
                        "Invalid Code",
                        "Sorry, the code you entered is invalid. Check and try again.",
                        3000,
                        null,
                        null,
                    );
                }
                else {
                    setProcessing(true);
                    addOder();
                }
            }}
        >
            Order Now
        </Button>
        
        </ModalFooter>
    </Modal>
    </Modal>
  );
};

const mapStateToProps = ({ authUser }) => {
    const { user } = authUser;
    return {user};
  };
  
  export default connect(
    mapStateToProps,
    {}
  )(AddNewOrderModal);