import React from "react";

import ReactSiemaCarousel from "../../components/ReactSiema/ReactSiemaCarousel";
import IconCard from "../../components/cards/IconCard";

const MiniHomeStats = ({data, className="icon-cards-row"}) => {
  const sliderPerPage = {
    0: 1,
    320: 2,
    576: 3,
    1800: 4
  };

  function navigateToDelivery(_tag) {
    window.open(`/app/deliveries/home?tag=${_tag}`,'_blank');
  }

  return (
    <div className={className}>
      <ReactSiemaCarousel perPage={sliderPerPage} controls={false} loop={false}>
        {data.map((item, index) => {
          return (
            <div key={`${index}`} onClick={()=> navigateToDelivery(item.tag)}>
              <IconCard {...item} className="mb-4"/>
            </div>
          );
        })}
      </ReactSiemaCarousel>
    </div>
  );
};
export default MiniHomeStats;
