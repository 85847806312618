import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label, Row, ModalFooter, Button
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";
import ReactTable from "react-table";
import * as FileSaver from 'file-saver';

import axios from 'axios';

import DataTablePagination from "../../components/DatatablePagination";
const base_image_url = 'https://api.tuatuagye.com/get-profile-image';

const signups_url = 'https://api.tuatuagye.com/sign-ups'

const formatAmount = (amount) => {
  if(!amount) return '0.00';
  if(amount > 999){
    let temp = amount.toString();
    let decimal = '.00';

    if(temp.indexOf('.') > -1){
      decimal = '.'+temp.split('.')[1];
      temp = temp.split('.')[0];
    }
    
    temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
    return temp;
  }
  
  return Number(amount).toFixed(2);
}

const formatDate = (_date) => {
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
}

const __getTime = (_date) => {
  // 3:16pm
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime}`;
}

const __getDate = (_date) => {
  // Mar 2
  if(!_date) return '';
  const date = new Date(_date);

  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${months[date.getMonth()]} ${date.getDate()}`;
}

const MerchantSignups = ({ merchant, modalOpen, toggleModal, startDate, endDate }) => {
  const [selectedPayment, setSelectedPayment] = useState(57);
  const [amount, setAmount] = useState(1);
  const [modalNestedContainer,setModalNestedContainer] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [deleteModal,setDeleteModal] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const [adminCode, setAdminCode] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);

    useEffect(()=> {
      if(merchant && merchant.id){
        axios.get(`${signups_url}?filter[where][merchantId]=${merchant.id}&filter[include][]=user&filter[where][and][0][createdAt][gte]=${startDate.toJSON()}&filter[where][and][1][createdAt][lte]=${endDate.toJSON()}&filter[order]=createdAt%20DESC`)
          .then(res => {
              if(res.data && res.data.length > 0){
                  let _total = 0;
                  let _count = 0;
                  res.data.forEach(signup => {
                    _total += signup.amount;
                    _count += 1;
                  });

                  setTotal(Number(_total).toFixed(2));
                  setCount(_count);
                  setData(res.data);
                  setLoading(false);
              }
              else {
                  setLoading(false);
              }
          })
          .catch(error => {
              console.log('error is',error);
              NotificationManager.error(
                  "Load Error",
                  "An unknown error occurred. Please check console for details or try again.",
                  3000,
                  null,
                  null,
              );
          })
    }
    else {
        NotificationManager.error(
            "No Merchant Info",
            "An error occurred. Please check console for details or try again.",
            3000,
            null,
            null,
        );
    }
        return ()=> {}
    },[])

  const dataTableColumns = [
    {
      Header: "Customer",
      accessor: "user",
      Cell: props => <p className="list-item-heading">{props.value.firstname} {props.value.lastname}</p>
    },
    {
      Header: "Product",
      accessor: "productName",
      Cell: props => <p className="text-muted truncated">{props.value}</p>
    },
    {
      Header: "Amount",
      accessor: "amount",
      headerStyle: {textAlign:'center'},
      width:80,
      Cell: props => <p className="list-item-heading" style={{textAlign:'center'}}>{formatAmount(props.value)}</p>
    },
    {
        Header: "Date & Time",
        accessor: "createdAt",
        width: 100,
        headerStyle:{textAlign:'center'},
        Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{__getTime(props.value)}<br/>{__getDate(props.value)}</p>
    },
    // {
    //   Header: "Mode",
    //   accessor: "payment",
    //   headerStyle: {textAlign:'center'},
    //   width:80,
    //   Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{props.value.mode}</p>
    // }
  ];

  const toggleNestedContainer = () => {
    setModalNestedContainer(!modalNestedContainer);
  };

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const exportToExcel = () => {
    const _data = data.map((record, i)=> {
      return {
        Customer: `${record.user.firstname} ${record.user.lastname}`,
        Product: record.productName,
        Amount: formatAmount(record.amount),
        'Date & Time': `${__getTime(record.createdAt)} | ${__getDate(record.createdAt)}`,
        // Mode: record.payment ? record.payment.mode : 'N/A',
        // 'MoMo Number': record.payment ? record.payment.momoNumber : 'N/A',
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(_data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, `${merchant.name} Signups Data - ${new Date().toJSON()}`);
    });
  }

  const saveAsExcelFile = (buffer, fileName) => {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  return (
    <Modal
      style={{width:600}}
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName="modal-right"
    >
      <ModalHeader style={{alignItems:"center"}} toggle={toggleModal}>
        <img 
          style={{width:"60px",height:"60px",borderRadius:"30px",marginRight:"10px"}} 
          src={`${base_image_url}?type=main&id=${merchant.id}`} 
          />
        <Label>{merchant.name}'s Sign-ups </Label>
      </ModalHeader>
      <ModalBody style={{padding:"0px"}}>
        {loading && <div className="loading"/>}
        {!loading && 
          // <h5 style={{paddingLeft:20,paddingTop:20}}>
          //   <span>{count}</span> | Total <span style={{color:'#008894'}}>GHS {formatAmount(total)}</span>
          // </h5>

          <div style={{display:'flex', alignItems:'center',paddingLeft:20,paddingTop:20}}> 
            <h5>
              <span>{count}</span> | Total <span style={{color:'#008894'}}>GHS {formatAmount(total)}</span>
            </h5>

            <div
              onClick={()=> {
                exportToExcel();
              }} 
              className="d-flex justify-content-end" style={{flex:1,cursor:"pointer",color:'#008894'}}>
              Download to Excel 
              <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
            </div>
          </div>
        }
        <Colxx xxs="12">
          <ReactTable
              data={data}
              columns={dataTableColumns}
              defaultPageSize={20}
              // filterable={true}
              showPageJump={true}
              PaginationComponent={DataTablePagination}
              showPageSizeOptions={true}
          />
        </Colxx>
      </ModalBody>
      
      <Modal
        isOpen={modalNestedContainer}
        toggle={toggleNestedContainer}
        // className={props.className}
      >
        <ModalHeader style={{alignItems:"center",padding:""}} toggle={toggleNestedContainer}>
          <img style={{width:"60px",height:"60px",borderRadius:"30px",marginRight:"10px"}} src="/assets/img/profile-pic-l-4.jpg" alt="Avatar"/>
          <Label>Edit {merchant.firstname}'s Collection </Label>
        </ModalHeader>
        <ModalBody>
          <h6>Editing <b>GH₵ {formatAmount(selectedPayment.amount)}</b>, payment made at <b>{formatDate(selectedPayment.createdAt)}</b></h6>
          <Row style={{paddingLeft:16,marginTop:20}}>
            <label>
              Amount:
              <input type="number" value={amount} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> setAmount(event.target.value)} />
            </label>

            <label style={{marginLeft:20}}>
              Authorization Code:
              <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> setAdminCode(event.target.value)} />
            </label>
          </Row>
          <Modal
            isOpen={nestedModal}
            toggle={toggleNested}
            onClosed={closeAll ? toggleNestedContainer: undefined}
          >
            <ModalHeader>Confirm Payment Edit</ModalHeader>
            <ModalBody>
              <h5>Are you sure you want to change payment of <b>GH₵ {formatAmount(selectedPayment.amount)}</b> to an amount of <b>GH₵ {formatAmount(amount)}?</b></h5>
            </ModalBody>
            <ModalFooter>
              <Button color="default" onClick={toggleNested}>
                Cancel
              </Button>{" "}
              <Button color="primary" onClick={toggleAll}>
                Yes, Change Payment
              </Button>
            </ModalFooter>
          </Modal>
        </ModalBody>
        <ModalFooter>
        <Button
            color="default"
            onClick={toggleNestedContainer}
          >
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            onClick={()=> {
              if(Number(amount) < 1){
                alert('Amount required')
              }
              else {
                toggleNested();
              }
            }}
          >
            Update
          </Button>
          
        </ModalFooter>
      </Modal>


      {/* DELETE */}
      <Modal
          isOpen={deleteModal}
          toggle={toggleDeleteModal}
          // className={props.className}
        >
          <ModalHeader style={{alignItems:"center",padding:""}} toggle={toggleDeleteModal}>
            <img style={{width:"60px",height:"60px",borderRadius:"30px",marginRight:"10px"}} src="/assets/img/profile-pic-l-4.jpg" alt="Avatar"/>
            <Label>
              Delete {merchant.firstname}'s Collection 
            </Label>
          </ModalHeader>
          <ModalBody>
            <h6>To delete this collection type <i><b>permanently delete</b></i> in the box below</h6>
            <Row style={{paddingLeft:16,marginTop:20}}>
              <input 
                type="text" 
                value={deleteText} 
                style={{outline:'none',height:45}} 
                onChange={(event)=> {
                  setDeleteText(event.target.value);
                }}
              />

              <label style={{marginLeft:20}}>
                Authorization Code:
                <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> setAdminCode(event.target.value)} />
              </label>
            </Row>
          </ModalBody>
          <ModalFooter>
          <Button
              color="default"
              onClick={toggleDeleteModal}
            >
              Cancel
            </Button>{" "}
            <Button
              color="danger"
              onClick={()=> {
                if(deleteText !== 'permanently delete'){
                  alert('Your input is incorrect. Please check and try again.')
                }
                else {
                  toggleDeleteModal();
                }
              }}
            >
              Delete Now
            </Button>
            
          </ModalFooter>
        </Modal>

    </Modal>
  );
};

export default MerchantSignups;
