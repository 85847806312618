import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  // OverlayView
} from "react-google-maps";

// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import axios from 'axios';
import { NotificationManager } from "../../components/common/react-notifications";

import SelectMerchantOption from "../../containers/SelectMerchantOption";

// const getPixelPositionOffset = (width, height) => ({
//   x: -(width / 2),
//   y: -(height / 2),
// })

const containerStyle = {
  width: '400px',
  height: '400px'
};

const collections_url = 'https://api.tuatuagye.com/collections'

const onMarkerClick = (evt) => {
  // console.log('njkjghkj');
};


// https://www.google.com/maps/@5.6131584,-0.2162688,12z
const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={12} onClick={(ev)=> console.log('aaa',ev)} defaultCenter={{ lat: props.coordinates[0] ? props.coordinates[0].lat : 5.6131584, lng: props.coordinates[0] ? props.coordinates[0].lng : -0.2162688 }}>
      {props.coordinates.map(coord => <Marker onClick={onMarkerClick} key={`${Math.random()}`} position={{ lat: coord.lat, lng: coord.lng }} />)}
      {/* {props.coordinates.map(coord => <OverlayView 
        position={{ lat: coord.lat, lng: coord.lng }} 
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
      >
        <h5>Test here...</h5>
      </OverlayView>)} */}
    </GoogleMap>
  ))
);

export default class MapsUi extends Component {
  constructor(props){
    super(props);

    this.state = {
      date: '',
      loading: true,
      mapCoordinates: [],
      merchant: null
    } 
  }

  componentDidMount() {
    const date = new Date();;
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    this.setState({ date });

    this.loadSampleCollections();

    // this.p()
  }

  // p() {
  //   const { isLoaded } = useJsApiLoader({
  //     id: 'google-map-script',
  //     googleMapsApiKey: "AIzaSyDu22se6zulB-fuE9UGzPJpYWmzEVSWxz8"
  //   }) 

  //   console.log('is loaded',isLoaded);
  // }

  loadSampleCollections() {
    axios.get(`${collections_url}?filter[where][createdAt][gte]=${new Date().toJSON().split('T')[0]}&filter[limit]=100`)
      .then(res=> {
        const _coordinates = [];
        if(res.data && res.data.length > 0){
          // console.log('len,,,',res.data.length);
          res.data.forEach(data => _coordinates.push({
            lat: Number(data.latitude), 
            lng: Number(data.longitude),
            merchantId: data.merchantId,
            merchantName: data.merchantName,
            customerId: data.customerId,
            customerProductId: data.customerProductId,
            productId: data.productId,
            createdAt: data.createdAt
          }))
        }
        this.setState({mapCoordinates: _coordinates, loading: false});
      })
      .catch(error=> {
        console.log('error...',error);
        this.setState({loading:false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
      })
  }

  changeOptions = (merchant) => {
    const  { date } = this.state;
    this.setState({merchant, loading: true})
    axios.get(`${collections_url}?filter[where][and][0][merchantId]=${merchant.id}&filter[where][and][1][createdAt][gte]=${date.toJSON()}`)
      .then(res=> {
        const _coordinates = [];
        if(res.data && res.data.length > 0){
          res.data.forEach(data => _coordinates.push({
            lat: Number(data.latitude), 
            lng: Number(data.longitude)
          }))
        }
        this.setState({mapCoordinates: _coordinates, loading: false});
      })
      .catch(error=> {
        console.log('error...',error);
        this.setState({loading:false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
      })
  }

  handleChangeDate = date => {
    this.setState({date: date});
    const { merchant } = this.state;
    this.setState({loading: false});
    let query = '';
    if(merchant){
      query = `filter[where][and][0][merchantId]=${merchant.id}&filter[where][and][1][createdAt][gte]=${date.toJSON()}`
    }
    else {
      query = `filter[where][createdAt][gte]=${date.toJSON()}&filter[limit]=100`
    }

    axios.get(`${collections_url}?${query}`)
      .then(res=> {
        const _coordinates = [];
        if(res.data && res.data.length > 0){
          res.data.forEach(data => _coordinates.push({
            lat: Number(data.latitude), 
            lng: Number(data.longitude)
          }))
        }
        this.setState({mapCoordinates: _coordinates, loading: false});
      })
      .catch(error=> {
        console.log('error...',error);
        this.setState({loading:false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
      })
  };

  render() {
    const { loading, mapCoordinates } = this.state;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Row style={{justifyContent:'space-between',alignItems:'center',marginRight:0,marginLeft:0}}>
              <h1>Collections Map</h1>
              <div style={{display:'flex'}}>
                <div style={{width:"100px"}}>
                  <DatePicker
                    selected={this.state.date}
                    onChange={this.handleChangeDate}
                    placeholderText="Date"
                    // showTimeSelect
                    // timeFormat="HH:mm"
                    timeIntervals={15}
                    // dateFormat="Pp"
                    // timeCaption="Time"
                  />
                </div>

                <div style={{width:260,marginLeft:10}}>
                  <SelectMerchantOption
                    onChange={this.changeOptions}
                  />
                </div>
              </div>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            {loading && <div className='loading'/>}
            {/* <GoogleMap
              mapContainerStyle={containerStyle}
              center={{
                lat: 5.6131584,
                lng: -0.2162688
              }}
              zoom={10}
              onLoad={()=> console.log('loaded ...')}
              // onUnmount={onUnmount}
            >
              <></>
            </GoogleMap> */}
                <MapWithAMarker
                  coordinates={mapCoordinates}
                  // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA6CBhV687ecDlgypkkHkk-LU4R2WWOkO4&v=3.exp&libraries=geometry,drawing,places"
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDu22se6zulB-fuE9UGzPJpYWmzEVSWxz8&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div className="map-item" />}
                  containerElement={<div className="map-item" />}
                  mapElement={<div className="map-item" />}
                />
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}


// const Customer = () => {
//   return (
//     <p>See me</p>
//   )
// }