import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Modal, ModalBody, ModalHeader, Label, Button, ModalFooter, Input
   } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { connect } from "react-redux";
import * as FileSaver from 'file-saver';

import ReactTable from "react-table";
import "react-datepicker/dist/react-datepicker.css";
import DataTablePagination from "../../components/DatatablePagination";
import AddNewOrder from "../../containers/AddNewOrderModal";
import axios from 'axios';
import { NotificationManager } from "../../components/common/react-notifications";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const pods_url = 'https://api.tuatuagye.com/pods';
const payments_url = 'https://api.tuatuagye.com/payments';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      amount:'',
      adminCode: '',
      selectedAmount: 34,
      isInFullScreen: false,
      searchKeyword: "",
      startDate: null,
      startDateTime: null,
      startDateRange: null,
      endDateRange: null,
      endDateTime: null,
      deleteModal: false,
      dropdownOpen: false,
      paymentType: 1,
      addOrderModalOpen: false,
      orders: [],
      totalPayments:0,
      selectedOrder: {},
      visible: true,
      processing: false,
      showDetails: false,
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
      dateModal: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.timer = 0;

    this.columns = [
      {
          Header: "Order ID",
          accessor: "orderId",
          maxWidth:90,
          height:20,
          Cell: props => <p className="list-item-heading">{props.value}</p>
      },
      {
        Header: "Customer",
        accessor: "customerName",
        maxWidth:160,
        Cell: props => <p className="list-item-heading">{props.value}</p>
      },
      {
          Header: "Phone",
          accessor: "customerPhone",
          width: 110,
          Cell: props => <p className="text-muted">{props.value}</p>
      },
      {
        Header: "Product",
        accessor: "productName",
        Cell: props => <p className="text-muted truncate">{props.value}</p>
      },
      {
        Header: "Amount",
        accessor: "amount",
        width:120,
        Cell: props => <p className="text-muted">GH₵ {this.formatAmount(props.value)}</p>
      },
      {
          Header: "Quantity",
          accessor: "quantity",
          headerStyle: {textAlign:'center'},
          width:100,
          Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{props.value}</p>
      },
      {
        Header: "Date",
        accessor: "createdAt",
        width:170,
        Cell: props => <p className="text-muted">{this.formatDate(props.value)}</p>
      },
      {
        Header: "Actions",
        accessor: "status",
        width: 100,
        headerStyle: {textAlign :'center'},
        Cell: props => <div style={{alignItems:"center",display:'flex',justifyContent:'space-between'}}>
          <div
            onClick={()=> {
                this.setState({selectedOrder: props.original, adminCode:'', showDetails: true});
              }}
            style={{cursor:'pointer',fontSize:15, color:'#4d4d4d'}}  
            className={"glyph-icon iconsminds-maximize"} 
          />
          
          {props.value === 1 && 
            <div
              onClick={()=> {
                  this.setState({selectedOrder: props.original, adminCode:''});
                  this.toggleNestedContainer();
                }}
              style={{cursor:'pointer',fontSize:18, color:'gold'}}  
              className={"glyph-icon iconsminds-checkout"} 
            />
          }
          {props.value === 1 && 
            <div 
                onClick={() => {
                    this.setState({selectedOrder: props.original, adminCode:''})
                    this.toggleDeleteModal();
                }}
              style={{color:'red',cursor:'pointer',fontSize:17}} 
              className={"glyph-icon simple-icon-trash ml-1"} 
            />
          }
          {props.value === 2 && <span style={{color:'#228822'}}>Delivered</span>}
        </div>
      }
    ]
  }

  componentDidMount() {
    const _startDateTime = new Date();
    _startDateTime.setHours(0);
    _startDateTime.setMinutes(0);
    _startDateTime.setSeconds(0);

    const _endDateTime = new Date();
    _endDateTime.setHours(23);
    _endDateTime.setMinutes(59);
    _endDateTime.setSeconds(59);

    axios.get(`${pods_url}?filter[order]=createdAt%20DESC&filter[limit]=30`)
      .then(res => {
        if(res.data && res.data.length > 0){
          let _totalPayments = 0;
          res.data.forEach(payment => _totalPayments += payment.amount);
          this.setState({orders: res.data, totalPayments: Number(_totalPayments).toFixed(2), loading:false})
        }
        else {
          this.setState({loading:false});
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
      })
  }

  toggleDateModal = () => {
    this.setState(prevState => ({
      dateModal: !prevState.dateModal,
    }));
  };

  searchWithName = (searchText) => {
      this.setState({loading: true});
      axios.get(`${pods_url}?filter[where][or][0][customerName][regexp]=/${searchText}/i&filter[where][or][1][productName][regexp]=/${searchText}/i&filter[where][or][2][orderId][regexp]=/${searchText}/i&filter[limit]=20&filter[order]=createdAt%20DESC`)
      .then(res => {
          if(res.data && res.data.length > 0){
          let _totalPayments = 0;
          res.data.forEach(payment => _totalPayments += payment.amount);
          this.setState({orders: res.data, totalPayments: Number(_totalPayments).toFixed(2), loading: false});
          }
          else {
          this.setState({totalPayments: 0, orders:[], loading:false})
          }
      })
      .catch(error => {
          this.setState({loading: false});
          NotificationManager.error(
            "Search error!",
            "An unknown error occurred. Please try again.",
            3000,
            null,
            null,
          );
          console.log('error...',error);
      })
  }

  handleChange(event) {
    this.setState({amount: event.target.value});
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  toggleNestedContainer = () => {
    this.setState(prevState => ({
      modalNestedContainer: !prevState.modalNestedContainer
    }));
  };

  toggleNested = () => {
    this.setState(prevState => ({
      nestedModal: !prevState.nestedModal,
      closeAll: false
    }));
  };

  toggleAll = () => {
    this.setState(prevState => ({
      nestedModal: !prevState.nestedModal,
      closeAll: true
    }));
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  
  toggleAddOrder = () => {
    this.setState(prevState => ({
      addOrderModalOpen: !prevState.addOrderModalOpen,
    }));
  }

  formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
      return temp;
    }
    
    return Number(amount).toFixed(2);
  }

  formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  handleChangeStartDateTime = (date) => {
    const { endDateTime } = this.state;
    this.setState({startDateTime: date});
    
    const _startDate = date.toJSON();
    let query = `filter[where][and][0][createdAt][gte]=${_startDate}`;

    if(endDateTime > date){
      const _endDate = endDateTime.toJSON();
      query += `&filter[where][and][1][createdAt][lte]=${_endDate}`
    }

    // console.log('QUERY IS',query);

    this.setState({loading: true});
    axios.get(`${pods_url}?${query}`)
      .then(res => {
        if(res.data && res.data.length > 0){
          let _totalPayments = 0;
          res.data.forEach(payment => _totalPayments += payment.amount);
          this.setState({orders: res.data, totalPayments: Number(_totalPayments).toFixed(2), loading: false});
        }
        else {
          this.setState({totalPayments: 0, orders:[], loading:false});
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Filter error!",
          "An unknown error occurred. Please try again.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  }

  onDismiss = () => {
    this.setState({visible: false})
  }

  handleRefresh = (date) => {
    const { selectionRange } = this.state;
    const startDateTime = selectionRange.startDate;
    startDateTime.setHours(0);
    startDateTime.setMinutes(0);
    startDateTime.setSeconds(0);

    const endDateTime = new Date(selectionRange.endDate);
    endDateTime.setHours(23);
    endDateTime.setMinutes(59);
    endDateTime.setSeconds(59);

    let query = `filter[where][and][0][createdAt][gte]=${startDateTime.toJSON()}&filter[where][and][1][createdAt][lte]=${endDateTime.toJSON()}`;

    console.log('QUERY IS',query);

    this.setState({loading: true});
    axios.get(`${pods_url}?${query}`)
      .then(res => {
        if(res.data && res.data.length > 0){
          let _totalPayments = 0;
          res.data.forEach(payment => _totalPayments += payment.amount);
          this.setState({orders: res.data, totalPayments: Number(_totalPayments).toFixed(2), loading: false});
        }
        else {
          this.setState({totalPayments: 0, orders:[], loading:false});
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Filter error!",
          "An unknown error occurred. Please try again.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  }

  handleSelect = (ranges) => {
		this.setState({selectionRange: {
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
			key: 'selection',
		}})
	}

  handleStatusChange = (id, status) => {
    const { orders, selectedOrder } = this.state;
    this.setState({processing: true});
    
    console.log('sel order',selectedOrder);

    axios.patch(`${pods_url}/${id}`,{ status })
      .then(res=> {
        const payload = {
          amount: selectedOrder.amount,
          type: 8,
          payeeId: selectedOrder.customerId,
          payeeName: selectedOrder.customerName,
          momoNumber: selectedOrder.customerPhone,
          payToken: `${Date.now()}_${selectedOrder.customerId}`,
          mode: 'OFFICE_PAY',
          productName: selectedOrder.productName,
          productId: selectedOrder.productId,
          myProductId: 'N/A',
        }
        
        console.log('pod payl',payload);
        return axios.post(payments_url,payload)
      })
        .then(res=> {
            const temp = orders.map(order=> {
                if(order.id === id){
                    order.status = status;
                }
                return order;
            })
            this.setState({orders: temp, processing: false});
            this.toggleNestedContainer();
            NotificationManager.success(
                "Change successful",
                "Processing was successful",
                3000,
                null,
                null,
            );                
        })
        .catch(e=> {
            console.log('err',e);
            this.setState({processing: false});
            NotificationManager.error(
                "Unknown error",
                "Oooops, an unknown error occurred during processing. Please try again.",
                3000,
                null,
                null,
            );
        })
  }

  handleDelete = (id) => {
    const { orders } = this.state;
    this.setState({processing: true});
    axios.delete(`${pods_url}/${id}`)
        .then(res=> {
            const temp = orders.filter(order=> order.id !== id);
            this.setState({orders: temp, processing: false});
            this.toggleDeleteModal();
            NotificationManager.success(
                "Delete successful",
                "Processing was successful",
                3000,
                null,
                null,
            );                
        })
        .catch(e=> {
            console.log('err',e);
            this.setState({processing: false});
            NotificationManager.error(
                "Unknown error",
                "Oooops, an unknown error occurred during processing. Please try again.",
                3000,
                null,
                null,
            );
        })
  }

  __formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let yr = `${date.getFullYear()}`.slice(2);
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
  }

  exportToExcel() {
    const data = this.state.orders.map(record=> {
      return {
        'Order ID': record.orderId,
        Customer: record.customerName,
        Phone: record.customerPhone,
        Product: record.productName,
        Amount: record.amount,
        Quantity: record.quantity,
        Location: record.customerLocation,
        'Order Date': this.__formatDate(record.createdAt)
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `Orders Data - ${new Date().toJSON()}`);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  render() {
    const { addOrderModalOpen, selectionRange, processing, adminCode, orders, selectedOrder, startDateTime, totalPayments, loading, endDateTime } = this.state;
    const { startDate, endDate } = selectionRange;
    return (
            <Fragment>
            <Row>
              <Colxx xxs="12">
                <Row style={{justifyContent:'space-between',alignItems:'center',marginLeft:0,marginRight:0}}>
                  <h3>Orders | <span style={{color:'#008894'}}>GH₵ {this.formatAmount(totalPayments)}</span></h3>
                  <div style={{display:'flex', marginBottom:10}}>
                    <div className="payments-search">
                      <Input
                        style={{backgroundColor:'#fff'}}
                        // name="searchKeyword"
                        // id="searchKeyword"
                        placeholder={"Search"
                            // messages["menu.search"]
                        }
                        value={this.state.searchKeyword}
                        onChange={e => {
                          let value = e.target.value;
                          this.setState({searchKeyword: value})
                          clearTimeout(this.timer);
                          this.timer = setTimeout(() => {
                            this.searchWithName(value);
                          }, 1000);
                        }}
                        // onKeyPress={e => this.handleSearchInputKeyPress(e)}
                      />
                      <span
                          className="search-icon"
                          style={{right:10,top:10}}
                          // onClick={e => this.handleSearchIconClick(e)}
                      >
                          <i className="simple-icon-magnifier" />
                      </span>
                    </div>

                    <div onClick={this.toggleDateModal} style={{alignItems:'center',justifyContent:'center',display:'flex',paddingRight:10,paddingLeft:10,borderRadius:3,marginLeft:"10px",color:'#fff',backgroundColor:'rgb(0, 136, 148)',cursor:'pointer'}}>
                      {startDate.toDateString() == endDate.toDateString() ? startDate.toDateString() : `${startDate.toDateString()} - ${endDate.toDateString()}`}
                    </div>
                    
                    <div
                      onClick={this.toggleAddOrder}
                      style={{cursor:'pointer',fontSize:18,paddingTop:7,marginLeft:50}}  
                      className={"glyph-icon iconsminds-add-cart"} 
                    />
                  </div>
                </Row>
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card>
                  <CardBody>
                    {loading && <div className='loading'/>}
                    <ReactTable
                      data={orders}
                      columns={this.columns}
                      defaultPageSize={50}
                      showPageJump={false}
                      showPageSizeOptions={false}
                      PaginationComponent={DataTablePagination}
                      className={"react-table-fixed-height"}
                    />

                    <div
                      onClick={()=> {
                        this.exportToExcel();
                      }} 
                      className="d-flex justify-content-end" style={{flex:1,cursor:"pointer"}}>
                      Download to Excel 
                      <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>

            <AddNewOrder
              toggleModal={this.toggleAddOrder}
              modalOpen={addOrderModalOpen}
              onAdd={(newOrder)=> {
                const _orders = orders.map(o => o);
                _orders.unshift(newOrder);
                this.setState({orders: _orders})
              }}
            />

            {/* ORDER DETAILS */}
            <Modal
              isOpen={this.state.showDetails}
              toggle={()=> this.setState({showDetails: false})}
              className={this.props.className}
            >
              <ModalHeader style={{alignItems:"center",padding:""}} toggle={()=> this.setState({showDetails:false})}>
                <Label>Details |  Order #{selectedOrder.orderId}</Label>
              </ModalHeader>
              <ModalBody>
                <span style={{color:'#000',fontSize:18}}>{selectedOrder.productName}</span>
                <br/><br/>
                <span>Name: </span><span style={{color:'#000',fontSize:18}}>{selectedOrder.customerName}</span>
                <br/>
                <span>Phone: </span><span style={{color:'#000',fontSize:18}}>{selectedOrder.customerPhone}</span>
                <br/>
                <span>Location: </span><span style={{color:'#000',fontSize:18}}>{selectedOrder.customerLocation}</span>
                <br/><br/>
                <span>Date: </span><span style={{color:'#000',fontSize:18}}>{this.formatDate(selectedOrder.createdAt)}</span>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="default"
                  onClick={()=> this.setState({showDetails:false})}
                >
                  Ok
                </Button>
              </ModalFooter>
            </Modal>

            {/* EDIT MODAL */}
            <Modal
              isOpen={this.state.modalNestedContainer}
              toggle={this.toggleNestedContainer}
              className={this.props.className}
            >
              {processing && <div style={{position:'absolute',top:0,bottom:0,left:0,right:0,zIndex:99,backgroundColor:'rgba(200,200,200,.6)'}}>
                    <div className='loading'/>
                </div>}
              <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleNestedContainer}>
                <Label>Paying order #{selectedOrder.orderId}</Label>
              </ModalHeader>
              <ModalBody>
                <h6>Paying GH₵<b> {this.formatAmount(selectedOrder.amount)}</b>, for {selectedOrder.customerName}'s order made at <b>{this.formatDate(selectedOrder.createdAt)}</b></h6>
                <label style={{marginTop:20}}>
                Authorization Code:
                <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                </label>
                <Modal
                  isOpen={this.state.nestedModal}
                  toggle={this.toggleNested}
                  onClosed={
                    this.state.closeAll
                      ? this.toggleNestedContainer
                      : undefined
                  }
                >
                  <ModalHeader>Confirm Payment</ModalHeader>
                  <ModalBody>
                    <h5>Are you sure you want to make payment for order ID: <b># {selectedOrder.orderId}</b></h5>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="default" onClick={this.toggleNested}>
                      Cancel
                    </Button>{" "}
                    <Button color="primary" onClick={this.toggleAll}>
                      Yes, Make Payment
                    </Button>
                  </ModalFooter>
                </Modal>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="default"
                  onClick={this.toggleNestedContainer}
                >
                  Cancel
                </Button>{" "}
                
                <Button
                  color="primary"
                  onClick={()=> {
                    if(!adminCode){
                        NotificationManager.error(
                            "Authorization Code",
                            "Your security code required to perform this transaction. Thank you.",
                            3000,
                            null,
                            null,
                        );
                    }
                    else if(adminCode !== this.props.user.authCode) {
                        NotificationManager.error(
                            "Invalid Code",
                            "Sorry, the code you entered is invalid. Check and try again.",
                            3000,
                            null,
                            null,
                        );
                    }
                    else {
                        this.handleStatusChange(selectedOrder.id, selectedOrder.status+1);
                    }
                  }}
                >
                  Pay
                </Button>
                
              </ModalFooter>
            </Modal>

            {/* DELETE MODAL */}

            <Modal
              isOpen={this.state.deleteModal}
              toggle={this.toggleDeleteModal}
              className={this.props.className}
            >
              {processing && <div style={{position:'absolute',top:0,bottom:0,left:0,right:0,zIndex:99,backgroundColor:'rgba(200,200,200,.6)'}}>
                  <div className='loading'/>
              </div>}
              <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleDeleteModal}>
                <Label>Delete Order #{selectedOrder.orderId}</Label>
              </ModalHeader>
              <ModalBody>
                <h6>To delete this order, type <i><b>permanently delete</b></i> in the box below</h6>
                <Row style={{paddingLeft:"16px"}}>
                  <input 
                    type="text" value={this.state.value} 
                    style={{paddingBottom:5,paddingTop:5,outline:'none',height:45}} 
                    onChange={(event)=> {
                      this.setState({value: event.target.value});
                    }}
                  />

                  <label style={{marginLeft:20}}>
                    Authorization Code:
                    <input type="password" value={this.state.adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                  </label>
                </Row>
              </ModalBody>
              <ModalFooter>
              <Button
                  color="default"
                  onClick={this.toggleDeleteModal}
                >
                  Cancel
                </Button>{" "}
                <Button
                  color="danger"
                  onClick={()=> {
                    // if(this.state.value !== 'permanently delete'){
                    //   alert('Your input is incorrect. Please check and try again.')
                    // }
                    // else if(!adminCode){
                    //     alert('Security Code required.')
                    // }
                    // else {
                    //   this.toggleDeleteModal();
                    // }

                    if(this.state.value !== 'permanently delete'){
                      // alert('Your input is incorrect. Please check and try again.')
                      NotificationManager.error(
                        "Incorrect input",
                        "Review your input and try again",
                        3000,
                        null,
                        null,
                    );
                    }
                    else if(!adminCode){
                        NotificationManager.error(
                            "Authorization Code",
                            "Your security code required to perform this transaction. Thank you.",
                            3000,
                            null,
                            null,
                        );
                    }
                    else if(adminCode !== this.props.user.authCode) {
                        NotificationManager.error(
                            "Invalid Code",
                            "Sorry, the code you entered is invalid. Check and try again.",
                            3000,
                            null,
                            null,
                        );
                    }
                    else {
                        this.handleDelete(selectedOrder.id);
                    }
                  }}
                >
                  Delete Now
                </Button>
                
              </ModalFooter>
            </Modal>

            {/* DATE MODAL */}

            <Modal
              isOpen={this.state.dateModal}
              toggle={this.toggleDateModal}
              className={'date-modal'} 
            >
              <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleDateModal}>
                <Label>Set Date Range</Label>
              </ModalHeader>
              <ModalBody>
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={this.handleSelect}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="default"
                  onClick={this.toggleDateModal}
                >
                  Cancel
                </Button>{" "}
                
                <Button
                  color="success"
                  onClick={()=> {
                    this.handleRefresh();
                    this.setState({loading: true});
                    this.toggleDateModal();
                  }}
                >
                  Filter Now
                </Button>
                
              </ModalFooter>
            </Modal>
          </Fragment>
        )
    }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return {user};
};

export default connect(
  mapStateToProps,
  {}
)(Orders);