import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

const formatDate = (_date) => {
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
}

const Delivery = ({ createdAt, productName, buyerName, thumbnail }) => {
  return (
    <div className="d-flex flex-row mb-3 pb-3 border-bottom">
      <a href="/app/deliveries">
        <img
          //DEV change
          src={thumbnail ? `${thumbnail}`:''}
          alt={''}
          style={{width:60,height:40}}
          className="img-thumbnail list-thumbnail xsmall border-0"
        />
      </a>
      <div className="pl-3 pr-2">
        <a href="/app/deliveries">
          <p className="font-weight-medium mb-1">{productName}</p>
          <p className="text-muted mb-0 text-small">{buyerName}</p>
          <p className="text-muted mb-0 text-small" style={{marginTop:6}}>{formatDate(createdAt)}</p>
        </a>
      </div>
    </div>
  );
};

const TopnavNewDeliveries = ({ deliveries }) => {
  return (
    <div className="position-relative d-inline-block">
      <UncontrolledDropdown className="dropdown-menu-right">
        <DropdownToggle
          className="header-icon notificationButton"
          color="empty"
        >
          <i className="iconsminds-shopping-cart" />
          {deliveries.length > 0 && <span className="count">{deliveries.length}</span>}
        </DropdownToggle>
        <DropdownMenu
          className="position-absolute mt-3 scroll"
          right
          id="notificationDropdown"
        >
          <PerfectScrollbar
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            {deliveries.map((delivery, index) => {
              return <Delivery key={index} {...delivery} />;
            })}
          </PerfectScrollbar>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default TopnavNewDeliveries;
