import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import chatApp from './chat/reducer';
import newMerchants from './new-merchants/reducer';
import newDeliveries from './new-deliveries/reducer';
import newMessages from './new-messages/reducer';
import payments from './payments/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  chatApp,
  newMerchants,
  newDeliveries,
  newMessages,
  payments
});

export default reducers;