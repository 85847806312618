const data = [
  {
    id: "dashboards",
    icon: "iconsminds-home-3",
    label: "Dashboard",
    to: "/app/dashboard/home",
    roles:[1,2]
    // subs: [
    //   {
    //     icon: "iconsminds-home-3",
    //     label:"Home",
    //     to: "/app/dashboards/home"
    //   },
    //   // {
    //   //   icon: "iconsminds-statistic",
    //   //   label: "menu.analytics",
    //   //   to: "/app/dashboards/analytics"
    //   // }
    // ]
  },
  {
    id: "payments",
    icon: "iconsminds-wallet",
    label: "Payments",
    to: "/app/payments",
    roles: [1,2,3]
  },
  {
    id: "orders",
    icon: "simple-icon-basket-loaded",
    label: "Orders",
    to: "/app/orders",
    roles: [1,2,3]
  },
  {
    id: "users",
    icon: "iconsminds-male-female",
    label: "Users",
    to: "/app/users",
    roles: [1,2,3],
    subs: [
      {
        icon: "iconsminds-business-mens",
        label:"Customers",
        to: "/app/users/customers"
      },
      {
        icon: "iconsminds-network",
        label: "Merchants",
        to: "/app/users/merchants",
        roles: [1,2],
      },
      {
        icon: "iconsminds-conference",
        label: "Staff",
        to: "/app/users/admins",
        roles: [1]
      }
    ]
  },
  {
    id: "collections",
    icon: "iconsminds-financial",
    label: "Collections",
    to: "/app/collections",
    roles: [1,2]
  },
  {
    id: "cash-sales",
    icon: "simple-icon-bag",
    label: "Cash Sales",
    to: "/app/cash-sales",
    roles: [1,2,3]
  },
  {
    id: "attempted-payments",
    icon: "iconsminds-wallet",
    label: "Att. Payments",
    to: "/app/attempted-payments",
    roles: [1]
  },
  {
    id: "products",
    icon: "iconsminds-data-center",
    label: "Products",
    to: "/app/products",
    roles: [1,2,3]
  },
  {
    id: "chats",
    icon: "iconsminds-speach-bubble-4",
    label: "Chats",
    to: "/app/applications/chat",
    roles: [1,2,3]
  },
  // {
  //   id: "pages",
  //   icon: "iconsminds-digital-drawing",
  //   label: "menu.pages",
  //   to: "/app/pages",
  //   subs: [
  //     {
  //       icon: "simple-icon-credit-card",
  //       label: "menu.data-list",
  //       to: "/app/pages/data-list"
  //     },
  //     {
  //       icon: "simple-icon-list",
  //       label: "menu.thumb-list",
  //       to: "/app/pages/thumb-list"
  //     },
  //     {
  //       icon: "simple-icon-grid",
  //       label: "menu.image-list",
  //       to: "/app/pages/image-list"
  //     },
  //     {
  //       icon: "simple-icon-book-open",
  //       label: "menu.details",
  //       to: "/app/pages/details"
  //     },
  //     {
  //       icon: "simple-icon-magnifier",
  //       label: "menu.search",
  //       to: "/app/pages/search"
  //     },
  //     {
  //       icon: "simple-icon-envelope-open",
  //       label: "menu.mailing",
  //       to: "/app/pages/mailing"
  //     },
  //     {
  //       icon: "simple-icon-bag",
  //       label: "menu.invoice",
  //       to: "/app/pages/invoice"
  //     },
  //     {
  //       icon: "simple-icon-user-following",
  //       label: "menu.login",
  //       to: "/user/login",
  //       newWindow: true
  //     },
  //     {
  //       icon: "simple-icon-user-follow",
  //       label: "menu.register",
  //       to: "/user/register",
  //       newWindow: true
  //     },
  //     {
  //       icon: "simple-icon-user-following",
  //       label: "menu.forgot-password",
  //       to: "/user/forgot-password",
  //       newWindow: true
  //     },
  //     {
  //       icon: "simple-icon-exclamation",
  //       label: "menu.error",
  //       to: "/error",
  //       newWindow: true
  //     }
  //   ]
  // },
  // {
  //   id: "applications",
  //   icon: "iconsminds-box-with-folders",
  //   label: "menu.applications",
  //   to: "/app/applications",
  //   subs: [
      // {
      //   icon: "simple-icon-check",
      //   label: "menu.todo",
      //   to: "/app/applications/todo"
      // },
  //     {
  //       icon: "iconsminds-speach-bubble-4",
  //       label: "menu.chat",
  //       to: "/app/applications/chat"
  //     }
  //   ]
  // },
  // {
  //   id: "ui",
  //   icon: "iconsminds-pantone",
  //   label: "menu.ui",
  //   to: "/app/ui",
  //   subs: [
  //     { icon: "simple-icon-bell", label: "menu.alerts", to: "/app/ui/alerts" },
  //     { icon: "simple-icon-badge", label: "menu.badges", to: "/app/ui/badges" },
  //     {
  //       icon: "simple-icon-control-play",
  //       label: "menu.buttons",
  //       to: "/app/ui/buttons"
  //     },
  //     { icon: "simple-icon-layers", label: "menu.cards", to: "/app/ui/cards" },
  //     {
  //       icon: "simple-icon-picture",
  //       label: "menu.carousel",
  //       to: "/app/ui/carousel"
  //     },
  //     { icon: "simple-icon-chart", label: "menu.charts", to: "/app/ui/charts" },
  //     {
  //       icon: "simple-icon-arrow-up",
  //       label: "menu.collapse",
  //       to: "/app/ui/collapse"
  //     },
  //     {
  //       icon: "simple-icon-arrow-down",
  //       label: "menu.dropdowns",
  //       to: "/app/ui/dropdowns"
  //     },
  //     {
  //       icon: "simple-icon-book-open",
  //       label: "menu.editors",
  //       to: "/app/ui/editors"
  //     },
  //     {
  //       icon: "simple-icon-notebook",
  //       label: "menu.form-layouts",
  //       to: "/app/ui/form-layouts"
  //     },
  //     {
  //       icon: "simple-icon-puzzle",
  //       label: "menu.form-components",
  //       to: "/app/ui/form-components"
  //     },
  //     {
  //       icon: "simple-icon-check",
  //       label: "menu.form-validations",
  //       to: "/app/ui/form-validations"
  //     },
  //     { icon: "simple-icon-star", label: "menu.icons", to: "/app/ui/icons" },
  //     {
  //       icon: "simple-icon-note",
  //       label: "menu.input-groups",
  //       to: "/app/ui/input-groups"
  //     },
  //     {
  //       icon: "simple-icon-screen-desktop",
  //       label: "menu.jumbotron",
  //       to: "/app/ui/jumbotron"
  //     },
  //     { icon: "simple-icon-map", label: "menu.maps", to: "/app/ui/maps" },
  //     { icon: "simple-icon-docs", label: "menu.modal", to: "/app/ui/modal" },
  //     {
  //       icon: "simple-icon-cursor",
  //       label: "menu.navigation",
  //       to: "/app/ui/navigation"
  //     },
  //     {
  //       icon: "simple-icon-pin",
  //       label: "menu.popover-tooltip",
  //       to: "/app/ui/popover-tooltip"
  //     },
  //     {
  //       icon: "simple-icon-shuffle",
  //       label: "menu.sortable",
  //       to: "/app/ui/sortable"
  //     },
  //     { icon: "simple-icon-grid", label: "menu.tables", to: "/app/ui/tables" }
  //   ]
  // },
  {
    id: "deliveries",
    icon: "iconsminds-basket-coins",
    label: "Deliveries",
    to: "/app/deliveries",
    roles: [1,2,3],
    subs: [
      {
        icon: "simple-icon-present",
        label: "Home",
        to: "/app/deliveries/home"
      },
      {
        icon: "simple-icon-layers",
        label: "Dispatch",
        to: "/app/deliveries/dispatch"
      }
    ]
  },
  {
    id: "userproducts",
    icon: "iconsminds-scale",
    label: "Users Prod.",
    to: "/app/user-products",
    roles: [1,2,3]
  },
  {
    id: "map",
    icon: "iconsminds-map-marker-2",
    label: "menu.collections-map",
    to: "/app/collections-map",
    roles: [1]
  },
  {
    id: "swap-requests",
    icon: "simple-icon-basket-loaded",
    label: "Swap Requests",
    to: "/app/swap-requests",
    roles: [1,2]
  },
  {
    id: "settings",
    icon: "simple-icon-settings",
    label: "Settings",
    to: "/app/settings",
    roles: [1,2]
  },
  {
    id: "sign-ups",
    icon: "iconsminds-network",
    label: "Sign-ups",
    to: "/app/sign-ups",
    roles: [1,2]
  },
  // {
  //   id: "docs",
  //   icon: "iconsminds-library",
  //   label: "menu.docs",
  //   to: "https://gogo-react-docs.coloredstrategies.com/",
  //   newWindow:true
  // }
];
export default data;
