import React from "react";
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const markets_url = 'https://api.tuatuagye.com/markets'

export default class LocationsAutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  loadOptions = (inputValue, callback) => {
    axios.get(`${markets_url}?filter[where][name][regexp]=/${inputValue}/i&filter[limit]=5`)
      .then(res => {
        callback(res.data.map(product => {
          product.label = product.name;
          product.value = `${product.id}`
          return product;
        }));
      })
      .catch(error => {
        console.log('Error...',error);
      })
  };

  onChange = (newValue) => {
    this.props.onChange(newValue);
  };

  render() {
    return (
      <AsyncSelect
        placeholder='Select location'
        cacheOptions
        loadOptions={this.loadOptions}
        defaultOptions
        onChange={this.onChange}
      />
    );
  }
}