import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";

import axios from 'axios';

import { ThemeColors } from '../../helpers/ThemeColors'
import { AreaChart } from "../../components/charts";
import { getDays, _getDates } from '../../helpers/Utils';

const colors = ThemeColors();

const _days = getDays(7);
const dates = getDays(30);//['1', '2', '3', '4', '5', '6', '7','8', '9', '10', '11', '12', '13', '14','15', '16', '17', '18', '19', '20', '21','22', '23', '24', '25', '26', '27', '28','29','30']
const ONE_WEEK = 'ONE_WEEK';
const MONTH = 'MONTH';

const datasets = [
  {
    label: '',
    // data: daysData,
    borderColor: colors.themeColor1,
    pointBackgroundColor: colors.foregroundColor,
    pointBorderColor: colors.themeColor1,
    pointHoverBackgroundColor: colors.themeColor1,
    pointHoverBorderColor: colors.foregroundColor,
    pointRadius: 6,
    pointBorderWidth: 2,
    pointHoverRadius: 8,
    fill: false
  }
]
  
const payments_url = 'https://api.tuatuagye.com/payments';

const RecentPayments = ({user, className = "", controls = true }) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState('');
  const [option, setOption] = useState(ONE_WEEK);
  const [data, setData] = useState([]);

  useEffect(()=> {
    loadData(ONE_WEEK)
    return () => {}
  }, []);

  const loadData = (option) => {
    setLoading(true);
    let date;
    let dates;

    if(option === ONE_WEEK){
      dates = _getDates(7);

      const sevenDays = 1000*60*60*24*7;
      date = new Date(Date.now() - sevenDays);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
    }
    else {
      dates = _getDates(30);

      const thirtyDays = 1000*60*60*24*30;
      date = new Date(Date.now() - thirtyDays);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
    }

    axios.get(`${payments_url}?filter[where][payeeId]=${user.id}&filter[where][createdAt][gt]=${date.toJSON()}`)
      .then(res => {
        if(res.data && res.data.length > 0){
          let _totalPayments = 0;

          const keys = {};
          dates.forEach(d=> keys[d] = 0);
                    
          res.data.forEach(payment => {
            _totalPayments += payment.amount;

            const _date = new Date(payment.createdAt).getDate();
            if(keys[_date]){
              keys[_date] = keys[_date] + payment.amount;
            }
            else {
              keys[_date] = payment.amount;
            }
          });

          const _data = [];
          dates.forEach(d=> {
            const tmp = keys[d];
            _data.push(tmp)
          });

          setData(_data);

          // setData(Object.values(keys));
          setTotal(Number(_totalPayments).toFixed(2));
        }

        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        alert('An errror occurred.')
      })
  }

  const formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
      return temp;
    }
    
    return Number(amount).toFixed(2);
  }

  return (
    <Card className={`${className} dashboard-filled-line-chart`}>
      <CardBody>
        <div className="float-left float-none-xs">
          <div className="d-inline-block">
            <h5 className="d-inline">GHS {formatAmount(total)}</h5>
            <span className="text-muted text-small d-block">
              {option === ONE_WEEK ? 'Last 7 days' : 'Last 30 days'}
            </span>
          </div>
        </div>
        {!controls && (
          <div className="btn-group float-right float-none-xs mt-2">
            <UncontrolledDropdown>
              <DropdownToggle caret color="primary" className="btn-xs" style={{paddingTop:5}} outline>
                {option === ONE_WEEK ? '1 week' : '1 Month'}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem style={{cursor:'pointer'}} onClick={()=> {
                  setOption(ONE_WEEK);
                  loadData(ONE_WEEK);
                }}>
                  1 Week
                </DropdownItem>
                <DropdownItem style={{cursor:'pointer'}} onClick={()=> {
                  setOption(MONTH);
                  loadData(MONTH);
                }}>
                  1 Month
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      </CardBody>

      <div className="chart card-body pt-0">
        {loading && <div className="loading" />}
        <AreaChart shadow data={{datasets: [{...datasets[0], data }], labels: option === ONE_WEEK ? _days : dates }} />
      </div>
    </Card>
  );
};

export default RecentPayments;
