import React from "react";
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const dispatch_url = 'https://api.tuatuagye.com/dispatches';

export default class DispatchAutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  loadOptions = (inputValue, callback) => {
    axios.get(`${dispatch_url}?filter[where][or][0][name][regexp]=/${inputValue}/i&filter[where][or][1][location][regexp]=/${inputValue}/i&filter[limit]=5`)
      .then(res => {
        callback(res.data.map(dispatch => {
          dispatch.label = `${dispatch.numberPlate} - ${dispatch.name}`;
          dispatch.value = `${dispatch.phone}`
          return dispatch;
        }));
      })
      .catch(error => {
        console.log('Error...',error);
      })
  };

  onChange = (newValue) => {
    this.props.onChange(newValue);
  };

  render() {
    return (
      <AsyncSelect
        cacheOptions
        loadOptions={this.loadOptions}
        defaultOptions
        onChange={this.onChange}
      />
    );
  }
}