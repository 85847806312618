import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAnHwS-XGJ2SCWnozil5f42LQzIFgg1ZPw",
  authDomain: "tuatuagye-mobile.firebaseapp.com",
  projectId: "tuatuagye-mobile",
  storageBucket: "tuatuagye-mobile.appspot.com",
  messagingSenderId: "928443444214",
  appId: "1:928443444214:web:7b91b025e7d785dd58f5d7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);