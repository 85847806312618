import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import axios from "axios";
import * as FileSaver from 'file-saver';

import { Row, Card, CardBody, Input,
  Modal,
  ModalHeader,
  ModalBody,Label 
} from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";

// import DataTablePagination from "../../components/DatatablePagination";
import CustomSelectInput from "../../components/common/CustomSelectInput";
// import Table from "../../components/Table";

import ProductDetails from "./productDetails";
import NewProduct from "./newProduct";

// import ProductsAutoSuggest from "../../containers/ProductsAutoSuggest";
// import { NavLink } from "react-router-dom";
import { NotificationManager } from "../../components/common/react-notifications";

import {
  setContainerClassnames,
  changeDefaultClassnames
} from "../../redux/actions";

// function shuffle(array) {
//   var currentIndex = array.length, temporaryValue, randomIndex;

//   // While there remain elements to shuffle...
//   while (0 !== currentIndex) {

//     // Pick a remaining element...
//     randomIndex = Math.floor(Math.random() * currentIndex);
//     currentIndex -= 1;

//     // And swap it with the current element.
//     temporaryValue = array[currentIndex];
//     array[currentIndex] = array[randomIndex];
//     array[randomIndex] = temporaryValue;
//   }

//   return array;
// }

// const selectData = [
//   { label: "Page 1", value: "1", key: 1 },
//   { label: "Page 2", value: "2", key: 2 },
//   { label: "Page 3", value: "3", key: 3 },
//   { label: "Page 4", value: "4", key: 4 },
//   { label: "Page 5", value: "5", key: 5 },
//   { label: "Page 6", value: "6", key: 6 },
//   { label: "Page 7", value: "7", key: 7 },
//   { label: "Page 8", value: "8", key: 8 },
//   { label: "Page 9", value: "9", key: 9 },
//   { label: "Page 10", value: "10", key: 10 },
//   { label: "Page 11", value: "11", key: 11 },
//   { label: "Page 12", value: "12", key: 12 },
//   { label: "Page 13", value: "13", key: 13 },
//   { label: "Page 14", value: "14", key: 14 },
//   { label: "Page 15", value: "15", key: 15 },
//   { label: "Page 16", value: "16", key: 16 },
//   { label: "Page 17", value: "17", key: 17 },
//   { label: "Page 18", value: "18", key: 18 },
//   { label: "Page 19", value: "19", key: 19 },
//   { label: "Page 20", value: "20", key: 20 },

//   { label: "Page 21", value: "21", key: 21 },
//   { label: "Page 22", value: "22", key: 22 },
//   { label: "Page 23", value: "23", key: 23 },
//   { label: "Page 24", value: "24", key: 24 },
//   { label: "Page 25", value: "25", key: 25 },
//   { label: "Page 26", value: "26", key: 26 },
//   { label: "Page 27", value: "27", key: 27 },
//   { label: "Page 28", value: "28", key: 28 },
//   { label: "Page 29", value: "29", key: 29 },
//   { label: "Page 30", value: "30", key: 30 },
//   { label: "Page 31", value: "31", key: 31 },
//   { label: "Page 32", value: "32", key: 32 },
//   { label: "Page 33", value: "33", key: 33 },
//   { label: "Page 34", value: "34", key: 34 },
//   { label: "Page 35", value: "35", key: 35 },
//   { label: "Page 36", value: "36", key: 36 },
//   { label: "Page 37", value: "37", key: 37 },
//   { label: "Page 38", value: "38", key: 38 },
//   { label: "Page 39", value: "39", key: 39 },
//   { label: "Page 40", value: "40", key: 40 },
// ];

const selectData = [
  {label:'Page size 10', key:10,value:'10'},
  {label:'Page size 20', key:20,value:'20'},
  {label:'Page size 40', key:40,value:'40'},
  {label:'Page size 50', key:50,value:'50'},
]
const products_url = 'https://api.tuatuagye.com/products';
const search_products_url = 'https://api.tuatuagye.com/search-product';
const products_count_url = 'https://api.tuatuagye.com/products-count'
const img_base_url = 'https://api.tuatuagye.com/get-image?type=product&id='

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      processing: false,
      confirmModal:false,
      modalViewType:'',
      searchKeyword:'',
      selectedOption: selectData[0],
      minPrice:0,
      maxPrice:9999,
      adminCode: '',
      selectedCategoryOption: "",
      selectedBrandOption: "",
      dispatchModalOpen:false,
      newProductModalOpen:false,
      selectedRider:null,
      selectedProduct: null,
      loading:true,
      page: 1,
      perPage: 10,
      totalPage: 1,
      productCount:1
    }

    this.timer = 0;
    this.columns = [
      {
        Header: "Name",
        accessor: "name",
        Cell: props => <Row style={{marginLeft:0,marginRight:0,alignItems:'center'}}>
          {props.original.thumbnail && props.original.thumbnail.length > 0 && (<img style={{width:70,height:70,borderRadius:5,marginRight:10}} src={`${img_base_url}${props.original.thumbnail}`} alt="Product"/>)}
          <span style={{fontSize:16}}>{props.value}</span> 
        </Row>
      },
      {
        Header: "Brand",
        accessor: "brand",
        // headerStyle:{textAlign:'center'},
        width:160,
        Cell: props => <p style={{textAlign:'left',paddingTop:25}}>{props.value}</p>
      },
      {
        Header: "Price GH₵",
        accessor: "cashPrice",
        // headerStyle:{textAlign:'center'},
        width:100,
        Cell: props => <p className="text-muted"  style={{textAlign:'left',paddingTop:15}}>
          Cash: {props.value} <br/> TTG: {props.original.posTTGPrice}
        </p>
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        headerStyle:{textAlign:'center'},
        width:90,
        Cell: props => <p className="text-muted"  style={{textAlign:'center',paddingTop:25}}>{props.value}</p>
      },
      {
        Header: "Action",
        accessor: "id",
        // headerStyle:{textAlign:'center'},
        width:70,
        Cell: props =>
            <div 
              onClick={()=> {
                this.setState({selectedProduct: props.original});
                this.toggleModal()
              }}
              style={{fontSize:17,marginLeft:10,paddingTop:25,cursor:'pointer'}} 
              className={"simple-icon-info"} 
            />
      }
    ]
  }

  componentDidMount() {
    const { perPage } = this.state;
    axios.get(`${products_count_url}`)
      .then(res=> {
        this.setState({productCount: res.data.count, totalPage: Math.ceil(Number(res.data.count)/perPage)})
        return axios.get(`${products_url}?filter[limit]=${perPage}&filter[order]=createdAt desc`)
      })
      .then(res=> {
        this.setState({data: res.data, loading:false})
      })
      .catch(err => {
        console.log('error loading product',err)
        this.setState({loading:false})
      })
  }

  searchWithName = (searchText) => {
    this.setState({processing: true});
    const query = `${search_products_url}?name=${searchText}`;
    axios.get(query)
    .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({data: res.data, processing: false});
        }
        else {
          this.setState({data:[], processing:false});
        }
    })
    .catch(error => {
        this.setState({processing: false});
        alert('Sorry, an unknown error occurred.');
        console.log('error...',error);
    })
}

  changeDefaultMenuType = (e, containerClassnames) => {
    e.preventDefault();
    let nextClasses = this.getMenuClassesForResize(containerClassnames);
    this.props.setContainerClassnames(
      0,
      nextClasses.join(" "),
      this.props.selectedMenuHasSubItems
    );
  };

  getMenuClassesForResize = classes => {
    const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props;
    let nextClasses = classes.split(" ").filter(x => x !== "");
    const windowWidth = window.innerWidth;
    if (windowWidth < menuHiddenBreakpoint) {
      nextClasses.push("menu-mobile");
    } else if (windowWidth < subHiddenBreakpoint) {
      nextClasses = nextClasses.filter(x => x !== "menu-mobile");
      if (
        nextClasses.includes("menu-default") &&
        !nextClasses.includes("menu-sub-hidden")
      ) {
        nextClasses.push("menu-sub-hidden");
      }
    } else {
      nextClasses = nextClasses.filter(x => x !== "menu-mobile");
      if (
        nextClasses.includes("menu-default") &&
        nextClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = nextClasses.filter(x => x !== "menu-sub-hidden");
      }
    }
    return nextClasses;
  };

  handleCategoryChange = selectedCategoryOption => {
    this.setState({ selectedCategoryOption });
  };

  handleBrandChange = selectedBrandOption => {
    this.setState({ selectedBrandOption });
  };

  handleFilterChange = selectedOption => {
    this.setState({ selectedOption });
    let skip = 0;
    if(selectedOption.key > 1){
      skip = (selectedOption.key - 1) * 50
    }

    this.setState({loading: true});
    axios.get(`${products_url}?filter[skip]=${skip}&filter[limit]=50&filter[order]=createdAt desc`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({data: res.data, loading: false});
        }
        else {
          this.setState({data:[], loading:false})
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  };

  handlePageChange = (page, perPage) => {
    let skip = page === 1 ? 0 : (page-1)*perPage;

    if(this.state.searchKeyword){
      this.setState({processing: true});

      const query = `${products_url}?filter[where][or][0][name][regexp]=/${this.state.searchKeyword}/i&filter[where][or][1][description][regexp]=/${this.state.searchKeyword}/i&filter[limit]=20&filter[skip]=${skip}&filter[order]=createdAt%20DESC`;
      axios.get(query)
        .then(res => {
            if(res.data && res.data.length > 0){
              this.setState({data: res.data, processing: false});
            }
            else {
              this.setState({data:[], processing:false});
            }
        })
        .catch(error => {
            this.setState({processing: false});
            alert('Sorry, an unknown error occurred.');
            console.log('error...',error);
        })
    }
    else {
      this.setState({loading: true});

      axios.get(`${products_url}?filter[skip]=${skip}&filter[limit]=${perPage}&filter[order]=createdAt desc`)
        .then(res => {
          if(res.data && res.data.length > 0){
            this.setState({data: res.data, loading: false});
          }
          else {
            this.setState({data:[], loading:false})
          }
        })
        .catch(error => {
          this.setState({loading: false});
          NotificationManager.error(
            "Loading error!",
            "An unknown error occurred. Please refresh page.",
            3000,
            null,
            null,
          );
          console.log('error...',error);
        })
    }
  };

  toggleModal = () => {
    this.setState(prevState => ({
      // modalViewType:'DISPATCH',
      dispatchModalOpen: !prevState.dispatchModalOpen
    }))
  }

  toggleNewProductModal = () => {
    this.setState(prevState => ({
      newProductModalOpen: !prevState.newProductModalOpen
    }))
  }

  toggleConfirmModal = () => {
    this.setState(prevState => ({
      confirmModal: !prevState.confirmModal
    }))
  }

  onChange = (val) => {
    if(this.state.modalViewType === 'DISPATCH'){
      this.setState({selectedRider: val});
    }
    else {
      this.setState({selectedProduct: val});
    }
  }

  onChangeProduct = (val) => {
    this.setState({selectedProduct: val})
  }

  handleSubmit = () => {
    this.setState({processing:true});

    setTimeout(() => {
      this.toggleConfirmModal();
      this.toggleModal();
    }, 4000);
  }

  onDelete = (product, closeModal) => {
    const temp = this.state.data.filter(prod=> prod.id != product.id)

    this.setState({data: temp});

    if(closeModal){
      this.toggleModal();
    }
  }

  onUpdate = (product, closeModal) => {
    NotificationManager.success(
      "Product Updated",
      "Your product update was successful. Thank you, "+this.props.user.firstname+".",
      3000,
      null,
      null,
    );

    const temp = this.state.data.map(prod=> {
      if(prod.id === product.id){
        return product;
      }

      return prod;
    })

    this.setState({data: temp});

    if(closeModal){
      this.toggleModal();
    }
  }

  onAdd = (product) => {
    NotificationManager.success(
      "Product Added",
      "New product added successfully. Thank you, "+this.props.user.firstname+".",
      3000,
      null,
      null,
    );

    const temp = this.state.data.map(prod=>  prod);
    temp.unshift(product);

    this.setState({data: temp});

    this.toggleNewProductModal();
  }

  __formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let yr = `${date.getFullYear()}`.slice(2);
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
  }

  exportToExcel() {
    const data = this.state.data.map(record=> {
      return {
        Name: record.name,
        Quantity: record.quantity,
        Brand: record.brand,
        'Cash Price': record.cashPrice,
        'Online Price': record.onlineTTGPrice,
        'TTG Price': record.posTTGPrice,
        'Date Added': this.__formatDate(record.createdAt)
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `Products Data - ${new Date().toJSON()}`);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  render() {
    const { dispatchModalOpen, totalPage, productCount, page, perPage, newProductModalOpen, processing, data, selectedProduct, modalViewType } = this.state;
    return (
      <Fragment>
        {/* {!processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>} */}
        <Row>
          <Colxx xxs="12">
            <Row style={{justifyContent:'space-between',marginLeft:0,marginRight:0}}>
            <h1>Products</h1>

            <div style={{display:'flex',alignItems:'center', marginBottom:10,marginLeft:0,marginRight:0}}>
              <div className="payments-search">
                <Input
                  style={{backgroundColor:'#fff'}}
                  // name="searchKeyword"
                  // id="searchKeyword"
                  placeholder={"Search"
                      // messages["menu.search"]
                  }
                  value={this.state.searchKeyword}
                  onChange={e => {
                    let value = e.target.value;
                    this.setState({searchKeyword: value})
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                      this.searchWithName(value);
                    }, 1000);
                  }}
                  // onKeyPress={e => this.handleSearchInputKeyPress(e)}
                />
                <span
                    className="search-icon"
                    style={{top:10}}
                    // onClick={e => this.handleSearchIconClick(e)}
                >
                    <i className="simple-icon-magnifier" />
                </span>
              </div>

              {/* <div className="payments-search" style={{display:'flex',flexDirection:'row', marginLeft:30,width:100}}>
                <span>Min Price</span>
                <Input
                  style={{backgroundColor:'#fff'}}
                  name="minPrice"
                  id="minPrice"
                  placeholder={"Min"}
                  min={0}
                  type="number"
                  value={minPrice}
                  onChange={e => this.setState({minPrice: e.target.value})}
                  // onKeyPress={e => this.handleSearchInputKeyPress(e)}
                />
              </div> */}

              {/* <div className="payments-search" style={{display:'flex',flexDirection:'row', marginLeft:10,marginRight:30,width:100,alignItems:'center'}}>
                <span>Max Price</span>
                <Input
                  style={{backgroundColor:'#fff'}}
                  name="maxPrice"
                  id="maxPrice"
                  placeholder={"Max"}
                  min={0}
                  type="number"
                  value={maxPrice}
                  onChange={e => this.setState({maxPrice: e.target.value})}
                  // onKeyPress={e => this.handleSearchInputKeyPress(e)}
                />
              </div> */}

              {/* <div style={{minWidth:"130px",marginLeft:10}}>
                <Select
                  placeholder="Category"
                  components={{ Input: CustomSelectInput }}
                  className="react-select"
                  classNamePrefix="react-select"
                  name="form-field-name"
                  value={selectedCategoryOption}
                  onChange={this.handleCategoryChange}
                  options={categories}
                />
              </div> */}

              {/* <div style={{minWidth:"130px",marginLeft:10}}>
                <Select
                  placeholder="Brand"
                  components={{ Input: CustomSelectInput }}
                  className="react-select"
                  classNamePrefix="react-select"
                  name="form-field-name"
                  value={selectedBrandOption}
                  onChange={this.handleBrandChange}
                  options={brands}
                />
              </div> */}

              <div style={{minWidth:"150px",marginLeft:10}}>
                <Select
                  components={{ Input: CustomSelectInput }}
                  placeholder="Page size 10"
                  className="react-select"
                  classNamePrefix="react-select"
                  name="form-field-name"
                  value={this.state.selectedOption}
                  // onChange={this.handleFilterChange}
                  onChange={(val)=> {
                    this.setState({selectedOption: val, perPage:val.key, totalPage: Math.ceil(Number(productCount)/val.key)});
                    this.handlePageChange(page, val.key)
                  }}
                  options={selectData}
                />
              </div>

              {/* <NavLink style={{textAlign:'center',marginLeft:10}} 
                to={`/app/products/new-product`}> */}
                <div 
                  style={{fontSize:17,cursor:'pointer',marginLeft:10}}
                  onClick={()=> this.toggleNewProductModal()}
                  className={"simple-icon-plus"} 
                />
              {/* </NavLink> */}
            </div></Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          {this.state.loading ? 
          <div className="loading"/>
          :
          <Colxx xxs="12" className="mb-4">
            <Card>
              <CardBody>
                {processing && 
                  <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
                    <div className="loading" />  
                  </div>
                }
                <ReactTable
                  data={this.state.data}
                  columns={this.columns}
                  defaultPageSize={perPage}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  PaginationComponent={()=> null}
                  className={"react-table-fixed-height"}
                />

                <div className="flex justify-between bg-red-100 p-4">
                  <button
                    onClick={() => {
                      this.setState({page: 1});
                      this.handlePageChange(1, perPage)
                    }}
                    disabled={page === 1}
                    >
                    first
                  </button>{' '}
                  <button
                    onClick={() => {
                        // setPage((s) => (s === 0 ? 0 : s - 1));
                        const temp = page - 1;
                        this.setState({page: temp});
                        this.handlePageChange(temp, perPage)
                    }}
                    disabled={page === 1}
                    >
                    prev
                  </button>{' '}
                  <button
                    onClick={() => {
                        const temp = page + 1;
                        this.setState({page: temp});
                        this.handlePageChange(temp, perPage)
                    }}
                    disabled={page === totalPage}
                    >
                    next
                  </button>{' '}
                  <button
                    onClick={() => {
                      this.setState({page: totalPage});
                      this.handlePageChange(totalPage, perPage)
                    }}
                    disabled={page === totalPage}
                    >
                    last
                  </button>{' '}
                  <span>
                  Page{' '}
                  <strong>{page} of {totalPage}</strong>{' '}
                  </span>
                  <span>
                  | Go to page:{' '}
                  <input
                      type="number"
                      defaultValue={1}
                      min="1"
                      max={totalPage}
                      onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) : 1;
                        clearTimeout(this.timer);
                        this.timer = setTimeout(() => {
                          this.setState({page})
                          this.handlePageChange(page, perPage)
                        }, 2000);
                      }}
                      className=""
                  />
                  </span>{' '}
                  {/* <select
                  value={perPage}
                  onChange={(e) => {
                      // setPageSize(Number(e.target.value));
                      // setPerPage(Number(e.target.value));
                  }}
                  >
                  {[5, 10, 20].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                      </option>
                  ))}
                  </select> */}
              </div>

                {/* <Table
                  data={data}
                  columns={this.columns}
                  setPage={(page)=> this.setState({page})}
                  setPerPage={(perPage)=> this.setState({perPage})}
                  currentpage={page}
                  perPage={perPage}
                  totalPage={totalPage}
                  view='products'
                /> */}

                <div
                  onClick={()=> {
                    this.exportToExcel();
                  }} 
                  className="d-flex justify-content-end" style={{flex:1,cursor:"pointer"}}>
                  Download to Excel 
                  <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                </div>
                {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                  <div onClick={this.exportToExcel} style={{display:'flex',flexDirection:'row',marginRight:15}}>
                    Export
                    <div style={{marginLeft:5}} className={"glyph-icon iconsminds-right-1"}/>
                  </div>

                  <div style={{display:'flex',flexDirection:'row'}}>
                    Print 
                    <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-printer"}/>
                  </div>
                </div> */}
              </CardBody>
            </Card>
          </Colxx>
          }
        </Row>

        <Modal
            isOpen={dispatchModalOpen}
            toggle={this.toggleModal}
            wrapClassName="modal-right"
            // backdrop="static"
        >
          <ModalHeader toggle={this.toggleModal}>
            Product Details
          </ModalHeader>
          <ModalBody>
            <ProductDetails product={selectedProduct} onUpdate={this.onUpdate} onDelete={this.onDelete}/>
          </ModalBody>  
        </Modal>

        <Modal
            isOpen={newProductModalOpen}
            toggle={this.toggleNewProductModal}
            wrapClassName="modal-right"
          >
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleNewProductModal}>
                <Label style={{}}>New Product</Label>
            </ModalHeader>
            <ModalBody>
              <NewProduct onAdd={this.onAdd} />
            </ModalBody>
        </Modal> 
      </Fragment>
    );
  }
}
const mapStateToProps = ({ menu, authUser }) => {
  const {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems
  } = menu;
  const { user } = authUser;

  return {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems,
    user
  };
};

export default connect(
  mapStateToProps,
  {
    setContainerClassnames,
    changeDefaultClassnames
  }
)(Products);