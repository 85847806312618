export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime=()=>{
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}

export const getDays=(len)=>{
  const days = [];
  const timestamp = Date.now();
  const one_day = 1000*60*60*24;
  let x = len-1;
  while(x > -1){
    const _d = new Date(timestamp - (x * one_day)).getDate();
    if(_d === 1){
      days.push(`${_d}st`)
    }
    else if(_d === 2){
      days.push(`${_d}nd`)
    }
    else if(_d === 3){
      days.push(`${_d}rd`)
    }
    else if(_d === 21){
      days.push(`${_d}st`)
    }
    else if(_d === 22){
      days.push(`${_d}nd`)
    }
    else if(_d === 23){
      days.push(`${_d}rd`)
    }
    else if(_d === 31){
      days.push(`${_d}st`)
    }
    else {
      days.push(`${_d}th`)
    }
    
    x = x-1;
  }

  return days
}

export const _getDates=(len)=>{
  const days = [];
  const timestamp = Date.now();
  const one_day = 1000*60*60*24;
  let x = len-1;
  while(x > -1){
    const _d = new Date(timestamp - (x * one_day)).getDate();
    days.push(_d);
    x = x-1;
  }

  return days
}

