import React, { Component, Fragment } from "react";
import { Card, CardBody, CardTitle, Row, Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter } from "reactstrap";
import ReactTable from "react-table";
import axios from 'axios';
import { connect } from "react-redux";

import Pagination from "../../components/DatatablePagination";
import { NotificationManager } from "../../components/common/react-notifications";

const markets_url = 'https://api.tuatuagye.com/markets'

class Markets extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            fullscreenImageModalOpen: false,
            processing: false,
            selectedMarket: {},
            fileUrl:'',
            file: null,
            loading: true,
            adminCode: '',
            markets: [],
            locations: [],
            name: '',
            name2:''
        }

        this.inp = null;

        this.columns = [
            {
            Header: "Name",
            accessor: "name",
            Cell: props => <Row style={{marginLeft:0,marginRight:0,alignItems:'center'}}>
                
                <span style={{fontSize:16}}>{props.value}</span>
            </Row>
            },
            {
            Header: "Merchant",
            accessor: "user",
            width: 390,
            Cell: props => <p className="text-muted">{props.value || 'N/A'}</p>
            },
            {
                Header: "Action",
                accessor: "",
                width: 90,
                Cell: props => <div
                    onClick={()=> {
                        console.log('p', props.original.name);
                        this.setState({selectedMarket: props.original, name: props.original.name});
                        this.toggleFullScreenImageModal();
                    }}
                    style={{cursor:'pointer',fontSize:14,paddingTop:25}}  
                    className={"glyph-icon iconsminds-pen-2"} 
                />
            }
        ];
    }

    componentDidMount() {
        axios.get(`${markets_url}?filter[where][type]=1`)
            .then(res=> {
                this.setState({
                    markets: res.data, loading: false
                })
            })
            .catch(err=> {
                console.log('markets fetch err',err);
                this.setState({loading: false});

                NotificationManager.error(
                    "Loading Error",
                    "An unknown error occurred. Please try again.",
                    3000,
                    null,
                    null,
                );
            })
    
    }

    toggleFullScreenImageModal = () => {
        this.setState({fullscreenImageModalOpen: !this.state.fullscreenImageModalOpen, name: ''})
    }
        
    handleSubmit = async () => {
        this.setState({processing:true});
        const data = {name: this.state.name, type: 1}
        axios.post(markets_url,data)
            .then(res=> {
                const tmp = [res.data, ...this.state.markets];
                this.setState({markets: tmp, processing: false});
                this.toggleFullScreenImageModal();
            })
            .catch(error=> {
                console.log('error',error);
                this.setState({processing:false});
                NotificationManager.error(
                    "Upload Error",
                    "An unknown error occurred. Please check console for details or try again.",
                    3000,
                    null,
                    null,
                );
            })
    }

    handleUpdate = async () => {
        const { selectedMarket, name } = this.state;
        this.setState({processing:true});
        const data = { name };
        axios.patch(`${markets_url}/${selectedMarket.id}`,data)
            .then(res=> {
                const tmp = this.state.markets.map(m=> {
                    if(m.id === selectedMarket.id){
                        m.name = name
                    }
                    return m;
                });
                console.log('tmp',tmp);
                this.setState({markets: tmp, processing: false});
                this.toggleFullScreenImageModal();
            })
            .catch(error=> {
                console.log('error',error);
                this.setState({processing:false});
                NotificationManager.error(
                    "Upload Error",
                    "An unknown error occurred. Please check console for details or try again.",
                    3000,
                    null,
                    null,
                );
            })
    }

    render() {
        const { fullscreenImageModalOpen, name, loading, adminCode, processing, selectedMarket, markets } = this.state;
        return (
            <Fragment>
                <Card className="h-100">
                    <CardBody>
                        <CardTitle>
                            Markets
                        </CardTitle>
                        {loading ? 
                            <p>Loading...</p>
                            :
                            <div>
                                <Button
                                    color="success"
                                    onClick={this.toggleFullScreenImageModal}
                                    style={{marginBottom :20}}
                                >
                                    Add Market
                                </Button>
                                <ReactTable
                                    defaultPageSize={6}
                                    data={markets}
                                    columns={this.columns}
                                    minRows={0}
                                    showPageJump={false}
                                    showPageSizeOptions={false}
                                    PaginationComponent={Pagination}
                                />
                            </div>
                        }
                    </CardBody>

                    <Modal
                        isOpen={fullscreenImageModalOpen}
                        toggle={this.toggleFullScreenImageModal}
                    >
                        {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
                        <div className="loading" />  
                        </div>}
                        <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleFullScreenImageModal}>
                            {selectedMarket.id ? 'Edit Market Info' : 'Add New Market'}
                        </ModalHeader>
                        <ModalBody style={{paddingVertical:40}}>
                            <Row>
                                <input 
                                    type="text" 
                                    placeholder="Enter name"
                                    value={name} 
                                    style={{outline:'none',height:45,paddingLeft:10}} 
                                    onChange={(event)=> {
                                        this.setState({name: event.target.value});
                                    }}
                                />

                                <label style={{marginLeft:30}}>
                                    Authorization Code:
                                    <input type="password" value={adminCode} style={{marginLeft:10,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                                </label>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                        <Button
                            color=""
                            onClick={this.toggleFullScreenImageModal}
                        >
                            Close
                        </Button>

                        <Button
                            color="primary"
                            onClick={()=> {
                                if(!adminCode){
                                    NotificationManager.error(
                                        "Authorization Code",
                                        "Your security code required to perform this transaction. Thank you.",
                                        3000,
                                        null,
                                        null,
                                    );
                                }
                                else if(adminCode !== this.props.user.authCode) {
                                    NotificationManager.error(
                                        "Invalid Code",
                                        "Sorry, the code you entered is invalid. Check and try again.",
                                        3000,
                                        null,
                                        null,
                                    );
                                }
                                else {
                                    selectedMarket.id ? this.handleUpdate() : this.handleSubmit();
                                }
                            }}
                            >
                            {selectedMarket.id ? 'Update':'Save'}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Card>
            </Fragment>
        );
    }
};

const mapStateToProps = ({ authUser }) => {
    const { user } = authUser;
    return {user};
  };
  
  export default connect(
    mapStateToProps,
    {}
  )(Markets);