import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Resizer from "react-image-file-resizer";

import {
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  Button,
  TabContent,
  TabPane,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,Label,FormGroup
} from "reactstrap";

import { NavLink } from "react-router-dom";
import classnames from "classnames";
import axios from 'axios';

import {
    FormikReactSelect,
    FormikTagsInput,
  } from "../../containers/form-validations/FormikFields";

import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";

const image_upload_url = 'https://api.tuatuagye.com/upload-product-images';
const products_url = 'https://api.tuatuagye.com/products';
const search_items_url = 'https://api.tuatuagye.com/search-items';
const products_categories_url = 'https://api.tuatuagye.com/product-categories'

const options = [
  { value: "1", label: "Electronics" },
  { value: "2", label: "Home Appliance" },
  { value: "3", label: "Phones & Accessories" }
];

const ProductSchema = Yup.object().shape({
    name: Yup.string()
    //   .name("Invalid name")
      .required("Name is required!"),
    brand: Yup.string()
      .required("Brand required"),
    description: Yup.string()
        .required("Description required"),
    // password: Yup.string().required("Password is required!"),
    // tags: Yup.array()
    //   .min(3, "Pick at least 3 tags")
    //   .required("At least one tag is required"),
    date: Yup.date()
      .nullable()
      .required("Date required"),
    // state: Yup.object()
    //   .shape({
    //     label: Yup.string().required(),
    //     value: Yup.string().required()
    //   })
    //   .nullable()
    //   .required("State is required!")
  });
  
const resizeFile = (file, size) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    size,
    size,
    "JPEG",
    90,
    0,
    (uri) => {
      resolve(uri);
    },
    "file"
  );
});

function getSlug(name){
  const __name = name ? name.trim().toLowerCase() : '';
  const temp = `${__name}`;

  let result = temp.split(' ').join('-').split(' ').join('-');

  result = result.split('"').join('');

  console.log('slug is',result);

  return result;
}

class NewProduct extends Component {
  constructor(props) {
    super(props);

    this.toggleTab = this.toggleTab.bind(this);
    this.state = {
      loading: true,
      activeFirstTab: "1",
      modalOpen: false,
      saveModalOpen: false,
      productImageModalOpen:false,
      fullscreenImageModalOpen: false,
      adminCode:'',
      commissionAmount:'',
      processing: false,
      saving: false,
      modalViewType:'',
      fileUrl:'',
      fullscreenImageUrl:'',
      file: null,
      imageFiles: [],
      blobs: [],
      data: null,
      productId: '',
      adminCode: '',
      categories: [],
      mainCategories: [],
      subCategories: [],
      selectedCategoryId: ''
    };

    this.formRef1 = null;
    this.formRef2 = null;
    this.formRef3 = null;

    this.inp = null;
    this.inp2 = null;
  }

  componentDidMount() {
    // console.log('path...',this.props.location.pathname);
    // this.setState({data: this.props.product})
    // const id = this.props.product.id; //this.props.location.pathname.split('/').pop();
    // this.setState({productId: id});
    this.getCategories(1, '');
  }

  getCategories = (type, parentId) => {
    this.setState({loading: true})
    const __url = !!parentId ? `${products_categories_url}?filter[where][and][0][type]=${type}&filter[where][and][1][parentId]=${parentId}` : `${products_categories_url}?filter[where][type]=${type}`
    axios.get(__url)
      .then(res=> {
        const __data = res.data.map(d=> {
          d.label = d.name;
          d.value = d.id;
          return d;
        })

        if(type == 1){
          this.setState({mainCategories: __data, loading:false})
        }
        else if(type == 2){
          this.setState({subCategories: __data, loading:false})
        }
        else {
          this.setState({categories: __data, loading:false})
        }
      })
      .catch(err => {
        console.log('error loading categories',err)
        this.setState({loading: false})
      })
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }))
  }

  toggleSaveModal = () => {
    this.setState(prevState => ({
      saveModalOpen: !prevState.saveModalOpen
    }))
  }

  toggleFullImageModal = () => {
    this.setState(prevState => ({
      productImageModalOpen: !prevState.productImageModalOpen
    }))
  }

  toggleFullScreenImageModal = () => {
    this.setState(prevState => ({
      fullscreenImageModalOpen: !prevState.fullscreenImageModalOpen
    }))
  }
  

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }

  handleSubmit = (values) => {
    this.setState({processing:true});

    setTimeout(() => {
      this.toggleModal();
    }, 4000);
  }

  handleImageUpload = async () => {
    this.setState({processing:true});

    this.setState({processing:true});
    const { file } = this.state;
    const image = await resizeFile(file, 500);
    // console.log('image is',image);
    const data = new FormData();
    
    // data.append('images', image);

    let newImage = '';
    let existingImages = [];

    axios.post(image_upload_url,data)
      .then(res=> {
        // console.log('success',res.data);
        if(res.data.ids && res.data.ids[0]){
          newImage = res.data.ids[0];

          // const { id, images } = this.props.product;
          const { id, images } = this.state;
          
          // console.log('productId...',id,'new url',newImage);
          
          existingImages = images || [];
          existingImages.push(newImage);

          // console.log('existing images now',existingImages);

          if(id && existingImages.length > 0){
            return axios.patch(`${products_url}/${id}`,{ images: existingImages })
          }
        }
        
        return null;
      })
      .then(res=> {
        if(res){
          // console.log('update successful');
          const { product } = this.props;

          this.props.onUpdate({...product, images: existingImages}, false);
        }
        this.setState({processing:false});
        this.toggleFullImageModal();
      })
      .catch(error=> {
        console.log('error',error);
        this.setState({processing:false});
        NotificationManager.error(
          "Upload Error",
          "An unknown error occurred. Please check console for details or try again.",
          3000,
          null,
          null,
        );
      })
  }

  imageSelected = (event) => {
    // console.log('file is ',event.target.files[0]);;
    this.setState({file: event.target.files[0]})
    const reader = new FileReader();

    const _func = (e) => this.setState({fileUrl: e.target.result}, () => this.toggleModal())
    
    reader.onload = function (e) {
      _func(e)
    }

    reader.readAsDataURL(event.target.files[0]);
    // this.toggleModal()
  }

  imageSelected2 = (event) => {
    // console.log('*** file is ',event.target.files[0]);
    const { imageFiles, blobs } = this.state;
    
    const temp = imageFiles.map(img=> img);
    const temp2 = blobs.map(blob => blob);
    
    const url = URL.createObjectURL(event.target.files[0]);
    temp.push(url);

    temp2.push(event.target.files[0]);

    this.setState({imageFiles: temp, blobs: temp2});
  }

  // uploadThumbnail = async () => {
  //   this.setState({processing:true});
  //   const { file } = this.state;
  //   const image = await resizeFile(file, 300);
  //   console.log('image is',image);
  //   const data = new FormData();
    
  //   data.append('images', image);

  //   let thumbnail = '';

  //   return axios.post(image_upload_url,data)
  // }

  handleRemove = async () => {
    const { imageFiles, fullscreenImageUrl } = this.state;

    const temp = imageFiles.filter(img=> img !== fullscreenImageUrl);
    
    this.setState({imageFiles: temp});

    this.toggleFullScreenImageModal();
  }

  handleSearchInputKeyPress = e => {
    const { adminCode } = this.state;

    if (e.key === "Enter") {
      if(!adminCode){
        NotificationManager.error(
          "Authorization Code",
          "Your security code required to perform this transaction. Thank you.",
          3000,
          null,
          null,
        );
      }
      else if(adminCode !== this.props.user.authCode) {
        NotificationManager.error(
          "Invalid Code",
          "Sorry, the code you entered is invalid. Check and try again.",
          3000,
          null,
          null,
        );
      }
      else {
          this.save();
      }
    }
  };

  handleSave = () => {
    let { name, description, tags, category } = this.formRef1.state.values;
    tags = tags.map(tag=> tag.toLowerCase());
    const { cashPrice, onlineTTGPrice, posTTGPrice, pseudoPrice } = this.formRef2.state.values;
    const { quantity } = this.formRef3.state.values;
    const { imageFiles } = this.state;

    if(!name){
      NotificationManager.error(
        "Name missing",
        "Please provide name of new product.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!name){
      NotificationManager.error(
        "Brand missing",
        "Please provide brand of new product.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!description){
      NotificationManager.error(
        "Description missing",
        "Please provide description of new product.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(tags.length === 0){
      NotificationManager.error(
        "Add tag",
        "Please provide at least 1 tag.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(imageFiles.length === 0){
      NotificationManager.error(
        "Add image",
        "Please provide at least 1 image.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!cashPrice || cashPrice === 0){
      NotificationManager.error(
        "Add Cash Price",
        "Cash price cannot be 0.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!onlineTTGPrice || onlineTTGPrice === 0){
      NotificationManager.error(
        "Add Online-TTG Price",
        "Online-TTG price cannot be 0.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!posTTGPrice || posTTGPrice === 0){
      NotificationManager.error(
        "Add Pos-TTG Price",
        "Pos-TTG price cannot be 0.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!pseudoPrice || pseudoPrice === 0){
      NotificationManager.error(
        "Add Pseudo Price",
        "Pseudo price cannot be 0.",
        3000,
        null,
        null,
      );

      return;
    }

    this.toggleSaveModal();
  }

  save = async () => {
    // let { name, description, tags, category } = this.formRef1.state.values;
    // tags = tags.map(tag=> tag.toLowerCase());
    // const { cashPrice, onlineTTGPrice, posTTGPrice, pseudoPrice } = this.formRef2.state.values;
    // const { quantity } = this.formRef3.state.values;

    const { blobs } = this.state;

    this.setState({saving: true});

    try {
      const image = await resizeFile(blobs[0], 300);
      console.log('image is',image);
      const data = new FormData();
      
      data.append('images', image);

      let thumbnail = '';

      const res = await axios.post(image_upload_url,data);
      
      if(res && res.data && res.data.ids && res.data.ids[0]){
        thumbnail = res.data.ids[0];
      }

      console.log('new thumbnail is',thumbnail);

      if(thumbnail.length > 0){
        const _data = new FormData();
        let doneCount = 0;
        blobs.forEach(async blob=> {
          const _image = await resizeFile(blob, 500);
          console.log('resized img',_image);
          _data.append('images', _image);
          doneCount += 1;
          if(doneCount === blobs.length){
            this.uploadImages(thumbnail, _data);
          }
        })

        // const _res = await axios.post(image_upload_url,_data);

        // if(_res && _res.data && _res.data.ids && _res.data.ids.length > 0){
        //   images = res.data.ids;
        //   console.log('images are',images);

        //   if(tags && tags.length > 0){
        //     this.createSearchKeywords(tags)
        //   }

        //   const payload = { name, description, tags, thumbnail, images, cashPrice: Number(cashPrice), onlineTTGPrice: Number(onlineTTGPrice), posTTGPrice: Number(posTTGPrice), pseudoPrice: Number(pseudoPrice), quantity: Number(quantity) };
        //   console.log('payload',payload);

        //   axios.post(products_url, payload)
        //     .then(res=> {
        //       this.props.onAdd(res.data);
        //       this.setState({saving: false});
        //     })
        //     .catch(e=> {
        //       this.setState({saving: false});
        //       console.log('error creating product',e);
              
        //       NotificationManager.error(
        //         "Error",
        //         "Sorry, an unknown error occurred. Please try again.",
        //         3000,
        //         null,
        //         null,
        //       );
        //     })
        // }
        // else {
        //   console.log('res.data',_res.data)
        //   console.log('no images gotten');
        // }
        
      }
      else console.log('no thumbnail');
    } catch (error) {
      console.log('create product error',error);
    }
  }

  uploadImages = async (thumbnail, data) => {
    const res = await axios.post(image_upload_url,data);

    if(res && res.data && res.data.ids && res.data.ids.length > 0){
      const images = res.data.ids;
      console.log('images are',images);
      this.createProduct(thumbnail, images)
    }
    else {
      console.log('no images',res.data);
      this.setState({processing: false});
    }
  }

  createProduct = (thumbnail,images) => {
    let { name, description, brand, tags, category } = this.formRef1.state.values;
    tags = tags.map(tag=> tag.toLowerCase());
    const { cashPrice, onlineTTGPrice, posTTGPrice, pseudoPrice } = this.formRef2.state.values;
    const { quantity } = this.formRef3.state.values;

    if(tags && tags.length > 0){
      this.createSearchKeywords(tags)
    }

    const creatorId = this.props.user.id;

    const payload = { creatorId, name, brand, description, tags, slug: getSlug(name),  thumbnail, images, cashPrice: Number(cashPrice), onlineTTGPrice: Number(onlineTTGPrice), posTTGPrice: Number(posTTGPrice), pseudoPrice: Number(pseudoPrice), quantity: Number(quantity) };
    console.log('payload',payload);

    axios.post(products_url, payload)
      .then(res=> {
        this.props.onAdd(res.data);
        this.setState({saving: false});
        this.toggleSaveModal();
      })
      .catch(e=> {
        this.setState({saving: false});
        console.log('error creating product',e);
        
        NotificationManager.error(
          "Error",
          "Sorry, an unknown error occurred. Please try again.",
          3000,
          null,
          null,
        );
      })
  }

  createSearchKeywords = (tags) => {
    tags.forEach(tag=> {
      tag = tag.toLowerCase();
      // console.log('tag is',tag);
      axios.get(`${search_items_url}?filter[where][name]=${tag}&filter[limit]=1`)
        .then(res=> {
          if(res.data && res.data.length === 0){
            return axios.post(search_items_url, {name: tag});
          }
          else {
            // console.log('already existing');
          }
        })
        .catch(error=> {
          console.log('error creating tag',error);
        })
    })
  }
  render() {
    const { loading, data, adminCode, mainCategories, subCategories, categories, imageFiles, fullscreenImageUrl, saveModalOpen, fileUrl,saving,fullscreenImageModalOpen, productImageModalOpen, processing, modalOpen, } = this.state;

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
          {loading && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            {/* <div style={{display:'flex', alignItems:'center'}}> */}
                {/* <div onClick={()=> this.inp.click()} style={{width:80,height:80,cursor:'pointer',marginRight:10,backgroundColor:'#ccc',borderRadius:3}}>
                  {fileUrl.length > 0 && (<img style={{width:80,height:80}} src={fileUrl} alt="Img"/>)}
                  <input type='file' accept="image/jpeg, image/jpg, image/png" ref={(inp)=> this.inp = inp} onChange={this.imageSelected} name='thumbnail' hidden={true} />
                </div> */}
                {/* <div style={{flex:1,justifyContent:'space-between'}}>
                    <h5>{data.name}</h5>
                    <h5>New Product</h5>
                </div> */}

                {/* <div style={{paddingTop:10,paddingRight:10}} className="float-sm-right mb-2">
                  <Button 
                    onClick={()=> {
                      this.toggleSaveModal();
                    }} 
                    disabled={saving} outline color={"primary"}>
                      <span>{saving ? 'Please wait...':'Save'}</span>
                  </Button>
                </div>
            </div> */}
            

            <Nav tabs className="separator-tabs ml-0 mb-5" style={{marginTop:1}}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "1",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("1");
                  }}
                  to="#basic"
                >
                  BASIC INFO
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "2",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                  to="#images"
                >
                  PRODUCT IMAGES
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "3",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("3");
                  }}
                  to="#prices"
                >
                  PRICING
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "4",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("4");
                  }}
                  to="#quantity"
                >
                  INVENTORY
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeFirstTab}>
              <TabPane tabId="1">
                <Card>
                    <CardBody>
                    <Formik
                      ref={(i)=> this.formRef1 = i}
                        initialValues={{
                            name: '',
                            description: '',
                            mainCategory: '',
                            subCategory: '',
                            category: '',
                            tags: [],
                        }}
                        validationSchema={ProductSchema}
                        onSubmit={this.handleSubmit}
                        >
                        {({
                            handleSubmit,
                            setFieldValue,
                            setFieldTouched,
                            handleChange,
                            handleBlur,
                            values,
                            errors,
                            touched,
                            isSubmitting
                        }) => (
                            <Form className="av-tooltip tooltip-label-bottom">
                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Name
                                </Label>
                                <Field className="form-control" name="name" />
                                {errors.name && touched.name ? (
                                <div className="invalid-feedback d-block">
                                    {errors.name}
                                </div>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Brand
                                </Label>
                                <Field className="form-control" name="brand" />
                                {errors.brand && touched.brand ? (
                                <div className="invalid-feedback d-block">
                                    {errors.brand}
                                </div>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Description
                                </Label>
                                <Field as="textarea" rows="6" className="form-control" name="description" />
                                {errors.description && touched.description ? (
                                <div className="invalid-feedback d-block">
                                    {errors.description}
                                </div>
                                ) : null}
                            </FormGroup>
                            
                            <FormGroup className="error-l-100">
                              <Label>Main Categories </Label>
                              <FormikReactSelect
                                name="mainCategory"
                                id="mainCategory"
                                value={values.mainCategory}
                                // isMulti={true}
                                options={mainCategories}
                                onChange={(name, value)=> {
                                  setFieldValue(name, undefined);
                                  this.getCategories(2, value)
                                }}
                                onBlur={setFieldTouched}
                              />
                              {errors.mainCategory && touched.mainCategory ? (
                                <div className="invalid-feedback d-block">
                                  {errors.mainCategory}
                                </div>
                              ) : null}
                            </FormGroup>

                            <FormGroup className="error-l-100">
                              <Label>Sub Categories </Label>
                              <FormikReactSelect
                                name="subCategory"
                                id="subCategory"
                                value={values.subCategory}
                                // isMulti={true}
                                options={subCategories}
                                // onChange={setFieldValue}
                                onChange={(name, value)=> {
                                  setFieldValue(name, undefined);
                                  this.getCategories(3, value)
                                }}
                                onBlur={setFieldTouched}
                              />
                              {errors.subCategory && touched.subCategory ? (
                                <div className="invalid-feedback d-block">
                                  {errors.subCategory}
                                </div>
                              ) : null}
                            </FormGroup>

                            <FormGroup className="error-l-100">
                              <Label>Product Categories </Label>
                              <FormikReactSelect
                                name="category"
                                id="category"
                                value={values.category}
                                // isMulti={true}
                                options={categories}
                                // onChange={setFieldValue}
                                onChange={(name, value)=> {
                                  setFieldValue(name, undefined);
                                  this.setState({selectedCategoryId: value})
                                }}
                                onBlur={setFieldTouched}
                              />
                              {errors.category && touched.category ? (
                                <div className="invalid-feedback d-block">
                                  {errors.category}
                                </div>
                              ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label className="d-block">
                                Tags
                                </Label>
                                <FormikTagsInput
                                  name="tags"
                                  value={values.tags}
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                />

                                {errors.tags && touched.tags ? (
                                <div className="invalid-feedback d-block">
                                    {errors.tags}
                                </div>
                                ) : null}
                            </FormGroup>

                            </Form>
                        )}
                        </Formik>
                    </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
              <Card>
                  <CardBody>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            {(imageFiles.length < 5) && (
                              <div onClick={()=> this.inp2.click()} style={{width:100,height:100,cursor:'pointer',backgroundColor:'#eee',position:'relative',marginRight:15}}>
                                  <div className='glyph-icon simple-icon-cloud-upload' style={{fontSize:20, position:'absolute', top:'50%',left:'50%', marginRight:'-50%', transform: 'translate(-50%, -50%)'}}/>
                                  <input type='file' accept="image/jpeg, image/jpg, image/png" ref={(inp)=> this.inp2 = inp} onChange={this.imageSelected2} name='image' hidden={true} />
                              </div>
                            )}
                            {imageFiles.map((img, index) => (
                              <img 
                                key={index+''} 
                                onClick={()=> {
                                  this.setState({fullscreenImageUrl: img});
                                  this.toggleFullScreenImageModal();
                                }} 
                                style={{width:100,height:100,backgroundColor:'#eee',marginRight:15, cursor:'pointer'}} src={img} alt="Img"
                              />      
                            ))}
                        </div>
                  </CardBody>
              </Card>
              </TabPane>
              <TabPane tabId="3">
                <Card>
                    <CardBody>
                    <Formik
                      ref={(i)=> this.formRef2 = i}
                        initialValues={{
                            cashPrice: 0,
                            onlineTTGPrice: 0,
                            posTTGPrice: 0,
                            pseudoPrice: 0
                        }}
                        validationSchema={ProductSchema}
                        onSubmit={this.handleSubmit}
                        >
                        {({
                            errors,
                            touched
                        }) => (
                            <Form className="av-tooltip tooltip-label-bottom">
                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Cash Price GH₵
                                </Label>
                                <Field className="form-control" name="cashPrice" />
                                {errors.cashPrice && touched.cashPrice ? (
                                <div className="invalid-feedback d-block">
                                    {errors.cashPrice}
                                </div>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Online TTG Price GH₵
                                </Label>
                                <Field className="form-control" name="onlineTTGPrice" />
                                {errors.onlineTTGPrice && touched.onlineTTGPrice ? (
                                <div className="invalid-feedback d-block">
                                    {errors.onlineTTGPrice}
                                </div>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Pos TTG Price GH₵
                                </Label>
                                <Field className="form-control" name="posTTGPrice" />
                                {errors.posTTGPrice && touched.posTTGPrice ? (
                                <div className="invalid-feedback d-block">
                                    {errors.posTTGPrice}
                                </div>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Pseudo Price GH₵
                                </Label>
                                <Field className="form-control" name="pseudoPrice" />
                                {errors.pseudoPrice && touched.pseudoPrice ? (
                                <div className="invalid-feedback d-block">
                                    {errors.pseudoPrice}
                                </div>
                                ) : null}
                            </FormGroup>
                            
                            
                            </Form>
                        )}
                        </Formik>
                    </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="4">
              <Card>
                    <CardBody>
                    <Formik
                      ref={(i)=> this.formRef3 = i}
                        initialValues={{
                            quantity: 0
                        }}
                        validationSchema={ProductSchema}
                        onSubmit={this.handleSubmit}
                        >
                        {({
                            errors,
                            touched
                        }) => (
                            <Form className="av-tooltip tooltip-label-bottom">
                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Quantity
                                </Label>
                                <Field className="form-control" name="quantity" />
                                {errors.quantity && touched.quantity ? (
                                <div className="invalid-feedback d-block">
                                    {errors.quantity}
                                </div>
                                ) : null}
                            </FormGroup>
                            </Form>
                        )}
                        </Formik>
                    </CardBody>
                </Card>
              </TabPane>
            </TabContent>
            <div style={{marginTop:30,paddingRight:10}} className="float-sm-right mb-2">
              <Button 
                onClick={this.handleSave} 
                disabled={saving} outline color={"primary"}>
                  <span>{saving ? 'Please wait...':'Save'}</span>
              </Button>
            </div>
          </Colxx>
        </Row>

        {/* Thumbnail modal */}
        {/* <Modal
            isOpen={modalOpen}
            toggle={this.toggleModal}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleModal}>
                <Label style={{}}>Upload Thumbnail</Label>
            </ModalHeader>
            <ModalBody>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:150,height:150,borderRadius:3}} src={fileUrl} alt="Img"/>
              </div>
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.toggleModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color="primary"
                onClick={this.uploadThumbnail}
            >
              Upload Thumbnail
            </Button>
            </ModalFooter>
        </Modal> */}

        <Modal
            isOpen={productImageModalOpen}
            toggle={this.toggleFullImageModal}
            // className={this.props.className}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleFullImageModal}>
                Upload Product Image
            </ModalHeader>
            <ModalBody style={{padding:0}}>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:500,borderRadius:3}} src={fileUrl} alt="Img"/>
              </div>
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.toggleFullImageModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color={"primary"}
                onClick={this.handleImageUpload}
            >
              Upload Now
            </Button>
            </ModalFooter>
        </Modal>

          {/* fullscreen image view */}
        <Modal
            isOpen={fullscreenImageModalOpen}
            toggle={this.toggleFullScreenImageModal}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleFullScreenImageModal}>
                Product Image
            </ModalHeader>
            <ModalBody style={{padding:0}}>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:600,maxWidth:'98%',borderRadius:3}} src={`${fullscreenImageUrl}`} alt="Img"/>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                  // color="danger"
                  onClick={this.handleRemove}
                  style={{marginRight:20}}
              >
                  Remove
              </Button>{" "}
              <Button
                  color=""
                  onClick={this.toggleFullScreenImageModal}
              >
                  Close
              </Button>
            </ModalFooter>
        </Modal>

        {/* Confirm save modal */}
        <Modal
            isOpen={saveModalOpen}
            toggle={this.toggleSaveModal}
          >
            {saving && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleSaveModal}>
                <Label style={{}}>Confirm</Label>
            </ModalHeader>
            <ModalBody>
              <h4>Are you sure you want to add this new product?</h4>
              {/* <label style={{}}>
                Authorization Code:
                <input type="password" onKeyPress={e => this.handleSearchInputKeyPress(e)} value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                </label> */}
            </ModalBody>
            <ModalFooter>
              <Button
                  color=""
                  onClick={this.toggleSaveModal}
              >
                  Cancel
              </Button>{" "}
              <Button
                  color="primary"
                  onClick={()=> {
                    this.save();
                    // if(!adminCode){
                    //   NotificationManager.error(
                    //     "Authorization Code",
                    //     "Your security code required to perform this transaction. Thank you.",
                    //     3000,
                    //     null,
                    //     null,
                    //   );
                    // }
                    // else if(adminCode !== this.props.user.authCode) {
                    //   NotificationManager.error(
                    //     "Invalid Code",
                    //     "Sorry, the code you entered is invalid. Check and try again.",
                    //     3000,
                    //     null,
                    //     null,
                    //   );
                    // }
                    // else {
                    //     this.handleSave();
                    // }
                  }}
              >
                Save Now
              </Button>
            </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;

  return { user };
};

export default connect(
  mapStateToProps,
  {}
)(NewProduct);
