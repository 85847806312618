import React, { Component } from "react";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input
} from "reactstrap";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import {
  setContainerClassnames,
  clickOnMobileMenu,
  logoutUser,
  changeLocale,
  setPayments,
  setPaymentsTotal
} from "../../redux/actions";

import {
  menuHiddenBreakpoint,
  searchPath,
} from "../../constants/defaultValues";
import { NotificationManager } from "../../components/common/react-notifications";

import { MobileMenuIcon, MenuIcon } from "../../components/svg";
import TopnavEasyAccess from "./Topnav.EasyAccess";
import TopnavNewDeliveries from "./Topnav.NewDeliveries";
// import TopChats from "./Topnav.Chats";
import TopMerchants from "./Topnav.NewMerchants";
// import TopnavAddNotification from './Topnav.AddNotification';

import axios from 'axios';

const payments_url = 'https://api.tuatuagye.com/payments';
const img_base_url = 'https://api.tuatuagye.com/get-image?type=profile&id='

class TopNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInFullScreen: false,
      searchKeyword: "",
      refreshing: true
    };
  }

  componentDidMount() {
    this.refreshPayments(true);
  }

  handleChangeLocale = locale => {
    this.props.changeLocale(locale);
  };
  isInFullScreen = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };
  handleSearchIconClick = e => {
    if (window.innerWidth < menuHiddenBreakpoint) {
      let elem = e.target;
      if (!e.target.classList.contains("search")) {
        if (e.target.parentElement.classList.contains("search")) {
          elem = e.target.parentElement;
        } else if (
          e.target.parentElement.parentElement.classList.contains("search")
        ) {
          elem = e.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains("mobile-view")) {
        this.search();
        elem.classList.remove("mobile-view");
        this.removeEventsSearch();
      } else {
        elem.classList.add("mobile-view");
        this.addEventsSearch();
      }
    } else {
      this.search();
    }
  };
  addEventsSearch = () => {
    document.addEventListener("click", this.handleDocumentClickSearch, true);
  };
  removeEventsSearch = () => {
    document.removeEventListener("click", this.handleDocumentClickSearch, true);
  };

  handleDocumentClickSearch = e => {
    let isSearchClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains("navbar") ||
        e.target.classList.contains("simple-icon-magnifier"))
    ) {
      isSearchClick = true;
      if (e.target.classList.contains("simple-icon-magnifier")) {
        this.search();
      }
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      e.target.parentElement.classList.contains("search")
    ) {
      isSearchClick = true;
    }

    if (!isSearchClick) {
      const input = document.querySelector(".mobile-view");
      if (input && input.classList) input.classList.remove("mobile-view");
      this.removeEventsSearch();
      this.setState({
        searchKeyword: ""
      });
    }
  };
  handleSearchInputChange = e => {
    this.setState({
      searchKeyword: e.target.value
    });
  };
  handleSearchInputKeyPress = e => {
    if (e.key === "Enter") {
      this.search();
    }
  };

  search = () => {
    const { searchKeyword } = this.state;

    if(searchKeyword.length === 0){
      NotificationManager.error(
        "No search key",
        "You need to provide either name or phone number",
        3000,
        null,
        null,
      );

      return;
    }

    if(window.searchName){
      window.searchName(searchKeyword);
    }
    else {
      this.props.history.push(searchPath + "/" + searchKeyword);
      this.setState({searchKeyword: ""});
    }
  };

  toggleFullScreen = () => {
    const isInFullScreen = this.isInFullScreen();

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    this.setState({
      isInFullScreen: !isInFullScreen
    });
  };

  handleLogout = () => {
    this.props.logoutUser(this.props.history);
  };

  menuButtonClick = (e, menuClickCount, containerClassnames) => {
    e.preventDefault();

    setTimeout(() => {
      var event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, false);
      window.dispatchEvent(event);
    }, 350);
    this.props.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.props.selectedMenuHasSubItems
    );
  };
  mobileMenuButtonClick = (e, containerClassnames) => {
    e.preventDefault();
    this.props.clickOnMobileMenu(containerClassnames);
  };

  refreshPayments = (isNewLoad) => {
    this.setState({refreshing:true});
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start = start.toJSON();

    let end = new Date();
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end = end.toJSON();

    axios.get(`${payments_url}?filter[where][and][0][createdAt][gte]=${start}&filter[where][and][1][createdAt][lte]=${end}&filter[order]=createdAt%20DESC`)
      .then(res => {
        if(res.data && res.data.length > 0){
          let total = 0; 
          res.data.forEach(p=> total += p.amount);
          this.props.setPaymentsTotal(total);

          if(isNewLoad){
            this.props.setPayments(res.data);
          }
        }
        else this.props.setPayments([])

        this.setState({refreshing: false})
      })
      .catch(error => {
        this.setState({refreshing: false});
        NotificationManager.error(
          "Refresing payments error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
      })
  }

  formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        if(Number(decimal) < 10 && decimal.length < 2){
          decimal += '0' 
        }
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`;
      return temp;
    }
    
    return Number(amount).toFixed(2);
  }

  render() {
    const { containerClassnames, menuClickCount, locale } = this.props;
    const { refreshing } = this.state;
    const user = this.props.authUser.user;

    // const { messages } = this.props.intl;
    return (
      <nav className="navbar fixed-top">
        <NavLink
          to="#"
          className="menu-button d-none d-md-block"
          onClick={e =>
            this.menuButtonClick(e, menuClickCount, containerClassnames)
          }
        >
          <MenuIcon />
        </NavLink>
        <NavLink
          to="#"
          className="menu-button-mobile d-xs-block d-sm-block d-md-none"
          onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
        >
          <MobileMenuIcon />
        </NavLink>
        
        {user.type === 1 && (
          <div style={{display:'flex',marginRight:7}}>
            <span style={{}}>Today: GHS <span style={{color:'#228B22',fontWeight:'bold'}}>{this.formatAmount(Number(this.props.todayTotal).toFixed(2))}</span></span>
            
            {refreshing ?
            <span style={{marginLeft:10}}>...</span>
            :
            <div
              onClick={()=> this.refreshPayments(false)}
              style={{cursor:'pointer',fontSize:15,color:'#000',marginLeft:10}}  
              className={"simple-icon-refresh"} 
            />}
          </div>
        )}

        {((user.type === 1) || (user.type === 2)) && (
        <div className="search" data-search-path="/app/search">
          <Input
            // name="searchKeyword"
            // id="searchKeyword"
            placeholder="Search"
            value={this.state.searchKeyword}
            onChange={e => this.handleSearchInputChange(e)}
            onKeyPress={e => this.handleSearchInputKeyPress(e)}
          />
          <span
            className="search-icon"
            onClick={e => this.handleSearchIconClick(e)}
          >
            <i className="simple-icon-magnifier" />
          </span>
        </div>)}

        <a className="navbar-logo" href="/">
          <img src='/assets/img/logo.png' width="50" height="50" />
          {/* <span className="logo d-none d-xs-block" />
          <span className="logo-mobile d-block d-xs-none" /> */}
        </a>

        <div className="ml-auto">
          <div className="header-icons d-inline-block align-middle">
            {/* {user.type === 1 &&<TopnavAddNotification/>} */}
            {((user.type === 1) || (user.type === 2)) && (<TopMerchants merchants={this.props.newMerchants.entities} />)}
            {user.type === 1 && <TopnavEasyAccess />}
            {/* {((user.type === 1) || (user.type === 2)) && (<TopChats messages={this.props.newMessages.entities} />)} */}
            <TopnavNewDeliveries deliveries={this.props.newDeliveries.entities} />
            {/* <button
              className="header-icon btn btn-empty d-none d-sm-inline-block"
              type="button"
              id="fullScreenButton"
              onClick={this.toggleFullScreen}
            >
              {this.state.isInFullScreen ? (
                <i className="simple-icon-size-actual d-block" />
              ) : (
                <i className="simple-icon-size-fullscreen d-block" />
              )}
            </button> */}
          </div>
          <div className="user d-inline-block">
            <UncontrolledDropdown className="dropdown-menu-right">
              <DropdownToggle className="p-0" color="empty">
                <div className="name mr-1" style={{display:'inline-block',position:'relative',paddingBottom:10}}>
                  <span className="name mr-1">{user.firstname} {user.lastname}</span>
                  <span style={{position:'absolute',bottom:-4,left:0,fontSize:10,color:'#008894'}}>{user.type === 1 ? 'SUPER-ADMIN' : user.type === 2 ? 'ADMIN' : user.type === 3 ? 'STAFF':''}</span>
                </div>
                <span>
                  <img alt="" src={user.imageThumbnail ? `${img_base_url}${user.imageThumbnail}` : "/assets/img/no-image.jpg"} />
                </span>
              </DropdownToggle>
              <DropdownMenu className="mt-3" right>
                {/* <DropdownItem>Account</DropdownItem>
                <DropdownItem>Features</DropdownItem>
                <DropdownItem>History</DropdownItem>
                <DropdownItem>Support</DropdownItem>
                <DropdownItem divider /> */}
                <DropdownItem onClick={() => this.handleLogout()}>
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({ menu, settings, authUser, newMerchants, newDeliveries, newMessages, payments }) => {
  const { containerClassnames, menuClickCount, selectedMenuHasSubItems } = menu;
  const { locale } = settings;
  const { todayTotal } = payments;

  return {
    containerClassnames,
    menuClickCount,
    selectedMenuHasSubItems,
    locale,
    authUser,
    newMerchants,
    newDeliveries,
    newMessages,
    todayTotal
  };
};
export default connect(
    mapStateToProps,
    { setContainerClassnames, clickOnMobileMenu, logoutUser, changeLocale, setPayments, setPaymentsTotal }
  )(TopNav);
