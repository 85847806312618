import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const formatDate = (_date) => {
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}`;
}

const ChatHeading = ({ name, thumb, userId }) => {
  return (
    <Fragment>
      <div className="d-flex flex-row chat-heading">
        <div className="d-flex">
          <img
            alt={'img'}
            src={thumb ? ``:'/assets/img/no-image.jpg'}
            className="img-thumbnail border-0 rounded-circle ml-0 mr-4 list-thumbnail align-self-center small"
          />
        </div>
        <div className=" d-flex min-width-zero">
          <div className="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
            <div className="min-width-zero">
              <div>
                <p className="list-item-heading mb-1 truncate ">
                <Link to={`/app/users/customers/${userId}`} style={{fontSize:16}}>{name}</Link>
                </p>
              </div>
              {/* <p className="mb-0 text-muted text-small"> */}
                {/* DEV TEST */}
                {/* {lastSeenDate === "Online" ? "Online" : formatDate(Date.now())}
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="separator mb-5" />
    </Fragment>
  );
};

export default ChatHeading;
