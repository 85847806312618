import {
    ADD_MERCHANT,
    REMOVE_MERCHANT
} from '../actions';

export const addMerchant = (payload) => {
    return (
        {
            type: ADD_MERCHANT,
            payload: payload
        }
    )
}

export const removeMerchant = (payload) => {
    return (
        {
            type: REMOVE_MERCHANT,
            payload: payload
        }
    )
}