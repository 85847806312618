import {
    ADD_NAV_MESSAGE,
    REMOVE_NAV_MESSAGE
} from '../actions';

export const addNavMessage = (payload) => {
    return (
        {
            type: ADD_NAV_MESSAGE,
            payload: payload
        }
    )
}

export const removeNavMessage = (payload) => {
    return (
        {
            type: REMOVE_NAV_MESSAGE,
            payload: payload
        }
    )
}