import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Modal, ModalHeader, Label, ModalBody, ModalFooter, Button } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { DateRangePicker } from 'react-date-range';

import "react-datepicker/dist/react-datepicker.css";
import DataTablePagination from "../../components/DatatablePagination";

import axios from 'axios';
import { NotificationManager } from "../../components/common/react-notifications";

import SelectMerchantOption from "../../containers/SelectMerchantOption";
import ReactTable from "react-table";
import * as FileSaver from 'file-saver';
import LocationsAutoSuggest from "../../containers/LocationAutoSuggest";

// const getPixelPositionOffset = (width, height) => ({
//   x: -(width / 2),
//   y: -(height / 2),
// })
const signups_url = 'https://api.tuatuagye.com/sign-ups'

export default class Signups extends Component {
  constructor(props){
    super(props);

    this.state = {
      signups: [],
      totalAmount: 0,
      date: '',
      loading: true,
      mapCoordinates: [],
      merchant: null,
      defaultPageSize: 50,
      startDate: null,
      startDateTime: null,
      startDateRange: null,
      endDateRange: null,
      endDateTime: null,
      dateModal: false,
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    }

    this.columns = [
        {
            Header: "Customer",
            accessor: "user",
            maxWidth:180,
            Cell: props => <p className="list-item-heading">{props.value.firstname} {props.value.lastname}</p>
        },
        {
            Header: "Phone",
            accessor: "user",
            width: 110,
            Cell: props => <p className="text-muted">{props.value.phone}</p>
        },
        {
            Header: "Product",
            accessor: "productName",
            Cell: props => <p className="text-muted truncate">{props.value}</p>
        },
        {
            Header: "Amount",
            accessor: "amount",
            width:120,
            Cell: props => <p className="text-muted">GH₵ {this.formatAmount(props.value)}</p>
        },
        {
          Header: "Momo N#",
          accessor: "momoNumber",
          Cell: props => <p className="text-muted">{props.value}</p>
        },
        {
            Header: "Location",
            accessor: "user",
            Cell: props => <p className="text-muted">{props.value.location}</p>
        },
        {
            Header: "Merchant",
            accessor: "merchantName",
            Cell: props => <p className="text-muted">{props.value}</p>
        },
        {
            Header: "Date",
            accessor: "createdAt",
            width:170,
            Cell: props => <p className="text-muted">{this.formatDate(props.value)}</p>
        },
        // {
        //     Header: "Action",
        //     accessor: "status",
        //     width: 120,
        //     Cell: props => <div style={{alignItems:"center",display:'flex',justifyContent:'space-between'}}>
        //         <div
        //           onClick={()=> {
        //               this.setState({selectedOrder: props.original, adminCode:'', showDetails: true});
        //             }}
        //           style={{cursor:'pointer',fontSize:15, color:'#4d4d4d'}}  
        //           className={"glyph-icon iconsminds-maximize"} 
        //         />

        //         {props.value === 1 && 
        //             <span
        //                 onClick={()=> {
        //                     this.setState({selectedOrder: props.original, adminCode:''});
        //                     this.toggleNestedContainer();
        //                 }}
        //                 style={{cursor:'pointer',color:'gold'}}  
        //             >
        //             PENDING    
        //             </span>
        //         }
        //         {props.value === 2 && 
        //             <span
        //                 onClick={()=> {
        //                     this.setState({selectedOrder: props.original, adminCode:''});
        //                     this.toggleNestedContainer();
        //                 }}
        //                 style={{cursor:'pointer',color:'#00f'}}  
        //             >
        //             SHIPPED    
        //             </span>
        //         }
        //         {props.value === 3 && <span style={{color:'#228822'}}>DELIVERED</span>}
        //     </div>
        // }
    ]
    
  }

  componentDidMount() {
    const _startDateTime = new Date();
    _startDateTime.setHours(0);
    _startDateTime.setMinutes(0);
    _startDateTime.setSeconds(0);

    const _endDateTime = new Date();
    _endDateTime.setHours(23);
    _endDateTime.setMinutes(59);
    _endDateTime.setSeconds(59);

    this.setState({startDateTime: _startDateTime, endDateTime: _endDateTime});
    
    const date = new Date();;
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    this.setState({ date });

    this.loadData();
  }

  loadData() {
    const { selectionRange, merchant } = this.state;
    const startDateTime = selectionRange.startDate;
    startDateTime.setHours(0);
    startDateTime.setMinutes(0);
    startDateTime.setSeconds(0);

    const endDateTime = new Date(selectionRange.endDate);
    endDateTime.setHours(23);
    endDateTime.setMinutes(59);
    endDateTime.setSeconds(59);

    let query = '';
    if(merchant && merchant.id){
      query = `filter[where][and][0][merchantId]=${merchant.id}&filter[where][and][1][createdAt][gte]=${startDateTime.toJSON()}&filter[where][and][2][createdAt][lte]=${endDateTime.toJSON()}&filter[include][]=user&filter[order]=createdAt desc`;
    }
    else {
      query = `filter[where][and][0][createdAt][gte]=${startDateTime.toJSON()}&filter[where][and][1][createdAt][lte]=${endDateTime.toJSON()}&filter[include][]=user&filter[order]=createdAt desc`;
    }

    axios.get(`${signups_url}?${query}`)
      .then(res=> {
        if(res.data && res.data.length > 0){
          let total = 0;
          res.data.forEach(d=> total += d.amount);
          this.setState({totalAmount: total})
        }
        else this.setState({totalAmount: 0})
        this.setState({signups: res.data, loading: false});
      })
      .catch(error=> {
        console.log('error...',error);
        this.setState({loading:false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
      })
  }

  changeOptions = (merchant) => {
    const  { date } = this.state;
    this.setState({merchant, loading: true}, ()=> this.loadData())
    // axios.get(`${signups_url}?filter[where][and][0][merchantId]=${merchant.id}&filter[where][and][1][createdAt][gte]=${date.toJSON()}&filter[include][]=user`)
    //   .then(res=> {
    //     if(res.data && res.data.length > 0){
    //       let total = 0;
    //       res.data.forEach(d=> total += d.amount);
    //       this.setState({totalAmount: total})
    //     }
    //     else this.setState({totalAmount: 0})
    //     this.setState({signups: res.data, loading: false});
    //   })
    //   .catch(error=> {
    //     console.log('error...',error);
    //     this.setState({loading:false});
    //     NotificationManager.error(
    //       "Loading error!",
    //       "An unknown error occurred. Please refresh page.",
    //       3000,
    //       null,
    //       null,
    //     );
    //   })
  }

  locationChange = (loc) => {
    if(!loc) return;

    const { selectionRange, merchant } = this.state;
    const startDateTime = selectionRange.startDate;
    startDateTime.setHours(0);
    startDateTime.setMinutes(0);
    startDateTime.setSeconds(0);

    const endDateTime = new Date(selectionRange.endDate);
    endDateTime.setHours(23);
    endDateTime.setMinutes(59);
    endDateTime.setSeconds(59);
    this.setState({loading: true});
    // let query = '';
    // if(merchant){
    //   query = `filter[where][and][0][merchantId]=${merchant.id}&filter[where][and][1][locationId]=${loc.id}&filter[where][and][2][createdAt][gte]=${date.toJSON()}&filter[include][]=user`
    // }
    // else {
    //   query = `filter[where][createdAt][gte]=${date.toJSON()}&filter[include][]=user`
    // }

    let query = '';
    if(merchant && merchant.id){
      query = `filter[where][and][0][merchantId]=${merchant.id}&filter[where][and][1][locationId]=${loc.id}&filter[where][and][2][createdAt][gte]=${startDateTime.toJSON()}&filter[where][and][3][createdAt][lte]=${endDateTime.toJSON()}&filter[include][]=user&filter[order]=createdAt desc`;
    }
    else {
      query = `filter[where][and][0][createdAt][gte]=${startDateTime.toJSON()}&filter[where][and][1][createdAt][lte]=${endDateTime.toJSON()}&filter[where][and][2][locationId]=${loc.id}&filter[include][]=user&filter[order]=createdAt desc`;
    }

    axios.get(`${signups_url}?${query}`)
      .then(res=> {
        if(res.data && res.data.length > 0){
          let total = 0;
          res.data.forEach(d=> total += d.amount);
          this.setState({totalAmount: total})
        }
        else this.setState({totalAmount: 0})

        this.setState({signups: res.data, loading: false});
      })
      .catch(error=> {
        console.log('error...',error);
        this.setState({loading:false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
      })

    // this.setState({ page: 1, loading: true });
    // const { selectedOption } = this.state;
    // let query = '';
    // if(selectedOption.key === 0){
    //   query = `filter[where][type][gt]=4`
    // }
    // else {
    //   query = `filter[where][type]=${selectedOption.key}`
    // }

    // axios.get(`${users_url}?${query}&filter[order]=createdAt%20DESC&filter[limit]=50&filter[where][locationId]=${loc.id}`)
    //   .then(res => {
    //     if(res.data && res.data.length > 0){
    //       this.setState({customers: res.data, loading: false});
    //     }
    //     else {
    //       this.setState({customers:[], loading:false})
    //     }
    //   })
    //   .catch(error => {
    //     this.setState({loading: false});
    //     alert('Sorry, an unknown error occurred.');
    //     console.log('error...',error);
    //   })
  }

  handleChangeDate = date => {
    this.setState({date: date});
    const { merchant } = this.state;
    this.setState({loading: true});
    let query = '';
    if(merchant){
      query = `filter[where][and][0][merchantId]=${merchant.id}&filter[where][and][1][createdAt][gte]=${date.toJSON()}&filter[include][]=user`
    }
    else {
      query = `filter[where][createdAt][gte]=${date.toJSON()}&filter[include][]=user`
    }

    axios.get(`${signups_url}?${query}`)
      .then(res=> {
        if(res.data && res.data.length > 0){
          let total = 0;
          res.data.forEach(d=> total += d.amount);
          this.setState({totalAmount: total})
        }
        else this.setState({totalAmount: 0})

        this.setState({signups: res.data, loading: false});
      })
      .catch(error=> {
        console.log('error...',error);
        this.setState({loading:false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
      })
  };

  formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
      return temp;
    }
    
    return Number(amount).toFixed(2);
  }

  __formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let yr = `${date.getFullYear()}`.slice(2);
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
  }

  exportToExcel() {
    const data = this.state.signups.map(record=> {
      return {
        Amount: record.amount,
        Merchant: record.merchantName,
        'MoMo Number': record.momoNumber,
        Customer: `${record.user.firstname} ${record.user.lastname}`,
        Phone: record.user.phone,
        Location: record.user.location,
        Occupation: record.user.occupation,
        Product: record.productName,
        'Date Signed': this.__formatDate(record.createdAt)
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `Signups Data - ${new Date().toJSON()}`);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  toggleDateModal = () => {
    this.setState(prevState => ({
      dateModal: !prevState.dateModal,
    }));
  };

  handleSelect = (ranges) => {
		this.setState({selectionRange: {
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
			key: 'selection',
		}})
	}

  render() {
    const { loading, signups, selectionRange, totalAmount, defaultPageSize } = this.state;
    const { startDate, endDate } = selectionRange;

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Row style={{justifyContent:'space-between',alignItems:'center',marginRight:0,marginLeft:0}}>
              <h1>Sign ups ({signups.length}) | Total {this.formatAmount(totalAmount)}</h1>
              <div style={{display:'flex'}}>
                <div onClick={this.toggleDateModal} style={{alignItems:'center',justifyContent:'center',display:'flex',paddingRight:10,paddingLeft:10,borderRadius:3,marginLeft:"10px",color:'#fff',backgroundColor:'rgb(0, 136, 148)',cursor:'pointer'}}>
                  {startDate.toDateString() == endDate.toDateString() ? startDate.toDateString() : `${startDate.toDateString()} - ${endDate.toDateString()}`}
                </div>
                {/* <div style={{width:"100px"}}>
                  <DatePicker
                    selected={this.state.date}
                    onChange={this.handleChangeDate}
                    placeholderText="Date"
                    // showTimeSelect
                    // timeFormat="HH:mm"
                    timeIntervals={15}
                    // dateFormat="Pp"
                    // timeCaption="Time"
                  />
                </div> */}

                <div style={{width:260,marginLeft:10}}>
                  <SelectMerchantOption
                    onChange={this.changeOptions}
                  />
                </div>
                <div style={{minWidth:"200px",marginLeft:10}}>
                  <LocationsAutoSuggest onChange={this.locationChange} />
                </div>
              </div>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card>
              <CardBody>
                {loading && <div className='loading'/>}
                <ReactTable
                    data={signups}
                    columns={this.columns}
                    defaultPageSize={defaultPageSize}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    className={"react-table-fixed-height"}
                />
                <div
                  onClick={()=> {
                    this.exportToExcel();
                  }} 
                  className="d-flex justify-content-end" style={{flex:1,cursor:"pointer"}}>
                  Download to Excel 
                  <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        {/* DATE MODAL */}

        <Modal
          isOpen={this.state.dateModal}
          toggle={this.toggleDateModal}
          className={'date-modal'} 
        >
          <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleDateModal}>
            <Label>Set Date Range</Label>
          </ModalHeader>
          <ModalBody>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={this.handleSelect}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              onClick={this.toggleDateModal}
            >
              Cancel
            </Button>{" "}
            
            <Button
              color="success"
              onClick={()=> {
                this.loadData();
                this.setState({loading: true});
                this.toggleDateModal();
              }}
            >
              Filter Now
            </Button>
            
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}