import React, { Component } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
// import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import axios from 'axios';

import { loginUser } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { NotificationManager } from "../../components/common/react-notifications";

// const users_url = 'https://api.tuatuagye.com/users';
const users_url = 'https://api.tuatuagye.com/users'

const getcode = () => {
  let code = '';
  [1, 1, 1, 1, 1, 1].forEach(element => {
    const num = Math.floor(Math.random() * 9) + 1;
    code += num;
  });
  
  return code;
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      code: "",
      loginFailed: false,
      unAuthorized: false,
      processing: false,
      step: 1
    };
  }
  
  onUserLogin() {
    const { phone, code } = this.state;
    if(!phone){
      NotificationManager.error(
        "Error",
        "Phone number required",
        3000,
        null,
        null,
      );
    }
    else if(phone.length !== 10){
      NotificationManager.error(
        "Error",
        "Phone number must be 10 digits!",
        3000,
        null,
        null,
      );
    }
    else if(!code){
      NotificationManager.error(
        "Error",
        "Please enter code",
        3000,
        null,
        null,
      );
    }
    else if(code.length !== 6){
      NotificationManager.error(
        "Error",
        "Code must be 6 digits!",
        3000,
        null,
        null,
      );
    }
    else {
      this.setState({loginFailed: false, unAuthorized: false, processing: true})
      this.props.loginUser(this.state, this.props.history);
      window.loginFailed = () => {
        this.setState({loginFailed: true, processing:false})
      }

      window.unAuthorized = () => {
        this.setState({unAuthorized: true, processing:false})
      }
    }
  }

  handleInputKeyPress = e => {
    if (e.key === "Enter") {
      this.onUserLogin();
    }
  };

  handleLogin = () => {
    const { phone, step, code } = this.state;
    console.log('stp',step);
    if(step === 1){
      if(!phone){
        alert('Enter valid phone number')
      }
      else {
        this.setState({processing: true});

        let authCode = '';
        axios.get(`${users_url}?filter[where][and][0][phone]=${phone}&filter[where][and][1][type][lt]=4&filter[where][and][2][enabled]=true`)
          .then(res=> {
              if(res.data.length > 0 && res.data[0]){
                  const u = res.data[0];
                  authCode = getcode();
                  // console.log('code is',authCode);
                  return axios.patch(`${users_url}/${u.id}`, { authCode })
              }
              else {
                  alert(`Sorry, you are not authorized to use this system. \n\nThank you.`);
                  this.setState({processing: false})
                  return null;
              }
          })
          .then(res => {
              if(!!res && authCode.length > 0){
                  // console.log('___res is',res);
                  const message ='Your OTP code is: '+authCode;
                  return axios.post('https://api.tuatuagye.com/send-message', { phone, msg: message});
              }
              return null;
          })
          .then(res => {
              if(res){
                  console.log('res ...',res.data._response);

                  this.setState({step:2,processing:false});
              }
              else {
                  this.setState({processing:false})
              }
          })
          .catch(e=> {
              console.log('error is',e);
              alert('An unknown error occurred. Please try again.');
              this.setState({processing:false})
          })
      }
    }
    else {
      if(!code){
          alert('Code required');
          return;
      }
      else if(code.length < 6){
          alert('Code should be 6 digits')
          return;
      }

      this.setState({processing: true});
      axios.get(`${users_url}?filter[where][and][0][phone]=${phone}&filter[where][and][1][authCode]=${code}`)
      .then(res=> {
          if(res.data.length > 0 && res.data[0]){
              const user = res.data[0];

              this.onUserLogin();
              // return axios.get(`${config.MERCHANTS_API}?filter[where][userId]=${user.id}&filter[limit]=1`);
          }
          else {
              this.setState({processing:false});
              alert('The code you submitted did not match that of your account. Kindly check the received code and try again. \n\nThank you.');
              return null;
          }
      })
      // .then(res => {
      //     if(res && res.data[0]){
      //         const merchant = res.data[0];
                                  
      //         // console.log('merchant is',merchant);

      //         user = {
      //             ...user, 
      //             collectionsCommission: merchant.collectionsCommission, 
      //             signUpsCommission: merchant.signUpsCommission,
      //             cashSalesCommission: merchant.cashSalesCommission,
      //             merchantId: merchant.id
      //         };
              
      //         AsyncStorage.setItem('USER',JSON.stringify(user));

      //         this.props.setUser(user);
      //         this.props.signIn();
      //     }
      //     // else console.log('else part');
      //     return null;
      // })
      .catch(e=> {
          console.log('e found',e);
          alert('An unknown error occurred. Please try again.');
          this.setState({processing:false})
      })
    }
  }
  
  render() {
    const { loginFailed, unAuthorized, processing } = this.state;
    
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="4" className="mx-auto my-auto">
          <Card className="auth-card" style={{borderRadius:5}}>
            {/* <div className="position-relative image-side ">
              <p className="text-white h2">TTG ADMIN</p>
              <p className="white mb-0">
                Please use your credentials to login.
                <br /><br />
                If you don't have an account yet, please contact admin.
                .
              </p>
            </div> */}
            <div className="form-side" style={{paddingTop:0}}>
              <div style={{marginBottom:70,position:'relative',backgroundColor:'gold'}}>
                <img src="/assets/img/logo.png" style={{width:70,height:70,padding:7,borderRadius:35,backgroundColor:'#fff',position:'absolute', top:'50%',left:'50%', marginRight:'-50%', transform: 'translate(-50%, -50%)'}}/>
              </div>
              <CardTitle className="mb-4">
                <IntlMessages id="user.login-title" />
              </CardTitle>
              <Form>
                {loginFailed && <p style={{color:'#f00'}}>Email / Password mismatch</p>}
                {unAuthorized && <p style={{color:'#f00'}}>You are not authorized to access this portal yet.</p>}
                {/* {step === 1 &&  */}
                  <Label className="form-group has-float-label mb-4">
                    <Input disabled={processing} type="phone"  
                      onChange={(e)=> {
                        // console.log(`typeof e.target.value === 'number'`,typeof e.target.value);
                        // if(typeof e.target.value === 'number') {
                          this.setState({phone: e.target.value})
                        // }
                      }} 
                      defaultValue={this.state.phone} />
                    <IntlMessages
                      id="user.phone"
                      defaultValue={this.state.password}
                    /> 
                  </Label>
                {/* } */}
                
                {/* {step === 2 &&  */}
                  <Label className="form-group has-float-label mb-4">
                    <Input disabled={processing} type="password" onChange={(e)=> this.setState({code: e.target.value})} onKeyPress={e => this.handleInputKeyPress(e)} defaultValue={this.state.code} />
                    <IntlMessages
                      id="user.password"
                      defaultValue={this.state.password}
                    />
                  </Label>
                {/* } */}
                
                <div className="d-flex justify-content-between align-items-center">
                  {/* <NavLink to={`/user/forgot-password`}>
                    <IntlMessages id="user.forgot-password-question" />
                  </NavLink> */}
                  <span></span>
                  <Button
                    disabled={processing} 
                    color="primary"
                    className="btn-shadow"
                    size="lg"
                    onClick={()=> this.onUserLogin()}
                    // onClick={() => this.handleLogin()}
                  >
                    {processing ? 'Please wait...':'Login'}
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(
  mapStateToProps,
  {
    loginUser
  }
)(Login);
