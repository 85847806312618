import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import axios from 'axios';
import { NotificationManager } from "../../../components/common/react-notifications";
import * as FileSaver from 'file-saver';
import { Row, Card, CardBody, Input, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,Label, Button } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
// import DataTablePagination from "../../../components/DatatablePagination";
import CustomSelectInput from "../../../components/common/CustomSelectInput";

import {
  setContainerClassnames,
  changeDefaultClassnames
} from "../../../redux/actions";
import LocationsAutoSuggest from "../../../containers/LocationAutoSuggest";

const selectData = [
  { label: "All", value: "all", key: 0 },
  { label: "SELF", value: "online", key: 5 },
  { label: "POS", value: "pos", key: 6 }
];

const users_url = 'https://api.tuatuagye.com/users'
const img_base_url = 'https://api.tuatuagye.com/get-image?type=profile&id='

class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      modalOpen: false,
      processing: false,
      confirmModal:false,
      modalViewType:'',
      searchKeyword:'',
      adminCode: '',
      selectedOption: selectData[0],
      selectedUser: {},
      customers: [],
      total: 0,
      loading:true,
      page: 1,
      perPage: 100,
      totalPage: Math.ceil(4450/50),
      customersCount: 4450
    }

    this.columns = [
      {
        Header: "Name",
        accessor: "firstname",
        Cell: props => <Row style={{marginLeft:0,marginRight:0,alignItems:'center'}}>
          {/* <img style={{width:50,height:50,borderRadius:25,marginRight:10}} src="/assets/img/no-image.jpg" alt="IMG"/> */}
          <img style={{width:50,height:50,borderRadius:25,marginRight:10,backgroundColor:'#eee'}} src={props.original.imageThumbnail ? `${img_base_url}${props.original.imageThumbnail}` : "/assets/img/no-image.jpg"} alt="img"/>
          <span style={{fontSize:16}}>{props.original.firstname} {props.original.lastname}</span>
        </Row>
      },
      {
        Header: "Phone",
        accessor: "phone",
        headerClassName:'custom-td',
        className: 'custom-td',
        Cell: props => <p style={{paddingTop:15}} className="text-muted">{props.value}</p>
      },
      // {
      //   Header: "Last Seen",
      //   accessor: "lastSeen",
      //   headerClassName:'custom-td',
      //   className: 'custom-td',
      //   Cell: props => <p className="text-muted"  style={{paddingTop:15, color: props.value === 'Online' ? '#2ECC71':'#4d4d4d'}}>
      //     {this.__formatDate(Number(props.value || props.original.createdAt))}
      //   </p>
      // },
      {
        Header: "Type",
        accessor: "type",
        headerClassName:'custom-td',
        className: 'custom-td',
        Cell: props => <div style={{paddingTop:15}}>{props.value === 5 ? 'SELF':'POS'}</div>
      },
      {
        Header: "Date Joined",
        accessor: "createdAt",
        headerClassName:'custom-td',
        className: 'custom-td',
        Cell: props => <div style={{paddingTop:15}}>{this.__formatDate(props.value)}</div>
      },
      {
        Header: "Status",
        accessor: "enabled",
        width:90,
        Cell: props => <div style={{paddingTop:15}}><span style={{backgroundColor: props.value ? '#2ECC71' : '#E74C3C',color:'#fff',paddingRight:7,paddingLeft:7,paddingTop:5,paddingBottom:5,borderRadius:4}}>{props.value ? 'Active' : 'Inactive'}</span></div>
      },
      {
        Header: "Action",
        accessor: "enabled",
        width: 80,
        Cell: props => <Row style={{paddingLeft:"12px",paddingTop:10}}>
          {!props.value && 
            <div
              onClick={()=> {
                this.setState({selectedUser:props.original, modalViewType: 'ENABLE'})
                this.toggleModal();
              }}
              style={{cursor:'pointer',fontSize:20,color:'#2ECC71'}}  
              className={"glyph-icon iconsminds-add-user"} 
            />
          }
          {props.value  && 
            <div 
              onClick={()=> {
                this.setState({selectedUser:props.original, modalViewType: 'DISABLE'})
                this.toggleModal();
              }}
              style={{cursor:'pointer',fontSize:17,paddingTop:5,marginRight:7,paddingLeft:4,color:'#E74C3C'}} 
              className={"glyph-icon simple-icon-close"} 
            />
          }
          <NavLink target="_blank" style={{marginLeft:15,paddingTop:5}} to={`/app/users/customers/${props.original.id}`}>
            <div style={{fontSize:17}} className={'glyph-icon simple-icon-eye'}/>
          </NavLink>
        </Row>
      }
    ]
  }

  componentDidMount() {
    axios.get(`${users_url}?filter[where][type][gt]=4&filter[order]=createdAt%20DESC&filter[limit]=50`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({customers: res.data, loading:false})
        }
      })
      .catch(error => {
        this.setState({loading: false});
        // alert('An errror occurred.')
        NotificationManager.error(
          "Loading error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
      })

      //move to server side and get total count
    // axios.get(`${users_url}/users?filter[where][type][gt]=4&filter[order]=createdAt%20DESC&filter={"fields":["name","address"]}`)
    //   .then(res => {
    //     if(res.data && res.data.length > 0){
    //       console.log('res is',res.data)
    //       this.setState({total: res.data.length})
    //     }
    //   })
    //   .catch(error => {
    //     this.setState({loading: false});
    //     alert('An errror occurred.')
    //   })
  }

  exportToExcel() {
    const data = this.state.customers.map(record=> {
      return {
        Name: `${record.firstname} ${record.lastname}`,
        Phone: `${record.phone}`.indexOf('N/A') > -1 ? 'NOT SET':record.phone,
        Email: record.email || 'NOT SET',
        Type: record.type === 5 ? 'SELF':'POS',
        Active: record.enabled ? 'YES':'NO',
        Location: record.location || 'NOT SET',
        'Date Joined': this.__formatDate(record.createdAt)
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `Customers Data | Page ${this.state.page} - ${new Date().toJSON()}`);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  searchWithName = (searchText) => {
    this.setState({loading: true});
    let query = '';
    let temp = searchText.trim();

    if(temp.split(' ').length > 1){
      query = `filter[where][and][0][firstname][regexp]=/${temp.split(' ')[0]}/i&filter[where][and][1][lastname][regexp]=/${temp.split(' ')[1]}/i&filter[where][and][2][type][gt]=4`
    }
    else {
      query = `filter[where][or][0][firstname][regexp]=/${temp}/i&filter[where][or][1][lastname][regexp]=/${temp}/i&filter[where][or][2][phone][regexp]=/${temp}/i&filter[where][and][3][type][gt]=4`
    }

    axios.get(`${users_url}?${query}&filter[limit]=20&filter[order]=createdAt desc`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({customers: res.data, loading: false});
          return null;
        }
        else if(temp.split(' ').length > 1){
          query = `filter[where][and][0][firstname][regexp]=/${temp.split(' ')[1]}/i&filter[where][and][1][lastname][regexp]=/${temp.split(' ')[0]}/i&filter[where][and][2][type][gt]=4`;
          return axios.get(`${users_url}?${query}&filter[limit]=20`)
        }
        else {
          this.setState({customers:[], loading:false});
          return null;
        }
      })
      .then(res=> {
        if(res){
          this.setState({customers: res.data, loading: false});
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Search error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }))
  }

  handleSubmit = (user) => {
    const { customers } = this.state
    const enabled = user.enabled ? false : true;

    this.setState({processing:true});

    axios.patch(`${users_url}/${user.id}`,{ enabled })
      .then(res => {
        const temp = customers.map(usr => {
          if(usr.id === user.id){
            usr.enabled = enabled;
          }

          return usr;
        })

        this.setState({customers: temp, processing: false, adminCode: ''});
        this.toggleModal();
      })
      .catch(error => {
        this.setState({loading: false});
        console.log('edit eror',error);
        NotificationManager.error(
          "Edit error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
      })
  }

  handleTypeOptionChange = selectedOption => {
    if(selectedOption.key !== this.state.selectedOption.key){
      this.setState({ selectedOption, page: 1 });

      this.setState({loading: true});
      let query = '';
      if(selectedOption.key === 0){
        query = `filter[where][type][gt]=4`
      }
      else {
        query = `filter[where][type]=${selectedOption.key}`
      }
      axios.get(`${users_url}?${query}&filter[order]=createdAt%20DESC&filter[limit]=50`)
        .then(res => {
          if(res.data && res.data.length > 0){
            this.setState({customers: res.data, loading: false});
          }
          else {
            this.setState({customers:[], loading:false})
          }
        })
        .catch(error => {
          this.setState({loading: false});
          alert('Sorry, an unknown error occurred.');
          console.log('error...',error);
        })
    }
  };

  formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  // __formatDate = (_date) => {
  //   // 3:16pm | Mar 2
  //   if(!_date) return '';
  //   const date = new Date(_date);
  //   let hours = date.getHours();
  //   let minutes = date.getMinutes();
  //   let ampm = hours >= 12 ? 'pm' : 'am';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   minutes = minutes < 10 ? '0'+minutes : minutes;
    
  //   let strTime = hours + ':' + minutes + '' + ampm;
    
  //   let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
  //   return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}`;
  // }

  __formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let yr = `${date.getFullYear()}`.slice(2);
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
  }

  locationChange = (loc) => {
    if(!loc) return;

    this.setState({ page: 1, loading: true });
    const { selectedOption } = this.state;
    let query = '';
    if(selectedOption.key === 0){
      query = `filter[where][type][gt]=4`
    }
    else {
      query = `filter[where][type]=${selectedOption.key}`
    }

    axios.get(`${users_url}?${query}&filter[order]=createdAt%20DESC&filter[limit]=50&filter[where][locationId]=${loc.id}`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({customers: res.data, loading: false});
        }
        else {
          this.setState({customers:[], loading:false})
        }
      })
      .catch(error => {
        this.setState({loading: false});
        alert('Sorry, an unknown error occurred.');
        console.log('error...',error);
      })
  }

  handlePageChange = (page, perPage) => {
    this.setState({loading: true});
    let skip = page === 1 ? 0 : (page-1)*perPage;
    const { selectedOption } = this.state;

    let filter = '';
    if(selectedOption.key === 0){
      filter = `filter[where][type][gt]=4`
    }
    else {
      filter = `filter[where][type]=${selectedOption.key}`
    }

    axios.get(`${users_url}?${filter}&filter[skip]=${skip}&filter[limit]=${perPage}&filter[order]=createdAt%20DESC`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({customers: res.data, loading: false});
        }
        else {
          this.setState({customers:[], loading:false})
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  };

  render() {
    const { adminCode, customers,customersCount,page, perPage, totalPage, total, searchKeyword, selectedOption, loading, modalViewType, processing, modalOpen, selectedUser } = this.state;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Row style={{justifyContent:'space-between',marginLeft:0,marginRight:0}}>
              <h1>Customers</h1>
              <Row style={{marginBottom:10,marginLeft:0,marginRight:0}}>
                <div className="payments-search" style={{marginRight:0}}>
                  <Input
                    style={{backgroundColor:'#fff'}}
                    // name="searchKeyword"
                    // id="searchKeyword"
                    placeholder={"Search"
                        // messages["menu.search"]
                    }
                    value={searchKeyword}
                    onChange={e => {
                      let value = e.target.value;
                      this.setState({searchKeyword: value})
                      clearTimeout(this.timer);
                      this.timer = setTimeout(() => {
                        this.searchWithName(value);
                      }, 1000);
                      // this.handleSearchInputChange(e)
                    }}
                    // onKeyPress={e => this.handleSearchInputKeyPress(e)}
                  />
                  <span
                      className="search-icon"
                      style={{top:10}}
                      // onClick={e => this.handleSearchIconClick(e)}
                  >
                      <i className="simple-icon-magnifier" />
                  </span>
                </div>

                <div style={{minWidth:"130px",marginLeft:10}}>
                  <Select
                    components={{ Input: CustomSelectInput }}
                    placeholder='All'
                    className="react-select"
                    classNamePrefix="react-select"
                    name="form-field-name"
                    value={selectedOption}
                    onChange={this.handleTypeOptionChange}
                    options={selectData}
                  />
                </div>

                <div style={{minWidth:"200px",marginLeft:10}}>
                  <LocationsAutoSuggest onChange={this.locationChange} />
                </div>
                </Row>
              </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card>
              <CardBody>
                {loading && <div className='loading'/>}
                <ReactTable
                  data={customers}
                  columns={this.columns}
                  defaultPageSize={50}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  PaginationComponent={()=> null}
                  className={"react-table-fixed-height"}
                />

                <div className="flex justify-between bg-red-100 p-4">
                  <button
                    onClick={() => {
                      this.setState({page: 1});
                      this.handlePageChange(1, perPage)
                    }}
                    disabled={page === 1}
                    >
                    first
                  </button>{' '}
                  <button
                    onClick={() => {
                        // setPage((s) => (s === 0 ? 0 : s - 1));
                        const temp = page - 1;
                        this.setState({page: temp});
                        this.handlePageChange(temp, perPage)
                    }}
                    disabled={page === 1}
                    >
                    prev
                  </button>{' '}
                  <button
                    onClick={() => {
                        const temp = page + 1;
                        this.setState({page: temp});
                        this.handlePageChange(temp, perPage)
                    }}
                    disabled={page === totalPage}
                    >
                    next
                  </button>{' '}
                  <button
                    onClick={() => {
                      this.setState({page: totalPage});
                      this.handlePageChange(totalPage, perPage)
                    }}
                    disabled={page === totalPage}
                    >
                    last
                  </button>{' '}
                  <span>
                  Page{' '}
                  <strong>{page} of {totalPage}</strong>{' '}
                  </span>
                  {/* <span>
                  | Go to page:{' '}
                  <input
                      type="number"
                      defaultValue={1}
                      min="1"
                      max={totalPage}
                      onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) : 1;
                        clearTimeout(this.timer);
                        this.timer = setTimeout(() => {
                          this.setState({page})
                          this.handlePageChange(page, perPage)
                        }, 2000);
                      }}
                      className=""
                  />
                  </span>{' '} */}
                  
                  <div
                    onClick={()=> {
                      this.exportToExcel();
                    }} 
                    className="d-flex justify-content-end" style={{flex:1,cursor:"pointer"}}>
                    Download to Excel 
                    <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>


        <Modal
            isOpen={modalOpen}
            toggle={this.toggleModal}
            // className={this.props.className}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleModal}>
                {/* <img style={{width:"50px",height:"50px",borderRadius:"25px",marginRight:"10px"}} src="/assets/img/profile-pic-l-4.jpg" alt="Avatar"/> */}
                <Label style={{}}>Change Customer Status</Label>
            </ModalHeader>
            <ModalBody>
              {modalViewType === 'ENABLE' ?
                <h6 style={{marginBottom:10}}>Are you sure you want to  <span style={{color:'#2ECC71'}}><b>enable</b></span> <b>{selectedUser.firstname} {selectedUser.lastname}'s</b> account?</h6>
                :
                <h6 style={{marginBottom:10}}>Are you sure you want to <span style={{color:'#E74C3C'}}><b>disable</b></span> <b>{selectedUser.firstname} {selectedUser.lastname}'s</b> account?</h6>
              }
              <h6>Complete transaction by providing your <b>authorization code</b> in the box below</h6>
                <label style={{}}>
                Authorization Code:
                <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                </label>
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.toggleModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color={modalViewType === 'ENABLE' ? "primary":"danger"}
                onClick={()=> {
                  if(!adminCode){
                      NotificationManager.error(
                          "Authorization Code",
                          "Your security code required to perform this transaction. Thank you.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else if(adminCode !== this.props.user.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else {
                    this.handleSubmit(selectedUser);
                  }
                }}
            >
              {modalViewType === 'ENABLE' ? 'Enable':'Disable'} Now
            </Button>
            {/* <div className="loading" /> */}
            </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ menu, authUser }) => {
  const {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems
  } = menu;
  const { user } = authUser;
  return {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems,
    user
  };
};

export default connect(
  mapStateToProps,
  {
    setContainerClassnames,
    changeDefaultClassnames
  }
)(Customers);
