import {
    ADD_MERCHANT,
    REMOVE_MERCHANT
} from '../actions';

const INIT_STATE = {
    entities: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case ADD_MERCHANT:
            const temp = state.entities.map(m => m);
            temp.unshift(action.payload);
            return Object.assign({}, state, {
				entities: temp
			})

		case REMOVE_MERCHANT:
            return Object.assign({}, state, {
                entities: state.entities.filter(m=> m.id !== action.payload.id)
            })

		default: return { ...state };
	}
}
