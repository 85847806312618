import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Modal, ModalBody, ModalHeader, Label, Button, ModalFooter, Input
   } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { connect } from "react-redux";
// import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
// import 'react-dates/lib/css/_datepicker.css';
import * as FileSaver from 'file-saver';

import ReactTable from "react-table";

import DataTablePagination from "../../components/DatatablePagination";
// import AddNewOrder from "../../containers/AddNewOrderModal";
import axios from 'axios';
import { NotificationManager } from "../../components/common/react-notifications";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const pendings_url = 'https://api.tuatuagye.com/pendings';
const payments_url = 'https://api.tuatuagye.com/payments';
const collections_url = 'https://api.tuatuagye.com/collections';

class AttemptedPayments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      amount:'',
      adminCode: '',
      selectedAmount: 34,
      isInFullScreen: false,
      searchKeyword: "",
      startDate: null,
      startDateTime: null,
      startDateRange: null,
      endDateRange: null,
      endDateTime: null,
      deleteModal: false,
      dropdownOpen: false,
      paymentType: 1,
      addOrderModalOpen: false,
      attemptedPayments: [],
      totalPayments:0,
      selectedOrder: {},
      processing: false,
      showDetails: false,
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
      dateModal: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.timer = 0;

    this.columns = [
      
        {
            Header: "Customer",
            accessor: "customerName",
            maxWidth:180,
            Cell: props => <p className="list-item-heading">{props.value}</p>
        },
        {
            Header: "Zeepay Token",
            accessor: "payToken",
            maxWidth:110,
            Cell: props => <p className="text-muted">{props.value}</p>
        },
        {
            Header: "Phone",
            accessor: "customerMobileNumber",
            width: 110,
            Cell: props => <p className="text-muted">{props.value}</p>
        },
        {
            Header: "Product",
            accessor: "productName",
            Cell: props => <p className="text-muted truncate">{props.value}</p>
        },
        {
            Header: "Amount",
            accessor: "amount",
            width:120,
            Cell: props => <p className="text-muted">GH₵ {this.formatAmount(props.value)}</p>
        },
        {
            Header: "Location",
            accessor: "location",
            Cell: props => <p className="text-muted">{props.value}</p>
        },
        {
            Header: "Date",
            accessor: "createdAt",
            width:170,
            Cell: props => <p className="text-muted">{this.formatDate(props.value)}</p>
        },
        {
            Header: "Action",
            accessor: "status",
            width: 70,
            Cell: props => <div style={{alignItems:"center",display:'flex',justifyContent:'space-between'}}>
                <div
                  onClick={()=> {
                      this.setState({selectedOrder: props.original, adminCode:'', showDetails: true});
                    }}
                  style={{cursor:'pointer',fontSize:15, color:'#4d4d4d'}}  
                  className={"glyph-icon iconsminds-full-view"} 
                />

                <div
                    onClick={()=> {
                        this.setState({selectedOrder: props.original, adminCode:''})
                        this.toggleNestedContainer();
                    }}
                  style={{cursor:'pointer',fontSize:15, color:'#4d4d4d'}}  
                  className={"glyph-icon iconsminds-credit-card"} 
                />
            </div>
        }
      ]
    
  }

  componentDidMount() {
    axios.get(`${pendings_url}?filter[order]=createdAt%20DESC&filter[limit]=50`)
      .then(res => {
        if(res.data && res.data.length > 0){
          let _totalPayments = 0;
          res.data.forEach(payment => _totalPayments += payment.amount);
          this.setState({attemptedPayments: res.data, totalPayments: Number(_totalPayments).toFixed(2), loading:false})
        }
        else {
          this.setState({loading:false});
        }
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
            "Loading error",
            "An error occurred. Kindly try again.",
            3000,
            null,
            null,
        );
      })
  }

  handleSelect = (ranges) => {
		this.setState({selectionRange: {
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
			key: 'selection',
		}})
	}

  searchWithName = (searchText) => {
      this.setState({loading: true});
      axios.get(`${pendings_url}?filter[where][or][0][customerName][regexp]=/${searchText}/i&filter[where][or][1][productName][regexp]=/${searchText}/i&filter[where][or][2][customerMobileNumber][regexp]=/${searchText}/i&filter[where][or][3][location][regexp]=/${searchText}/i&filter[where][or][4][payToken][regexp]=/${searchText}/i&filter[limit]=20&filter[order]=createdAt%20DESC`)
      .then(res => {
          if(res.data && res.data.length > 0){
          let _totalPayments = 0;
          res.data.forEach(payment => _totalPayments += payment.amount);
          this.setState({attemptedPayments: res.data, totalPayments: Number(_totalPayments).toFixed(2), loading: false});
          }
          else {
          this.setState({totalPayments: 0, attemptedPayments:[], loading:false})
          }
      })
      .catch(error => {
          this.setState({loading: false});
          alert('Sorry, an unknown error occurred.');
          console.log('error...',error);
      })
  }

  handleChange(event) {
    this.setState({amount: event.target.value});
  }

  toggleDateModal = () => {
    this.setState(prevState => ({
      dateModal: !prevState.dateModal,
    }));
  };

  toggleNestedContainer = () => {
    this.setState(prevState => ({
      modalNestedContainer: !prevState.modalNestedContainer
    }));
  };

  toggleNested = () => {
    this.setState(prevState => ({
      nestedModal: !prevState.nestedModal,
      closeAll: false
    }));
  };

  toggleAll = () => {
    this.setState(prevState => ({
      nestedModal: !prevState.nestedModal,
      closeAll: true
    }));
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  
  toggleAddOrder = () => {
    this.setState(prevState => ({
      addOrderModalOpen: !prevState.addOrderModalOpen,
    }));
  }

  formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
      return temp;
    }
    
    return Number(amount).toFixed(2);
  }

  formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  handleRefresh = (date) => {
    const {startDate, endDate} = this.state.selectionRange;
    let start = startDate;
    start.setHours(0)
    start.setMinutes(0);
    start.setSeconds(0);
    start = start.toJSON();

    let end = new Date(endDate);
    end.setHours(23)
    end.setMinutes(59);
    end.setSeconds(59);
    end = end.toJSON();

    let query = `filter[where][and][0][createdAt][gte]=${start}&filter[where][and][1][createdAt][lte]=${end}&filter[order]=createdAt%20DESC`;

    this.setState({loading: true});
    axios.get(`${pendings_url}?${query}`)
      .then(res => {
        if(res.data && res.data.length > 0){
          let _totalPayments = 0;
          res.data.forEach(payment => _totalPayments += payment.amount);
          this.setState({attemptedPayments: res.data, totalPayments: Number(_totalPayments).toFixed(2), loading: false});
        }
        else {
          this.setState({totalPayments: 0, attemptedPayments:[], loading:false});
        }
      })
      .catch(error => {
        this.setState({loading: false});

        NotificationManager.error(
            "Filtering error",
            "An error occurred. Kindly try again.",
            3000,
            null,
            null,
        );
        // console.log('error...',error);
      })
  }

  handleConfirm = (payment) => {
    const { attemptedPayments } = this.state;
    this.setState({processing:true});

    const { id } = payment;
// console.log('pmt',payment);
//     return;
    let paymentId = '';

    axios.get(`${pendings_url}/${id}`)
        .then(res=> {
            const responseData = res.data;
            if(responseData.id){
                const payload = {
                    amount: `${payment.amount}`.indexOf('.03') > -1 ? Math.floor(payment.amount) : payment.amount,
                    type: payment.merchantId ? 10 : 1,
                    payeeId: payment.userId,
                    payeeName: payment.customerName,
                    momoNumber: payment.customerMobileNumber,
                    payToken: payment.payToken,
                    mode: payment.payOption == "MTN_MONEY" ? "MTN" : payment.payOption,
                    productName: payment.productName,
                    productId: payment.productId,
                    myProductId: payment.myProductId,
                    createdAt: payment.createdAt
                }
                //console.log('payl',payload);
                return axios.post(payments_url,payload)
            }
            else {
                return null;
            }
        })
        .then(res=> {
            if(res){
              paymentId = res.data.id
              return axios.delete(`${pendings_url}/${id}`)
            }
            else {
                return null;
            }
        })
        .then(res=> {
          if(res){
            if(payment.merchantId){
              // console.log('is collection');
              const collectionPayload = {
                merchantId: payment.merchantId,
                merchantName: payment.merchantName,
                customerId: payment.userId,
                customerProductId: payment.myProductId,
                latitude: "5.6402914",
                longitude: "-0.3091245",
                productId: payment.productId,
                createdAt: payment.createdAt,
                paymentId: paymentId
              }

              // console.log('coll pl',collectionPayload);

              return axios.post(collections_url, collectionPayload);
            }
            else return res;
          }
          else {
            return null;
          }
        })
        .then(res=> {
            if(res){
                const temp = attemptedPayments.filter(order=> order.id !== id)
                this.setState({attemptedPayments: temp, processing: false});
                this.toggleNestedContainer();
                NotificationManager.success(
                    "Confirmation successful",
                    "Processing was successful",
                    3000,
                    null,
                    null,
                );
            }
            else {
                NotificationManager.error(
                    "Error",
                    "An error occurred. Please try again.",
                    3000,
                    null,
                    null,
                );
            }          
        })
        .catch(e=> {
            console.log('err',e);
            this.setState({processing: false});
            NotificationManager.error(
                "Unknow error",
                "Oooops, an unknown error occurred during processing. Please try again.",
                3000,
                null,
                null,
            );
        })
  }

  __formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let yr = `${date.getFullYear()}`.slice(2);
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
  }

  exportToExcel() {
    const data = this.state.attemptedPayments.map(record=> {
      return {
        Customer: record.customerName,
        Merchant: record.merchantName,
        Amount: record.amount,
        Network: record.payOption,
        'MoMo Number': record.customerMobileNumber,
        Product: record.productName,
        'Z_Token': record.payToken,
        Location: record.location,
        'Date': this.__formatDate(record.createdAt)
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `Attempted Payments Data - ${new Date().toJSON()}`);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  render() {
    const { selectionRange, processing, adminCode, attemptedPayments, selectedOrder, startDateTime, totalPayments, loading, endDateTime } = this.state;
    const { startDate, endDate } = selectionRange;
    
    return (
            <Fragment>
            <Row>
              <Colxx xxs="12">
                <Row style={{justifyContent:'space-between',alignItems:'center',marginLeft:0,marginRight:0}}>
                  <h3>Attempted Payments</h3>
                  <div style={{display:'flex', marginBottom:10}}>
                    <div className="payments-search">
                      <Input
                        style={{backgroundColor:'#fff'}}
                        // name="searchKeyword"
                        // id="searchKeyword"
                        placeholder={"Search"
                            // messages["menu.search"]
                        }
                        value={this.state.searchKeyword}
                        onChange={e => {
                          let value = e.target.value;
                          this.setState({searchKeyword: value})
                          clearTimeout(this.timer);
                          this.timer = setTimeout(() => {
                            this.searchWithName(value);
                          }, 1000);
                        }}
                        // onKeyPress={e => this.handleSearchInputKeyPress(e)}
                      />
                      <span
                          className="search-icon"
                          style={{right:10,top:10}}
                          // onClick={e => this.handleSearchIconClick(e)}
                      >
                          <i className="simple-icon-magnifier" />
                      </span>
                    </div>

                    <div onClick={this.toggleDateModal} style={{alignItems:'center',justifyContent:'center',display:'flex',paddingRight:10,paddingLeft:10,borderRadius:3,marginLeft:"10px",color:'#fff',backgroundColor:'rgb(0, 136, 148)',cursor:'pointer'}}>
                      {startDate.toDateString() == endDate.toDateString() ? startDate.toDateString() : `${startDate.toDateString()} - ${endDate.toDateString()}`}
                    </div>

                    {/* <div
                      onClick={this.toggleAddOrder}
                      style={{cursor:'pointer',fontSize:18,paddingTop:7,marginLeft:10}}  
                      className={"glyph-icon iconsminds-add-cart"} 
                    /> */}
                  </div>
                </Row>
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card>
                  <CardBody>
                    {loading && <div className='loading'/>}
                    <ReactTable
                      data={attemptedPayments}
                      columns={this.columns}
                      defaultPageSize={50}
                      showPageJump={false}
                      showPageSizeOptions={false}
                      PaginationComponent={DataTablePagination}
                      className={"react-table-fixed-height"}
                    />

                    <div
                      onClick={()=> {
                        this.exportToExcel();
                      }} 
                      className="d-flex justify-content-end" style={{flex:1,cursor:"pointer"}}>
                      Download to Excel 
                      <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>

            {/* <AddNewOrder
              toggleModal={this.toggleAddOrder}
              modalOpen={addOrderModalOpen}
              onAdd={(newOrder)=> {
                const _orders = attemptedPayments.map(o => o);
                _orders.unshift(newOrder);
                this.setState({attemptedPayments: _orders})
              }}
            /> */}

            {/* EDIT MODAL */}

            {/* ORDER DETAILS */}
            <Modal
              isOpen={this.state.showDetails}
              toggle={()=> this.setState({showDetails: false})}
              className={this.props.className}
            >
              <ModalHeader style={{alignItems:"center",padding:""}} toggle={()=> this.setState({showDetails:false})}>
                <Label>Details</Label>
              </ModalHeader>
              <ModalBody>
                <span style={{color:'#000',fontSize:18}}>{selectedOrder.productName}</span>
                <br/><br/>
                <span>Name: </span><span style={{color:'#000',fontSize:18}}>{selectedOrder.customerName}</span>
                <br/>
                <span>Phone: </span><span style={{color:'#000',fontSize:18}}>{selectedOrder.customerMobileNumber}</span>
                <br/>
                <span>Amount: </span><span style={{color:'#000',fontSize:18}}>{selectedOrder.amount}</span>
                <br/>
                <span>Network: </span><span style={{color:'#000',fontSize:18}}>{selectedOrder.payOption}</span>
                <br/>
                <span>Location: </span><span style={{color:'#000',fontSize:18}}>{selectedOrder.location}</span>
                <br/><br/>
                <span>Date: </span><span style={{color:'#000',fontSize:18}}>{this.formatDate(selectedOrder.createdAt)}</span>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="default"
                  onClick={()=> this.setState({showDetails:false})}
                >
                  Ok
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.modalNestedContainer}
              toggle={this.toggleNestedContainer}
              className={this.props.className}
            >
                {processing && <div style={{position:'absolute',top:0,bottom:0,left:0,right:0,zIndex:99,backgroundColor:'rgba(200,200,200,.6)'}}>
                    <div className='loading'/>
                </div>}
              <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleNestedContainer}>
                <Label>Add Payment | {selectedOrder.customerName}</Label>
              </ModalHeader>
              <ModalBody>
                  
                <h6>Are you sure you want to confirm and add this payment (<b>GHs {selectedOrder.amount}</b>), made at <b>{this.formatDate(selectedOrder.createdAt)}</b></h6>
                <label style={{marginTop:20}}>
                Authorization Code:
                <input type="password" value={adminCode} style={{marginLeft:20,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                </label>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="default"
                  onClick={this.toggleNestedContainer}
                >
                  Cancel
                </Button>{" "}
                
                <Button
                  color="primary"
                  onClick={()=> {
                    if(!adminCode){
                        NotificationManager.error(
                            "Authorization Code",
                            "Your security code required to perform this transaction. Thank you.",
                            3000,
                            null,
                            null,
                        );
                    }
                    else if(adminCode !== this.props.user.authCode) {
                        NotificationManager.error(
                            "Invalid Code",
                            "Sorry, the code you entered is invalid. Check and try again.",
                            3000,
                            null,
                            null,
                        );
                    }
                    else {
                        this.handleConfirm(selectedOrder);
                    }
                  }}
                >
                  Confirm Now
                </Button>
                
              </ModalFooter>
            </Modal>

            {/* DATE MODAL */}

            <Modal
              isOpen={this.state.dateModal}
              toggle={this.toggleDateModal}
              className={'date-modal'} 
            >
              <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleDateModal}>
                <Label>Set Date Range</Label>
              </ModalHeader>
              <ModalBody>
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={this.handleSelect}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="default"
                  onClick={this.toggleDateModal}
                >
                  Cancel
                </Button>{" "}
                
                <Button
                  color="success"
                  onClick={()=> {
                    this.handleRefresh();
                    this.setState({loading: true});
                    this.toggleDateModal();
                  }}
                >
                  Filter Now
                </Button>
                
              </ModalFooter>
            </Modal>
          </Fragment>
        )
    }
}


const mapStateToProps = ({ authUser }) => {
    const { user } = authUser;
    return {user};
  };
  
  export default connect(
    mapStateToProps,
    {}
  )(AttemptedPayments);