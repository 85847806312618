import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row } from "reactstrap";
import axios from 'axios';

import { Colxx } from "../../../components/common/CustomBootstrap";
import { _getDates } from '../../../helpers/Utils';

import MiniHomeStats from '../../../containers/dashboards/MiniHomeStats';
import RecentDeliveries from "../../../containers/dashboards/RecentDeliveries";
import Logs from "../../../containers/dashboards/Logs";
import NewUsers from "../../../containers/dashboards/NewUsers";
import BestSellers from "../../../containers/dashboards/BestSellers";
import SalesChartCard from "../../../containers/dashboards/SalesChartCard";
import SalesCategoriesPolarArea from "../../../containers/dashboards/SalesCategoriesPolarArea";

const logs_url = 'https://api.tuatuagye.com/logs'
const products_url = 'https://api.tuatuagye.com/products';
const deliveries_url = 'https://api.tuatuagye.com/deliveries';

const sales_stats_url = 'https://api.tuatuagye.com/sales-stat';
const payments_url = 'https://api.tuatuagye.com/payments';
const users_url = 'https://api.tuatuagye.com/users';
const deliveries_stat = 'https://api.tuatuagye.com/get-deliveries-stat';

class HomeDashboard extends Component {
  constructor(props){
    super(props);

    this.state = {
      sales: [],
      recentDeliveries: [],
      pendingDeliveriesCount: 0,
      completedDeliveriesCount: 0,
      shippedCount: 0,
      refundRequest: 0,
      paymentStats: [],
      logs:[],
      newUsers: [],
      topSellingProducts: [],
      topBanners: []
    }
  }
  componentDidMount() {
    const sevenDays = 1000*60*60*24*7;
    const date = new Date(Date.now() - sevenDays);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    console.log(date.toJSON());

    const dates = _getDates(7);

    axios.get(`${payments_url}?filter[where][createdAt][gt]=${date.toJSON()}`)
      .then(res => {
        if(res.data && res.data.length > 0){
          const keys = {};
          dates.forEach(d=> keys[d] = 0);

          res.data.forEach(pay=> {
            const _date = new Date(pay.createdAt).getDate();

            if(keys[_date]){
              keys[_date] = keys[_date] + pay.amount;
            }
            else {
              keys[_date] = pay.amount;
            }
          })

          // console.log('keys are',keys);
          // console.log('values are',Object.values(keys))
          
          const data = [];

          dates.forEach(d=> {
            const tmp = keys[d];
            data.push(tmp)
          });

          // console.log('data...',data);

          this.setState({sales: data})
        }       

        return axios.get(`${deliveries_url}?filter[include][][relation]=product&filter[limit]=10&filter[order]=createdAt%20DESC`)
      })
      .then(res => {
        //set deliveries
        // console.log('del',res.data);
        this.setState({recentDeliveries: res.data})
        return axios.get(deliveries_stat)
      })
      .then(res => {
        // console.log('res.data',res.data);
        //set pending order count
        this.setState({
          pendingDeliveriesCount: res.data.pendings,
          completedDeliveriesCount: res.data.delivered,
          shippedCount: res.data.shipped
        });
        // return axios.get(`${deliveries_url}?filter[where][status]=${2}&filter[order]=createdAt%20DESC&filter[limit]=15`);
        return axios.get(`${sales_stats_url}`)
      })
      // .then(res => {
      //   this.setState({completedDeliveriesCount: res.data.length});
      //   return axios.get(`${sales_stats_url}`)
      // })
      .then(res => {
        this.setState({
          paymentStats: [Number(res.data.mobile).toFixed(2), Number(res.data.web).toFixed(2), Number(res.data.pos).toFixed(2)]
        })

        return axios.get(`${logs_url}?filter[limit]=50&filter[order]=createdAt%20DESC`)
      })
      .then(res => {
        this.setState({
          logs: res.data
        })

        return axios.get(`${users_url}?filter[limit]=10&filter[order]=createdAt%20DESC`)
      })
      .then(res => {
        this.setState({
          newUsers: res.data
        })

        return axios.get(`${products_url}?filter[where][sold][gt]=0&filter[order]=createdAt%20DESC&filter[limit]=10`)
      })
      .then(res => {
        this.setState({
          topSellingProducts: res.data
        })

      })
      // .then(res=> {
      //   this.setState({
      //     topBanners: res.data
      //   })
      // })

  }

  render() {
    const { sales, recentDeliveries, topSellingProducts, topBanners, newUsers, logs, shippedCount, completedDeliveriesCount, pendingDeliveriesCount, refundRequest, paymentStats } = this.state;
    // const {messages} = this.props.intl;

    return (
      <Fragment>
        <Row>
          <Colxx lg="12" xl="6">
            <Row>
              <Colxx md="12" className="mb-4">
                <SalesChartCard data={sales}/>
              </Colxx>
            </Row>
            <MiniHomeStats 
              data={[
                { title: 'Pending Deliveries', icon: "iconsminds-clock", value: pendingDeliveriesCount, tag: 'pending' },
                { title: 'Shipped Deliveries', icon: "iconsminds-basket-coins", value: shippedCount, tag: 'shipped' },
                { title: 'Completed Deliveries', icon: "iconsminds-arrow-refresh", value: completedDeliveriesCount, tag: 'completed' },
              ]}
            />
          </Colxx>
          <Colxx lg="12" xl="6" className="mb-4">
            <RecentDeliveries data={recentDeliveries} />
          </Colxx>
        </Row>

        <Row>
          <Colxx lg="4" md="12" className="mb-4">
            <SalesCategoriesPolarArea 
              data={paymentStats} 
              chartClass="dashboard-donut-chart"
              />
          </Colxx>
          
          <Colxx lg="4" md="6" className="mb-4">
           <Logs data={logs}/>
          </Colxx>
          <Colxx lg="4" md="6" className="mb-4">
            <NewUsers data={newUsers}/>
          </Colxx>
        </Row>

        <Row>
          {/* <Colxx xl="6" lg="12" className="mb-4">
            <Calendar/>
          </Colxx> */}
          <Colxx xl="12" lg="12" className="mb-4">
            <BestSellers data={topSellingProducts}/>
          </Colxx>
        </Row>
          
        {/* <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <TopMobileBanners data={topBanners} />
          </Colxx>
        </Row>

        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <TopWebBanners />
          </Colxx>
        </Row>

        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <Markets />
          </Colxx>
        </Row>

        <Row>
         <Colxx xl="12" lg="12" className="mb-4">
            <Locations />
          </Colxx>
        </Row> */}
      </Fragment>
    );
  }
}
export default injectIntl(HomeDashboard);