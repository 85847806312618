import React, { Component, Fragment } from "react";
import { Row, Input,Modal, ModalBody, ModalHeader, Label, Button, ModalFooter, Card, CardBody } from "reactstrap";
import axios from "axios";
import * as FileSaver from 'file-saver';

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";

// import Pagination from "../../../containers/pages/Pagination";
import ContextMenuContainer from "../../../containers/pages/ContextMenuContainer";
// import ListPageHeading from "../../../containers/pages/ListPageHeading";
import ImageListView from "../../../containers/pages/ImageListView";
import MerchantCollections from "../../../containers/pages/MerchantCollections";
import MerchantSignups from "../../../containers/pages/MerchantSignups";
import { NotificationManager } from "../../../components/common/react-notifications";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

function collect(props) {
  return { data: props.data };
}

const collections_url = 'https://api.tuatuagye.com/collections';

const formatAmount = (amount) => {
  if(!amount) return '0.00';
  if(amount > 999){
    let temp = amount.toString();
    let decimal = '.00';

    if(temp.indexOf('.') > -1){
      decimal = '.'+temp.split('.')[1];
      temp = temp.split('.')[0];
    }
    
    temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
    return temp;
  }
  
  return Number(amount).toFixed(2);
}

class Merchants extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require('mousetrap');

    this.state = {
      displayMode: "imagelist",
      selectedMerchant: {},

      selectedPageSize: 10,
  
      pageSizes: [10, 20, 40,50,60],
      collections: [],
      dropdownSplitOpen: false,
      modalOpen: false,
      showSignupModal: false,
      currentPage: 1,
      totalItemCount: 0,
      totalPage: 1,
      search: "",
      selectedItems: [],
      lastChecked: null,
      isLoading: true,
      searchKeyword: '',
      total: 0,
      items: [],
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
      dateModal: false
    };
  }

  componentDidMount() {
    this.dataListRender();
    this.mouseTrap.bind(["ctrl+a", "command+a"], () =>
      this.handleChangeSelectAll(false)
    );
    this.mouseTrap.bind(["ctrl+d", "command+d"], () => {
      this.setState({
        selectedItems: []
      });
      return false;
    });
  }
  
  componentWillUnmount() {
    this.mouseTrap.unbind("ctrl+a");
    this.mouseTrap.unbind("command+a");
    this.mouseTrap.unbind("ctrl+d");
    this.mouseTrap.unbind("command+d");
  }

  handleSelect = (ranges) => {
		this.setState({selectionRange: {
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
			key: 'selection',
		}})
	}

  toggleDateModal = () => {
    this.setState(prevState => ({
      dateModal: !prevState.dateModal,
    }));
  };

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  };

  toggleSignupsModal = () => {
    this.setState({
      showSignupModal: !this.state.showSignupModal
    })
  }

  onChangePage = page => {
    this.setState(
      {
        currentPage: page
      },
      () => this.dataListRender()
    );
  };

  onSearchKey = e => {
    if (e.key === "Enter") {
      this.setState(
        {
          search: e.target.value.toLowerCase()
        },
        () => this.dataListRender()
      );
    }
  };

  onCheckItem = (event, id) => {
    if (
      event.target.tagName === "A" ||
      (event.target.parentElement && event.target.parentElement.tagName === "A")
    ) {
      return true;
    }
    if (this.state.lastChecked === null) {
      this.setState({
        lastChecked: id
      });
    }

    let selectedItems = this.state.selectedItems;
    if (selectedItems.includes(id)) {
      selectedItems = selectedItems.filter(x => x !== id);
    } else {
      selectedItems.push(id);
    }
    this.setState({
      selectedItems
    });

    if (event.shiftKey) {
      var items = this.state.items;
      var start = this.getIndex(id, items, "id");
      var end = this.getIndex(this.state.lastChecked, items, "id");
      items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
      selectedItems.push(
        ...items.map(item => {
          return item.id;
        })
      );
      selectedItems = Array.from(new Set(selectedItems));
      this.setState({
        selectedItems
      });
    }
    document.activeElement.blur();
  };

  getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1;
  }
  handleChangeSelectAll = isToggle => {
    if (this.state.selectedItems.length >= this.state.items.length) {
      if (isToggle) {
        this.setState({
          selectedItems: []
        });
      }
    } else {
      this.setState({
        selectedItems: this.state.items.map(x => x.id)
      });
    }
    document.activeElement.blur();
    return false;
  };

  dataListRender() {
    const {startDate, endDate} = this.state.selectionRange;
    let start = startDate;
    start.setHours(0)
    start.setMinutes(0);
    start.setSeconds(0);
    start = start.toJSON();

    let end = new Date(endDate);
    end.setHours(23)
    end.setMinutes(59);
    end.setSeconds(59);
    end = end.toJSON();
    
    axios.get(`${collections_url}?filter[where][and][0][createdAt][gte]=${start}&filter[where][and][1][createdAt][lte]=${end}&filter[include][]=payment&filter[order]=createdAt%20DESC`)
      .then(res => {
        let cols = Object.assign({});
        // console.log('col here',cols);
        if(res.data && res.data.length > 0){
          // console.log('slice',res.data.slice(0,3));
          res.data.forEach(c => {
            if(cols[c.merchantId]){
              if(c.payment){
                cols[c.merchantId].total = cols[c.merchantId].total + c.payment.amount;
              }
              else {
                console.log('no payment',c);
              }
              cols[c.merchantId].entities = [...cols[c.merchantId].entities,c];
              cols[c.merchantId].image = c.merchantImage;
            }
            else {
              cols[c.merchantId] = {
                id: c.merchantId,
                name: c.merchantName,
                image: c.merchantImage,
                total: c.payment ? c.payment.amount : 0,
                entities: [c]
              }
            }
          })
        }

        // console.log('updated cols',cols);
        const temp = Object.values(cols);
        let total = 0;
        temp.forEach(t=> total += t.total);

        this.setState({
          totalPage: Math.ceil(temp.length /10),
          items: temp,
          selectedItems: [],
          totalItemCount: temp.length,
          isLoading: false,
          total,
          collections: res.data
        });

      })
      .catch(e=> {
        console.log('err is',e);
        NotificationManager.error(
          "Load Error",
          "An unknown error occurred. Please check console for details or try again.",
          3000,
          null,
          null,
        );
        this.setState({isLoading: false})
      })
  }

  onContextMenuClick = (e, data, target) => {
    console.log(
      "onContextMenuClick - selected items",
      this.state.selectedItems
    );
    console.log("onContextMenuClick - action : ", data.action);
  };

  onContextMenu = (e, data) => {
    const clickedProductId = data.data;
    if (!this.state.selectedItems.includes(clickedProductId)) {
      this.setState({
        selectedItems: [clickedProductId]
      });
    }

    return true;
  };

  searchWithName = (searchText) => {
    const {startDate, endDate} = this.state.selectionRange;
    let start = startDate;
    start.setHours(0)
    start.setMinutes(0);
    start.setSeconds(0);
    start = start.toJSON();

    let end = new Date(endDate);
    end.setHours(23)
    end.setMinutes(59);
    end.setSeconds(59);
    end = end.toJSON();

    this.setState({isLoading: true});
    axios.get(`${collections_url}?filter[where][merchantName][regexp]=/${searchText}/i&filter[where][and][0][createdAt][gte]=${start}&filter[where][and][1][createdAt][lte]=${end}&filter[include][]=payment&filter[order]=createdAt%20DESC`)
      .then(res => {
        let cols = {};
        if(res.data && res.data.length > 0){
          res.data.forEach(c => {
            if(cols[c.merchantId]){
              cols[c.merchantId].total = cols[c.merchantId].total + c.payment.amount;
              cols[c.merchantId].entities = [c, ...cols[c.merchantId].entities];
              cols[c.merchantId].image = c.merchantImage;
            }
            else {
              cols[c.merchantId] = {
                id: c.merchantId,
                name: c.merchantName,
                image: c.merchantImage,
                total: c.payment.amount,
                entities: [c]
              }
            }
          })
        }
        // console.log('COLLECTIONS FORMATTED',cols);
        const temp = Object.values(cols);
        let total = 0;
        temp.forEach(t=> total += t.total);

        this.setState({
          totalPage: Math.ceil(temp.length /10),
          items: temp,
          selectedItems: [],
          totalItemCount: temp.length,
          isLoading: false,
          total
        });
      })
      .catch(e=> {
        console.log('err is',e);
        NotificationManager.error(
          "Search Error",
          "An unknown error occurred. Please check console for details or try again.",
          3000,
          null,
          null,
        );
        this.setState({isLoading: false})
      })
  }

  refreshCollections = () => {
    this.setState({isLoading: true});

    this.dataListRender()
  }

  formatStartDate = (_date) => {
    let start = new Date(_date);
    start.setHours(0)
    start.setMinutes(0);
    start.setSeconds(0);
    return start;
  }

  formatEndDate = (_date) => {
    let end = new Date(_date);
    end.setHours(23)
    end.setMinutes(59);
    end.setSeconds(59);
    return end;
  }

  __formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let yr = `${date.getFullYear()}`.slice(2);
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
  }

  exportToExcel() {
    const data = this.state.collections.map(record=> {
      return {
        Merchant: record.merchantName,
        Amount: record.payment.amount,
        Network: record.payment.mode,
        'MoMo Number': record.payment.momoNumber,
        Product: record.payment.productName,
        Customer: record.payment.payeeName,
        'Z_Token': record.payment.payToken,
        'Date': this.__formatDate(record.createdAt)
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `Collections Data - ${new Date().toJSON()}`);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  render() {
    const {
      // currentPage,
      items,
      // displayMode,
      // selectedPageSize,
      modalOpen,
      showSignupModal,
      searchKeyword,
      total,
      selectedMerchant,
      selectionRange
    } = this.state;
    // const { match } = this.props;
    // const startIndex = (currentPage - 1) * selectedPageSize;
    // const endIndex = currentPage * selectedPageSize;

    const { startDate, endDate } = selectionRange;

    return this.state.isLoading ? (
      <div className="loading" />
    ) : (
      <Fragment>
        <div className="disable-text-selection">
          <Row>
          <Colxx xxs="12" className="mb-4">
            {/* <Card>
              <CardBody> */}
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                  <h3>Total | <span style={{color:'#008894'}}>GHS {formatAmount(total)}</span></h3>
                  <div
                    onClick={()=> {
                      this.exportToExcel();
                    }} 
                    className="d-flex justify-content-end pt-1 pb-1 mt-0 mr-2" style={{alignItems:'center',justifyContent:'center',display:'flex',paddingRight:10,paddingLeft:10,borderRadius:3,marginLeft:"10px",color:'#fff',backgroundColor:'rgb(0, 136, 148)',cursor:'pointer'}}>
                    Download to Excel 
                    <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                  </div>
                  <div style={{display:'flex', marginBottom:10,marginLeft:0,marginRight:0}}>
                    <div className="payments-search" style={{marginRight:10}}>
                      <Input
                        style={{backgroundColor:'#fff'}}
                        name=""
                        // id="searchKeyword"
                        placeholder={"Search with name"}
                        value={searchKeyword}
                        onChange={e => {
                          let value = e.target.value;
                          this.setState({searchKeyword: value})
                          clearTimeout(this.timer);
                          this.timer = setTimeout(() => {
                            this.searchWithName(value);
                          }, 1000);
                          // this.handleSearchInputChange(e)
                        }}
                        // onKeyPress={e => this.handleSearchInputKeyPress(e)}
                      />
                      <span
                          className="search-icon"
                          style={{top:10}}
                          // onClick={e => this.handleSearchIconClick(e)}
                      >
                          <i className="simple-icon-magnifier" />
                      </span>
                    </div>

                    <div onClick={this.toggleDateModal} style={{alignItems:'center',justifyContent:'center',display:'flex',paddingRight:10,paddingLeft:10,borderRadius:3,marginLeft:"10px",color:'#fff',backgroundColor:'rgb(0, 136, 148)',cursor:'pointer'}}>
                      {startDate.toDateString() == endDate.toDateString() ? startDate.toDateString() : `${startDate.toDateString()} - ${endDate.toDateString()}`}
                    </div>

                  <div
                    onClick={this.refreshCollections}
                    style={{cursor:'pointer',fontSize:18,color:'#000',marginLeft:15,marginTop:11}}  
                    className={"simple-icon-refresh"} 
                  />
                  </div>
                </div>
              {/* </CardBody>
            </Card> */}
            <Separator className="mb-5" />
          </Colxx>
          </Row>
          <Row>
            {items.length > 0 ?
              items.map((merchant, index) => {
                return (
                  <ImageListView
                    key={merchant.id}
                    merchant={merchant}
                    isSelect={this.state.selectedItems.includes(merchant.id)}
                    collect={collect}
                    startDate={startDate}
                    endDate={endDate}
                    selectMerchant={(m) => this.setState({selectedMerchant: m})}
                    // onCheckItem={this.onCheckItem}
                    toggleModal={this.toggleModal}
                    toggleSignupsModal={this.toggleSignupsModal}
                  />
                );
              })
              :
              <div style={{paddingRight:15,paddingLeft:15}}>
                <p>No result found for {startDate.toDateString() == endDate.toDateString() ? startDate.toDateString() : `${startDate.toDateString()} - ${endDate.toDateString()}`}</p>
              </div>
            }
            {/* <Pagination
              currentPage={this.state.currentPage}
              totalPage={this.state.totalPage}
              onChangePage={i => this.onChangePage(i)}
            /> */}

              {/* <div
                onClick={()=> {
                  this.exportToExcel();
                }} 
                className="d-flex justify-content-end mb-4" style={{flex:1,cursor:"pointer"}}>
                Download to Excel 
                <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
              </div> */}
            <ContextMenuContainer
              onContextMenuClick={this.onContextMenuClick}
              onContextMenu={this.onContextMenu}
            />
          </Row>

          {modalOpen && (
            <MerchantCollections
              modalOpen={modalOpen}
              toggleModal={this.toggleModal}
              merchant={selectedMerchant}
            />
          )}
          
          {showSignupModal &&
            <MerchantSignups
              modalOpen={showSignupModal}
              toggleModal={this.toggleSignupsModal}
              merchant={selectedMerchant}
              startDate={this.formatStartDate(startDate)}
              endDate={this.formatEndDate(endDate)}
            />
          }
        </div>

        {/* DATE MODAL */}

        <Modal
          isOpen={this.state.dateModal}
          toggle={this.toggleDateModal}
          className={'date-modal'} 
        >
          <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleDateModal}>
            <Label>Set Date Range</Label>
          </ModalHeader>
          <ModalBody>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={this.handleSelect}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              onClick={this.toggleDateModal}
            >
              Cancel
            </Button>{" "}
            
            <Button
              color="success"
              onClick={()=> {
                this.refreshCollections();
                this.setState({loading: true});
                this.toggleDateModal();
              }}
            >
              Filter Now
            </Button>
            
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
export default Merchants;
