import React from "react";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, CardBody, CardTitle, Badge } from "reactstrap";

// import IntlMessages from "../../helpers/IntlMessages";

const img_base_url = 'https://api.tuatuagye.com/get-image?type=product&id='

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  const strTime = hours + ':' + minutes + '' + ampm;

  let mon = date.getMonth();
  if(mon < 10){
      mon = '0'+mon;
  }

  let day = date.getDate();
  if(day < 10){
      day = '0'+day;
  }

  return `${strTime} | ${day}.${mon}.${date.getFullYear()}`;
}

export default function RecentDeliveries({ data }) {
  return (
    <Card>
      {/* <div className="position-absolute card-top-buttons">
        <button className="btn btn-header-light icon-button">
          <i className="simple-icon-refresh" />
        </button>
      </div> */}
      <CardBody>
        <CardTitle>
          Recent Deliveries
        </CardTitle>
        <div className="scroll dashboard-list-with-thumbs">
          <PerfectScrollbar
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            {data.map((delivery, index) => {
              return (
                <div key={index} className="d-flex flex-row mb-3">
                  <div
                    className="d-block position-relative"
                    style={{padding:10}}
                  >
                    <img
                      src={`${img_base_url}${delivery.product.thumbnail}`}
                      // alt={'img'}
                      // className="list-thumbnail border-0"
                      style={{width:60,height:60,backgroundColor:'#ccc'}}
                    />
                    <Badge
                      key={index}
                      style={{paddingTop:7}}
                      className="position-absolute badge-top-right"
                      color={delivery.status === 1 ? 'warning' : delivery.status === 2 ? 'secondary' : delivery.status === 3 ? 'primary' : ''}
                      pill
                    >
                      {delivery.status === 1 ? 'PENDING' : delivery.status === 2 ? 'SHIPPED' : delivery.status === 3 ? 'COMPLETED' : 'DEFAULT'}
                    </Badge>
                  </div>

                  <div className="pl-3 pt-2 pr-2 pb-2">
                    <NavLink to="/app/deliveries">
                      <p className="list-item-heading" style={{marginBottom:3}}>{delivery.product.name}</p>
                      <div className="pr-4">
                        <p className="text-muted mb-1">
                          {delivery.buyerName}
                        </p>
                      </div>
                      <div className="text-primary text-small font-weight-medium d-none d-sm-block">
                        {formatTimestamp(delivery.createdAt)}
                      </div>
                    </NavLink>
                  </div>
                </div>
              );
            })}
          </PerfectScrollbar>
        </div>
      </CardBody>
    </Card>
  );
}
