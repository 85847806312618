import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import AppLayout from "../../layout/AppLayout";
import dashboards from "./dashboards";
import search from "./search";
import collections from "./collections/merchants";
import applications from "./applications";
import deliveries from "./deliveries";
import users from "./users";
import payments from "./payments";
import products from "./products";
import productDetails from "./productDetails";
import newProduct from "./newProduct";
import cashSales from './cashSales';
import userProductsNearingCompletion from './user-products';

import orders from "./orders";

import googleMap from "./google-map";

import {
  addToMessages,
  addPayment,
  addDelivery,
  addMerchant,
  addNavMessage,
  updateConversation
} from "../../redux/actions";
import attemptedPayments from "./attemptedPayments";
import swapRequests from "./swapRequests";
import Settings from "./settings";
import Signups from "./sign-ups";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../firebase/firebaseConfig";
import MerchantSignups from "./merchant-sign-ups";
import MerchantCollections from "./merchant-collections";
import MerchantCashSales from "./merchant-cash-sales";

// const WS_URL = 'ws://13.245.87.148:3000';
const WS_URL = 'wss://13.245.108.94:3000'
class App extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount(){
    // this.connectToWebsocket({id: 'admin'});
    this.listenToNotifications();
  }

  async listenToNotifications() {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      onMessage(messaging, (payload)=> {
        console.log('msg',payload);
        const data = payload.data;
        const notif = new Notification(data.title || data.senderName, {
          body: data.message || data.body,
          data: payload
        })
  
        notif.addEventListener("click", (ev)=> {
          const info = ev.target.data.data;
          if(info.type == 'MSG') {
            if(window.location.href.indexOf('chat') > -1) {
              if(window.location.href.indexOf(info.senderId) == -1){
                window.location.href = `/app/applications/chat?ref=${info.senderId}`
              }
            }
            else {
              window.open(`/app/applications/chat?ref=${info.senderId}`,'_blank');
            }
          }
          
          notif.close();
        })

        const { messages, conversations } = this.props.chatApp;

        const { chatApp, addNavMessage, addToMessages, addMerchant, addDelivery, addPayment, updateConversation } = this.props;
        
        const path = this.props.location.pathname;

        const message = data;

        if(message.type === 'MSG' || message.senderId){
          const __msg = messages.find(m=> m.id === message.id)
          if(!__msg){
            addToMessages(message);
            
            if(path.indexOf('chat') === -1){
              // console.log('not on chat');
              addNavMessage(message);
            }
            else if(!chatApp.selectedUser){
              // console.log('we do not have selected user');
              addNavMessage(message)
            }
            else if(chatApp.selectedUser && (chatApp.selectedUser.id !== message.senderId)){
              // console.log('not user');
              addNavMessage(message)
            }

            const __chat = conversations.find(m=> m.ownerId === message.senderId);

            if(__chat){
              // console.log('chat is available',__chat);
              const unreadCount = (!chatApp.selectedUser || chatApp.selectedUser.ownerId !== message.senderId) ? __chat.unreadCount + 1 : 0;
              updateConversation({...__chat, lastMessage: message, createdAt: message.timestamp, unreadCount})
            }
          }
        }
        else if(message.type === 'NEW_MERCHANT'){
          addMerchant(message);
        }
        else if(message.type === 'NEW_DELIVERY'){
          addDelivery(message);
        }
        else if(message.__type === 'NEW_PAYMENT'){
          addPayment(message);
        }
      })

    }
  }

  connectToWebsocket(user){
    const client = new W3CWebSocket(`${WS_URL}?userId=${user.id}`);

    client.onopen = () => {
        // alert('connection success')
        console.log('WebSocket Client Connected');

        window.wsClient = client;
    };
    
    client.onmessage = (msg) => {
      const {messages, conversations} = this.props.chatApp;

      const { chatApp, addNavMessage, addToMessages, addMerchant, addDelivery, addPayment, updateConversation } = this.props;
      
      const path = this.props.location.pathname;

      const message = JSON.parse(msg.data)
      if(message.type === 'MSG' || message.senderId){
        const __msg = messages.find(m=> m.id === message.id)
        if(!__msg){
          addToMessages(message);
          
          if(path.indexOf('chat') === -1){
            // console.log('not on chat');
            addNavMessage(message);
          }
          else if(!chatApp.selectedUser){
            // console.log('we do not have selected user');
            addNavMessage(message)
          }
          else if(chatApp.selectedUser && (chatApp.selectedUser.id !== message.senderId)){
            // console.log('not user');
            addNavMessage(message)
          }

          const __chat = conversations.find(m=> m.ownerId === message.senderId);

          if(__chat){
            console.log('chat is available',__chat);
            const unreadCount = (!chatApp.selectedUser || chatApp.selectedUser.ownerId !== message.senderId) ? __chat.unreadCount + 1 : 0;
            updateConversation({...__chat, lastMessage: message, createdAt: message.timestamp, unreadCount})
          }
        }
      }
      else if(message.type === 'NEW_MERCHANT'){
        addMerchant(message);
      }
      else if(message.type === 'NEW_DELIVERY'){
        addDelivery(message);
      }
      else if(message.__type === 'NEW_PAYMENT'){
        addPayment(message);
      }
    };

    client.onerror = () => {
        window.wsClient = null;
        // alert('socket error')
        console.error('WEBSOCKET ERROR....');
    }
  }

  render() {
    const { match, user } = this.props;
    let redirectUrl = 'dashboard';
    if(user.type === 2){
      redirectUrl = 'payments'
    }
    else if(user.type === 3){
      redirectUrl = 'orders'
    }

    return (
      <AppLayout>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/${redirectUrl}`} />
          {((user.type === 1) || (user.type === 2)) && (<Route path={`${match.url}/search`} component={search} />)}
          {((user.type === 1) || (user.type === 2)) && (<Route path={`${match.url}/dashboard`} component={dashboards} />)}
          {((user.type === 1) || (user.type === 2)) && (<Route path={`${match.url}/collections`} component={collections} />)}
          <Route path={`${match.url}/applications`} component={applications} />
          {/* <Route path={`${match.url}/pages`} component={pages} /> */}
          {/* <Route path={`${match.url}/ui`} component={ui} /> */}
          <Route path={`${match.url}/deliveries`} component={deliveries} />
          {/* {((user.type === 1) || (user.type === 2)) && ( */}
          <Route path={`${match.url}/users`} component={users} />
          {/* )} */}
          {((user.type === 1) || (user.type === 2)) && (<Route path={`${match.url}/payments`} component={payments} />)}
          <Route path={`${match.url}/products/new-product`} exact component={newProduct} />
          <Route path={`${match.url}/products/:id`} exact component={productDetails} />
          <Route path={`${match.url}/products`} component={products} />
          <Route path={`${match.url}/orders`} component={orders} />
          <Route path={`${match.url}/swap-requests`} component={swapRequests} />
          <Route path={`${match.url}/sign-ups`} component={Signups} />
          <Route path={`${match.url}/merchant-sign-ups`} component={MerchantSignups} />
          <Route path={`${match.url}/merchant-collections`} component={MerchantCollections} />
          <Route path={`${match.url}/merchant-cash-sales`} component={MerchantCashSales} />
          {((user.type === 1) || (user.type === 2)) && (<Route path={`${match.url}/settings`} component={Settings} />)}
          <Route path={`${match.url}/cash-sales`} component={cashSales}/>
          <Route path={`${match.url}/attempted-payments`} component={attemptedPayments}/>
          <Route path={`${match.url}/user-products`} component={userProductsNearingCompletion}/>
          {((user.type === 1)) && (<Route path={`${match.url}/collections-map`} component={googleMap} />)}
          <Redirect to="/error" />
        </Switch>
      </AppLayout>
    );
  }
}
const mapStateToProps = ({ menu, chatApp, authUser }) => {
  const { containerClassnames } = menu;
  const { user } = authUser;
  return { containerClassnames, chatApp, user };
};

export default withRouter(
  connect(
    mapStateToProps,
    {addToMessages, addMerchant, addDelivery, addNavMessage, addPayment, updateConversation}
  )(App)
);
