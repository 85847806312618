import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Resizer from "react-image-file-resizer";

import {
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  Button,
  TabContent,
  TabPane,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,Label,FormGroup
} from "reactstrap";

import { NavLink } from "react-router-dom";
import classnames from "classnames";
import axios from 'axios';

import {
    FormikReactSelect,
    FormikTagsInput,
  } from "../../containers/form-validations/FormikFields";

import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";

const img_base_url = 'https://api.tuatuagye.com/get-image?type=product&id='
const image_upload_url = 'https://api.tuatuagye.com/upload-product-images';
const products_url = 'https://api.tuatuagye.com/products'
const priceChangeUrl = 'https://api.tuatuagye.com/update-product-prices';
const search_items_url = 'https://api.tuatuagye.com/search-items';
const myProducts_url = 'https://api.tuatuagye.com/m-products';

const options = [
  { value: "1", label: "Electronics" },
  { value: "2", label: "Home Appliance" },
  { value: "3", label: "Phones & Accessories" }
];

const stockOptions = [
  { value: 1, label: "In-Stock" },
  { value: 2, label: "Out of Stock" }
];

const ProductSchema = Yup.object().shape({
    name: Yup.string()
    //   .name("Invalid name")
      .required("Name is required!"),
    description: Yup.string()
        .required("Description required"),
    // password: Yup.string().required("Password is required!"),
    // tags: Yup.array()
    //   .min(3, "Pick at least 3 tags")
    //   .required("At least one tag is required"),
    date: Yup.date()
      .nullable()
      .required("Date required"),
    // state: Yup.object()
    //   .shape({
    //     label: Yup.string().required(),
    //     value: Yup.string().required()
    //   })
    //   .nullable()
    //   .required("State is required!")
  });
  
const resizeFile = (file, size) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    size,
    size,
    "JPEG",
    90,
    0,
    (uri) => {
      resolve(uri);
    },
    "file"
  );
});

function getSlug(name){
    const __name = name ? name.trim().toLowerCase() : '';
    const temp = `${__name}`;

    let result = temp.split(' ').join('-').split(' ').join('-');

    result = result.split('"').join('');

    // console.log('slug is',result);

    return result;
}

class ProductDetails extends Component {
  constructor(props) {
    super(props);

    this.toggleTab = this.toggleTab.bind(this);
    this.state = {
      deleteModalOpen: false,
      deleting: false,
      loading: false,
      activeFirstTab: "1",
      modalOpen: false,
      saveModalOpen: false,
      productImageModalOpen:false,
      fullscreenImageModalOpen: false,
      adminCode:'',
      commissionAmount:'',
      processing: false,
      saving: false,
      modalViewType:'',
      fileUrl:'',
      fullscreenImageUrl:'',
      file: null,
      data: null,
      productId: '',
      adminCode: '',
      showPriceUpdate:false,
      changeReason: ''
    };

    this.formRef1 = null;
    this.formRef2 = null;
    this.formRef3 = null;

    this.inp = null;
    this.inp2 = null;
  }

  componentDidMount() {
    // console.log('path...',this.props.location.pathname);
    if(typeof this.props.product.images === 'object'){
      this.setState({data: this.props.product})
    }
    else {
      // console.log('type is',typeof this.props.product.images);
      const prodd = this.props.product;
      this.setState({
        data: {
          ...prodd,
          images: JSON.parse(prodd.images),
          tags: JSON.parse(prodd.tags)
        }
      })
    }
    // const id = this.props.product.id; //this.props.location.pathname.split('/').pop();
    // this.setState({productId: id});
    // axios.get(`https://api.tuatuagye.com/products/${id}`)
    // .then(res=> {
    //   this.setState({data: res.data, loading:false})
    // })
    // .catch(err => {
    //   console.log('error loading product',err)
    //   this.setState({loading: false})
    // })
    
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }))
  }

  toggleSaveModal = () => {
    if(this.state.saveModalOpen){
      this.setState({showPriceUpdate: false})
    }
    this.setState(prevState => ({
      saveModalOpen: !prevState.saveModalOpen
    }))
  }

  toggleFullImageModal = () => {
    this.setState(prevState => ({
      productImageModalOpen: !prevState.productImageModalOpen
    }))
  }

  toggleFullScreenImageModal = () => {
    this.setState(prevState => ({
      fullscreenImageModalOpen: !prevState.fullscreenImageModalOpen
    }))
  }
  

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }

  handleSubmit = (values) => {
    this.setState({processing:true});

    setTimeout(() => {
      this.toggleModal();
    }, 4000);
  }

  handleImageUpload = async () => {
    this.setState({processing:true});

    this.setState({processing:true});
    const { file } = this.state;
    const image = await resizeFile(file, 500);
    // console.log('image is',image);
    const data = new FormData();
    
    data.append('images', image);

    let newImage = '';
    let existingImages = [];

    axios.post(image_upload_url,data)
      .then(res=> {
        // console.log('success',res.data);
        if(res.data.ids && res.data.ids[0]){
          newImage = res.data.ids[0];

          const { id, images } = this.props.product;
          
          // console.log('productId...',id,'new url',newImage);
          
          existingImages = images || [];
          existingImages.push(newImage);

          // console.log('existing images now',existingImages);

          if(id && existingImages.length > 0){
            return axios.patch(`${products_url}/${id}`,{ images: existingImages })
          }
        }
        
        return null;
      })
      .then(res=> {
        if(res){
          // console.log('update successful');
          const { product } = this.props;

          this.props.onUpdate({...this.state.data, images: existingImages}, false);
          this.setState({data: {...this.state.data, images: existingImages}})
        }
        this.setState({processing:false});
        this.toggleFullImageModal();
      })
      .catch(error=> {
        console.log('error',error);
        this.setState({processing:false});
        NotificationManager.error(
          "Upload Error",
          "An unknown error occurred. Please check console for details or try again.",
          3000,
          null,
          null,
        );
      })
  }

  imageSelected = (event) => {
    // console.log('file is ',event.target.files[0]);;
    this.setState({file: event.target.files[0]})
    const reader = new FileReader();

    const _func = (e) => this.setState({fileUrl: e.target.result}, () => this.toggleModal())
    
    reader.onload = function (e) {
      _func(e)
    }

    reader.readAsDataURL(event.target.files[0]);
    // this.toggleModal()
  }

  imageSelected2 = (event) => {
    console.log('*** file is ',event.target.files[0]);;
    this.setState({file: event.target.files[0]})
    const reader = new FileReader();

    const _func = (e) => this.setState({fileUrl: e.target.result}, () => this.toggleFullImageModal())
    
    reader.onload = function (e) {
      _func(e)
    }

    reader.readAsDataURL(event.target.files[0]);
  }

  uploadThumbnail = async () => {
    this.setState({processing:true});
    const { file } = this.state;
    const image = await resizeFile(file, 300);
    console.log('image is',image);
    const data = new FormData();
    
    data.append('images', image);

    let thumbnail = '';

    axios.post(image_upload_url,data)
      .then(res=> {
        // console.log('success',res.data);
        if(res.data.ids && res.data.ids[0]){
          thumbnail = res.data.ids[0];

          const productId = this.props.product.id;
          // console.log('productId...',productId,'new url',thumbnail);

          if(productId){
            return axios.patch(`${products_url}/${productId}`,{ thumbnail })
          }
        }
        
        return null;
      })
      .then(res=> {
        if(res){
          // console.log('update successful');
          const { product } = this.props;

          if(product.thumbnail && product.thumbnail.length > 0){
            // console.log('has existing thumbnail',product.thumbnail);
            this.deleteImage(product.thumbnail)
          }
          // this.props.onUpdate({...product, thumbnail}, false);
          this.props.onUpdate({...this.state.data, thumbnail}, false);
          this.setState({data: {...this.state.data, thumbnail}});
        }
        this.setState({processing:false});
        this.toggleModal();
      })
      .catch(error=> {
        console.log('error',error);
        this.setState({processing:false});
        NotificationManager.error(
          "Upload Error",
          "An unknown error occurred. Please check console for details or try again.",
          3000,
          null,
          null,
        );
      })
  }

  deleteImage = async (url) => {
    //redo url for delete!
    const res = await axios.delete(`${img_base_url}${url}`);
    console.log('res status',res.status);
    if(res.status === 200){
      return true;
    }
    else {
      NotificationManager.error(
        "Upload Error",
        "An unknown error occurred. Please check console for details or try again.",
        3000,
        null,
        null,
      );

      return false;
    }
  }

  handleDeleteImage = async () => {
    this.setState({processing:true});
    const url = this.state.fullscreenImageUrl;

    try {
      await this.deleteImage(url);

      // const { product } = this.props;
      const product = this.state.data;
      const temp = product.images.filter(img=> img !== url);
      
      // console.log('temp is',temp);

      const productId = product.id;
      if(productId){
        const newData = {...product, images: temp};

        await axios.patch(`${products_url}/${productId}`,{ images: temp })
        
        this.props.onUpdate(newData);

        this.toggleFullScreenImageModal();
        this.setState({data: newData, processing:false});
      }
      else {
        alert('An error occurred.');
        this.setState({processing: false})
      }
      
    } catch (error) {
      console.log('delete error is',error);
      this.setState({processing:false});
      NotificationManager.error(
        "Upload Error",
        "An unknown error occurred. Please check console for details or try again.",
        3000,
        null,
        null,
      );
    } 
  }


  handleSave = () => {
    // console.log(this.formRef3.state.values);
    // return
    let { name, brand, description, tags, category } = this.formRef1.state.values;
    tags = tags.map(tag=> tag.toLowerCase());
    const { cashPrice, onlineTTGPrice, posTTGPrice, pseudoPrice } = this.formRef2.state.values;
    const { quantity, hasStock } = this.formRef3.state.values;
    const { showPriceUpdate, changeReason } = this.state;

    if(!name){
      NotificationManager.error(
        "Name missing",
        "Please provide name of new product.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!name){
      NotificationManager.error(
        "Brand missing",
        "Please provide brand of new product.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!description){
      NotificationManager.error(
        "Description missing",
        "Please provide description of new product.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(tags.length === 0){
      NotificationManager.error(
        "Add tag",
        "Please provide at least 1 tag.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!cashPrice || cashPrice === 0){
      NotificationManager.error(
        "Add Cash Price",
        "Cash price cannot be 0.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!onlineTTGPrice || onlineTTGPrice === 0){
      NotificationManager.error(
        "Add Online-TTG Price",
        "Online-TTG price cannot be 0.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!posTTGPrice || posTTGPrice === 0){
      NotificationManager.error(
        "Add Pos-TTG Price",
        "Pos-TTG price cannot be 0.",
        3000,
        null,
        null,
      );

      return;
    }
    else if(!pseudoPrice || pseudoPrice === 0){
      NotificationManager.error(
        "Add Pseudo Price",
        "Pseudo price cannot be 0.",
        3000,
        null,
        null,
      );

      return;
    }

    const productId = this.props.product.id;

    const payload = { name, brand, description, tags, slug: getSlug(name), cashPrice: Number(cashPrice), onlineTTGPrice: Number(onlineTTGPrice), posTTGPrice: Number(posTTGPrice), pseudoPrice: Number(pseudoPrice), quantity: Number(quantity), hasStock };

    this.setState({saving: true})

    // alert('product id is '+productId)
    if(productId && productId.length > 0){
      if(tags && tags.length > 0){
        this.createSearchKeywords(tags)
      }
      // else console.log('no tags');

      axios.patch(`${products_url}/${productId}`, payload)
        .then(res=> {
          if(showPriceUpdate){
            const priceUpdatePayload = {
              productId,
              posTTGPrice, 
              onlineTTGPrice,
              changeMessage: changeReason
            }

            return axios.post(priceChangeUrl, priceUpdatePayload);
          }
          return null;
        })
        .then(res=> {
          // if(res){
          //   console.log('product-price change success');
          // }

          this.props.onUpdate({...this.state.data, ...payload}, true);
          this.setState({saving: false});
        })
        .catch(e=> {
          console.log('error updating product',e);
          alert('Sorry, an error occurred. Please try again.')
        })
    }
    else {
      // console.log('no id found, product is',this.props.product);
    }
  }

  handleDelete = () => {
    this.setState({deleting: true});
    
    const productId = this.props.product.id;

    axios.get(`${myProducts_url}?filter[where][productId]=${productId}`)//&filter[fields]=status
      .then(r=> {

        if(r.data.length > 0){
          const x = window.confirm('This product is has been selected by a customer! Do you want to continue deleting it?');
          if(x){
            // return true;
            return axios.patch(`${products_url}/${productId}`,{deleted: true})
          }
          else {
            return null;
          }
        }
        else {
          return axios.delete(`${products_url}/${productId}`)
          // return true;
        }
      })
      .then(r=> {
        if(r){
          NotificationManager.success(
            "Delete Success",
            "The selected product has been deleted successfully.",
            3000,
            null,
            null,
          )

          this.props.onDelete(this.props.product, true);
        }
      })
      .catch(e=> {
        console.log('err',e);
      })
      .finally(()=> this.setState({deleting: false}))
  }

  createSearchKeywords = (tags) => {
    tags.forEach(tag=> {
      tag = tag.toLowerCase();
      // console.log('tag is',tag);
      axios.get(`${search_items_url}?filter[where][name]=${tag}&filter[limit]=1`)
        .then(res=> {
          if(res.data && res.data.length === 0){
            return axios.post(search_items_url, {name: tag});
          }
          else {
            // console.log('already existing');
          }
        })
        .catch(error=> {
          console.log('error creating tag',error);
        })
    })
  }
  render() {
    const { loading, data, adminCode,deleteModalOpen, deleting, fullscreenImageUrl, saveModalOpen, fileUrl,saving,fullscreenImageModalOpen, productImageModalOpen, processing, modalOpen, showPriceUpdate, changeReason } = this.state;
    return (
      <Fragment>
        <Row>
          {loading ?
            <div className='loading'/>
            :
            !data ? 
              <h3 style={{color:'red'}}>Product info not found!</h3>
            :
          <Colxx xxs="12">
            <div style={{display:'flex', alignItems:'center'}}>
                <div onClick={()=> this.inp.click()} style={{width:80,height:80,cursor:'pointer',marginRight:10,backgroundColor:'#ccc',borderRadius:3}}>
                  <img style={{width:80,height:80}} src={`${img_base_url}${data.thumbnail}`} alt="Img"/>
                  <input type='file' accept="image/jpeg, image/jpg, image/png" ref={(inp)=> this.inp = inp} onChange={this.imageSelected} name='thumbnail' hidden={true} />
                </div>
                <div style={{flex:1,justifyContent:'space-between'}}>
                    <h5>{data.name}</h5>
                    <span>Product Details</span>
                </div>

                <div style={{paddingTop:10,paddingRight:10}} className="float-sm-right mb-2">
                  <Button 
                    onClick={()=> {
                      this.setState({deleteModalOpen: true})
                    }}
                    className="mr-2"
                    disabled={deleting} color={"danger"}>
                      {/* <i className="iconsminds-speach-bubble-4" /> */}
                      <span>{deleting ? 'Please wait...':'Delete Product'}</span>
                  </Button>

                  <Button 
                    onDoubleClick={this.handleSave} //dev, change before major deployment
                    onClick={()=> {
                      const { onlineTTGPrice, posTTGPrice } = this.formRef2.state.values;

                      if((posTTGPrice != data.posTTGPrice) || (onlineTTGPrice != data.onlineTTGPrice)){
                        this.setState({showPriceUpdate: true});
                      }

                      this.toggleSaveModal();
                    }}
                    disabled={saving} outline color={"primary"}>
                      {/* <i className="iconsminds-speach-bubble-4" /> */}
                      <span>{saving ? 'Please wait...':'Save'}</span>
                  </Button>
                </div>
            </div>
            

            <Nav tabs className="separator-tabs ml-0 mb-5" style={{marginTop:1}}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "1",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("1");
                  }}
                  to="#basic"
                >
                  BASIC INFO
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "2",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                  to="#images"
                >
                  PRODUCT IMAGES
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "3",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("3");
                  }}
                  to="#prices"
                >
                  PRICING
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "4",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("4");
                  }}
                  to="#quantity"
                >
                  INVENTORY
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeFirstTab}>
              <TabPane tabId="1">
                <Card>
                    <CardBody>
                    <Formik
                      ref={(i)=> this.formRef1 = i}
                        initialValues={{
                          name: data.name,
                          brand: data.brand,
                          description: data.description,
                          tags: data.tags || [],
                        }}
                        validationSchema={ProductSchema}
                        onSubmit={this.handleSubmit}
                        >
                        {({
                            handleSubmit,
                            setFieldValue,
                            setFieldTouched,
                            handleChange,
                            handleBlur,
                            values,
                            errors,
                            touched,
                            isSubmitting
                        }) => (
                            <Form className="av-tooltip tooltip-label-bottom">
                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Name
                                </Label>
                                <Field className="form-control" name="name" />
                                {errors.name && touched.name ? (
                                <div className="invalid-feedback d-block">
                                    {errors.name}
                                </div>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Brand
                                </Label>
                                <Field className="form-control" name="brand" />
                                {errors.brand && touched.brand ? (
                                <div className="invalid-feedback d-block">
                                    {errors.brand}
                                </div>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Description
                                </Label>
                                <Field as="textarea" rows="6" className="form-control" name="description" />
                                {errors.description && touched.description ? (
                                <div className="invalid-feedback d-block">
                                    {errors.description}
                                </div>
                                ) : null}
                            </FormGroup>
                            
                            <FormGroup className="error-l-100">
                              <Label>Categories </Label>
                              <FormikReactSelect
                                name="reactSelect"
                                id="reactSelect"
                                value={values.reactSelect}
                                isMulti={true}
                                options={options}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                              />
                              {errors.reactSelect && touched.reactSelect ? (
                                <div className="invalid-feedback d-block">
                                  {errors.reactSelect}
                                </div>
                              ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label className="d-block">
                                Tags
                                </Label>
                                <FormikTagsInput
                                  name="tags"
                                  value={values.tags}
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                />

                                {errors.tags && touched.tags ? (
                                <div className="invalid-feedback d-block">
                                    {errors.tags}
                                </div>
                                ) : null}
                            </FormGroup>

                            </Form>
                        )}
                        </Formik>
                    </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
              <Card>
                  <CardBody>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            {(!data.images || data.images.length < 5) && (
                              <div onClick={()=> this.inp2.click()} style={{width:100,height:100,cursor:'pointer',backgroundColor:'#eee',position:'relative',marginRight:15}}>
                                  <div className='glyph-icon simple-icon-cloud-upload' style={{fontSize:20, position:'absolute', top:'50%',left:'50%', marginRight:'-50%', transform: 'translate(-50%, -50%)'}}/>
                                  <input type='file' accept="image/jpeg, image/jpg, image/png" ref={(inp)=> this.inp2 = inp} onChange={this.imageSelected2} name='thumbnail' hidden={true} />
                              </div>
                            )}
                            {data.images && data.images.map((img, index) => (
                              <img 
                                key={index+''} 
                                onClick={()=> {
                                  this.setState({fullscreenImageUrl: img});
                                  this.toggleFullScreenImageModal();
                                }} 
                                style={{width:100,height:100,backgroundColor:'#eee',marginRight:15, cursor:'pointer'}} src={`${img_base_url}${img}`} alt="Img"
                              />      
                            ))}
                        </div>
                  </CardBody>
              </Card>
              </TabPane>
              <TabPane tabId="3">
                <Card>
                    <CardBody>
                    <Formik
                      ref={(i)=> this.formRef2 = i}
                        initialValues={{
                            cashPrice: data.cashPrice,
                            onlineTTGPrice: data.onlineTTGPrice,
                            posTTGPrice: data.posTTGPrice,
                            pseudoPrice: data.pseudoPrice
                        }}
                        validationSchema={ProductSchema}
                        onSubmit={this.handleSubmit}
                        >
                        {({
                            errors,
                            touched
                        }) => (
                            <Form className="av-tooltip tooltip-label-bottom">
                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Cash Price GH₵
                                </Label>
                                <Field className="form-control" name="cashPrice" />
                                {errors.cashPrice && touched.cashPrice ? (
                                <div className="invalid-feedback d-block">
                                    {errors.cashPrice}
                                </div>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Online TTG Price GH₵
                                </Label>
                                <Field className="form-control" name="onlineTTGPrice" />
                                {errors.onlineTTGPrice && touched.onlineTTGPrice ? (
                                <div className="invalid-feedback d-block">
                                    {errors.onlineTTGPrice}
                                </div>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Pos TTG Price GH₵
                                </Label>
                                <Field className="form-control" name="posTTGPrice" />
                                {errors.posTTGPrice && touched.posTTGPrice ? (
                                <div className="invalid-feedback d-block">
                                    {errors.posTTGPrice}
                                </div>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="form-group has-top-label">
                                <Label>
                                Pseudo Price GH₵
                                </Label>
                                <Field className="form-control" name="pseudoPrice" />
                                {errors.pseudoPrice && touched.pseudoPrice ? (
                                <div className="invalid-feedback d-block">
                                    {errors.pseudoPrice}
                                </div>
                                ) : null}
                            </FormGroup>
                            
                            
                            </Form>
                        )}
                        </Formik>
                    </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="4">
              <Card>
                    <CardBody>
                    <Formik
                      ref={(i)=> this.formRef3 = i}
                        initialValues={{
                          quantity: data.quantity || 0,
                          hasStock: data.hasStock || 1
                        }}
                        validationSchema={ProductSchema}
                        onSubmit={this.handleSubmit}
                        >
                        {({
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                            values
                        }) => (
                            <Form className="av-tooltip tooltip-label-bottom">
                              <FormGroup className="form-group has-top-label">
                                  <Label>
                                  Quantity
                                  </Label>
                                  <Field className="form-control" name="quantity" />
                                  {errors.quantity && touched.quantity ? (
                                  <div className="invalid-feedback d-block">
                                      {errors.quantity}
                                  </div>
                                  ) : null}
                              </FormGroup>
                              <FormGroup className="error-l-100">
                                <Label>Stock Status</Label>
                                <FormikReactSelect
                                  name="hasStock"
                                  id="hasStock"
                                  value={stockOptions ? stockOptions.find(option => option.value == values.hasStock) : ''}
                                  options={stockOptions}
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                />
                                {errors.reactSelect && touched.reactSelect ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.reactSelect}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </Form>
                        )}
                        </Formik>
                    </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Colxx>
          }
        </Row>

        {/* Thumbnail modal */}
        <Modal
            isOpen={modalOpen}
            toggle={this.toggleModal}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleModal}>
                <Label style={{}}>Upload Thumbnail</Label>
            </ModalHeader>
            <ModalBody>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:150,height:150,borderRadius:3}} src={fileUrl} alt="Img"/>
              </div>
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.toggleModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color="primary"
                onClick={this.uploadThumbnail}
            >
              Upload Thumbnail
            </Button>
            </ModalFooter>
        </Modal>

        <Modal
            isOpen={productImageModalOpen}
            toggle={this.toggleFullImageModal}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleFullImageModal}>
                Upload Product Image
            </ModalHeader>
            <ModalBody style={{padding:0}}>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:500,borderRadius:3}} src={fileUrl} alt="Img"/>
              </div>
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.toggleFullImageModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color={"primary"}
                onClick={this.handleImageUpload}
            >
              Upload Now
            </Button>
            </ModalFooter>
        </Modal>

          {/* fullscreen image view */}
        <Modal
            isOpen={fullscreenImageModalOpen}
            toggle={this.toggleFullScreenImageModal}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleFullScreenImageModal}>
                Product Image
            </ModalHeader>
            <ModalBody style={{padding:0}}>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:600,maxWidth:'98%',borderRadius:3}} src={`${img_base_url}${fullscreenImageUrl}`} alt="Img"/>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                  color="danger"
                  onClick={this.handleDeleteImage}
                  style={{marginRight:20}}
              >
                  Delete Image
              </Button>{" "}
              <Button
                  color=""
                  onClick={this.toggleFullScreenImageModal}
              >
                  Close
              </Button>
            </ModalFooter>
        </Modal>

        {/* Confirm save modal */}
        <Modal
            isOpen={saveModalOpen}
            toggle={this.toggleSaveModal}
          >
            {saving && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleSaveModal}>
                <Label style={{}}>Confirm</Label>
            </ModalHeader>
            <ModalBody>
              <h4>Are you sure you want to perform this update on product - {data ? data.name : null}?</h4>
              {showPriceUpdate && <div style={{marginTop:20}}>
                  <label style={{}}>Reason for price change (if any):</label>
                  <textarea rows={4} type="text" value={changeReason} style={{padding:10,outline:'none',width:'100%',fontSize:16}} onChange={(event)=> this.setState({changeReason: event.target.value})}></textarea>
                </div>
              }

              <label style={{marginTop:20}}>Admin Code:
                <input type="password" value={adminCode} style={{marginLeft:20,padding:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})}/>
              </label>
                  
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.toggleSaveModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color="primary"
                onClick={()=> {
                  // if(showPriceUpdate && changeReason.length == 0){
                  //   NotificationManager.error(
                  //     "Reason Required",
                  //     "Please provide the reason of the price change. This will be sent to each customer.",
                  //     3000,
                  //     null,
                  //     null,
                  //   );
                  // }
                  // else 
                  if(!adminCode){
                    NotificationManager.error(
                      "Authorization Code",
                      "Your security code required to perform this transaction. Thank you.",
                      3000,
                      null,
                      null,
                    );
                  }
                  else if(adminCode !== this.props.user.authCode) {
                    NotificationManager.error(
                      "Invalid Code",
                      "Sorry, the code you entered is invalid. Check and try again.",
                      3000,
                      null,
                      null,
                    );
                  }
                  else {
                    this.handleSave();
                  }
                }}
            >
              UPDATE NOW
            </Button>
            </ModalFooter>
        </Modal>

        {/* Confirm delete modal */}
        <Modal
            isOpen={deleteModalOpen}
            toggle={()=> this.setState({deleteModalOpen: false})}
          >
            {deleting && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={()=> this.setState({deleteModalOpen: false})}>
                <Label style={{}}>Confirm Delete</Label>
            </ModalHeader>
            <ModalBody>
              <h4>Are you sure you want to delete this product - {data ? data.name : null}?</h4>
              
              <label style={{marginTop:20}}>Admin Code:
                <input type="password" value={adminCode} style={{marginLeft:20,padding:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})}/>
              </label>
                  
            </ModalBody>
            <ModalFooter>
              <Button
                  color=""
                  onClick={()=> this.setState({deleteModalOpen: false})}
              >
                  Cancel
              </Button>{" "}
              <Button
                color="danger"
                onClick={()=> {
                  if(!adminCode){
                    NotificationManager.error(
                      "Authorization Code",
                      "Your security code required to perform this transaction. Thank you.",
                      3000,
                      null,
                      null,
                    );
                  }
                  else if(adminCode !== this.props.user.authCode) {
                    NotificationManager.error(
                      "Invalid Code",
                      "Sorry, the code you entered is invalid. Check and try again.",
                      3000,
                      null,
                      null,
                    );
                  }
                  else {
                    this.handleDelete();
                  }
                }}
              >
                DELETE NOW
              </Button>
            </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
// export default withRouter(ProductDetails);

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;

  return { user };
};

export default connect(
  mapStateToProps,
  {}
)(ProductDetails);