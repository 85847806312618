import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

const img_base_url = 'https://api.tuatuagye.com/get-image?type=product&id='

const formatDate = (_date) => {
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}`;
}

const MessageCard = ({ sender, item, currentUserid, selectedUser }) => {
  // console.log('currentUserid...',currentUserid);
  return (
    <Fragment>
      <Card
        className={`d-inline-block mb-3 float-${
          item.senderId !== currentUserid ? "left" : "right"
        }`}
      >
        <div className="position-absolute  pt-1 pr-2 r-0">
          <span className="text-extra-small text-muted">{formatDate(item.timestamp)}</span>
        </div>
        <CardBody style={{padding:8,paddingTop:15,paddingLeft:12}}>
          <div className="d-flex flex-row pb-1">
            {item.senderId !== currentUserid && (item.senderId !== selectedUser.ownerId) && (
              <img
                alt={'IMG'}
                src={item.senderThumbnail ? ``:'/assets/img/no-image.jpg'}
                className="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall"
              />
            )}
            {item.senderId !== currentUserid && (item.senderId !== selectedUser.ownerId) && (
              <div className=" d-flex flex-grow-1 min-width-zero">
                <div className="m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                  <div className="min-width-zero">
                    <p className="mb-0 truncate list-item-heading">
                      {item.senderName}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {item.imageId &&
              <div className=" d-flex flex-grow-1 min-width-zero">
                <div className="m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                  <div className="min-width-zero">
                    <img src={`${img_base_url}${item.imageId.split('_')[1]}`} style={{width:300,height:300,borderRadius:5,marginTop:10}} />
                  </div>
                </div>
              </div>
            }
          </div>

          <div className={((item.imageId || (item.senderId === currentUserid)) && item.body.length > 11) ? "" : (item.senderId === selectedUser.ownerId && item.body.length < 13) ? "chat-text-left" : (item.senderId === selectedUser.ownerId) ? "" : "chat-text-left"} style={{marginTop: (item.senderId !== currentUserid) ? 10: item.imageId ? 0: 10}}>
            <p className="mb-0" style={{color:'#333',fontSize:16}}>{item.body}</p>
          </div>
        </CardBody>
      </Card>
      <div className="clearfix" />
    </Fragment>
  );
};

export default MessageCard;
