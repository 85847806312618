import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getCurrentTime } from "../../helpers/Utils";
import axios from 'axios';

import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONVERSATIONS,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
  CHAT_SEARCH_CONTACT,
  // ADD_MESSAGE,
  // SET_MESSAGES,
  GET_MESSAGES
} from "../actions";

import {
  getMessagesSuccess,
  getMessagesError,
  getContactsSuccess,
  getContactsError,
  getConversationsSuccess,
  getConversationsError
} from "./actions";

function* loadContacts() {
  try {
    const response = yield call(loadContactsAsync);
    const { contacts, currentUser } = response;
    yield put(getContactsSuccess(contacts, currentUser));
  } catch (error) {console.log('err',error)
    yield put(getContactsError(error));
  }
}

function* loadMessges({ payload }) {
  try {
    const response = yield call(loadMessagesAsync, payload);
    const { messages } = response;
    yield put(getMessagesSuccess(messages));
  } catch (error) {console.log('err',error)
    yield put(getMessagesError(error));
  }
}

const loadMessagesAsync = async (id) => {
  // console.log('owner id is',id);
  return await axios.get(`https://api.tuatuagye.com/messages?filter[where][or][0][senderId]=${id}&filter[where][or][1][receiverId]=${id}&filter[order]=timestamp asc`)
    .then(res => {
      // console.log('data rec',res.data);
      return {messages: res.data}
    })
    .then(response => {
      return response
    })
    .catch(error => error);
};

function* searchContacts({ payload }) {
  try {
    const response = yield call(searchContactsAsync, payload);
    const { contacts, currentUser } = response;
    yield put(getContactsSuccess(contacts, currentUser));
  } catch (error) {console.log('err',error)
    yield put(getContactsError(error));
  }
}

const loadContactsAsync = async () => {
  // const contacts = contactsData.data;
  // const currentUser = null; //contacts[0];
  return await axios.get('https://api.tuatuagye.com/users?filter[where][type]=5&filter[limit]=15')
  // return await new Promise((success, fail) => {
  //   setTimeout(() => {
  //     success({ contacts, currentUser });
  //   }, 2000);
  // })
    .then(res => {
      return {contacts: res.data, currentUser: null}
    })
    .then(response => {
      return response
    })
    .catch(error => error);
};

const searchContactsAsync = async (searchText) => {
  // console.log('search ...',searchText);
  let query = '';
  let temp = searchText.trim();

  if(temp.split(' ').length > 1){
    query = `filter[where][and][0][firstname][regexp]=/${temp.split(' ')[0]}/i&filter[where][and][1][lastname][regexp]=/${temp.split(' ')[1]}/i&filter[where][and][2][type]=5`
  }
  else {
    query = `filter[where][or][0][firstname][regexp]=/${temp}/i&filter[where][or][1][lastname][regexp]=/${temp}/i&filter[where][or][2][phone][regexp]=/${temp}/i&filter[where][and][3][type]=5`
  }
  return await axios.get(`https://api.tuatuagye.com/users?${query}&filter[limit]=10`)
    .then(res => {
      return {contacts: res.data, currentUser: null}
    })
    .then(response => {
      return response
    })
    .catch(error => error);
};

function* loadConversations(userId) {
  // console.log('uid ',userId)
  try {
    const response = yield call(loadConversationsAsync, userId);
    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const loadConversationsAsync = async ({ payload }) => {
  // let conversations = conversationsData.data;
  // conversations = conversations.filter(x => x.users.includes(payload));
  // const selectedUser = conversations[0].users.find(x => x !== payload);
  // return await new Promise((success, fail) => {
  //   setTimeout(() => {
  //     success({ conversations, selectedUser });
  //   }, 1000);
  // })
    return await axios.get('https://api.tuatuagye.com/chats?filter[limit]=50&filter[order]=createdAt desc')
    .then(response => {
      return { conversations: response.data, selectedUser: null}
    })
    .catch(error => error);
};

function* addMessageToConversation({ payload }) {
  try {
    const {
      currentUserId,
      selectedUserId,
      message,
      allConversations
    } = payload;

    const response = yield call(
      addMessageToConversationAsync,
      currentUserId,
      selectedUserId,
      message,
      allConversations
    );
    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}
const addMessageToConversationAsync = async (
  currentUserId,
  selectedUserId,
  message,
  allConversations
) => {
  let conversation = allConversations.find(
    x => x.users.includes(currentUserId) && x.users.includes(selectedUserId)
  );
  const time = getCurrentTime();
  if (conversation) {
    conversation.messages.push({
      sender: currentUserId,
      time: time,
      text: message
    });
    conversation.lastMessageTime = time;
    let conversations = allConversations.filter(x => x.id !== conversation.id);
    conversations.splice(0, 0, conversation);

    return await new Promise((success, fail) => {
      setTimeout(() => {
        success({ conversations, selectedUser: selectedUserId });
      }, 500);
    })
      .then(response => response)
      .catch(error => error);
  }
};

function* createNewConversation({ payload }) {
  try {
    const { currentUserId, selectedUserId, allConversations } = payload;
    const response = yield call(
      createNewConversationAsync,
      currentUserId,
      selectedUserId,
      allConversations
    );
    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const createNewConversationAsync = async (
  currentUserId,
  selectedUserId,
  allConversations
) => {
  let conversation = {
    id: allConversations.length + 1,
    users: [currentUserId, selectedUserId],
    lastMessageTime: "-",
    messages: []
  };

  allConversations.splice(0, 0, conversation);
  return await new Promise((success, fail) => {
    setTimeout(() => {
      success({
        conversations: allConversations,
        selectedUser: selectedUserId
      });
    }, 500);
  })
    .then(response => response)
    .catch(error => error);
};

export function* watchGetContact() {
  yield takeEvery(CHAT_GET_CONTACTS, loadContacts);
}
export function* watchGetMessages() {
  yield takeEvery(GET_MESSAGES, loadMessges);
}
export function* watchSearchContact() {
  yield takeEvery(CHAT_SEARCH_CONTACT, searchContacts);
}
export function* watchGetConversation() {
  yield takeEvery(CHAT_GET_CONVERSATIONS, loadConversations);
}
export function* watchAddMessageToConversation() {
  yield takeEvery(CHAT_ADD_MESSAGE_TO_CONVERSATION, addMessageToConversation);
}
export function* watchCreateConversation() {
  yield takeEvery(CHAT_CREATE_CONVERSATION, createNewConversation);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetMessages),
    fork(watchGetContact),
    fork(watchSearchContact),
    fork(watchGetConversation),
    fork(watchAddMessageToConversation),
    fork(watchCreateConversation)
  ]);
}
