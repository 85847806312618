import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import { NavLink } from "react-router-dom";
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import * as FileSaver from 'file-saver';
import Select from "react-select";

import { Row, Card, CardBody, Input, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,Label, Button } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import { NotificationManager } from "../../../components/common/react-notifications";

import {
  setContainerClassnames,
  changeDefaultClassnames
} from "../../../redux/actions";
import CustomSelectInput from "../../../components/common/CustomSelectInput";

const selectData = [
  { label: "All", value: "all", key: 0 },
  { label: "SELF", value: "online", key: 5 },
  { label: "POS", value: "pos", key: 2 }
];

const genderData = [
  { label: "Male", value: 1, key: 1 },
  { label: "Female", value: 1, key: 2},
];

const users_url = 'https://api.tuatuagye.com/users'
const add_admin_url = 'https://api.tuatuagye.com/register-new-admin'
const img_base_url = 'https://api.tuatuagye.com/get-image?type=profile&id='

const perms = [
    {label: 'Update Customer Balance', value: 'UPDATE_CUSTOMER_BALANCE'},
    {label: 'Delete Customer Product', value: 'DELETE_CUSTOMER_PRODUCT'},
    {label: 'Swap Customer Product', value: 'SWAP_CUSTOMER_PRODUCT'}
]

const roles = [
  {label: 'Senior Admin', value: 2},
  {label: 'Admin', value: 3}
]

class Admins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      modalOpen: false,
      permissionModalOpen: false,
      processing: false,
      confirmModal:false,
      modalViewType:'',
      searchKeyword:'',
      adminCode: '',
      selectedOption: selectData[0],
      selectedUser: {},
      admins: [],
      total: 0,
      permissions: [],
      showAddStaffModal: false,
      firstname:'',
      lastname:'',
      email:'',
      location:'',
      phone:'',
      roleType: 0
    }

    this.columns = [
      {
        Header: "Name",
        accessor: "firstname",
        Cell: props => <Row style={{marginLeft:0,marginRight:0,alignItems:'center'}}>
          <img style={{width:50,height:50,borderRadius:25,marginRight:10,backgroundColor:'#eee'}} src={props.original.imageThumbnail ? `${img_base_url}${props.original.imageThumbnail}` : "/assets/img/no-image.jpg"} alt="img"/>
          <span style={{fontSize:16}}>{props.original.firstname} {props.original.lastname}</span>
        </Row>
      },
      {
        Header: "Phone",
        accessor: "phone",
        width:110,
        // headerClassName:'custom-td',
        // className: 'custom-td',
        Cell: props => <p style={{paddingTop:15}} className="text-muted">{props.value}</p>
      },
      // {
      //   Header: "Last Seen",
      //   accessor: "lastSeen",
      //   headerClassName:'custom-td',
      //   className: 'custom-td',
      //   Cell: props => <p className="text-muted"  style={{paddingTop:15, color: props.value === 'Online' ? '#2ECC71':'#4d4d4d'}}>
      //     {this.__formatDate(Number(props.value || props.original.createdAt))}
      //   </p>
      // },
      {
        Header: "Location",
        accessor: "location",
        width: 150,
        Cell: props => <div style={{paddingTop:15}}>{props.value || 'N/A'}</div>
      },
      {
        Header: "Date Joined",
        accessor: "createdAt",
        width: 150,
        Cell: props => <div style={{paddingTop:15}}>{this.__formatDate(props.value)}</div>
      },
      {
        Header: "Status",
        accessor: "enabled",
        width:100,
        Cell: props => <div style={{paddingTop:15}}><span style={{backgroundColor: props.value ? '#2ECC71' : '#E74C3C',color:'#fff',paddingRight:7,paddingLeft:7,paddingTop:5,paddingBottom:5,borderRadius:4}}>{props.value ? 'Active' : 'Inactive'}</span></div>
      },
      {
        Header: "Action",
        accessor: "enabled",
        width:90,
        Cell: props => <Row style={{paddingLeft:"12px",paddingTop:10}}>
          {!props.value && 
            <div
              onClick={()=> {
                this.setState({selectedUser:props.original, roleType: props.original.type, modalViewType: 'ENABLE'})
                this.toggleModal();
              }}
              style={{cursor:'pointer',fontSize:20,color:'#2ECC71'}}  
              className={"glyph-icon iconsminds-add-user"} 
            />
          }
          {props.value  && 
            <div 
              onClick={()=> {
                this.setState({selectedUser:props.original, modalViewType: 'DISABLE'})
                this.toggleModal();
              }}
              style={{cursor:'pointer',fontSize:17,paddingTop:5,marginRight:7,paddingLeft:4,color:'#E74C3C'}} 
              className={"glyph-icon simple-icon-close"} 
            />
          }
          {/* <NavLink style={{marginLeft:15,paddingTop:5}} to={`/app/users/admins/${props.original.id}`}>
            <div style={{fontSize:17}} className={'glyph-icon simple-icon-eye'}/>
          </NavLink> */}
            <div
              onClick={()=> {
                this.setState({selectedUser:props.original})
                this.togglePermissionModal();
              }}
              style={{cursor:'pointer',fontSize:20,paddingTop:3,marginLeft:10}}  
              className={"glyph-icon simple-icon-list"} 
            />
        </Row>
      }
    ]
  }

  componentDidMount() {
    axios.get(`${users_url}?filter[where][type][lt]=4&filter[order]=createdAt%20DESC`)
      .then(res => {
        if(res.data && res.data.length > 0){
            if(res.data && res.data.length > 0){
                this.setState({admins: res.data.filter(r=> r.type != 1)})
            }
        }
        this.setState({loading:false})
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Loading error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
        console.log('error is',error);
      })

      //REDO. getting total of admins
      axios.get(`${users_url}?filter[where][type][lt]=4`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({total: res.data.length})
        }
        this.setState({loading:false})
      })
      .catch(error => {
        this.setState({loading: false});
        NotificationManager.error(
          "Loading error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
        console.log('error is',error);
      })
  }

  searchWithName = (searchText) => {
    this.setState({loading: true});
    let query = '';
    let temp = searchText.trim();

    if(temp.split(' ').length > 1){
      query = `filter[where][and][0][firstname][regexp]=/${temp.split(' ')[0]}/i&filter[where][and][1][lastname][regexp]=/${temp.split(' ')[1]}/i&filter[where][and][2][type][lt]=4`
    }
    else {
      query = `filter[where][or][0][firstname][regexp]=/${temp}/i&filter[where][or][1][lastname][regexp]=/${temp}/i&filter[where][or][2][phone][regexp]=/${temp}/i&filter[where][and][3][type][lt]=4`
    }

    axios.get(`${users_url}?${query}&filter[limit]=20`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({admins: res.data, loading: false});
          return null;
        }
        else if(temp.split(' ').length > 1){
          query = `filter[where][and][0][firstname][regexp]=/${temp.split(' ')[1]}/i&filter[where][and][1][lastname][regexp]=/${temp.split(' ')[0]}/i&filter[where][and][2][type][lt]=4`;
          return axios.get(`${users_url}?${query}&filter[limit]=20`)
        }
        else {
          this.setState({admins:[], loading:false});
          return null;
        }
      })
      .then(res=> {
        if(res){
          this.setState({admins: res.data, loading: false});
        }
      })
      .catch(error => {
        this.setState({loading: false});
        alert('Sorry, an unknown error occurred.');
        console.log('error...',error);
      })
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }))
  }

  togglePermissionModal = () => {
    this.setState(prevState => ({
      permissionModalOpen: !prevState.permissionModalOpen
    }))
  }

  handleSubmit = (user) => {
    const { admins, modalViewType, roleType } = this.state
    const enabled = user.enabled ? false : true;

    this.setState({processing:true});
    const pl = { enabled };

    if((modalViewType === 'ENABLE') && roleType){
      pl.type = roleType
    }

    axios.patch(`${users_url}/${user.id}`,pl)
      .then(res => {
        const temp = admins.map(usr => {
          if(usr.id === user.id){
            usr.enabled = enabled;
          }

          return usr;
        })

        this.setState({admins: temp, processing: false, adminCode: ''});
        this.toggleModal();
      })
      .catch(error => {
        this.setState({loading: false});
        console.log('edit eror',error);
        NotificationManager.error(
          "Edit error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
      })
  }

  handleChangePermission = (user) => {
    const { admins, permissions } = this.state
    // const enabled = user.enabled ? false : true;
    console.log('perms',permissions);
    this.setState({processing:true});

    axios.patch(`${users_url}/${user.id}`,{ permissions })
      .then(res => {
        const temp = admins.map(usr => {
          if(usr.id === user.id){
            usr.permissions = permissions;
          }

          return usr;
        })

        this.setState({admins: temp, processing: false, adminCode: ''});
        this.togglePermissionModal();
        NotificationManager.success(
          "Success",
          "Permissions updated successfully.",
          3000,
          null,
          null,
        );
      })
      .catch(error => {
        this.setState({loading: false});
        console.log('edit eror',error);
        NotificationManager.error(
          "Edit error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
      })
  }

  loadOptions = (inputValue, callback) => {
      if(inputValue && inputValue.toLowerCase){
          const ttmp = perms.filter(p=> p.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
          callback(ttmp || [])
      }
      else {
          callback(perms)
      }

    // axios.get(`${users_url}/dispatches?filter[where][or][0][name][regexp]=/${inputValue}/i&filter[where][or][1][location][regexp]=/${inputValue}/i&filter[limit]=5`)
    //   .then(res => {
    //     callback(res.data.map(dispatch => {
    //       dispatch.label = `${dispatch.numberPlate} - ${dispatch.name}`;
    //       dispatch.value = `${dispatch.phone}`
    //       return dispatch;
    //     }));
    //   })
    //   .catch(error => {
    //     console.log('Error...',error);
    //   })
  };

  onChange = (newValue) => {
    if(newValue && newValue.length > 0){
        const tmp = newValue.map(v=> v.value);
        this.setState({permissions: tmp})
    }
    else {
        this.setState({permissions: []})
    }
  };

  onTypeChange = (newValue) => {
    this.setState({roleType: newValue.value})
  };

  handlePermissions = (user) => {
    const { admins, permissions } = this.state
    const enabled = user.enabled ? false : true;

    this.setState({processing:true});

    axios.patch(`${users_url}/${user.id}`,{ permissions })
      .then(res => {
        const temp = admins.map(usr => {
          if(usr.id === user.id){
            usr.permissions = permissions;
          }

          return usr;
        })

        this.setState({admins: temp, processing: false, adminCode: ''});
        this.togglePermissionModal();
      })
      .catch(error => {
        this.setState({loading: false});
        console.log('edit eror',error);
        NotificationManager.error(
          "Edit error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
      })
  }

  formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  __formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let yr = `${date.getFullYear()}`.slice(2);
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  exportToExcel() {
    const data = this.state.admins.map(record=> {
      return {
        Name: `${record.firstname} ${record.lastname}`,
        Phone: `${record.phone}`.indexOf('N/A') > -1 ? 'NOT SET':record.phone,
        Email: record.email || 'NOT SET',
        // Type: record.type === 5 ? 'SELF':'POS',
        Active: record.enabled ? 'YES':'NO',
        Location: record.location || 'NOT SET',
        'Date Joined': this.__formatDate(record.createdAt)
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `Admins Data | ${new Date().toJSON()}`);
    });
  }

  handleChange = (val) => {
    this.setState({gender: val})
  }

  addStaff() {
    const { firstname, lastname, location, phone, email, admins, gender } = this.state
    const payload = { firstname, lastname, location, phone, email, gender: gender.value };

    this.setState({processing:true});

    axios.post(add_admin_url, payload)
      .then(res => {
        if(res.data.status == 1){
          const temp = admins.map(usr => {
            return usr;
          })
          temp.unshift({
            ...payload,
            createdAt: new Date
          })

          //todo: change!!
          axios.get(`${users_url}?filter[where][type][lt]=4&filter[order]=createdAt%20DESC`)
            .then(res => {
              if(res.data && res.data.length > 0){
                  if(res.data && res.data.length > 0){
                      this.setState({admins: res.data.filter(r=> r.type != 1)})
                  }
              }
            })

          this.setState({admins: temp, processing: false, adminCode: ''});
          this.toggleStaffModal();
          NotificationManager.success(
            "Success",
            "New staff added successfully.",
            3000,
            null,
            null,
          );
        }
        else {
          NotificationManager.warning(
            "Warning",
            res.data.message,
            3000,
            null,
            null,
          );

          this.setState({processing: false})
        }
      })
      .catch(error => {
        this.setState({processing: false});
        NotificationManager.error(
          "System error",
          "An error occurred. Kindly double-check the phone number or email and try again.",
          3000,
          null,
          null,
        );
      })
  }

  toggleStaffModal = () => {
    this.setState(prevState => ({
      showAddStaffModal: !prevState.showAddStaffModal
    }))
  }

  render() {
    const { adminCode, admins, total, searchKeyword, roleType, loading, modalViewType, processing, modalOpen, permissionModalOpen, selectedUser } = this.state;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Row style={{justifyContent:'space-between',marginLeft:0,marginRight:0}}>
              <h1>Admins | {total}</h1>
              <Row style={{marginBottom:10,marginLeft:0,marginRight:0}}>
                <div style={{display:'flex'}}>
                  <div
                    onClick={this.toggleStaffModal}
                    style={{cursor:'pointer',fontSize:22,color:'#008894',marginRight:15,marginTop:11}}  
                    className={"simple-icon-plus"} 
                  />
                  <div className="payments-search" style={{marginRight:0}}>
                    <Input
                      style={{backgroundColor:'#fff'}}
                      // name="searchKeyword"
                      // id="searchKeyword"
                      placeholder={"Search"
                          // messages["menu.search"]
                      }
                      value={searchKeyword}
                      onChange={e => {
                        let value = e.target.value;
                        this.setState({searchKeyword: value})
                        clearTimeout(this.timer);
                        this.timer = setTimeout(() => {
                          this.searchWithName(value);
                        }, 1000);
                        // this.handleSearchInputChange(e)
                      }}
                      // onKeyPress={e => this.handleSearchInputKeyPress(e)}
                    />
                    <span
                        className="search-icon"
                        style={{top:10}}
                        // onClick={e => this.handleSearchIconClick(e)}
                    >
                        <i className="simple-icon-magnifier" />
                    </span>
                  </div>
                </div>
                </Row>
              </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card>
              <CardBody>
                {loading && <div className='loading'/>}
                <ReactTable
                  data={admins}
                  columns={this.columns}
                  defaultPageSize={5}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  PaginationComponent={DataTablePagination}
                  className={"react-table-fixed-height"}
                />

                <div
                  onClick={()=> {
                    this.exportToExcel();
                  }} 
                  className="d-flex justify-content-end" style={{flex:1,cursor:"pointer"}}>
                  Download to Excel 
                  <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        {/* ADD STAFF. */}
        {this.state.showAddStaffModal && (<Modal
          isOpen={this.state.showAddStaffModal}
          toggle={()=> {
            if(!this.state.processing){
              this.toggleStaffModal();
            }
          }}
        >
          {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
          <ModalHeader 
            toggle={()=> {
              if(!this.state.processing){
                this.toggleStaffModal();
              }
            }}
            style={{alignItems:"center",padding:""}} 
          >
            <Label>
              Add new staff account
            </Label>
          </ModalHeader>
          <ModalBody>
            {/* <h6>You can reset customer's TTG balance here. Current balance is: <b>GHS {this.formatAmount(details.ttgCash)}</b></h6> */}
            <div style={{marginTop:20}}>
              <label>
                First name:
                <input disabled={this.state.processing} value={this.state.firstname} style={{paddingLeft:10,paddingBottom:10,paddingTop:10,marginLeft:10,outline:'none',width:120}} onChange={(event)=> {event.target.value.length < 21 && this.setState({firstname: event.target.value})}}/>
              </label>

              <label style={{marginLeft:10}}>
                Last name:
                <input disabled={this.state.processing} value={this.state.lastname} style={{marginLeft:10,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:120}} onChange={(event)=> {event.target.value.length < 21 &&  this.setState({lastname: event.target.value})}}/>
              </label>
            </div>

            <div style={{marginTop:10}}>
              <label>
                Phone number:
                <input disabled={this.state.processing} value={this.state.phone} style={{paddingLeft:10,paddingBottom:10,paddingTop:10,marginLeft:10,outline:'none',width:120}} onChange={(event)=> {event.target.value.length < 11 && this.setState({phone: event.target.value})}}/>
              </label>

              <label style={{marginLeft:10}}>
                Email:
                <input disabled={this.state.processing} value={this.state.email} style={{marginLeft:10,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:160}} onChange={(event)=> {event.target.value.length < 51 && this.setState({email: event.target.value})}}/>
              </label>
            </div>

            <div style={{marginTop:10}}>
              <label>
                Location:
                <input disabled={this.state.processing} value={this.state.location} style={{paddingLeft:10,paddingBottom:10,paddingTop:10,marginLeft:10,outline:'none',width:160}} onChange={(event)=> {event.target.value.length < 21 && this.setState({location: event.target.value})}}/>
              </label>

              <div style={{marginTop:10,display:'flex',alignItems:'center'}}>
                <label style={{marginRight:10,marginBottom:0}}>Gender:</label>
                <div style={{flex:.5}}>
                  <Select
                    components={{ Input: CustomSelectInput }}
                    placeholder="Select"
                    className="react-select"
                    classNamePrefix="react-select"
                    name="select-paymenttype-options"
                    value={this.state.gender}
                    onChange={this.handleChange}
                    options={genderData}
                  />
                </div>
              </div>

              <label style={{marginTop:20}}>
                Auth. Code:
                <input disabled={this.state.processing} type="password" value={this.state.adminCode} style={{marginLeft:10,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})}/>
              </label>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={this.state.processing}
              color="default"
              onClick={this.toggleStaffModal}
            >
              Cancel
            </Button>{" "}
            <Button
              disabled={this.state.processing}
              color="danger"
              onClick={()=> {
                if(!this.state.firstname){
                  NotificationManager.error(
                      "Name Error",
                      "First name required.",
                      3000,
                      null,
                      null,
                  );
                }
                else if(!this.state.lastname){
                  NotificationManager.error(
                      "Name Error",
                      "Last name required.",
                      3000,
                      null,
                      null,
                  );
                }
                else if(!this.state.phone || this.state.phone.length !== 10){
                  NotificationManager.error(
                      "Phone number Error",
                      "Valid phone number required (10 digits).",
                      3000,
                      null,
                      null,
                  );
                }
                else if(!this.state.email){
                  NotificationManager.error(
                      "Email Error",
                      "Email required.",
                      3000,
                      null,
                      null,
                  );
                }
                else if(!this.state.location){
                  NotificationManager.error(
                      "Location Error",
                      "Location required.",
                      3000,
                      null,
                      null,
                  );
                }
                else if(!this.state.adminCode){
                  NotificationManager.error(
                      "Authorization Code",
                      "Your security code required to perform this transaction. Thank you.",
                      3000,
                      null,
                      null,
                  );
                }
                else if(this.state.adminCode !== this.props.user.authCode) {
                    NotificationManager.error(
                        "Invalid Code",
                        "Sorry, the code you entered is invalid. Check and try again.",
                        3000,
                        null,
                        null,
                    );
                }
                else {
                  this.addStaff();
                }
              }}
            >
              {this.state.processing ? 'Please wait...' : 'Add Now'}
            </Button>
            
          </ModalFooter>
        </Modal>)}

        <Modal
            isOpen={modalOpen}
            toggle={this.toggleModal}
            // className={this.props.className}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleModal}>
                {/* <img style={{width:"50px",height:"50px",borderRadius:"25px",marginRight:"10px"}} src="/assets/img/profile-pic-l-4.jpg" alt="Avatar"/> */}
                <img style={{width:50,height:50,borderRadius:25,marginRight:10}} src={selectedUser.imageThumbnail ? `${img_base_url}${selectedUser.imageThumbnail}` : "/assets/img/no-image.jpg"} alt="img"/>

                <Label style={{}}>Edit Staff Status</Label>
            </ModalHeader>
            <ModalBody>
              {modalViewType === 'ENABLE' ?
                <h6 style={{marginBottom:15}}>Are you sure you want to  <span style={{color:'#2ECC71'}}><b>enable</b></span> <b>{selectedUser.firstname} {selectedUser.lastname}'s</b> account?</h6>
                :
                <h6 style={{marginBottom:10}}>Are you sure you want to <span style={{color:'#E74C3C'}}><b>disable</b></span> <b>{selectedUser.firstname} {selectedUser.lastname}'s</b> account?</h6>
              }

              {modalViewType === 'ENABLE' && (
                <Select
                  placeholder='Select role...'
                  options={roles}
                  // value={this.state.roleType}
                  onChange={this.onTypeChange}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      marginBottom:15,
                    })
                  }}
                />
              )}

              <h6>Complete transaction by providing your <b>authorization code</b> in the box below</h6>
                <label style={{}}>
                Authorization Code:
                <input type="password" value={adminCode} style={{marginLeft:20,paddingLeft:5,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                </label>
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.toggleModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color={modalViewType === 'ENABLE' ? "primary":"danger"}
                onClick={()=> {
                  if(!adminCode){
                      NotificationManager.error(
                          "Authorization Code",
                          "Your security code required to perform this transaction. Thank you.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else if(adminCode !== this.props.user.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else {
                    this.handleSubmit(selectedUser);
                  }
                }}
            >
              {modalViewType === 'ENABLE' ? 'Enable':'Disable'} Now
            </Button>
            {/* <div className="loading" /> */}
            </ModalFooter>
        </Modal>

        <Modal
            isOpen={permissionModalOpen}
            toggle={this.togglePermissionModal}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.togglePermissionModal}>
                {/* <img style={{width:"50px",height:"50px",borderRadius:"25px",marginRight:"10px"}} src="/assets/img/profile-pic-l-4.jpg" alt="Avatar"/> */}
                <img style={{width:50,height:50,borderRadius:25,marginRight:10}} src={selectedUser.imageThumbnail ? `${img_base_url}${selectedUser.imageThumbnail}` : "/assets/img/no-image.jpg"} alt="img"/>

                <Label style={{}}>Update Staff Permissions</Label>
            </ModalHeader>
            <ModalBody>
                <h6 style={{marginBottom:10}}>Add / Remove permssions below</h6>

                <AsyncSelect
                    cacheOptions
                    isMulti
                    loadOptions={this.loadOptions}
                    defaultOptions
                    onChange={this.onChange}
                />
                
                <h6 style={{marginTop:20}}>Complete update by providing your <b>authorization code</b> in the box below</h6>
                <label style={{}}>
                    Authorization Code:
                <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                </label>
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.togglePermissionModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color={"primary"}
                onClick={()=> {
                  if(!adminCode){
                      NotificationManager.error(
                          "Authorization Code",
                          "Your security code required to perform this transaction. Thank you.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else if(adminCode !== this.props.user.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else {
                    this.handleChangePermission(selectedUser);
                  }
                }}
            >
              Update Now
            </Button>
            </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ menu, authUser }) => {
  const {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems
  } = menu;

  const { user } = authUser;

  return {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems,
    user
  };
};

export default connect(
  mapStateToProps,
  {
    setContainerClassnames,
    changeDefaultClassnames
  }
)(Admins);