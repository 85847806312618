
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import { auth } from '../../helpers/Firebase';
import axios from 'axios';

import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER
} from '../actions';

import {
    loginUserSuccess,
    registerUserSuccess
} from './actions';

const users_url = 'https://api.tuatuagye.com/users';

const loginWithEmailPasswordAsync = async (phone, code) =>
    await axios.get(`${users_url}?filter[where][and][0][type][lt]=4&filter[where][and][1][phone]=${phone}&filter[where][and][2][authCode]=${code}`)
        .then(res => (res.data && res.data[0]) ? res.data[0]: null)
        .catch(error => error);

    // await auth.signInWithEmailAndPassword(email, password)
    //     .then(authUser => authUser)
    //     .catch(error => error);



function* loginWithEmailPassword({ payload }) {
    const { phone, code } = payload.user;

    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, phone, code);

        if (loginUser && loginUser.id) {
            if(loginUser.enabled){
                localStorage.setItem('user_', JSON.stringify(loginUser));
                yield put(loginUserSuccess(loginUser));
                history.push('/');
            }
            else {
                if(window.unAuthorized){
                    window.unAuthorized();
                }
            }
        } else {
            // console.log('login failed :', loginUser);
            if(window.loginFailed){
                window.loginFailed();
            }
        }
    } catch (error) {
        console.log('login error : ', error)
        if(window.loginFailed){
            window.loginFailed();
        }
    }
}

// const registerWithEmailPasswordAsync = async (email, password) =>
//     await auth.createUserWithEmailAndPassword(email, password)
//         .then(authUser => authUser)
//         .catch(error => error);

// function* registerWithEmailPassword({ payload }) {
//     const { email, password } = payload.user;
//     const { history } = payload
//     try {
//         const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
//         if (!registerUser.message) {
//             localStorage.setItem('user_id', registerUser.user.uid);
//             yield put(registerUserSuccess(registerUser));
//             history.push('/')
//         } else {
//             console.log('register failed :', registerUser.message)
//         }
//     } catch (error) {
//         console.log('register error : ', error)
//     }
// }



const logoutAsync = async (history) => {
    // await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
}

function* logout({payload}) {
    const { history } = payload
    try {
        yield call(logoutAsync,history);
        localStorage.removeItem('user_');
    } catch (error) {
    }
}



// export function* watchRegisterUser() {
//     yield takeEvery(REGISTER_USER, registerWithEmailPassword);
// }

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        // fork(watchRegisterUser)
    ]);
}