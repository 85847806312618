import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import axios from 'axios';

// import "./helpers/Firebase";
import AppLocale from "./lang";
// import ColorSwitcher from "./components/common/ColorSwitcher";
import NotificationContainer from "./components/common/react-notifications/NotificationContainer";
import { NotificationManager } from "./components/common/react-notifications";

// import { isMultiColorActive } from "./constants/defaultValues";
import main from "./views";
import app from "./views/app";
import user from "./views/user";
import error from "./views/error";
require('dotenv').config();


const AuthRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/user/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

function sendToken(token) {
  axios.post('https://api.tuatuagye.com/register-push-token', { token, type: 3, platform: 'web' })
    // .then(res => {console.log('tkn',res.data)})
    // .catch(err=> console.log('err',err))
}

async function requestPermission() {
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    try {
      const token = await getToken(messaging, {
        vapidKey: 'BF-_HBVqDxVesvqECrgyfvL02iJEES4iVFuz5X-bAzzXpqUCBlrrJxTvhG5Tx-yVSqPj-7tlneCd5u1538lspwU',
      });
      
      //We can send token to server
      sendToken(token)
    } catch (error) {
      // console.log('fb error',error);
      NotificationManager.error(
        "Error",
        "A system notification processing error occured.",
        3000,
        null,
        null,
      );
    }
  } else if (permission === "denied") {
    //notifications are blocked
    // alert("You denied for the notification");
    NotificationManager.warning(
      "Warning",
      "You denied permission for notifications.",
      3000,
      null,
      null,
    );
  }
}

class App extends Component {
  componentDidMount() {
    requestPermission();
  }
  render() {
    const { locale, loginUser } = this.props;
    const currentAppLocale = AppLocale[locale];

    return (
      <div className="h-100">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <React.Fragment>
            <NotificationContainer />
            {/* {isMultiColorActive && <ColorSwitcher />} */}
            <Router>
              <Switch>
                <AuthRoute path="/app" authUser={loginUser} component={app} />
                <Route path="/user" component={user} />
                <Route path="/error" exact component={error} />
                <Route path="/" exact component={main} />
                <Redirect to="/error" />
              </Switch>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user: loginUser } = authUser;
  const { locale } = settings;
  return { loginUser, locale };
};
const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);
