import React, { Component } from "react";
import Select from "react-select";
import CustomSelectInput from "../components/common/CustomSelectInput";
import { Colxx } from "../components/common/CustomBootstrap";

const selectData = [
    { label: "Mobile TTG", value: "m_ttg", key: 1 },
    { label: "Mobile Cash", value: "m_cash", key: 2 },
    { label: "Mobile POD", value: "m_pod", key: 3 },
    { label: "Web TTG", value: "w_ttg", key: 4 },
    { label: "Web Cash", value: "w_cash", key: 5 },
    { label: "Web POD", value: "w_pod", key: 6 },
    { label: "POS TTG/CASH", value: "p_ttg", key: 7 },
    { label: "POS POD", value: "p_pod", key: 8 },
    { label: "POS SIGNUP", value: "p_signup", key: 9 },
    { label: "POS MOMO", value: "p_momo", key: 10 },
];

export default class SelectPaymentOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: []
    };
  }
  handleChangeMulti = selectedOptions => {
    this.setState({ selectedOptions });
    this.props.changeOptions(selectedOptions);
  };

  render() {
    return (
        <Colxx style={{minWidth:"160px",paddingRight:0}}>
            <Select
                components={{ Input: CustomSelectInput }}
                placeholder="Filter"
                className="react-select"
                classNamePrefix="react-select"
                closeMenuOnSelect={false}
                isMulti
                name="select-paymenttype-options"
                value={this.state.selectedOptions}
                onChange={this.handleChangeMulti}
                options={selectData}
            />
        </Colxx>
    );
  }
}
