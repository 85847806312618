import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle
} from "reactstrap";

import { ThemeColors } from '../../helpers/ThemeColors'

import {LineChart} from "../../components/charts"

import { getDays } from '../../helpers/Utils';

const colors = ThemeColors();

const LCD = {
  labels: [],
  datasets: [
    {
      label: '',
      borderColor: colors.themeColor1,
      pointBackgroundColor: colors.foregroundColor,
      pointBorderColor: colors.themeColor1,
      pointHoverBackgroundColor: colors.themeColor1,
      pointHoverBorderColor: colors.foregroundColor,
      pointRadius: 6,
      pointBorderWidth: 2,
      pointHoverRadius: 8,
      fill: false
    }
  ]
}

const SalesChartCard = ({ data }) => {
  const [loading, setLoading] = useState(true);

  useEffect(()=> {

    const _days = getDays(7);
    LCD.labels = _days;
    // LCD.datasets[0]['data'] = data;
    
    // console.log('lcd now...',LCD);
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {

    }
  }, []);

  return (
    <Card>
      {/* <div className="position-absolute card-top-buttons">
        <div className="btn btn-header-light icon-button">
          <i className="simple-icon-refresh" />
        </div>
      </div> */}
      <CardBody>
      {loading && <div className="loading"/>}
        <CardTitle>
          Past Week Sales (GHS)
        </CardTitle>
        <div className="dashboard-line-chart">
          <LineChart shadow data={{...LCD, datasets: [{...LCD.datasets[0], data }]}}/>
        </div>
      </CardBody>
    </Card>
  );
};

export default SalesChartCard;
