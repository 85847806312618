import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Modal, ModalBody, ModalHeader, Label, Button, ModalFooter, Input
   } from "reactstrap";
// import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { connect } from "react-redux";
import { NotificationManager } from "../../components/common/react-notifications";
import * as FileSaver from 'file-saver';

import ReactTable from "react-table";
import "react-datepicker/dist/react-datepicker.css";
import DataTablePagination from "../../components/DatatablePagination";
import SelectPaymentOption from "../../containers/SelectPaymentOption";
import axios from 'axios';

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import {
  setPaymentStartDate,
  setPaymentEndDate,
  setPayments
} from "../../redux/actions";
import { Link } from "react-router-dom";

const payments_url = 'https://api.tuatuagye.com/payments';

class Payments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtering: false,
      amount:'',
      adminCode: '',
      selectedPayment: {},
      searchKeyword: "",
      startDate: null,
      startDateTime: null,
      startDateRange: null,
      endDateRange: null,
      endDateTime: null,
      dateModal: false,
      dropdownOpen: false,
      paymentType: 1,
      addPaymentModalOpen: false,
      payments: [],
      totalPayments:0,
      changeOptions: [],
      processing: false,
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.timer = 0;

    this.columns = [
      {
        Header: "Customer",
        accessor: "payeeName",
        Cell: props => <div style={{alignItems:'center'}}>
          <img style={{width:40,height:40,borderRadius:20,marginRight:10}} src="/assets/img/no-image.jpg" alt="Img"/>
          <Link to={`/app/users/customers/${props.original.payeeId}`} style={{fontSize:16}}>{props.value}</Link>
        </div>
      },
      {
        Header: "Product",
        accessor: "productName",
        Cell: props => <p className="text-muted truncate">{props.value}</p>
      },
      {
        Header: "Amount",
        accessor: "amount",
        width:110,
        Cell: props => <p style={{color:'#008894'}}>₵ {this.formatAmount(props.value)}</p>
      },
      {
        Header: "Transaction ID",
        accessor: "transactionId",
        width:130,
        Cell: props => <p className="text-muted">{props.value}</p>
      },
      {
        Header: "Type",
        accessor: "type",
        width: 90,
        Cell: props => <p className="text-muted">
          {props.value === 1 ? 'MOB_TTG' : props.value === 2 ? 'MOB_CASH' : props.value === 3 ? 'MOB_POD' : props.value === 4 ? 'WEB_TTG' : props.value === 5 ? 'WEB_CASH' : props.value === 6 ? 'WEB_POD' : props.value === 7 ? 'POS_TTG' : props.value === 8 ? 'POS_POD' : props.value === 9 ? 'POS_SIGNUP' : props.value === 10 ? 'POS_MOMO': 'N/A'}
        </p>
      },
      {
        Header: "Date",
        accessor: "createdAt",
        width:170,
        Cell: props => <p className="text-muted">{this.formatDate(props.value)}</p>
      }
    ]

    // if(props.user.type === 1){
    //   this.columns.push({
    //     Header: "Actions",
    //     accessor: "",
    //     width: 65,
    //     // headerStyle: {textAlign :'center'},
    //     Cell: props => 
    //     // <div style={{alignItems:"center",display:'flex',justifyContent:'space-between'}}>
    //       <div
    //         onClick={()=> {
    //           this.setState({selectedPayment: props.original,adminCode:''});
    //           this.toggleNestedContainer();
    //         }}
    //         style={{cursor:'pointer',fontSize:17}}  
    //         className={"glyph-icon iconsminds-pen-2"} 
    //       />
    //     //   <div 
    //     //     onClick={()=> {
    //     //       this.setState({selectedPayment: props.original, adminCode:''});
    //     //       this.toggleDeleteModal();
    //     //     }}
    //     //     style={{color:'red',cursor:'pointer',fontSize:17}} 
    //     //     className={"glyph-icon simple-icon-trash ml-1"} 
    //     //   /> 
    //     // </div> 
    //   })
    // }
  }

  componentDidMount() {
    const _startDateTime = new Date();
    _startDateTime.setHours(0);
    _startDateTime.setMinutes(0);
    _startDateTime.setSeconds(0);

    const _endDateTime = new Date();
    _endDateTime.setHours(23);
    _endDateTime.setMinutes(59);
    _endDateTime.setSeconds(59);

    this.setState({startDateTime: _startDateTime, endDateTime: _endDateTime});
  }

  searchWithName = (searchText) => {
    const { user } = this.props;

    this.setState({filtering: true});
    let query = `filter[where][or][0][payeeName][regexp]=/${searchText}/i&filter[where][or][1][productName][regexp]=/${searchText}/i&filter[where][or][2][transactionId][regexp]=/${searchText}/i&filter[order]=createdAt%20DESC`
    
    if(user.type === 2){
      query += '&filter[where][and][0][type][gt]=6'
    }

    query += '&filter[limit]=30';

    console.log('query is',query)
    axios.get(`${payments_url}?${query}`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.props.setPayments(res.data)
          this.setState({filtering: false});
        }
        else {
          this.props.setPayments([])
          this.setState({filtering:false})
        }
      })
      .catch(error => {
        this.setState({filtering: false});
        // alert('Sorry, an unknown error occurred.');
        NotificationManager.error(
          "Searching error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  }

  getTotal = (list) => {
    let total = 0;
    list.forEach(p=> total += p.amount);
    return Number(total).toFixed(2);
  }

  handleChange(event) {
    this.setState({amount: event.target.value});
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  toggleNestedContainer = () => {
    this.setState(prevState => ({
      modalNestedContainer: !prevState.modalNestedContainer
    }));
  };

  toggleNested = () => {
    this.setState(prevState => ({
      nestedModal: !prevState.nestedModal,
      closeAll: false
    }));
  };

  toggleAll = () => {
    this.setState(prevState => ({
      nestedModal: !prevState.nestedModal,
      closeAll: true
    }));
  };

  toggleDateModal = () => {
    this.setState(prevState => ({
      dateModal: !prevState.dateModal,
    }));
  };
  
  toggleAddPayment = () => {
    this.setState(prevState => ({
      addPaymentModalOpen: !prevState.addPaymentModalOpen,
    }));
  }

  changeOptions = (changeOptions) => {
    this.setState({ changeOptions })

    const { selectionRange } = this.state;
    const startDateTime = selectionRange.startDate;
    startDateTime.setHours(0);
    startDateTime.setMinutes(0);
    startDateTime.setSeconds(0);

    const endDateTime = new Date(selectionRange.endDate);
    endDateTime.setHours(23);
    endDateTime.setMinutes(59);
    endDateTime.setSeconds(59);
    
    let query = '';
    if(changeOptions && changeOptions.length > 0){
      query = `filter[where][or][0][type]=${changeOptions[0].key}`;
      changeOptions.slice(1).forEach((type, index)=> {
        query += `&filter[where][or][${index+1}][type]=${type.key}`
      })
    }
    else {
      query = 'filter[where][type][gt]=0'
    }

    query += `&filter[where][and][0][createdAt][gte]=${startDateTime.toJSON()}&filter[where][and][1][createdAt][lte]=${endDateTime.toJSON()}&filter[order]=createdAt%20DESC`;

    console.log('Query is',query);

    this.setState({filtering: true});
    axios.get(`${payments_url}?${query}`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.props.setPayments(res.data);
          this.setState({filtering: false});
        }
        else {
          this.props.setPayments([]);
          this.setState({filtering: false});
        }
      })
      .catch(error => {
        this.setState({filtering: false});
        NotificationManager.error(
          "Filtering error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  }

  formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
      return temp;
    }
    
    return Number(amount).toFixed(2);
  }

  formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  refreshPayments = () => {
    const { selectionRange, changeOptions } = this.state;
    const startDateTime = selectionRange.startDate;
    startDateTime.setHours(0);
    startDateTime.setMinutes(0);
    startDateTime.setSeconds(0);

    const endDateTime = new Date(selectionRange.endDate);
    endDateTime.setHours(23);
    endDateTime.setMinutes(59);
    endDateTime.setSeconds(59);

    let query = '';
    if(changeOptions && changeOptions.length > 0){
      query = `filter[where][or][0][type]=${changeOptions[0].key}`;
      changeOptions.slice(1).forEach((type, index)=> {
        query += `&filter[where][or][${index+1}][type]=${type.key}`
      })
    }
    else {
      query = 'filter[where][type][gt]=0'
    }
    
    query += `&filter[where][and][0][createdAt][gte]=${startDateTime.toJSON()}&filter[where][and][1][createdAt][lte]=${endDateTime.toJSON()}&filter[order]=createdAt%20DESC`;

    // console.log('QUERY IS',query);

    this.setState({filtering: true});
    axios.get(`${payments_url}?${query}`)
      .then(res => {
        if(res.data && res.data.length > 0){
          let _totalPayments = 0;
          res.data.forEach(payment => _totalPayments += payment.amount);
          this.props.setPayments(res.data);
          this.setState({filtering: false});
        }
        else {
          this.props.setPayments([]);
          this.setState({filtering:false});
        }
      })
      .catch(error => {
        this.setState({filtering: false});
        NotificationManager.error(
          "Searching error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
        console.log('error...',error);
      })
  }

  handlePaymentEdit = (payment) => {
    // this.toggleAll
    alert('Sorry, this feature is still under development');
  }

  handlePaymentDelete = (payment) => {

  }

  handleSelect = (ranges) => {
		this.setState({selectionRange: {
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
			key: 'selection',
		}})
	}

  __formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    let yr = `${date.getFullYear()}`.slice(2);
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}, ${yr}`;
  }

  exportToExcel() {
    const data = this.props.entities.map(record=> {
      return {
        Customer: record.payeeName,
        Product: record.productName,
        Amount: record.amount,
        Type: record.type === 1 ? 'MOB_TTG' : record.type === 2 ? 'MOB_CASH' : record.type === 3 ? 'MOB_POD' : record.type === 4 ? 'WEB_TTG' : record.type === 5 ? 'WEB_CASH' : record.type === 6 ? 'WEB_POD' : record.type === 7 ? 'POS_TTG' : record.type === 8 ? 'POS_POD' : record.type === 9 ? 'POS_SIGNUP' : record.type === 10 ? 'POS_MOMO': 'N/A',
        'Trasaction ID': record.transactionId,
        'MoMo Number': record.momoNumber,
        Network: record.mode,
        Z_Token: record.payToken,
        Date: this.__formatDate(record.createdAt)
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `Payments Data - ${new Date().toJSON()}`);
    });
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  render() {
    let { loading, entities, user } = this.props;
    const { selectedPayment, selectionRange,amount,processing,adminCode,searchKeyword, startDateTime, filtering, endDateTime } = this.state;
    const { startDate, endDate } = selectionRange;
    return (
            <Fragment>
            <Row>
              <Colxx xxs="12">
                <Row style={{justifyContent:'space-between',alignItems:'center',marginLeft:0,marginRight:0}}>
                  <h3>Payments | GHS <span style={{color:'#008894'}}>{this.formatAmount(this.getTotal(entities))}</span></h3>
                  <div style={{display:'flex', marginBottom:10}}>
                    <div className="payments-search">
                      <Input
                        style={{backgroundColor:'#fff'}}
                        name=""
                        placeholder={"Search"}
                        value={searchKeyword}
                        onChange={e => {
                          let value = e.target.value;
                          this.setState({searchKeyword: value})
                          clearTimeout(this.timer);
                          this.timer = setTimeout(() => {
                            this.searchWithName(value);
                          }, 1000);
                          // this.handleSearchInputChange(e)
                        }}
                        // onKeyPress={e => this.handleSearchInputKeyPress(e)}
                      />
                      <span
                          className="search-icon"
                          style={{top:10}}>
                          <i className="simple-icon-magnifier" />
                      </span>
                    </div>

                    {/* <div onClick={this.toggleDateModal} style={{padding:10,paddingBottom:8,borderRadius:3,marginLeft:"10px",color:'#fff',backgroundColor:'rgb(0, 136, 148)',cursor:'pointer'}}>
                      {startDate.toDateString() == endDate.toDateString() ? startDate.toDateString() : `${startDate.toDateString()} - ${endDate.toDateString()}`}
                    </div> */}

                    <div onClick={this.toggleDateModal} style={{alignItems:'center',justifyContent:'center',display:'flex',paddingRight:10,paddingLeft:10,borderRadius:3,marginLeft:"10px",color:'#fff',backgroundColor:'rgb(0, 136, 148)',cursor:'pointer'}}>
                      {startDate.toDateString() == endDate.toDateString() ? startDate.toDateString() : `${startDate.toDateString()} - ${endDate.toDateString()}`}
                    </div>

                    {/* {user.type === 1 &&  */}
                      <SelectPaymentOption
                        changeOptions={this.changeOptions}
                      />
                    {/* } */}

                    <div
                      onClick={this.refreshPayments}
                      style={{cursor:'pointer',fontSize:18,color:'#000',marginLeft:15,marginTop:11}}  
                      className={"simple-icon-refresh"} 
                    />
                  </div>
                </Row>
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card>
                  <CardBody>
                    {filtering && <div className='loading'/>}
                    {loading && <span>Loading...</span>}
                    <ReactTable
                      data={entities}
                      columns={this.columns}
                      defaultPageSize={50}
                      showPageJump={false}
                      showPageSizeOptions={false}
                      PaginationComponent={DataTablePagination}
                      className={"react-table-fixed-height"}
                    />

                    <div
                      onClick={()=> {
                        this.exportToExcel();
                      }} 
                      className="d-flex justify-content-end" style={{flex:1,cursor:"pointer"}}>
                      Download to Excel 
                      <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>

            {/* EDIT MODAL */}
            <Modal
              isOpen={this.state.modalNestedContainer}
              toggle={this.toggleNestedContainer}
              className={this.props.className}
            >
              {processing && <div style={{position:'absolute',top:0,bottom:0,left:0,right:0,zIndex:99,backgroundColor:'rgba(200,200,200,.6)'}}>
                  <div className='loading'/>
              </div>}
              <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleNestedContainer}>
                {/* <img style={{width:"60px",height:"60px",borderRadius:"30px",marginRight:"10px"}} src="/assets/img/profile-pic-l-4.jpg" alt="Avatar"/> */}
                <Label>
                  Edit {selectedPayment.payeeName}'s Payment 
                </Label>
              </ModalHeader>
              <ModalBody>
                <h6>Editing <b>GH₵ {this.formatAmount(selectedPayment.amount)}</b>, payment made at <b>{this.formatDate(selectedPayment.createdAt)}</b></h6>
                <Row style={{paddingLeft:16,marginTop:20}}>
                  <label>
                    Amount:
                    <input type="number" value={amount} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({amount: event.target.value})} />
                  </label>

                  <label style={{marginLeft:20}}>
                    Authorization Code:
                    <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                  </label>
                </Row>
                <Modal
                  isOpen={this.state.nestedModal}
                  toggle={this.toggleNested}
                  onClosed={
                    this.state.closeAll
                      ? this.toggleNestedContainer
                      : undefined
                  }
                >
                  <ModalHeader>Confirm Payment Edit</ModalHeader>
                  <ModalBody>
                    <h5>Are you sure you want to change payment of <b>GH₵ {this.formatAmount(selectedPayment.amount)}</b> to an amount of <b>GH₵ {this.formatAmount(amount)}?</b></h5>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="default" onClick={this.toggleNested}>
                      Cancel
                    </Button>{" "}
                    <Button color="primary" onClick={this.handlePaymentEdit}>
                      Yes, Change Payment
                    </Button>
                  </ModalFooter>
                </Modal>
              </ModalBody>
              <ModalFooter>
              <Button
                  color="default"
                  onClick={this.toggleNestedContainer}
                >
                  Cancel
                </Button>{" "}
                <Button
                  color="primary"
                  onClick={()=> {
                    if(Number(this.state.amount) < 1){
                      NotificationManager.error(
                        "Amount required",
                        "What's the new amount for the update?",
                        3000,
                        null,
                        null,
                      );
                    }
                    else if(!adminCode){
                      NotificationManager.error(
                        "Missing Authorization Code",
                        "You need to provide your security code to proceed with this edit.",
                        3000,
                        null,
                        null,
                      );
                    }
                    else if(adminCode !== user.authCode){
                      NotificationManager.error(
                        "Invalide Code",
                        "The code you provided is invalid. Kindly check and try again.",
                        3000,
                        null,
                        null,
                      );
                    }
                    else {
                      alert('Sorry, this feature is still under development')
                      // this.toggleNested();
                    }
                  }}
                >
                  Update
                </Button>
                
              </ModalFooter>
            </Modal>

            {/* DATE MODAL */}

            <Modal
              isOpen={this.state.dateModal}
              toggle={this.toggleDateModal}
              className={'date-modal'} 
            >
              <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleDateModal}>
                <Label>Set Date Range</Label>
              </ModalHeader>
              <ModalBody>
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={this.handleSelect}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="default"
                  onClick={this.toggleDateModal}
                >
                  Cancel
                </Button>{" "}
                
                <Button
                  color="success"
                  onClick={()=> {
                    this.refreshPayments();
                    this.setState({loading: true});
                    this.toggleDateModal();
                  }}
                >
                  Filter Now
                </Button>
                
              </ModalFooter>
            </Modal>
          </Fragment>
        )
    }
}

const mapStateToProps = ({ payments, authUser }) => {
  const {
    entities,
    loading
  } = payments;
  const { user } = authUser;
  return {entities, loading, user};
};

export default connect(
  mapStateToProps,
  {
    setPaymentStartDate,
    setPaymentEndDate,
    setPayments
  }
)(Payments);