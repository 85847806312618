import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Modal, ModalHeader, Label, ModalBody, ModalFooter, Button } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { DateRangePicker } from 'react-date-range';

import "react-datepicker/dist/react-datepicker.css";
import DataTablePagination from "../../components/DatatablePagination";

import axios from 'axios';
import { NotificationManager } from "../../components/common/react-notifications";

import ReactTable from "react-table";
import * as FileSaver from 'file-saver';
// import LocationsAutoSuggest from "../../containers/LocationAutoSuggest";


const cashsales_url = 'https://api.tuatuagye.com/cash-sales'

export default class MerchantCashSales extends Component {
  constructor(props){
    super(props);

    this.state = {
      cashsales: [],
      totalAmount: 0,
      date: '',
      loading: true,
      mapCoordinates: [],
      merchant: null,
      defaultPageSize: 50,
      startDate: null,
      startDateTime: null,
      startDateRange: null,
      endDateRange: null,
      endDateTime: null,
      dateModal: false,
      name:'',
      merchantId: '',
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    }

    this.columns = [
        {
            Header: "Customer",
            accessor: "customerName",
            Cell: props => <p className="list-item-heading">{props.value}</p>
        },
        {
            Header: "Phone",
            accessor: "customerPhone",
            Cell: props => <p className="text-muted">{props.value}</p>
        },
        {
            Header: "Product",
            accessor: "productName",
            // width: 110,
            Cell: props => <p className="text-muted">{props.value}</p>
        },
        {
            Header: "Amount",
            accessor: "amount",
            width:120,
            Cell: props => <p className="text-muted">GH₵ {this.formatAmount(props.value)}</p>
        },
        {
          Header: "Location",
          accessor: "customerLocation",
          Cell: props => <p className="text-muted">{props.value}</p>
        },
        {
            Header: "Date",
            accessor: "createdAt",
            width:170,
            Cell: props => <p className="text-muted">{this.formatDate(props.value)}</p>
        }
    ]
    
  }

  componentDidMount() {
    const splits = window.location.href.split('=');
    let mid = splits[1];
    let dt = splits[2];
    dt = dt.slice(0,dt.length-5);
    let name = splits[3];
    name = name.replace('-',' ')
    name = name.replace('%20','');

    this.setState({ name, merchantId: mid })
    mid = mid.slice(0,mid.length-6)
    if(dt){
        dt = dt.split('T')[0];
    }

    let newDate;
    if(dt){
        newDate = new Date(dt);
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        this.setState({
            selectionRange: {
                ...this.state.selectionRange,
                startDate: newDate
            }
        })
    }
    

    this.loadData(mid, newDate);
  }

  loadData(id, dt, locationId) {
    let query = '';
    if(id){
        if(dt){
            // if(locationId){
            //     query = `filter[where][and][0][merchantId]=${id}&filter[where][and][1][createdAt][gte]=${dt.toJSON()}&filter[where][and][2][locationId]=${locationId}&filter[order]=createdAt desc`;
            // }
            // else {
                query = `filter[where][and][0][merchantId]=${id}&filter[where][and][1][createdAt][gte]=${dt.toJSON()}&filter[order]=createdAt desc`;
            // }
        }
        else {
            // if(locationId){
            //     query = `filter[where][and][0][merchantId]=${id}&filter[where][and][1][locationId]=${locationId}&filter[order]=createdAt desc`;
            // }
            // else {
                query = `filter[where][and][0][merchantId]=${id}&filter[order]=createdAt desc`;
            // }
        }
    }
    else {
        this.setState({loading:false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
        return;
    }

    axios.get(`${cashsales_url}?${query}`)
      .then(res=> {
        if(res.data && res.data.length > 0){
          let total = 0;
          res.data.forEach(d=> total += d.payment.amount);
          this.setState({totalAmount: total})
        }
        else this.setState({totalAmount: 0})
        this.setState({cashsales: res.data, loading: false});
      })
      .catch(error=> {
        console.log('error...',error);
        this.setState({loading:false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
      })
  }

//   locationChange = (loc) => {
//     const locationId = !loc ? '' : loc.id;

//     const { merchantId, selectionRange } = this.state;
//     this.loadData(merchantId, selectionRange.startDate, locationId);
//   }

  handleChangeDate = date => {
    this.setState({date: date});
    const { merchant } = this.state;
    this.setState({loading: true});
    let query = '';
    if(merchant){
      query = `filter[where][and][0][merchantId]=${merchant.id}&filter[where][and][1][createdAt][gte]=${date.toJSON()}`
    }
    else {
      query = `filter[where][createdAt][gte]=${date.toJSON()}`
    }

    axios.get(`${cashsales_url}?${query}`)
      .then(res=> {
        if(res.data && res.data.length > 0){
          let total = 0;
          res.data.forEach(d=> total += d.amount);
          this.setState({totalAmount: total})
        }
        else this.setState({totalAmount: 0})

        this.setState({cashsales: res.data, loading: false});
      })
      .catch(error=> {
        console.log('error...',error);
        this.setState({loading:false});
        NotificationManager.error(
          "Loading error!",
          "An unknown error occurred. Please refresh page.",
          3000,
          null,
          null,
        );
      })
  };

  formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
      return temp;
    }
    
    return Number(amount).toFixed(2);
  }

    exportToExcel() {
        if(this.state.cashsales.length == 0) return;

        const data = this.state.cashsales.map(record=> {
            return {
                Customer: record.customerName,
                Phone: record.customerPhone,
                Product: record.productName,
                Amount: this.formatAmount(record.amount),
                Location: record.customerLocation,
                'Date & Time': this.formatDate(record.createdAt)
            }
        })
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, `Merchant Cash Sales Data - ${new Date().toJSON()}`);
        });
  }

  saveAsExcelFile(buffer, fileName) {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  toggleDateModal = () => {
    this.setState(prevState => ({
      dateModal: !prevState.dateModal,
    }));
  };

    handleSelect = (ranges) => {
		this.setState({selectionRange: {
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
			key: 'selection',
		}})
	}

  render() {
    const { loading, cashsales, selectionRange, totalAmount, defaultPageSize, name } = this.state;
    const { startDate, endDate } = selectionRange;

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Row style={{justifyContent:'space-between',alignItems:'center',marginRight:0,marginLeft:0,marginBottom:10}}>
              <h3 style={{marginBottom:0}}>{name}'s cash-sales ({cashsales.length}) | Total {this.formatAmount(totalAmount)}</h3>
              <div style={{display:'flex'}}>
                <div onClick={this.toggleDateModal} style={{alignItems:'center',justifyContent:'center',display:'flex',paddingRight:10,paddingLeft:10,borderRadius:3,marginLeft:"10px",color:'#fff',backgroundColor:'rgb(0, 136, 148)',cursor:'pointer',padding:7}}>
                  {startDate.toDateString() == endDate.toDateString() ? startDate.toDateString() : `${startDate.toDateString()} - ${endDate.toDateString()}`}
                </div>
                {/* <div style={{width:"100px"}}>
                  <DatePicker
                    selected={this.state.date}
                    onChange={this.handleChangeDate}
                    placeholderText="Date"
                    // showTimeSelect
                    // timeFormat="HH:mm"
                    timeIntervals={15}
                    // dateFormat="Pp"
                    // timeCaption="Time"
                  />
                </div> */}

                {/* <div style={{width:260,marginLeft:10}}>
                  <SelectMerchantOption
                    onChange={this.changeOptions}
                  />
                </div> */}
                {/* <div style={{minWidth:"200px",marginLeft:10}}>
                  <LocationsAutoSuggest onChange={this.locationChange} />
                </div> */}
              </div>
            </Row>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card>
              <CardBody>
                {loading && <div className='loading'/>}
                <ReactTable
                    data={cashsales}
                    columns={this.columns}
                    defaultPageSize={defaultPageSize}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    className={"react-table-fixed-height"}
                />
                <div
                  onClick={()=> {
                    this.exportToExcel();
                  }} 
                  className="d-flex justify-content-end" style={{flex:1,cursor:"pointer"}}>
                  Download to Excel 
                  <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        {/* DATE MODAL */}

        <Modal
          isOpen={this.state.dateModal}
          toggle={this.toggleDateModal}
          className={'date-modal'} 
        >
          <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleDateModal}>
            <Label>Set Date Range</Label>
          </ModalHeader>
          <ModalBody>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={this.handleSelect}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              onClick={this.toggleDateModal}
            >
              Cancel
            </Button>{" "}
            
            <Button
              color="success"
              onClick={()=> {
                this.loadData(this.state.merchantId, this.state.selectionRange.startDate, '');
                this.setState({loading: true});
                this.toggleDateModal();
              }}
            >
              Filter Now
            </Button>
            
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}