import React, { Component, Fragment } from "react";
import {
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  // Button,
  TabContent,
  TabPane,
  Modal,
    ModalHeader,
    ModalBody, ModalFooter, Button,
    Label, Input
} from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import axios from 'axios';
import { NotificationManager } from "../../../components/common/react-notifications";
import { connect } from "react-redux";

import classnames from "classnames";
import Resizer from "react-image-file-resizer";

import { Colxx } from "../../../components/common/CustomBootstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import RecentPayments from "../../../containers/users/RecentPayments";
import RecentMessages from "../../../containers/users/RecentMessages";
import Products from '../../../containers/users/Products';

const users_url = 'https://api.tuatuagye.com/users';
const messages_url = 'https://api.tuatuagye.com/messages'
const img_base_url = 'https://api.tuatuagye.com/get-image?type=profile&id='
const myProducts_url = 'https://api.tuatuagye.com/m-products';
const logs_url = 'https://api.tuatuagye.com/logs'
const set_alt_url = 'https://api.tuatuagye.com/add-alt-number';
const image_upload_url = 'https://api.tuatuagye.com/upload-profile-images';

const resizeFile = (file, size) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    size,
    size,
    "JPEG",
    90,
    0,
    (uri) => {
      resolve(uri);
    },
    "file"
  );
});


class CustomerDetails extends Component {
  constructor(props) {
    super(props);

    this.toggleTab = this.toggleTab.bind(this);
    this.state = {
      activeFirstTab: "1",
      loadingDetails: true,
      details: null,
      products: [],
      messages:[],
      addAltNumber: false,
      resetTTGBalance: false,
      updateName: false,
      firstname:'',
      lastname: '',
      resetNumber: false,
      adding: false,
      altNumber: '',
      newNumber: '',
      amount: '',
      productImageModalOpen:false,
      fullscreenImageModalOpen: false,
      processing: false,
      fileUrl:'',
      file:null,
      data:null
    };

    this.inp = null;
  }

  componentDidMount() {
    // console.log('path...',this.props.location.pathname);

    const id = this.props.location.pathname.split('/').pop();
    axios.get(`${users_url}/${id}`)
      .then(res => {
        if(res.data){
          this.setState({details: res.data, loadingDetails: false});
          return axios.get(`${myProducts_url}?filter[where][userId]=${id}&filter[include][]=product&filter[order]=createdAt%20DESC`)
        }
        else {
          this.setState({loadingDetails:false})
          return null;
        }
      })
      .then(res => {
        if(res && res.data){
          this.setState({products: res.data});
        }
        return axios.get(`${messages_url}?filter[where][or][0][senderId]=${id}&filter[where][or][1][receiverId]=${id}&filter[limit]=7&filter[order]=timestamp%20DESC`)
      })
      .then(res => {
        if(res && res.data){
          this.setState({messages: res.data});
        }
      })
      .catch(error => {
        this.setState({loadingDetails: false});
        alert('Sorry, an unknown error occurred.');
        console.log('error...',error);
      })
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }

  formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
      return temp;
    }
    
    return Number(amount).toFixed(2);
  }

  handleSetAltNumber = () => {
    this.setState({adding: true});

    let payload = {
      altNumber: this.state.altNumber
    }

    if(this.state.details){
      payload.id = this.state.details.id;
    }

    // console.log('pl',payload);

    axios.post(`${set_alt_url}`,payload)
      .then(res => {
        if(res && res.data){
          if(res.data.success){
            this.setState({adding: false, addAltNumber: false,details: Object.assign({}, this.state.details, {altNumber: this.state.altNumber})});
            NotificationManager.success(
              "ALT Setup",
              "You have successfully setup a new ALT number",
              3000,
              null,
              null,
            );
          }
          else {
            NotificationManager.error(
              "Setup Failed",
              res.data.message,
              3000,
              null,
              null,
            );

            this.setState({adding: false});
          }
        }
      })
      .catch(error => {
        this.setState({adding: false});
        
        console.log('error...',error);
        if(error.message){
          NotificationManager.error(
            "Setup Failed",
            error.message,
            3000,
            null,
            null,
          );
        }
        else {
          NotificationManager.error(
            "Setup Failed",
            "An unknown error happened.",
            3000,
            null,
            null,
          );
        }
      })
  }

  toggleAltNumberModal = () => {
    this.setState({adminCode:'', altNumber:'', addAltNumber: !this.state.addAltNumber});
  }

  handleBalanceReset = () => {
    const { amount, details } = this.state;
    this.setState({adding: true});

    let payload = {
      ttgCash: !amount ? 0 : Number(amount)
    }

    const id = this.state.details.id;
    const url = `${users_url}/${id}`;
    // console.log('pl',payload,url);

    const pll = {
      label: `${this.props.admin.firstname} ${this.props.admin.lastname} updated a customer's TTG Balance from (${details.ttgCash}) to ${payload.ttgCash}`,
      color: 'green'
    }

    axios.patch(url,payload)
      .then(res => {
          if(res.status == 204){
            this.setState({adding: false, resetTTGBalance: false,details: Object.assign({}, this.state.details, {ttgCash: payload.ttgCash})});
            NotificationManager.success(
              "Balance Reset",
              "You have successfully reset the TTG Balance for this custormer",
              3000,
              null,
              null,
            );

            axios.post(logs_url,pll)
          }
          else {
            NotificationManager.error(
              "Reset Failed",
              res.data.message,
              3000,
              null,
              null,
            );

            this.setState({adding: false});
          }
      })
      .catch(error => {
        this.setState({adding: false});
        
        // console.log('error...',error);
        if(error.message){
          NotificationManager.error(
            "Reset Failed",
            error.message,
            3000,
            null,
            null,
          );
        }
        else {
          NotificationManager.error(
            "Reset Failed",
            "An unknown error happened.",
            3000,
            null,
            null,
          );
        }
      })
  }

  handleNameChange = () => {
    const { firstname, lastname, details } = this.state;
    this.setState({adding: true});

    let payload = { firstname, lastname };

    const id = this.state.details.id;
    const url = `${users_url}/${id}`;
    // console.log('pl',payload,url);
    
    axios.patch(url,payload)
      .then(res => {
          if(res.status == 204){
            this.setState({adding: false, updateName: false,details: Object.assign({}, this.state.details, { firstname, lastname })});
            NotificationManager.success(
              "Name Change",
              "You have successfully updated the name of this custormer",
              3000,
              null,
              null,
            );

            const pll = {
              label: `${this.props.admin.firstname} ${this.props.admin.lastname} changed a customer name from (${details.firstname} ${details.lastname}) to ${firstname} ${lastname}`,
              color: 'green'
            }

            axios.post(logs_url,pll)
          }
          else {
            NotificationManager.error(
              "Update Failed",
              res.data.message,
              3000,
              null,
              null,
            );

            this.setState({adding: false});
          }
      })
      .catch(error => {
        this.setState({adding: false});
        
        // console.log('error...',error);
        if(error.message){
          NotificationManager.error(
            "Update Failed",
            error.message,
            3000,
            null,
            null,
          );
        }
        else {
          NotificationManager.error(
            "Update Failed",
            "An unknown error happened.",
            3000,
            null,
            null,
          );
        }
      })
  }

  handleNumberReset = () => {
    this.setState({adding: true});
    const  { newNumber, details } = this.state;
    let payload = {
      phone: newNumber
    }

    const id = this.state.details.id;
    const url = `${users_url}/${id}`;
    // console.log('pl',payload,url);

    const pll = {
      label: `${this.props.admin.firstname} ${this.props.admin.lastname} changed a customer's Phone Number from (${details.phone}) to ${newNumber}`,
      color: 'green'
    }

    axios.patch(url,payload)
      .then(res => {
          if(res.status == 204){
            this.setState({adding: false, resetNumber: false,details: Object.assign({}, this.state.details, {phone: newNumber})});
            NotificationManager.success(
              "Number Reset",
              "You have successfully reset the phone number for this custormer",
              3000,
              null,
              null,
            );

            axios.post(logs_url,pll)
          }
          else {
            NotificationManager.error(
              "Reset Failed",
              res.data.message,
              3000,
              null,
              null,
            );

            this.setState({adding: false});
          }
      })
      .catch(error => {
        this.setState({adding: false});
        
        console.log('error...',error);
        if(error.message){
          NotificationManager.error(
            "Reset Failed",
            error.message,
            3000,
            null,
            null,
          );
        }
        else {
          NotificationManager.error(
            "Reset Failed",
            "An unknown error happened.",
            3000,
            null,
            null,
          );
        }
      })
  }

  toggleBalanceModal = () => {
    this.setState({adminCode:'', resetTTGBalance: !this.state.resetTTGBalance});
  }

  toggleNameModal = () => {
    this.setState({adminCode:'', firstname: this.state.details.firstname, lastname: this.state.details.lastname, updateName: !this.state.updateName});
  }

  toggleNumberModal = () => {
    this.setState({adminCode:'', resetNumber: !this.state.resetNumber});
  }

  updateProduct = (update) => {
    const temp = this.state.products.map(p=> {
      if(p.id === update.id){
       const temp = {...p, ...update};
       return temp; 
      }
      
      return p;
    });

    this.setState({products: temp});
  }

  deleteProduct = (myProductId) => {
    const temp = this.state.products.map((p,i)=> {
      if(p.id == myProductId){
        p.status = 9;
      }
      return p;
    })
    
    this.setState({products: temp});
  }

  toggleFullImageModal = () => {
    this.setState(prevState => ({
      productImageModalOpen: !prevState.productImageModalOpen
    }))
  }

  handleImageUpload = async () => {
    this.setState({processing:true});

    const { file, details } = this.state;
    const image = await resizeFile(file, 400);
    // console.log('image is',image);
    const data = new FormData();
    
    data.append('images', image);

    let newImage = '';

    axios.post(image_upload_url,data)
      .then(res=> {
        if(res.data.ids && res.data.ids[0]){
          newImage = res.data.ids[0];

          let payload = {
            imageThumbnail: newImage
          }
      
          const id = this.state.details.id;
          const url = `${users_url}/${id}`;
      
          return axios.patch(url,payload)
        }
        
        return null;
      })
      .then(res => {
        if(res == null) {
          throw Error('Failed to upload image file.')
        }
        
        if(res.status == 204){
          this.setState({processing: false, productImageModalOpen: false, details: Object.assign({}, this.state.details, {imageThumbnail: newImage})});
          NotificationManager.success(
            "Profile Image Reset",
            "You have successfully reset the profile image for this custormer",
            3000,
            null,
            null,
          );

          const pll = {
            label: `${this.props.admin.firstname} ${this.props.admin.lastname} updated ${details.firstname} ${details.lastname}'s profile image.`,
            color: 'green'
          }
          axios.post(logs_url,pll)
        }
        else {
          NotificationManager.error(
            "Reset Failed",
            res.data.message,
            3000,
            null,
            null,
          );

          this.setState({processing: false});
        }
    })
    .catch(error => {
      this.setState({adding: false});
      
      // console.log('error...',error);
      if(error.message){
        NotificationManager.error(
          "Reset Failed",
          error.message,
          3000,
          null,
          null,
        );
      }
      else {
        NotificationManager.error(
          "Reset Failed",
          "An unknown error happened.",
          3000,
          null,
          null,
        );
      }
    })
      // .then(res=> {
      //   if(res){
      //     // console.log('update successful');
      //     // this.setState({data: {...this.state.data, images: existingImages}})
      //   }
      //   this.setState({processing:false});
      //   this.toggleFullImageModal();
      // })
      // .catch(error=> {
      //   console.log('error',error);
      //   this.setState({processing:false});
      //   NotificationManager.error(
      //     "Upload Error",
      //     "An unknown error occurred. Please check console for details or try again.",
      //     3000,
      //     null,
      //     null,
      //   );
      // })
  }

  imageSelected = (event) => {
    // console.log('file is ',event.target.files[0]);;
    this.setState({file: event.target.files[0]})
    const reader = new FileReader();

    const _func = (e) => this.setState({fileUrl: e.target.result}, () => this.toggleFullImageModal())
    
    reader.onload = function (e) {
      _func(e)
    }

    reader.readAsDataURL(event.target.files[0]);
  }

  render() {
    const { details, loadingDetails, processing, productImageModalOpen, fileUrl, messages, products } = this.state;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <h1>Customer Details</h1>
            <div style={{paddingTop:10}} className="float-sm-right mb-2">
                
                {/* <NavLink to={`/app/applications/chat?ref=${details ? details.id : ''}`}>
                  <Button outline color={"primary"} className="icon-button">
                      <i className="simple-icon-speech" />
                  </Button>
                </NavLink> */}
            </div>

            <Nav tabs className="separator-tabs ml-0 mb-5">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "1",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("1");
                  }}
                  to="#"
                >
                  <IntlMessages id="pages.details" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "2",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                  to="#"
                >
                  PRODUCTS
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeFirstTab}>
              <TabPane tabId="1">
                {loadingDetails && <div className='loading'/>}
                {details && 
                  <Row>
                    <Colxx xxs="12" lg="3" className="mb-4">
                      <Card className="mb-4">
                          <img
                              src={details.imageThumbnail ? `${img_base_url}${details.imageThumbnail}` : "/assets/img/no-profile-image.jpeg"}
                              alt="Avatar"
                              className="card-img-top"
                              onDoubleClick={()=> this.inp.click()}
                          />

                          <input type='file' accept="image/jpeg, image/jpg, image/png" ref={(inp)=> this.inp = inp} onChange={this.imageSelected} name='thumbnail' hidden={true} />

                          <CardBody>
                              <p className="text-muted text-small mb-2" onDoubleClick={this.toggleNameModal}>
                              Name
                              </p>
                              <h6 className="mb-3">
                                {details.firstname} {details.lastname}
                              </h6>

                              <p className="text-muted text-small mb-2" onDoubleClick={this.toggleNumberModal}>
                              Phone
                              </p>
                              <h6 className="mb-3">
                                {details.phone}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              Alternative Number
                              </p>
                              <h6 className="mb-3">
                                {details.altNumber || 'N/A'}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              Email
                              </p>
                              <h6 className="mb-3">
                                {details.email || 'N/A'}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              Location
                              </p>
                              <h6 className="mb-3">
                                {details.location || 'N/A'}
                              </h6>

                              {/* <p className="text-muted text-small mb-2">
                              Last Seen
                              </p>
                              <h6 className="mb-3" style={{color: details.lastSeen === 'Online' ? '#2ECC71':'#4d4d4d'}}>
                                {this.formatDate(details.lastSeen)}
                              </h6> */}

                              <p className="text-muted text-small mb-2">
                              Type
                              </p>
                              <h6 className="mb-3">
                                {details.type === 5 ? 'SELF-SERVICE':'POS'}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              Date Joined
                              </p>
                              <h6 className="mb-3">
                                {this.formatDate(details.createdAt)}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              Status
                              </p>
                              <h6 className="mb-3" style={{color: details.enabled ? '#2ECC71':'#E74C3C'}}>
                                {details.enabled ? 'ENABLED':'DISABLED'}
                              </h6>

                              <p onDoubleClick={this.toggleBalanceModal} className="text-muted text-small mt-2 mb-2">
                              TTG Cash Bal.
                              </p>
                              <h6 className="mb-3" style={{color:'#008894'}}>
                              GH₵ {this.formatAmount(details.ttgCash)}
                              </h6>

                              {this.props.admin && this.props.admin.type === 1 && (
                                <Button
                                  disabled={this.state.adding}
                                  color="default"
                                  onClick={this.toggleAltNumberModal}
                                >
                                  Set ALT Number
                                </Button>
                              )}
                          </CardBody>
                      </Card>
                    </Colxx>

                    <Colxx xxs="12" lg="9">
                      {details && details.id && <RecentPayments className="mb-4" user={details} controls={false} />}
                      {details && details.id && details.type === 5 && <RecentMessages data={messages} className="mb-4" displayRate={true} />}
                    </Colxx>
                  </Row>
                }
              </TabPane>
              <TabPane tabId="2">
                <Products data={products} user={details} updateProduct={this.updateProduct} deleteProduct={this.deleteProduct}/>
              </TabPane>
            </TabContent>
          </Colxx>

          {/* ADD ALT NUMBER */}
          {this.state.addAltNumber && (<Modal
            isOpen={this.state.addAltNumber}
            toggle={()=> {
              if(!this.state.adding){
                this.toggleAltNumberModal();
              }
            }}
          >
            <ModalHeader 
              toggle={()=> {
                if(!this.state.adding){
                  this.toggleAltNumberModal();
                }
              }}
              style={{alignItems:"center",padding:""}} 
            >
              <Label>
                Set ALT number for {details.firstname}'s Account 
              </Label>
            </ModalHeader>
            <ModalBody>
              <h6>You can setup an alternative number that this user can use to make MoMo payment here.</h6>
              <div style={{marginTop:20}}>
                <label>
                  Number:
                  <input disabled={this.state.adding} value={this.state.altNumber} style={{paddingLeft:10,paddingBottom:10,paddingTop:10,marginLeft:10,outline:'none',width:150}} onChange={(event)=> {event.target.value.length < 11 && this.setState({altNumber: event.target.value})}}/>
                </label>

                <label style={{marginLeft:20}}>
                  Auth. Code:
                  <input disabled={this.state.adding} type="password" value={this.state.adminCode} style={{marginLeft:10,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})}/>
                </label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                disabled={this.state.adding}
                color="default"
                onClick={this.toggleAltNumberModal}
              >
                Cancel
              </Button>{" "}
              <Button
                disabled={this.state.adding}
                color="danger"
                onClick={()=> {
                  if(this.state.altNumber.length < 10){
                    NotificationManager.error(
                      "ALT Number",
                      "The new phone number should be 10 digits. Thank you.",
                      3000,
                      null,
                      null,
                  );
                  }
                  else if(!this.state.adminCode){
                    NotificationManager.error(
                        "Authorization Code",
                        "Your security code required to perform this transaction. Thank you.",
                        3000,
                        null,
                        null,
                    );
                  }
                  else if(this.state.adminCode !== this.props.admin.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else {
                    this.handleSetAltNumber();
                  }
                }}
              >
                {this.state.adding ? 'Please wait...' : 'Reset Now'}
              </Button>
              
            </ModalFooter>
          </Modal>)}

          {/* RESET BAL. */}
          {this.state.resetTTGBalance && (<Modal
            isOpen={this.state.resetTTGBalance}
            toggle={()=> {
              if(!this.state.adding){
                this.toggleBalanceModal();
              }
            }}
          >
            <ModalHeader 
              toggle={()=> {
                if(!this.state.adding){
                  this.toggleBalanceModal();
                }
              }}
              style={{alignItems:"center",padding:""}} 
            >
              <Label>
                Reset TTG balance for {details.firstname}'s Account 
              </Label>
            </ModalHeader>
            <ModalBody>
              <h6>You can reset customer's TTG balance here. Current balance is: <b>GHS {this.formatAmount(details.ttgCash)}</b></h6>
              <div style={{marginTop:20}}>
                <label>
                  Balance:
                  <input disabled={this.state.adding} value={this.state.amount} style={{paddingLeft:10,paddingBottom:10,paddingTop:10,marginLeft:10,outline:'none',width:150}} onChange={(event)=> {event.target.value.length < 11 && this.setState({amount: event.target.value})}}/>
                </label>

                <label style={{marginLeft:10}}>
                  Auth. Code:
                  <input disabled={this.state.adding} type="password" value={this.state.adminCode} style={{marginLeft:10,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})}/>
                </label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                disabled={this.state.adding}
                color="default"
                onClick={this.toggleBalanceModal}
              >
                Cancel
              </Button>{" "}
              <Button
                disabled={this.state.adding}
                color="danger"
                onClick={()=> {
                  if(!this.state.adminCode){
                    NotificationManager.error(
                        "Authorization Code",
                        "Your security code required to perform this transaction. Thank you.",
                        3000,
                        null,
                        null,
                    );
                  }
                  else if(this.state.adminCode !== this.props.admin.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else {
                    this.handleBalanceReset();
                  }
                }}
              >
                {this.state.adding ? 'Please wait...' : 'Set Now'}
              </Button>
              
            </ModalFooter>
          </Modal>)}

          {/* RESET NUMBER */}
          {this.state.resetNumber && (<Modal
            isOpen={this.state.resetNumber}
            toggle={()=> {
              if(!this.state.adding){
                this.toggleNumberModal();
              }
            }}
          >
            <ModalHeader 
              toggle={()=> {
                if(!this.state.adding){
                  this.toggleNumberModal();
                }
              }}
              style={{alignItems:"center",padding:""}} 
            >
              <Label>
                Reset phone number for {details.firstname}'s Account 
              </Label>
            </ModalHeader>
            <ModalBody>
              {/* <h6>You can reset customer's TTG balance here. Current balance is: <b>GHS {this.formatAmount(details.ttgCash)}</b></h6> */}
              <div style={{marginTop:20}}>
                <label>
                  Number:
                  <input disabled={this.state.adding} value={this.state.newNumber} style={{paddingLeft:10,paddingBottom:10,paddingTop:10,marginLeft:10,outline:'none',width:150}} onChange={(event)=> {event.target.value.length < 11 && this.setState({newNumber: event.target.value})}}/>
                </label>

                <label style={{marginLeft:10}}>
                  Auth. Code:
                  <input disabled={this.state.adding} type="password" value={this.state.adminCode} style={{marginLeft:10,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})}/>
                </label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                disabled={this.state.adding}
                color="default"
                onClick={this.toggleNumberModal}
              >
                Cancel
              </Button>{" "}
              <Button
                disabled={this.state.adding}
                color="danger"
                onClick={()=> {
                  if(!this.state.newNumber){
                    NotificationManager.error(
                        "Empty Value",
                        "Kindy provide the new number.",
                        3000,
                        null,
                        null,
                    );
                  }
                  else if(this.state.newNumber.length < 10) {
                      NotificationManager.error(
                          "Invalid Number",
                          "The number should be 10 digits",
                          3000,
                          null,
                          null,
                      );
                  }
                  else if(!this.state.adminCode){
                    NotificationManager.error(
                        "Authorization Code",
                        "Your security code required to perform this transaction. Thank you.",
                        3000,
                        null,
                        null,
                    );
                  }
                  else if(this.state.adminCode !== this.props.admin.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else {
                    this.handleNumberReset();
                  }
                }}
              >
                {this.state.adding ? 'Please wait...' : 'Set Now'}
              </Button>
              
            </ModalFooter>
          </Modal>)}

          {/* UPDATE NAME. */}
          {this.state.updateName && (<Modal
            isOpen={this.state.updateName}
            toggle={()=> {
              if(!this.state.adding){
                this.toggleNameModal();
              }
            }}
          >
            <ModalHeader 
              toggle={()=> {
                if(!this.state.adding){
                  this.toggleNameModal();
                }
              }}
              style={{alignItems:"center",padding:""}} 
            >
              <Label>
                Update {details.firstname}'s Account 
              </Label>
            </ModalHeader>
            <ModalBody>
              <h6>Change the name of this customer</h6>
              <div style={{marginTop:20}}>
                <label>
                  First name:
                  <input disabled={this.state.adding} value={this.state.firstname} style={{paddingLeft:10,paddingBottom:10,paddingTop:10,marginLeft:10,outline:'none',width:200}} onChange={(event)=> {event.target.value.length < 31 && this.setState({firstname: event.target.value})}}/>
                </label>

                <label>
                  Last name:
                  <input disabled={this.state.adding} value={this.state.lastname} style={{paddingLeft:10,paddingBottom:10,paddingTop:10,marginLeft:10,outline:'none',width:200}} onChange={(event)=> {event.target.value.length < 31 && this.setState({lastname: event.target.value})}}/>
                </label>

                <label style={{marginLeft:10}}>
                  Auth. Code:
                  <input disabled={this.state.adding} type="password" value={this.state.adminCode} style={{marginLeft:10,paddingLeft:10,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})}/>
                </label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                disabled={this.state.adding}
                color="default"
                onClick={this.toggleNameModal}
              >
                Cancel
              </Button>{" "}
              <Button
                disabled={this.state.adding}
                color="danger"
                onClick={()=> {
                  if(this.state.firstname.length < 3){
                    NotificationManager.error(
                        "Invalid Firstname",
                        "Firstname should be at least 3 characters.",
                        3000,
                        null,
                        null,
                    );
                  }
                  else if(this.state.lastname.length < 3){
                    NotificationManager.error(
                        "Invalid Lastname",
                        "Lastname should be at least 3 characters.",
                        3000,
                        null,
                        null,
                    );
                  }
                  else if(!this.state.adminCode){
                    NotificationManager.error(
                        "Authorization Code",
                        "Your security code required to perform this transaction. Thank you.",
                        3000,
                        null,
                        null,
                    );
                  }
                  else if(this.state.adminCode !== this.props.admin.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                  }
                  else {
                    this.handleNameChange();
                  }
                }}
              >
                {this.state.adding ? 'Please wait...' : 'Update Now'}
              </Button>
              
            </ModalFooter>
          </Modal>)}
          
          <Modal
            isOpen={productImageModalOpen}
            toggle={this.toggleFullImageModal}
          >
            {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
              <div className="loading" />  
            </div>}
            <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleFullImageModal}>
                Change Customer Image
            </ModalHeader>
            <ModalBody style={{padding:0}}>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:500,borderRadius:3}} src={fileUrl} alt="Img"/>
              </div>
            </ModalBody>
            <ModalFooter>
            <Button
                color=""
                onClick={this.toggleFullImageModal}
            >
                Cancel
            </Button>{" "}
            <Button
                color={"primary"}
                onClick={this.handleImageUpload}
            >
              Upload Now
            </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return {admin: user};
};

export default withRouter(connect(
  mapStateToProps,
  {}
)(CustomerDetails));