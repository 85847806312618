import {
	CHAT_GET_CONTACTS,
	CHAT_GET_CONTACTS_SUCCESS,
	CHAT_GET_CONTACTS_ERROR,
	CHAT_GET_CONVERSATIONS,
	CHAT_GET_CONVERSATIONS_SUCCESS,
	CHAT_GET_CONVERSATIONS_ERROR,
	CHAT_ADD_MESSAGE_TO_CONVERSATION,
	CHAT_CREATE_CONVERSATION,
	CHAT_SEARCH_CONTACT,
	CHAT_CHANGE_CONVERSATION,
	ADD_MESSAGE, 
	ADD_MESSAGES,
	GET_MESSAGES,
	GET_MESSAGES_SUCCESS,
	UPDATE_CONVERSATION,
	SENDING_MESSAGE
} from '../actions';

const INIT_STATE = {
	allContacts: null,
	contacts: null,
	conversations: null,
	error: '',
	searchKeyword: '',
	loadingContacts: true,
	loadingConversations: false,
	currentUser: null,
	selectedUser: null,
	selectedUserId: null,
	messages: [],
	loadingMessages: false,
	sendingMessage: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case ADD_MESSAGE:
			return { ...state, messages: [...state.messages, action.payload]};

		case ADD_MESSAGES:
			return { ...state, messages: [...action.payload, ...state.messages]};

		case GET_MESSAGES:
			return { ...state, loadingMessages: true };

		case CHAT_GET_CONTACTS:
			return { ...state, loadingContacts: true };

		case GET_MESSAGES_SUCCESS:
			return { ...state, messages: [...action.payload.messages], loadingMessages: false} //...state.messages

		case CHAT_GET_CONTACTS_SUCCESS:
			return { ...state, loadingContacts: false, allContacts: action.payload.contacts, contacts: action.payload.contacts, currentUser: action.payload.currentUser };

		case CHAT_GET_CONTACTS_ERROR:
			return { ...state, loadingContacts: false, error: action.payload };

		case SENDING_MESSAGE:
			return {...state, sendingMessage: action.payload};

		case CHAT_GET_CONVERSATIONS:
			return { ...state, loadingConversations: true };

		case CHAT_GET_CONVERSATIONS_SUCCESS:
			return { ...state, 
				loadingConversations: false, 
				conversations: action.payload.conversations, 
				selectedUserId:  action.payload.selectedUser 
			};

		case UPDATE_CONVERSATION:
			let _conversations = state.conversations ? state.conversations.filter(conv=> conv.id !== action.payload.id) : [];
			_conversations.unshift(action.payload);
			// console.log('_conv',_conversations);
			return { ...state, 
				conversations: _conversations
				// conversations: state.conversations ? state.conversations.map(conv=> {
				// 	if(conv.id === action.payload.id){
				// 		return action.payload;
				// 	}
				// 	else {
				// 		return conv;
				// 	}
				// }) : [], 
			};

		case CHAT_GET_CONVERSATIONS_ERROR:
			return { ...state, loadingConversations: false, error: action.payload };

		case CHAT_CHANGE_CONVERSATION:
			// return { ...state, selectedUser: state.allContacts.find(x => x.id === action.payload) };
			return { ...state, selectedUser: action.payload };


		case CHAT_ADD_MESSAGE_TO_CONVERSATION:
			return { ...state };

		case CHAT_CREATE_CONVERSATION:
			return { ...state };

		case CHAT_SEARCH_CONTACT:
			if (action.payload === '') {
				return { ...state, contacts: state.allContacts };
			} else {
				// const keyword = action.payload.toLowerCase();
				// const searchedContacts = state.allContacts.filter((item) => item.name.toLowerCase().indexOf(keyword) > -1);
				// return { ...state, contacts: searchedContacts }
			}

		default: return { ...state };
	}
}
