import {
    ADD_DELIVERY,
    REMOVE_DELIVERY,
    SET_DELIVERIES
} from '../actions';

export const setDeliveries = (payload) => {
    return (
        {
            type: SET_DELIVERIES,
            payload: payload
        }
    )
}

export const addDelivery = (payload) => {
    return (
        {
            type: ADD_DELIVERY,
            payload: payload
        }
    )
}

export const removeDelivery = (payload) => {
    return (
        {
            type: REMOVE_DELIVERY,
            payload: payload
        }
    )
}