import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Nav, TabContent, TabPane, CardHeader, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import axios from 'axios';

import ApplicationMenu from "../../components/common/ApplicationMenu";

import {
  changeConversation,
  createConversation,
  searchContact,
  getMessages,
  updateConversation
} from "../../redux/actions";

const img_base_url = 'https://api.tuatuagye.com/get-image?type=profile&id='

const formatDate = (_date) => {
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime} | ${months[date.getMonth()]} ${date.getDate()}`;
}

class ChatApplicationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: ""
    };
  }
  toggleAppMenu = tab => {
    if (this.props.activeTab !== tab) {
      this.props.toggleAppMenu(tab)
    }
    if (tab === "messages") {
      this.handleSearchContact("");
    }
  };

  handleSearchContact = keyword => {
    this.setState({
      searchKey: keyword
    });
    if (keyword.length > 0) {
      if (this.props.activeTab !== "users") {
        this.props.toggleAppMenu("users")
       
      }
      this.props.searchContact(keyword);
    } else {
      this.props.searchContact("");
    }
  };

  handleConversationClick = (e, selectedUserId, conversation) => {
    //TODO CHANGE FROM HERE
    if(conversation.id){
      axios.patch(`https://api.tuatuagye.com/chats/${conversation.id}`,{unreadCount:0})
    }
    // console.log('chat sel is',conversation);
    conversation['chatId'] = conversation.id;
    this.props.changeConversation(conversation);
    this.props.getMessages(conversation.ownerId)
    this.handleSearchContact("");

    if(conversation.unreadCount > 0){
      this.props.updateConversation({...conversation, unreadCount:0})
    }
  }

  handleContactClick = async (userId, user) => {
    if (this.props.activeTab !== "chats") {
      this.props.toggleAppMenu("chats")
      // this.props.searchContact("");
    }

    //just a workaround to get existing chat data
    let cht = await axios.get(`https://api.tuatuagye.com/chats?filter[where][ownerId]=${userId}`);
    if(cht && cht.data && cht.data[0]){
      this.props.changeConversation({
        ...user,
        pushToken: cht.data[0].pushToken,
        platform: cht.data[0].platform,
        chatId: cht.data[0].id,
        unreadCount: cht.data[0].unreadCount
      });
    }
    else {
      this.props.changeConversation(user);
    }
    this.props.getMessages(userId)

    const { conversations, currentUser } = this.props.chatApp;
    // alert('conv '+JSON.stringify(conversations))
    // const conversation = conversations.find(
    //   x => currentUser && x.users.includes(currentUser.id) && x.users.includes(userId)
    // );
    // if (conversations && conversations.length > 0) {alert('in')
    //   this.props.changeConversation(userId);
    // } else {alert('else')
    //   this.props.createConversation(currentUser.id, userId, conversations);
    //   this.props.changeConversation(userId);
    // }
  }


  render() {
    const {
      contacts,
      allContacts,
      conversations,
      loadingConversations,
      loadingContacts,
      currentUser
    } = this.props.chatApp;
    const { user } = this.props;

    return (
      <ApplicationMenu>
        <CardHeader className="pl-0 pr-0">
          <Nav tabs className="card-header-tabs ml-0 mr-0">
            <NavItem className="w-50 text-center">
              <NavLink
                className={classnames({
                  active: this.props.activeTab === "chats",
                  "nav-link": true
                })}
                onClick={() => {
                  this.toggleAppMenu("chats");
                }}
                to="#"
              >
                Chats
              </NavLink>
            </NavItem>
            <NavItem className="w-50 text-center">
              <NavLink
                className={classnames({
                  active: this.props.activeTab === "users",
                  "nav-link": true
                })}
                onClick={() => {
                  this.toggleAppMenu("users");
                }}
                to="#"
              >
                Users
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>

        <div className="pt-4 pr-4 pl-4 pb-0">
          <div className="form-group">
            <input
              type="text"
              className="form-control rounded"
              placeholder="Search users"
              value={this.state.searchKey}
              onChange={e => this.handleSearchContact(e.target.value)}
            />
          </div>
        </div>

        <TabContent
          activeTab={this.props.activeTab}
          className="chat-app-tab-content"
        >
          <TabPane tabId="chats" className="chat-app-tab-pane">
            <PerfectScrollbar
              option={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <div className="pt-2 pr-4 pl-4 pb-2">
                {conversations &&
                  conversations.map((item, index) => {
                    // const otherUser = allContacts.find(
                    //   u => u.id === item.users.find(x => (!!currentUser) || (x !== currentUser.id))
                    // );
                    return (
                      <div
                        key={index}
                        className="d-flex flex-row mb-1 border-bottom pb-3"
                        style={{cursor:'pointer'}}
                      >
                        <div
                          className="d-flex"
                          // to="#"
                          onClick={e =>
                            this.handleConversationClick(e, item.id, item)
                          }
                          style={{position:'relative', display:'flex',alignItems:'center',width:'100%'}}
                        >
                          <img
                            alt={item.name}
                            src={item.avatar ? ``:'/assets/img/no-image.jpg'}
                            className="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall"
                          />
                          <div className="d-flex flex-grow-1 min-width-zero" style={{maxWidth: '70%'}}>
                            <div className="pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                              <div className="min-width-zero">
                                <p className=" mb-0 truncate">
                                  {item.ownerName}
                                </p>
                                <p className="mb-0 truncate text-muted text-small">
                                  {item.lastMessage.body}
                                </p>
                                <p className="mt-2 truncate text-muted text-small">{formatDate(item.lastMessage.timestamp)}</p>
                              </div>
                            </div>
                          </div>
                          {item.unreadCount > 0 && item.lastMessage.senderId !== user.id && <p style={{position:'absolute',top:0,right:0, backgroundColor:'red',paddingRight:5,paddingLeft:5,paddingTop:0,paddingBottom:0,borderRadius:3,color:'#fff'}}>{item.unreadCount}</p>}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </PerfectScrollbar>
          </TabPane>
          <TabPane tabId="users" className="chat-app-tab-pane">
            <PerfectScrollbar
              option={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <div className="pt-2 pr-4 pl-4 pb-2">
                {contacts &&
                  contacts
                    .filter(x => {
                      if(currentUser){
                        return x.id !== currentUser.id
                      }
                      else return true
                    })
                    .map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-row mb-3 border-bottom pb-3"
                          style={{cursor:'pointer'}}
                        >
                          <div
                            className="d-flex"
                            // to="#"
                            onClick={()=>this.handleContactClick(item.id, item)}
                          >
                            <img
                              alt={'IMG'}
                              src={item.imageThumbnail ? `${img_base_url}${item.imageThumbnail}`:'/assets/img/no-image.jpg'}
                              className="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall"
                            />
                            <div className="d-flex flex-grow-1 min-width-zero">
                              <div className="m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                <div className="min-width-zero">
                                  <p className="mb-0 truncate">{item.firstname} {item.lastname}</p>
                                  <p className="mb-1 text-muted text-small">
                                    {item.email}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </PerfectScrollbar>
          </TabPane>
        </TabContent>
      </ApplicationMenu>
    );
  }
}

const mapStateToProps = ({ chatApp }) => {
  return { chatApp };
};
export default injectIntl(
  connect(
    mapStateToProps,
    {
      changeConversation,
      createConversation,
      searchContact,
      getMessages,
      updateConversation
    }
  )(ChatApplicationMenu)
);
