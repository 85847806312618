import {
    ADD_DELIVERY,
    REMOVE_DELIVERY,
    SET_DELIVERIES
} from '../actions';

const INIT_STATE = {
    entities: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
        case SET_DELIVERIES:
            return Object.assign({}, state, {
				entities: action.payload
			})
		case ADD_DELIVERY:
            const temp = state.entities.map(m => m);
            temp.unshift(action.payload);
            return Object.assign({}, state, {
				entities: temp
			})

		case REMOVE_DELIVERY:
            return Object.assign({}, state, {
                entities: state.entities.filter(m=> m.id !== action.payload.id)
            })

		default: return { ...state };
	}
}
