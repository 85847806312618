import React from "react";
import {
  Row,
  Card,
  CardBody,
  CardSubtitle,
  CardImg,
  CardText
} from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../../components/common/CustomBootstrap";

const base_image_url = 'https://api.tuatuagye.com/get-profile-image';

const __getDate = (_date) => {
  // Mar 2
  if(!_date) return '';
  const date = new Date(_date);

  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${months[date.getMonth()]} ${date.getDate()}`;
}

const ImageListView = ({ selectMerchant, merchant, isSelect, collect, startDate, endDate, toggleModal, toggleSignupsModal }) => {
  const formatAmount = (amt) => {
    if(!amt){
      return;
    }
    else {
      return Number(amt).toFixed(2);
    }
  }
  return (
    <Colxx sm="6" lg="4" xl="3" className="mb-3" key={merchant.id}>
      <ContextMenuTrigger id="menu_id" data={merchant.id} collect={collect}>
        <Card
          className={classnames({
            active: isSelect
          })}
        >
          <div className="position-relative">
            <NavLink to={`?ref=${merchant.id}`} className="w-40 w-sm-100" style={{cursor:'default'}}>
              <CardImg top alt="img" src={`${base_image_url}?type=main&id=${merchant.id}`} />
            </NavLink>
            {/* <Badge
              color={product.statusColor}
              pill
              className="position-absolute badge-top-left"
            >
              {product.status}
            </Badge> */}
          </div>
          <CardBody style={{padding:"10px"}}>
            <Row>
              <Colxx xxs="12" className="mb-1">
                <CardSubtitle>{merchant.name}</CardSubtitle>
                <CardSubtitle>GHS {formatAmount(merchant.total)}</CardSubtitle>
                <CardText className="text-muted text-small mb-0 font-weight-light">
                  {new Date(startDate).getDate() === new Date(endDate).getDate() ? ((new Date().getDate() == new Date(startDate).getDate()) && (new Date().getDate() == new Date(endDate).getDate())) ? 'TODAY' : __getDate(startDate) : `${__getDate(startDate)} / ${__getDate(endDate)}`} 
                </CardText>
                <div style={{display:'flex',justifyContent:'space-between',marginTop:10}}>
                  <button
                    onClick={()=> {
                      selectMerchant(merchant);
                      toggleModal();
                    }}
                    style={{fontSize:14,borderWidth:2,borderColor:'#008894',color:'#008894',borderStyle:'solid',backgroundColor:'#fff',paddingLeft:20,paddingRight:20,paddingTop:8,paddingBottom:8,borderRadius:2,cursor:'pointer'}}
                  >
                      Collections
                  </button>

                  <button
                    onClick={()=> {
                      selectMerchant(merchant);
                      toggleSignupsModal();
                    }}
                    style={{fontSize:14,borderWidth:1,color:'#fff',borderStyle:'none',backgroundColor:'#008894',paddingLeft:20,paddingRight:20,paddingTop:8,paddingBottom:8,borderRadius:2,cursor:'pointer'}}
                  >
                    Signups
                  </button>
                </div>
              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </ContextMenuTrigger>
    </Colxx>
  );
};

/* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
export default React.memo(ImageListView);
