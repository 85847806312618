import {
    ADD_PAYMENT,
    SET_PAYMENTS,
    SET_PAYMENTS_START_DATE,
    SET_PAYMENTS_END_DATE,
    SET_PAYMENTS_TOTAL
} from '../actions';

const INIT_STATE = {
    startDate: new Date().getDate(),
    endDate: new Date().getDate(),
    entities: [],
    todayTotal: 0,
    loading: true
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
        case SET_PAYMENTS:
            return Object.assign({}, state, {
                entities: action.payload,
                loading: false
			})
		case ADD_PAYMENT:
            let _total = state.todayTotal + action.payload.amount;
            
            const temp = state.entities.map(m => m);
            temp.unshift(action.payload);
            return Object.assign({}, state, {
                entities: temp,
                todayTotal: _total
            })
            
        case SET_PAYMENTS_TOTAL:
            return Object.assign({}, state, {
                todayTotal: action.payload
            })

		case SET_PAYMENTS_START_DATE:
            return Object.assign({}, state, {
                startDate: action.payload
            })

        case SET_PAYMENTS_END_DATE:
            return Object.assign({}, state, {
                endDate: action.payload
            })

		default: return { ...state };
	}
}
