import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Label, Row, ModalFooter, Button
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";
import ReactTable from "react-table";
import classnames from "classnames";
// import { DateRangePicker } from 'react-date-range';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from 'axios';

import DataTablePagination from "../../components/DatatablePagination";
const base_image_url = 'https://api.tuatuagye.com/get-profile-image';

const signups_url = 'https://api.tuatuagye.com/sign-ups'

const collections_url = 'https://api.tuatuagye.com/collections'

const formatAmount = (amount) => {
  if(!amount) return '0.00';
  if(amount > 999){
    let temp = amount.toString();
    let decimal = '.00';

    if(temp.indexOf('.') > -1){
      decimal = '.'+temp.split('.')[1];
      temp = temp.split('.')[0];
    }
    
    temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
    return temp;
  }
  
  return Number(amount).toFixed(2);
}

const formatDate = (_date) => {
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
}

const __getTime = (_date) => {
  // 3:16pm
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime}`;
}

const __getDate = (_date) => {
  // Mar 2
  if(!_date) return '';
  const date = new Date(_date);

  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${months[date.getMonth()]} ${date.getDate()}`;
}

const MerchantDayTransactions = ({ merchant, userDetails, modalOpen, toggleModal }) => {
  const [selectedPayment, setSelectedPayment] = useState(57);
  const [amount, setAmount] = useState(1);
  const [modalNestedContainer,setModalNestedContainer] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [deleteModal,setDeleteModal] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const [adminCode, setAdminCode] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [activeFirstTab, setActiveFirstTab] = useState("1");
  const [activeTab, setActiveTab] = useState("1")
  const [collections, setCollections] = useState([])
  const [collTotal, setCollTotal] = useState(0)
  const [collCom, setCollCom] = useState(0);
  const [signupComm,setSignupComm] = useState(0);
  const [dateModal, setDateModal] = useState(false);

    useEffect(()=> {
        loadData(new Date(), new Date());
        return ()=> {}
    },[])

    const loadData = (st, en) => {
        st.setHours(0)
        st.setMinutes(0);
        st.setSeconds(0);

        en.setHours(23)
        en.setMinutes(59);
        en.setSeconds(59);

        setData([])
        setCount(0)
        setCollCom(0)
        setCollections([])
        setCollTotal(0)
        setSignupComm(0)

      if(merchant && merchant.id){
        !setLoading && setLoading(true)
        axios.get(`${signups_url}?filter[where][merchantId]=${merchant.userId}&filter[include][]=user&filter[where][and][0][createdAt][gte]=${st.toJSON()}&filter[where][and][1][createdAt][lte]=${en.toJSON()}&filter[order]=createdAt%20DESC`)
          .then(res => {
              if(res.data && res.data.length > 0){
                  let _total = 0;
                  let _count = 0;
                  res.data.forEach(signup => {
                    _total += signup.amount;
                    _count += 1;
                  });

                  setTotal(Number(_total).toFixed(2));
                  setCount(_count);
                  setData(res.data);
                  if(merchant && merchant.mType == 2){
                    setSignupComm(10*_count)
                  }
                  else {
                    setSignupComm(5*_count)
                  }
                  setLoading(false);
              }

              return axios.get(`${collections_url}?filter[where][merchantId]=${merchant.userId}&filter[include][]=payment&filter[where][and][0][createdAt][gte]=${st.toJSON()}&filter[where][and][1][createdAt][lte]=${en.toJSON()}&filter[order]=createdAt%20DESC`)
              
          })
          .then(res => {
              if(res.data && res.data.length > 0){
                  let _total = 0;
                  let _com = 0;
                  res.data.forEach(col => {
                      if(col.payment && col.payment.amount){
                        _total += col.payment.amount;
                        _com += Number(col.payment.amount)*.01//0.2
                      }
                  });

                  setCollections(res.data);
                  setCollTotal(_total)
                  setCollCom(Number(_com).toFixed(2));
                  setLoading(false);
              }
              else {
                setLoading(false);
              }
          })
          .catch(error => {
              console.log('error is',error);
              NotificationManager.error(
                  "Load Error",
                  "An unknown error occurred. Please check console for details or try again.",
                  3000,
                  null,
                  null,
              );
          })
    }
    else {
        NotificationManager.error(
            "No Merchant Info",
            "An error occurred. Please check console for details or try again.",
            3000,
            null,
            null,
        );
    }
    }

  const dataTableColumns = [
    {
      Header: "Customer",
      accessor: "user",
      Cell: props => <p className="list-item-heading">{props.value.firstname} {props.value.lastname}</p>
    },
    {
      Header: "Product",
      accessor: "productName",
      Cell: props => <p className="text-muted truncated">{props.value}</p>
    },
    {
      Header: "Amount",
      accessor: "amount",
      headerStyle: {textAlign:'center'},
      width:80,
      Cell: props => <p className="list-item-heading" style={{textAlign:'center'}}>{formatAmount(props.value)}</p>
    },
    {
        Header: "Date & Time",
        accessor: "createdAt",
        width: 100,
        headerStyle:{textAlign:'center'},
        Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{__getTime(props.value)}<br/>{__getDate(props.value)}</p>
    },
    // {
    //   Header: "Mode",
    //   accessor: "payment",
    //   headerStyle: {textAlign:'center'},
    //   width:80,
    //   Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{props.value.mode}</p>
    // }
  ];

    const columns = [
    {
      Header: "Customer",
      accessor: "payment",
      Cell: props => <p className="list-item-heading">{props.value.payeeName}</p>
    },
    {
      Header: "Product",
      accessor: "payment",
      Cell: props => <p className="text-muted truncated">{props.value.productName}</p>
    },
    {
      Header: "Date & Time",
      accessor: "createdAt",
      width: 100,
      headerStyle:{textAlign:'center'},
      Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{__getTime(props.value)}<br/>{__getDate(props.value)}</p>
    },
    {
      Header: "Amount",
      accessor: "payment",
      headerStyle: {textAlign:'center'},
      width:80,
      Cell: props => <p className="list-item-heading" style={{textAlign:'center'}}>{formatAmount(props.value.amount)}</p>
    },
    {
      Header: "Mode",
      accessor: "payment",
      headerStyle: {textAlign:'center'},
      width:80,
      Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{props.value.mode}</p>
    },
    {
      Header: "MoMo Number",
      accessor: "payment",
      width: 120,
      headerStyle:{textAlign:'center'},
      Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{props.value.momoNumber || 'N/A'}</p>
    }
  ]

  const toggleNestedContainer = () => {
    setModalNestedContainer(!modalNestedContainer);
  };

  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const toggleTab = (tab) => {
      console.log('param tb',tab);
      console.log('state activeTab',activeTab);
    if (activeTab !== tab) {
        setActiveFirstTab(tab)
    }
  }

  const handleCloseDay = () => {

  }

  const refreshData = () => {

  }

  const handleSelect = (d) => {
      setStartDate(d);
      setEndDate(d)

      setTimeout(() => {
          loadData(new Date(d), new Date(d));
      }, 0);
  } 

  const toggleDateModal = () => {
      setDateModal(!dateModal)
    // this.setState(prevState => ({
    //   dateModal: !prevState.dateModal,
    // }));
  };

  return (
    <Modal
      style={{width:720}}
      isOpen={modalOpen}
      toggle={toggleModal}
      wrapClassName="modal-right"
    >
      <ModalHeader style={{alignItems:"center"}} toggle={toggleModal}>
        <img 
          style={{width:"60px",height:"60px",borderRadius:"30px",marginRight:"10px"}} 
          src={`${base_image_url}?type=main&id=${merchant.userId}`} 
          />
        <Label>{userDetails.firstname}'s Day Transactions</Label>
      </ModalHeader>
      <ModalBody style={{padding:"0px"}}>
        {loading && <div className="loading"/>}
        
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',margin:20}}>
            <div style={{}}>
                <h4>Total Collections: <span style={{color:'#000',fontWeight:'bold'}}>GHS {Number(collTotal || 0).toFixed()}</span></h4> 
                <p>Commission: {"\t"} GHS {Number(collTotal * 0.01).toFixed(2)}</p>   
            </div>

            <div style={{}}>
                <h4>Total Sign Ups: <span style={{color:'#000',fontWeight:'bold'}}>{count}</span></h4> 
                {/* <p>Commission: {"\t"} GHS {Number(count * 3).toFixed(2)}</p>    */}
                <p>Commission: {"\t"} GHS {Number(signupComm).toFixed(2)}</p>   
            </div>
        </div>

        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20,marginBottom:20}}>
            <div style={{width:"190px",marginLeft:"10px"}}>
                <DatePicker
                    selected={startDate}
                    onChange={handleSelect}
                    placeholderText="Date"
                />
            </div>
           
            {/* <Button className="mr-2" onClick={handleCloseDay} color="primary">Close Sales</Button> */}
       </div>

        <Colxx xxs="12">
            <Nav tabs className="separator-tabs ml-0 mb-5" style={{marginTop:1}}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeFirstTab == "1",
                    "nav-link": true
                  })}
                  onClick={() => {
                    toggleTab("1");
                  }}
                  to="#"
                >
                  COLLECTIONS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeFirstTab == "2",
                    "nav-link": true
                  })}
                  onClick={() => {
                    toggleTab("2");
                  }}
                  to="#"
                >
                  SIGN UPS
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeFirstTab}>
                <TabPane tabId="1">
                    <ReactTable
                        data={collections}
                        columns={columns}
                        defaultPageSize={20}
                        // filterable={true}
                        showPageJump={true}
                        PaginationComponent={DataTablePagination}
                        showPageSizeOptions={true}
                    />
              </TabPane>
              <TabPane tabId="2">
                  <ReactTable
                        data={data}
                        columns={dataTableColumns}
                        defaultPageSize={20}
                        // filterable={true}
                        showPageJump={true}
                        PaginationComponent={DataTablePagination}
                        showPageSizeOptions={true}
                    />
                
              </TabPane>
              
            </TabContent>

          {/* <ReactTable
              data={data}
              columns={dataTableColumns}
              defaultPageSize={20}
              // filterable={true}
              showPageJump={true}
              PaginationComponent={DataTablePagination}
              showPageSizeOptions={true}
          /> */}
        </Colxx>
      </ModalBody>
      
      <Modal
        isOpen={modalNestedContainer}
        toggle={toggleNestedContainer}
        // className={props.className}
      >
        <ModalHeader style={{alignItems:"center",padding:""}} toggle={toggleNestedContainer}>
          <img style={{width:"60px",height:"60px",borderRadius:"30px",marginRight:"10px"}} src="/assets/img/profile-pic-l-4.jpg" alt="Avatar"/>
          <Label>Edit {userDetails.firstname}'s Collection </Label>
        </ModalHeader>
        <ModalBody>
          <h6>Editing <b>GH₵ {formatAmount(selectedPayment.amount)}</b>, payment made at <b>{formatDate(selectedPayment.createdAt)}</b></h6>
          <Row style={{paddingLeft:16,marginTop:20}}>
            <label>
              Amount:
              <input type="number" value={amount} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> setAmount(event.target.value)} />
            </label>

            <label style={{marginLeft:20}}>
              Authorization Code:
              <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> setAdminCode(event.target.value)} />
            </label>
          </Row>
          <Modal
            isOpen={nestedModal}
            toggle={toggleNested}
            onClosed={closeAll ? toggleNestedContainer: undefined}
          >
            <ModalHeader>Confirm Payment Edit</ModalHeader>
            <ModalBody>
              <h5>Are you sure you want to change payment of <b>GH₵ {formatAmount(selectedPayment.amount)}</b> to an amount of <b>GH₵ {formatAmount(amount)}?</b></h5>
            </ModalBody>
            <ModalFooter>
              <Button color="default" onClick={toggleNested}>
                Cancel
              </Button>{" "}
              <Button color="primary" onClick={toggleAll}>
                Yes, Change Payment
              </Button>
            </ModalFooter>
          </Modal>
        </ModalBody>
        <ModalFooter>
        <Button
            color="default"
            onClick={toggleNestedContainer}
          >
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            onClick={()=> {
              if(Number(amount) < 1){
                alert('Amount required')
              }
              else {
                toggleNested();
              }
            }}
          >
            Update
          </Button>
          
        </ModalFooter>
      </Modal>


      {/* DELETE */}
      <Modal
          isOpen={deleteModal}
          toggle={toggleDeleteModal}
          // className={props.className}
        >
          <ModalHeader style={{alignItems:"center",padding:""}} toggle={toggleDeleteModal}>
            <img style={{width:"60px",height:"60px",borderRadius:"30px",marginRight:"10px"}} src="/assets/img/profile-pic-l-4.jpg" alt="Avatar"/>
            <Label>
              Delete {userDetails.firstname}'s Collection 
            </Label>
          </ModalHeader>
          <ModalBody>
            <h6>To delete this collection type <i><b>permanently delete</b></i> in the box below</h6>
            <Row style={{paddingLeft:16,marginTop:20}}>
              <input 
                type="text" 
                value={deleteText} 
                style={{outline:'none',height:45}} 
                onChange={(event)=> {
                  setDeleteText(event.target.value);
                }}
              />

              <label style={{marginLeft:20}}>
                Authorization Code:
                <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> setAdminCode(event.target.value)} />
              </label>
            </Row>
          </ModalBody>
          <ModalFooter>
          <Button
              color="default"
              onClick={toggleDeleteModal}
            >
              Cancel
            </Button>{" "}
            <Button
              color="danger"
              onClick={()=> {
                if(deleteText !== 'permanently delete'){
                  alert('Your input is incorrect. Please check and try again.')
                }
                else {
                  toggleDeleteModal();
                }
              }}
            >
              Delete Now
            </Button>
            
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={dateModal}
          toggle={toggleDateModal}
          className={'date-modal'} 
        >
          <ModalHeader style={{alignItems:"center",padding:""}} toggle={toggleDateModal}>
            <Label>Set Date</Label>
          </ModalHeader>
          <ModalBody>
            {/* <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
            /> */}
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              onClick={toggleDateModal}
            >
              Cancel
            </Button>{" "}
            
            <Button
              color="success"
              onClick={()=> {
                refreshData();
                // this.setState({loading: true});
                setLoading(true)
                toggleDateModal();
              }}
            >
              Set Now
            </Button>
            
          </ModalFooter>
        </Modal>

    </Modal>
  );
};

export default MerchantDayTransactions;
