import React from "react";
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const users_url = 'https://api.tuatuagye.com/users';

export default class SelectMerchantOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  loadOptions = (inputValue, callback) => {
    axios.get(`${users_url}?filter[where][and][0][type]=4&filter[where][and][1][firstname][regexp]=/${inputValue}/i&filter[limit]=10`)
      .then(res => {
        callback(res.data.map(user => {
          user.label = `${user.firstname} ${user.lastname} (${user.location})`;
          user.value = `${user.phone}`;
          return user;
        }));
      })
      .catch(error => {
        console.log('Error...',error);
      })
  };

  onChange = (newValue) => {
    this.props.onChange(newValue);
  };

  render() {
    return (
      <AsyncSelect
        placeholder='Search with firstname'
        cacheOptions
        loadOptions={this.loadOptions}
        defaultOptions
        onChange={this.onChange}
      />
    );
  }
}