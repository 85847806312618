import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label } from "reactstrap";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import { NavLink } from "react-router-dom";
import axios from 'axios';
import DataTablePagination from "../../../../components/DatatablePagination";
import { NotificationManager } from "../../../../components/common/react-notifications";
import { connect } from "react-redux";

const dispatches_url = 'https://api.tuatuagye.com/dispatches';
const img_base_url = 'https://api.tuatuagye.com/get-image?type=profile&id='

class Motors extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchKeyword: '',
      modalOpen:false,
      confirmModal: false,
      name:'',
      email:'',
      phone:'',
      location:'',
      numberPlate:'',
      driverLicenseNumber:'',
      adminCode:'',
      statusModalOpen: false,
      processing: false,
      selectedDispatch: {}
    }

    this.timer = 0;

    this.columns = [
      {
        Header: "Name",
        accessor: "name",
        Cell: props => <Row style={{marginLeft:0,marginRight:0,alignItems:'center'}}>
          <img style={{width:50,height:50,borderRadius:25,marginRight:10}} src={props.original.imageThumbnail ? `${img_base_url}${props.original.imageThumbnail}` : '/assets/img/no-image.jpg'} alt="IMG"/>
          <span style={{fontSize:16}}>{props.value}</span>
        </Row>
      },
      {
        Header: "Phone",
        accessor: "phone",
        Cell: props => <p className="text-muted" style={{paddingTop:15}}>{props.value}</p>
      },
      {
        Header: "Driv. License",
        accessor: "driverLicenseNumber",
        Cell: props => <p className="text-muted" style={{paddingTop:15}}>{props.value}</p>
      },
      {
        Header: "Number Plate",
        accessor: "numberPlate",
        Cell: props => <p className="text-muted" style={{paddingTop:15}}>{props.value}</p>
      },
      {
        Header: "Total Del.",
        accessor: "totalDeliveries",
        headerClassName:'custom-td',
        className: 'custom-td',
        Cell: props => <p className="text-muted" style={{paddingTop:15,paddingLeft:10}}>{props.value} / {props.original.assignedDeliveries}</p>
      },
      {
        Header: "Action",
        accessor: "enabled",
        headerClassName:'custom-td',
        className: 'custom-td',
        Cell: props => <Row style={{paddingLeft:"12px",paddingTop:10}}>
          {!props.value && 
            <div
              onClick={()=> {
                this.setState({selectedDispatch:props.original, adminCode: ''})
                this.toggleStatusModal();
              }}
              style={{cursor:'pointer',fontSize:20,color:'#2ECC71'}}  
              className={"glyph-icon iconsminds-add-user"} 
            />
          }
          {props.value && 
            <div 
              onClick={()=> {
                this.setState({selectedDispatch:props.original, adminCode: ''})
                this.toggleStatusModal();
              }}
              style={{cursor:'pointer',fontSize:17,paddingTop:5,marginRight:7,paddingLeft:4,color:'#E74C3C'}} 
              className={"glyph-icon simple-icon-close"} 
            />
          }
          {/* simple-icon-eye */}
          <NavLink style={{marginLeft:15,paddingTop:5}} 
            to={`/app/deliveries/dispatch/${props.original.id}`}
            >
            <div style={{fontSize:17}} className={'glyph-icon simple-icon-eye'}/>
          </NavLink>
        </Row>
      }
    ]
  }
  componentDidMount() {
    axios.get(`${dispatches_url}`)
    .then(res=> {
      this.setState({data: res.data, loading:false})
    })
    .catch(err => {
      console.log('error loading dispatches',err)
      NotificationManager.error(
        "Loading error!",
        "An unknown error occurred. Please refresh page.",
        3000,
        null,
        null,
      );
      this.setState({loading:false})
    })
  }

  searchWithName = (searchText) => {
    this.setState({loading: true});
    axios.get(`${dispatches_url}?filter[where][or][0][name][regexp]=/${searchText}/i&filter[where][or][1][driverLicenseNumber][regexp]=/${searchText}/i&filter[where][or][2][location][regexp]=/${searchText}/i&filter[where][or][3][numberPlate][regexp]=/${searchText}/i&filter[limit]=10`)
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({data: res.data, loading: false});
        }
        else {
          this.setState({data:[], loading:false})
        }
      })
      .catch(error => {
        this.setState({loading: false});
        alert('Sorry, an unknown error occurred.');
        console.log('error...',error);
      })
  }

  toggleModal = () => {
    this.setState({modalOpen: !this.state.modalOpen})
  }

  toggleConfirmModal = () => {
    this.setState({confirmModal: !this.state.confirmModal})
  }

  toggleStatusModal = () => {
    this.setState({statusModalOpen: !this.state.statusModalOpen})
  }

  handleSubmit = () => {
    const { name, email, phone, location, numberPlate, driverLicenseNumber } = this.state;

    if(!name){
      NotificationManager.error(
        "Name required",
        "Fullname must be provided.",
        3000,
        null,
        null,
      );
    }
    else if(!email){
      NotificationManager.error(
        "Email required",
        "A valid email address must be provided.",
        3000,
        null,
        null,
      );
    }
    else if(!phone){
      NotificationManager.error(
        "Mobile phone number required",
        "Provide contact number",
        3000,
        null,
        null,
      );
    }
    else if(!location){
      NotificationManager.error(
        "Location required",
        "Provide location.",
        3000,
        null,
        null,
      );
    }
    else if(!numberPlate){
      NotificationManager.error(
        "Number plate required",
        "Provide number plate.",
        3000,
        null,
        null,
      );
    }
    else if(!driverLicenseNumber){
      NotificationManager.error(
        "License required",
        "Provide driver's license.",
        3000,
        null,
        null,
      );
    }
    else {
      this.toggleConfirmModal();
    }
  }

  handleChangeStatus = (selectedDispatch) => {
    const id = selectedDispatch.id;
    const { data } = this.state
    const enabled = selectedDispatch.enabled ? false : true;

    this.setState({processing:true});

    axios.patch(`${dispatches_url}/${id}`,{ enabled })
      .then(res => {
        const temp = data.map(usr => {
          if(usr.id === id){
            usr.enabled = enabled;
          }

          return usr;
        })

        this.setState({data: temp, processing: false, adminCode: ''});
        this.toggleStatusModal();
        NotificationManager.success(
          "Update successful",
          "Your change was made successfully.",
          3000,
          null,
          null,
        );
      })
      .catch(error => {
        this.setState({processing: false});
        console.log('edit eror',error);
        NotificationManager.error(
          "Edit error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
      })
  }

  handleSave = () => {
    const { data, name, email, phone, location, numberPlate, driverLicenseNumber } = this.state;

    this.setState({processing:true});

    const payload = {
      name, email, phone, location, 
      type: 1,
      numberPlate,
      driverLicenseNumber,
      enabled: true
    }

    axios.post(`${dispatches_url}`,payload)
      .then(res=> {
        const temp = data.map(d=> d);
        temp.unshift(res.data);
        NotificationManager.success(
          'Rider added',
          'The new dispatch rider was successfully added',
          3000,
          null,
          null
        )
        this.setState({data: temp, processing:false, name:'',email:'',phone:'',location:'',numberPlate:'',driverLicenseNumber:''});
        this.toggleConfirmModal();
        this.toggleModal();
      })
      .catch(e=> {
        console.log('error occurred is',e);
        NotificationManager.error(
          "Save error",
          "An error occurred. Kindly try again.",
          3000,
          null,
          null,
        );
        this.setState({processing:false});
      })
  }
    render() {
      const { searchKeyword, selectedDispatch,processing, statusModalOpen, adminCode, email, phone, driverLicenseNumber, location, numberPlate, loading, name, data, modalOpen, confirmModal } = this.state;
        return (
          <Fragment>
            <Row>
              <Colxx xxs="12">
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',paddingBottom:10}}>
                  <h1 style={{marginBottom:0,paddingBottom:0}}>Riders</h1>
                  
                  <div style={{display:'flex', alignItems:'center'}}>
                    <div className="payments-search">
                      <Input
                        style={{backgroundColor:'#fff'}}
                        // name="searchKeyword"
                        // id="searchKeyword"
                        placeholder={"Search" }
                        value={searchKeyword}
                        onChange={e => {
                          let value = e.target.value;
                          this.setState({searchKeyword: value})
                          clearTimeout(this.timer);
                          this.timer = setTimeout(() => {
                            this.searchWithName(value);
                          }, 1000);
                        }}
                      />
                      <span
                          className="search-icon"
                          style={{top:10}}
                      >
                          <i className="simple-icon-magnifier" />
                      </span>
                    </div>

                    <div
                      onClick={()=> {
                        this.toggleModal();
                      }}
                      style={{cursor:'pointer',fontSize:23,color:'#000',marginLeft:10}}  
                      className={"glyph-icon iconsminds-add-user"} 
                    />
                  </div>
                </div>
                <Separator className="mb-5" />
              </Colxx>
            </Row>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card>
                  {loading && <div className='loading'/>}
                  <CardBody>
                    <ReactTable
                      data={data}
                      columns={this.columns}
                      defaultPageSize={5}
                      showPageJump={false}
                      showPageSizeOptions={false}
                      PaginationComponent={DataTablePagination}
                      className={"react-table-fixed-height"}
                    />
                  </CardBody>
                </Card>
              </Colxx>
            </Row>

            <Modal
                style={{width:400}}
                isOpen={modalOpen}
                toggle={this.toggleModal}
                wrapClassName="modal-right"
                backdrop="static"
            >
              <ModalHeader toggle={this.toggleModal}>
                New Dispatch
              </ModalHeader>
              <ModalBody>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                    <h6 style={{marginRight:20}}>Name:</h6>
                    <Input
                        style={{backgroundColor:'#eee',width:'70%'}}
                        // name="fullname"
                        // id="fullname"
                        placeholder={""}
                        value={name}
                        onChange={e => this.setState({name: e.target.value})}
                    />
                </div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                    <h6 style={{marginRight:20}}>Email:</h6>
                    <Input
                        style={{backgroundColor:'#eee',width:'70%'}}
                        // name="email"
                        // id="email"
                        placeholder={""}
                        value={email}
                        onChange={e => this.setState({email: e.target.value})}
                    />
                </div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                    <h6 style={{marginRight:20}}>Phone:</h6>
                    <Input
                        style={{backgroundColor:'#eee',width:'70%'}}
                        // name="phone"
                        // id="phone"
                        placeholder={""}
                        value={phone}
                        onChange={e => this.setState({phone: e.target.value})}
                    />
                </div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                    <h6 style={{marginRight:20}}>Location:</h6>
                    <Input
                        style={{backgroundColor:'#eee',width:'70%'}}
                        // name="location"
                        // id="location"
                        placeholder={""}
                        value={location}
                        onChange={e => this.setState({location: e.target.value})}
                    />
                </div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                    <h6 style={{marginRight:20}}>No. Plate:</h6>
                    <Input
                        style={{backgroundColor:'#eee',width:'70%'}}
                        // name="plate"
                        // id="plate"
                        placeholder={""}
                        value={numberPlate}
                        onChange={e => this.setState({numberPlate: e.target.value})}
                    />
                </div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                    <h6 style={{marginRight:20}}>License No.:</h6>
                    <Input
                        style={{backgroundColor:'#eee',width:'70%'}}
                        // name="license"
                        // id="licence"
                        placeholder={""}
                        value={driverLicenseNumber}
                        onChange={e => this.setState({driverLicenseNumber: e.target.value})}
                    />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="default" outline onClick={this.toggleModal}>
                  Cancel
                </Button>
                <Button color="primary" onClick={this.handleSubmit}>
                  Add
                </Button>{" "}
              </ModalFooter>

              <Modal
                  isOpen={confirmModal}
                  toggle={this.toggleConfirmModal}
              >
                {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
                  <div className="loading" />  
                </div>}
                  <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleConfirmModal}>
                      <Label>Confirm Dispatch </Label>
                  </ModalHeader>
                  <ModalBody>
                      <h6>Provide your <b>authorization code</b> in the box below</h6>
                      <label>
                      Authorization Code:
                      <input type="password" value={adminCode} style={{paddingBottom:10,paddingTop:10,outline:'none',width:80,marginLeft:10}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                      </label>
                  </ModalBody>
                  <ModalFooter>
                  <Button
                      color="default"
                      onClick={this.toggleConfirmModal}
                  >
                      Cancel
                  </Button>{" "}
                  <Button
                      color="primary"
                      onClick={()=> {
                        if(!adminCode){
                            NotificationManager.error(
                                "Authorization Code",
                                "Your security code required to perform this transaction. Thank you.",
                                3000,
                                null,
                                null,
                            );
                        }
                        else if(adminCode !== this.props.user.authCode) {
                            NotificationManager.error(
                                "Invalid Code",
                                "Sorry, the code you entered is invalid. Check and try again.",
                                3000,
                                null,
                                null,
                            );
                        }
                        else {
                            this.handleSave();
                        }
                      }}
                  >
                      Add Now
                  </Button>
                  
                  </ModalFooter>
              </Modal>
            </Modal>

            <Modal
              isOpen={statusModalOpen}
              toggle={this.toggleStatusModal}
            >
              {processing && <div style={{position:'absolute',top:0,left:0,right:0,bottom:0,backgroundColor:'rgba(240,240,240,.5)',zIndex:999}}>
                <div className="loading" />  
              </div>}
              <ModalHeader style={{alignItems:"center",padding:""}} toggle={this.toggleStatusModal}>
                  <img style={{width:"50px",height:"50px",borderRadius:"25px",marginRight:"10px"}} src="/assets/img/no-image.jpg" alt="IMG"/>
                  <Label style={{}}>Change Dispatch Status</Label>
              </ModalHeader>
              <ModalBody>
                {selectedDispatch.enabled ?
                  <h6 style={{marginBottom:10}}>Are you sure you want to <span style={{color:'#E74C3C'}}><b>disable</b></span> <b>{selectedDispatch.name}'s</b> account?</h6>
                  :
                  <h6 style={{marginBottom:10}}>Are you sure you want to  <span style={{color:'#2ECC71'}}><b>enable</b></span> <b>{selectedDispatch.name}'s</b> account?</h6>
                }
                <h6>Complete transaction by providing your <b>authorization code</b> in the box below</h6>
                  <label style={{}}>
                  Authorization Code:
                  <input type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
                  </label>
              </ModalBody>
              <ModalFooter>
              <Button
                  color=""
                  onClick={this.toggleStatusModal}
              >
                  Cancel
              </Button>{" "}
              <Button
                  color={selectedDispatch.enabled ? "danger":"primary"}
                  onClick={()=> {
                    if(!adminCode){
                      NotificationManager.error(
                          "Authorization Code",
                          "Your security code required to perform this transaction. Thank you.",
                          3000,
                          null,
                          null,
                      );
                    }
                    else if(adminCode !== this.props.user.authCode) {
                      NotificationManager.error(
                          "Invalid Code",
                          "Sorry, the code you entered is invalid. Check and try again.",
                          3000,
                          null,
                          null,
                      );
                    }
                    else {
                        this.handleChangeStatus(selectedDispatch);
                    }
                  }}
              >
                {selectedDispatch.enabled ? 'Disable':'Enable'} Now
              </Button>
              </ModalFooter>
          </Modal>

          </Fragment>
        )
    }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return {user};
};

export default connect(
  mapStateToProps,
  {}
)(Motors);