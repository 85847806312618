import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardText } from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import axios from "axios";

import Pagination from "../../containers/pages/Pagination";
import { Separator, Colxx } from "../../components/common/CustomBootstrap";

const users_url = 'https://api.tuatuagye.com/users';

class SearchPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      currentPage: 1,
      totalPage: 12,
      keyword: "",
      totalItemCount: 0,
      isLoading: true,
      pageSize: 10
    };
  }

  onChangePage(page) {
    this.setState(
      {
        currentPage: page
      },
      () => {
        this.dataListRender();
      }
    );
  }

  componentDidMount() {
    const path = this.props.location.pathname;
    let keyword ='';
    if(path){
     keyword = path.split('/').pop();
    }
    if(keyword.length > 0){
      this.dataListRender(keyword);
    }
    else {
      this.setState({isLoading: false})
    }

    window.searchName = (keyword) => {
      if(keyword && keyword.length > 0){
        this.setState({isLoading: true});
        this.dataListRender(keyword);
      }
    }
  }

  componentWillUnmount() {
    window.searchName = null;
  }

  dataListRender(keyword) {
    // const { pageSize, currentPage, keyword } = this.state;
    let query = '';
    let temp = keyword.trim();
    if(temp.split(' ').length > 1){
      query = `filter[where][and][0][firstname][regexp]=/${temp.split(' ')[0]}/i&filter[where][and][1][lastname][regexp]=/${temp.split(' ')[1]}/i&filter[where][and][2][type][gt]=3`
    }
    else {
      query = `filter[where][or][0][firstname][regexp]=/${temp}/i&filter[where][or][1][lastname][regexp]=/${temp}/i&filter[where][or][2][phone][regexp]=/${temp}/i&filter[where][and][3][type][gt]=3`
    }

    // console.log('q is ',query);
    axios.get(`${users_url}?${query}&filter[limit]=20`)
      // .get(
      //   `${apiUrl}?pageSize=${pageSize}&currentPage=${currentPage}&search=${keyword}`
      // )
      // .then(res => {
      //   return res.data;
      // })
      .then(res => {
        if(res.data && res.data.length > 0){
          this.setState({
            totalPage: Math.ceil(res.data.length / 10),//data.totalPage,
            items: res.data,
            totalItemCount: res.data.length,
            isLoading: false
          });
        }
        else if(temp.split(' ').length > 1) {
          query = `filter[where][and][0][firstname][regexp]=/${temp.split(' ')[1]}/i&filter[where][and][1][lastname][regexp]=/${temp.split(' ')[0]}/i&filter[where][and][2][type][gt]=3`;
          return axios.get(`${users_url}?${query}&filter[limit]=20`)
        }
        else {
          return null;
        }
      })
      .then(res=> {
        if(res){
          this.setState({
            totalPage: Math.ceil(res.data.length / 10),//data.totalPage,
            items: res.data,
            totalItemCount: res.data.length,
            isLoading: false
          });
        }
        else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  render() {
    const rowLength = this.state.items.length;
    const { isLoading, items, currentPage, totalPage } = this.state;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <h2>Search Results</h2>
            {/* <Breadcrumb heading="menu.search" match={this.props.match} /> */}
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card>
              <CardBody>
                {isLoading ? ( <div className="loading" />)
                :
                items.length === 0 ? <CardText>No results found</CardText>
                :
                (
                  items.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className={`${rowLength !== i + 1 ? "mb-3" : ""}`}
                      >
                        <NavLink to={`/app/users/${item.type === 4 ? 'merchants':'customers'}/${item.id}`} className="w-40 w-sm-100">
                          <p className="list-item-heading mb-1 color-theme-1">
                            {item.firstname} {item.lastname}
                          </p>
                          <p className="mb-1 text-muted text-small">
                            {item.type === 4 ? 'Merchant':'Customer'}
                          </p>
                          <p className="mb-4 text-small">{item.phone}</p>
                        </NavLink>
                        {rowLength !== i + 1 && <Separator />}
                      </div>
                    );
                  })
                )}
              </CardBody>
            </Card>
          </Colxx>
          {items.length > 0 && (
            <Pagination
              currentPage={currentPage}
              // totalPage={totalPage}
              // onChangePage={i => this.onChangePage(i)}
            />
          )}
        </Row>
      </Fragment>
    );
  }
}
export default withRouter(SearchPages);
