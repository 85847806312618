/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* TODOAPP*/
export const TODO_GET_LIST = "TODO_GET_LIST";
export const TODO_GET_LIST_SUCCESS = "TODO_GET_LIST_SUCCESS";
export const TODO_GET_LIST_ERROR = "TODO_GET_LIST_ERROR";
export const TODO_GET_LIST_WITH_FILTER = "TODO_GET_LIST_WITH_FILTER";
export const TODO_GET_LIST_WITH_ORDER = "TODO_GET_LIST_WITH_ORDER";
export const TODO_GET_LIST_SEARCH = "TODO_GET_LIST_SEARCH";
export const TODO_ADD_ITEM = "TODO_ADD_ITEM";
export const TODO_ADD_ITEM_SUCCESS = "TODO_ADD_ITEM_SUCCESS";
export const TODO_ADD_ITEM_ERROR = "TODO_ADD_ITEM_ERROR";
export const TODO_SELECTED_ITEMS_CHANGE = "TODO_SELECTED_ITEMS_CHANGE";

/* CHAT APP*/
export const CHAT_GET_CONTACTS = "CHAT_GET_CONTACTS";
export const CHAT_GET_CONTACTS_SUCCESS = "CHAT_GET_CONTACTS_SUCCESS";
export const CHAT_GET_CONTACTS_ERROR = "CHAT_GET_CONTACTS_ERROR";
export const CHAT_GET_CONVERSATIONS = "CHAT_GET_CONVERSATIONS";
export const CHAT_GET_CONVERSATIONS_SUCCESS = "CHAT_GET_CONVERSATIONS_SUCCESS";
export const CHAT_GET_CONVERSATIONS_ERROR = "CHAT_GET_CONVERSATIONS_ERROR";
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  "CHAT_ADD_MESSAGE_TO_CONVERSATION";
export const CHAT_CREATE_CONVERSATION = "CHAT_CREATE_CONVERSATION";
export const CHAT_SEARCH_CONTACT = "CHAT_SEARCH_CONTACT";
export const CHAT_CHANGE_CONVERSATION = "CHAT_CHANGE_CONVERSATION";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGES = "ADD_MESSAGES";
export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_ERROR = "GET_MESSAGES_ERROR";
export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION";
export const ADD_TO_MESSAGE = "ADD_TO_MESSAGE";
export const SENDING_MESSAGE = "SENDING_MESSAGE";

// NEW MERCHANTS
export const ADD_MERCHANT = "ADD_MERCHANT";
export const REMOVE_MERCHANT = "REMOVE_MERCHANT";

// NEW DELIVERIES
export const ADD_DELIVERY = "ADD_DELIVERY";
export const REMOVE_DELIVERY = "REMOVE_DELIVERY";
export const SET_DELIVERIES = "SET_DELIVERIES";

// NEW DELIVERIES
export const ADD_NAV_MESSAGE = "ADD_NAV_MESSAGE";
export const REMOVE_NAV_MESSAGE = "REMOVE_NAV_MESSAGE";

//PAYMENTS
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const SET_PAYMENTS_START_DATE = 'SET_PAYMENTS_START_DATE';
export const SET_PAYMENTS_END_DATE = 'SET_PAYMENTS_END_DATE';
export const SET_PAYMENTS_TOTAL = 'SET_PAYMENTS_TOTAL';

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./chat/actions";
export * from "./payments/actions";
export * from "./new-deliveries/actions";
export * from "./new-merchants/actions";
export * from "./new-messages/actions";
