import {
    ADD_PAYMENT,
    SET_PAYMENTS,
    SET_PAYMENTS_START_DATE,
    SET_PAYMENTS_END_DATE,
    SET_PAYMENTS_TOTAL
} from '../actions';

export const setPayments = (payload) => {
    return (
        {
            type: SET_PAYMENTS,
            payload: payload
        }
    )
}

export const setPaymentsTotal = (payload) => {
    return (
        {
            type: SET_PAYMENTS_TOTAL,
            payload: payload
        }
    )
}

export const addPayment = (payload) => {
    return (
        {
            type: ADD_PAYMENT,
            payload: payload
        }
    )
}

export const setPaymentStartDate = (payload) => {
    return (
        {
            type: SET_PAYMENTS_START_DATE,
            payload: payload
        }
    )
}

export const setPaymentEndDate = (payload) => {
    return (
        {
            type: SET_PAYMENTS_END_DATE,
            payload: payload
        }
    )
}