import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
const img_base_url = 'https://api.tuatuagye.com/get-image?type=profile&id='

const formatDate = (_date) => {
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
}

const Merchant = ({ firstname,id, lastname, createdAt, imageThumbnail }) => {
  return (
    <div className="d-flex flex-row mb-3 pb-3 border-bottom">
      <a href={`/app/users/merchants/${id}?status=new`}>
        <img
          //DEV change link formatting
          src={imageThumbnail ? `${img_base_url}${imageThumbnail}`:'/assets/img/no-image.jpg'}
          alt={'img'}
          className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
        />
      </a>
      <div className="pl-3 pr-2">
        <a href={`/app/users/merchants/${id}?status=new`}>
          <p className="font-weight-medium mb-1">{firstname} {lastname}</p>
          <p className="text-muted mb-0 text-small">{formatDate(createdAt)}</p>
        </a>
      </div>
    </div>
  );
};

const TopnavNewMerchants = ({ merchants }) => {
  return (
    <div className="position-relative d-inline-block">
      <UncontrolledDropdown className="dropdown-menu-right">
        <DropdownToggle
          className="header-icon notificationButton"
          color="empty"
        >
          <i className="iconsminds-business-man-woman" />
          {merchants.length > 0 && <span className="count new-merchant-count">{merchants.length}</span>}
        </DropdownToggle>
        <DropdownMenu
          className="position-absolute mt-3 scroll"
          right
          id="notificationDropdown"
        >
          <PerfectScrollbar
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            {merchants.map((merchant, index) => {
              return <Merchant key={index} {...merchant} />;
            })}
          </PerfectScrollbar>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default TopnavNewMerchants;
