import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label, Row, ModalFooter, Button
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";
import * as FileSaver from 'file-saver';

import ReactTable from "react-table";
import { connect } from "react-redux";

import axios from 'axios';

import DataTablePagination from "../../components/DatatablePagination";
const base_image_url = 'https://api.tuatuagye.com/get-profile-image';

const payments_url = 'https://api.tuatuagye.com/payments';
const collections_url = 'https://api.tuatuagye.com/collections';
const myProducts_url = 'https://api.tuatuagye.com/m-products';

const formatAmount = (amount) => {
  if(!amount) return '0.00';
  if(amount > 999){
    let temp = amount.toString();
    let decimal = '.00';

    if(temp.indexOf('.') > -1){
      decimal = '.'+temp.split('.')[1];
      temp = temp.split('.')[0];
    }
    
    temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
    return temp;
  }
  
  return Number(amount).toFixed(2);
}

const __getTime = (_date) => {
  // 3:16pm
  if(!_date) return '';
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  
  let strTime = hours + ':' + minutes + '' + ampm;
  
  // let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${strTime}`;
}

const __getDate = (_date) => {
  // Mar 2, 2024
  if(!_date) return '';
  const date = new Date(_date);

  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

class MerchantCollections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: props.merchant.entities || [],
      selectedPayment: {},
      selectedCollection: {},
      deleteModal: false,
      deleteText: '',
      adminCode: '',
      deleting: false,
      updateBalance: false
    }

    this.columns = [
      {
        Header: "Customer",
        accessor: "payment",
        Cell: props => <p className="list-item-heading">{props.value.payeeName}</p>
      },
      {
        Header: "Product",
        accessor: "payment",
        Cell: props => <p className="text-muted truncated">{props.value.productName}</p>
      },
      {
        Header: "Date & Time",
        accessor: "createdAt",
        width: 115,
        headerStyle:{textAlign:'center'},
        Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{__getTime(props.value)}<br/>{__getDate(props.value)}</p>
      },
      {
        Header: "Amount",
        accessor: "payment",
        headerStyle: {textAlign:'center'},
        width:80,
        Cell: props => <p className="list-item-heading" style={{textAlign:'center'}}>{formatAmount(props.value.amount)}</p>
      },
      {
        Header: "Mode",
        accessor: "payment",
        headerStyle: {textAlign:'center'},
        width:80,
        Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{props.value.mode || 'N/A'}</p>
      },
      {
        Header: "MoMo Number",
        accessor: "payment",
        width: 120,
        headerStyle:{textAlign:'center'},
        Cell: props => <p className="text-muted" style={{textAlign:'center'}}>{props.value.momoNumber || 'N/A'}</p>
      },
      {
        Header: "Action",
        accessor: "",
        width:80,
        Cell: ({ original }) => (
          <div
            onClick={()=> {
              // console.log('or',original);
              this.setState({selectedCollection: original, selectedPayment: original.payment, deleteModal: true})
            }}
            style={{color:'red',cursor:'pointer',fontSize:16}} 
            className={"glyph-icon simple-icon-trash ml-1"} 
          />
        )
      }
    ]
  }

  toggleDeleteModal = () => {
    this.setState({deleteModal: !this.state.deleteModal})
  }

  deleteCollection = () => {
    const { collections, selectedPayment, selectedCollection, updateBalance } = this.state;
    if(selectedCollection && selectedCollection.id){
      this.setState({deleting: true});
      axios.get(`${payments_url}/${selectedCollection.paymentId}`)
        .then(res=> {
          if(res.data.id){
            return axios.delete(`${payments_url}/${selectedCollection.paymentId}`)
          }
          else {
            return null;
          }
        })
        .then(res=> {
          if(res){
            return axios.delete(`${collections_url}/${selectedCollection.id}`)
          }
          else {
            return null;
          }
        })
        .then(res=> {
          if(res && updateBalance){
            return axios.get(`${myProducts_url}/${selectedCollection.customerProductId}`)
          }
          else {
            return null;
          }
        })
        .then(res=> {
          if(res && res.data.id){
            const myProduct = res.data;
            const balance = Number(myProduct.amountPaid) - Number(selectedPayment.amount);
            const update = {
              amountPaid: Number(balance),
              percentageCompleted: (Number(balance) / Number(myProduct.currentPrice)) * 100,
              status: 1
            }
            
            return axios.patch(`${myProducts_url}/${selectedCollection.customerProductId}`, update)
          }
          else {
            return null;
          }
        })
        .then(res=> {
          const temp = collections.filter(col=> col.id !== selectedCollection.id);
          NotificationManager.success(
            "Collection Deleted",
            "You successfully deleted a collection for: "+this.props.merchant.name,
            3000,
            null,
            null,
          );
          this.setState({collections: temp, deleting: false});
          this.toggleDeleteModal();
        })
        .catch(err=> {
          this.setState({deleting: false});
          NotificationManager.error(
            "Processing Failed",
            "An unknown error occurred. Please try again. Thank you.",
            3000,
            null,
            null,
          );
        })
    }
    else {
      NotificationManager.error(
        "Processing Failed",
        "An unknown error occurred. Please try again. Thank you.",
        3000,
        null,
        null,
      );
    }
  }

  exportToExcel = () => {
    const data = this.state.collections.map((record, i)=> {
      return {
        Customer: record.payment ? record.payment.payeeName : 'N/A',
        Product: record.payment ? record.payment.productName : 'N/A',
        'Date & Time': `${__getTime(record.createdAt)} | ${__getDate(record.createdAt)}`,
        Amount: record.payment ? formatAmount(record.payment.amount) : 'N/A',
        Mode: record.payment ? record.payment.mode : 'N/A',
        'MoMo Number': record.payment ? record.payment.momoNumber : 'N/A',
      }
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, `${this.props.merchant.name} Collections Data - ${new Date().toJSON()}`);
    });
  }

  saveAsExcelFile = (buffer, fileName) => {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  render(){
    const { merchant, modalOpen, toggleModal } = this.props;
    const { deleting, deleteModal, deleteText, collections, updateBalance, selectedPayment, adminCode } = this.state;
    return (
      <Modal
        style={{width:750}}
        isOpen={modalOpen}
        toggle={toggleModal}
        wrapClassName="modal-right"
      >
        <ModalHeader style={{alignItems:"center"}} toggle={toggleModal}>
          <img 
            style={{width:"60px",height:"60px",borderRadius:"30px",marginRight:"10px"}} 
            src={`${base_image_url}?type=main&id=${merchant.id}`} 
            />
          <Label>{merchant.name}'s Collections </Label>
        </ModalHeader>
        <ModalBody style={{padding:"0px"}}>
          <div style={{display:'flex', alignItems:'center',paddingLeft:20,paddingTop:20}}> 
            <h5 style={{color:'#008894'}}>
              Total GHS {formatAmount(this.props.merchant.total)}
            </h5>

            <div
              onClick={()=> {
                this.exportToExcel();
              }} 
              className="d-flex justify-content-end" style={{flex:1,cursor:"pointer",color:'#008894'}}>
              Download to Excel 
              <div style={{marginLeft:5,marginTop:3}} className={"glyph-icon simple-icon-download"}/>
            </div>
          </div>
          <Colxx xxs="12">
            <ReactTable
                data={collections}
                columns={this.columns}
                defaultPageSize={20}
                // filterable={true}
                showPageJump={true}
                PaginationComponent={DataTablePagination}
                showPageSizeOptions={true}
            />
          </Colxx>
        </ModalBody>

        {/* DELETE */}
        <Modal
          isOpen={deleteModal}
          toggle={()=> {
            if(!deleting){
              this.toggleDeleteModal();
            }
          }}
          // className={props.className}
        >
          <ModalHeader 
            toggle={()=> {
              if(!deleting){
                this.toggleDeleteModal();
              }
            }}
            style={{alignItems:"center",padding:""}} 
          >
            <Label>
              Delete {merchant.name}'s Collection 
            </Label>
          </ModalHeader>
          <ModalBody>
            <h6>To delete this collection - <b>GHS {Number(selectedPayment.amount).toFixed(2)}</b> - type <b>permanently delete</b> in the box below and provide your authorization code as well.</h6>
            <Row style={{paddingLeft:16,marginTop:20}}>
              <input
                disabled={deleting} 
                type="text" 
                value={deleteText} 
                style={{outline:'none',height:45}} 
                onChange={(event)=> {
                  this.setState({deleteText: event.target.value});
                }}
              />

              <label style={{marginLeft:20}}>
                Authorization Code:
                <input disabled={deleting} type="password" value={adminCode} style={{marginLeft:20,paddingBottom:10,paddingTop:10,outline:'none',width:80}} onChange={(event)=> this.setState({adminCode: event.target.value})} />
              </label>
            </Row>
            <input style={{marginTop:20}} type="checkbox" checked={updateBalance} onChange={(event)=> this.setState({updateBalance: !updateBalance})}/> <span style={{cursor:'pointer',fontSize:18,color:'#008894'}} onClick={(event)=> this.setState({updateBalance: !updateBalance})}>Update customer product balance?</span>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={deleting}
              color="default"
              onClick={this.toggleDeleteModal}
            >
              Cancel
            </Button>{" "}
            <Button
              disabled={deleting}
              color="danger"
              onClick={()=> {
                if(deleteText !== 'permanently delete'){
                  NotificationManager.error(
                    "Incorrect input",
                    "Review your input and try again",
                    3000,
                    null,
                    null,
                );
                }
                else if(!adminCode){
                    NotificationManager.error(
                        "Authorization Code",
                        "Your security code required to perform this transaction. Thank you.",
                        3000,
                        null,
                        null,
                    );
                }
                else if(adminCode !== this.props.user.authCode) {
                    NotificationManager.error(
                        "Invalid Code",
                        "Sorry, the code you entered is invalid. Check and try again.",
                        3000,
                        null,
                        null,
                    );
                }
                else {
                  this.deleteCollection();
                }
              }}
            >
              {deleting ? 'Please wait...' : 'Delete Now'}
            </Button>
            
          </ModalFooter>
        </Modal>

      </Modal>
    );
  }
};

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return {user};
};

export default connect(
  mapStateToProps,
  {}
)(MerchantCollections);