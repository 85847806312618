import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { NavLink } from "react-router-dom";

const TopnavEasyAccess = () => {
  return (
    <div className="position-relative d-none d-sm-inline-block">
      <UncontrolledDropdown className="dropdown-menu-right">
        <DropdownToggle className="header-icon" color="empty">
          <i className="simple-icon-grid" />
        </DropdownToggle>
        <DropdownMenu
          className="position-absolute mt-3"
          right
          id="iconMenuDropdown"
        >
          <NavLink to="/app/user-products" className="icon-menu-item">
            <i className="iconsminds-scale d-block" />{" "}
            User Prod.
          </NavLink>

          <NavLink to="/app/collections-map" className="icon-menu-item">
            <i className="iconsminds-map-marker-2 d-block" />{" "}
            Map
          </NavLink>
          <NavLink to="/app/deliveries" className="icon-menu-item">
            <i className="iconsminds-basket-coins d-block" />{" "}
            Deliveries
          </NavLink>
          {/* <NavLink to="/app/applications/chat" className="icon-menu-item">
            <i className="iconsminds-speach-bubble-4 d-block" />{" "}
            Chat
          </NavLink> */}
          <NavLink to="/app/users/customers" className="icon-menu-item">
            <i className="iconsminds-business-mens d-block" />{" "}
            Customers
          </NavLink>
          <NavLink to="/app/products" className="icon-menu-item">
            <i className="iconsminds-data-center d-block" />{" "}
            Products
          </NavLink>
          <NavLink to="/app/dashboard" className="icon-menu-item">
            <i className="iconsminds-home-3 d-block" />{" "}
            Dashboard
          </NavLink>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default TopnavEasyAccess;
