import React, { Component, Fragment } from "react";
import {
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  Button,
  TabContent,
  TabPane,
} from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import axios from 'axios';
import ReactTable from "react-table";

import classnames from "classnames";

import { Colxx } from "../../../../components/common/CustomBootstrap";
import DataTablePagination from "../../../../components/DatatablePagination";


const dispatches_url = 'https://api.tuatuagye.com/dispatches'
const deliveries_url = 'https://api.tuatuagye.com/deliveries'

class DispatchDetails extends Component {
  constructor(props) {
    super(props);

    this.toggleTab = this.toggleTab.bind(this);
    this.state = {
      activeFirstTab: "1",
      loadingDetails: true,
      details: null,
      deliveries: [],
      messages:[],
      loadingDeliveries: true
    };
  }

  componentDidMount() {

    const id = this.props.location.pathname.split('/').pop();
    axios.get(`${dispatches_url}/${id}`)
      .then(res => {
        if(res.data){
          this.setState({details: res.data, loadingDetails: false});
          return axios.get(`${deliveries_url}?filter[include][]=product&filter[where][dispatchId]=${id}&filter[order]=createdAt desc`)
        }
        else {
          this.setState({loadingDetails:false})
          return null;
        }
      })
      .then(res => {
        if(res && res.data){
          this.setState({deliveries: res.data, loadingDeliveries: false});
        }
        // return axios.get(`${users_url}/messages`)
      })
    //   .then(res => {
    //     if(res && res.data){
    //       this.setState({messages: res.data});
    //     }
    //   })
      .catch(error => {
        this.setState({loadingDetails: false});
        alert('Sorry, an unknown error occurred.');
        console.log('error...',error);
      })
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }

  formatDate = (_date) => {
    // 3:16pm | Mar 2 2021
    if(!_date) return '';
    const date = new Date(_date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let strTime = hours + ':' + minutes + '' + ampm;
    
    let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    
    return `${strTime} | ${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  }

  formatAmount = (amount) => {
    if(!amount) return '0.00';
    if(amount > 999){
      let temp = amount.toString();
      let decimal = '.00';

      if(temp.indexOf('.') > -1){
        decimal = '.'+temp.split('.')[1];
        temp = temp.split('.')[0];
      }
      
      temp = `${temp.slice(0,temp.length-3)},${temp.slice(temp.length-3)}${decimal}`
      return temp;
    }
    
    return Number(amount).toFixed(2);
  }


  render() {
    const { details, loadingDetails, loadingDeliveries, deliveries } = this.state;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <h1>Dispatch Details</h1>
            {/* <div style={{paddingTop:10}} className="float-sm-right mb-2">
                <Button outline color={"primary"} className="icon-button">
                    <i className="simple-icon-speech" />
                </Button>
            </div> */}

            <Nav tabs className="separator-tabs ml-0 mb-5">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeFirstTab === "1",
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggleTab("1");
                  }}
                  to="#"
                >
                  Details
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeFirstTab}>
              <TabPane tabId="1">
                {loadingDetails && <div className='loading'/>}
                {details && 
                  <Row>
                    <Colxx xxs="12" lg="3" className="mb-4">
                      <Card className="mb-4">
                          <img
                              src="/assets/img/no-profile-image.jpeg"
                              alt="Detail"
                              className="card-img-top"
                          />

                          <CardBody>
                              <p className="text-muted text-small mb-2">
                              Name
                              </p>
                              <h6 className="mb-3">
                                {details.name}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              Phone
                              </p>
                              <h6 className="mb-3">
                                {details.phone}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              Email
                              </p>
                              <h6 className="mb-3">
                                {details.email}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              Location
                              </p>
                              <h6 className="mb-3">
                                {details.location || 'N/A'}
                              </h6>

                              {/* <p className="text-muted text-small mb-2">
                              Last Seen
                              </p>
                              <h6 className="mb-3" style={{color: details.lastSeen === 'Online' ? '#2ECC71':'#4d4d4d'}}>
                                {this.formatDate(details.lastSeen)}
                              </h6> */}

                              <p className="text-muted text-small mb-2">
                              Number Plate
                              </p>
                              <h6 className="mb-3">
                                {details.numberPlate}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              License No.
                              </p>
                              <h6 className="mb-3">
                                {details.driverLicenseNumber}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              Date Joined
                              </p>
                              <h6 className="mb-3">
                                {this.formatDate(details.createdAt)}
                              </h6>

                              <p className="text-muted text-small mb-2">
                              Status
                              </p>
                              <h6 className="mb-3" style={{color: details.enabled ? '#2ECC71':'#E74C3C'}}>
                                {details.enabled ? 'ENABLED':'DISABLED'}
                              </h6>

                              {/* <p className="text-muted text-small mt-2 mb-2">
                              TTG Cash Bal.
                              </p>
                              <h6 className="mb-3" style={{color:'#008894'}}>
                              GH₵ {this.formatAmount(details.ttgCash)}
                              </h6> */}
                          </CardBody>
                      </Card>
                    </Colxx>

                    <Colxx xxs="12" lg="9">
                        <Card className="mb-4">
                            <CardBody>
                              {loadingDeliveries && <div className='loading'/>}
                                <ReactTable
                                    data={deliveries}
                                    columns={[
                                        {
                                            Header: "Customer",
                                            accessor: "buyerName",
                                            Cell: props => <span className="truncate" style={{fontSize:16}}>{props.value}</span>
                                        },
                                        {
                                            Header: "Product",
                                            accessor: "productName",
                                            Cell: props => <p style={{paddingTop:0}} className="text-muted truncate">{props.value}</p>
                                        },
                                        {
                                            Header: "Quantity",
                                            accessor: "quantity",
                                            headerStyle:{textAlign:'center'},
                                            width:80,
                                            Cell: props => <p className="text-muted"  style={{paddingTop:0,textAlign:'center'}}>{props.value}</p>
                                        },
                                        {
                                            Header: "Date Assigned",
                                            accessor: "dateAssigned",
                                            width:200,
                                            Cell: props => <p className="text-muted"  style={{paddingTop:0}}>{this.formatDate(props.value)}</p>
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "status",
                                            width:110,
                                            Cell: props => <div style={{paddingTop:0}}><span style={{backgroundColor: props.value === 1 ? '#B7950B' : props.value === 2 ? '#2E86C1' : '#117A65',color:'#fff',paddingRight:7,paddingLeft:7,paddingTop:5,paddingBottom:5,borderRadius:4}}>{props.value === 1 ? 'Pending' : props.value === 2 ? 'Shipped' : 'Delivered'}</span></div>
                                        },
                                        {
                                            Header: "Code",
                                            accessor: "code",
                                            headerStyle:{textAlign:'center'},
                                            width:80,
                                            Cell: props => <p className="text-muted"  style={{paddingTop:0,textAlign:'center'}}>{props.value}</p>
                                        },
                                    ]}
                                    defaultPageSize={5}
                                    showPageJump={false}
                                    showPageSizeOptions={false}
                                    PaginationComponent={DataTablePagination}
                                    className={"react-table-fixed-height"}
                                />
                            </CardBody>
                        </Card>
                    </Colxx>
                  </Row>
                }
              </TabPane>
              {/* <TabPane tabId="2">
                <Products data={products} user={details}/>
              </TabPane> */}
            </TabContent>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}
export default withRouter(DispatchDetails);
